import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {
  useMediaQuery,
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  Chip,
  Grid,
  SwipeableDrawer,
  Avatar,
  Icon,
  IconButton,
  MenuItem,
  Button,
  GridList,
  GridListTile,
  GridListTileBar,
  Divider,
  ListItemText,
  CardActionArea,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Fade from "@material-ui/core/Fade";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { Alert, AlertTitle } from "@material-ui/lab";
import Footer from "./components/Footer";
import AppAppBar from "./components/AppAppBar";

import NewsServices from "../../services/NewsServices";
import Util from "../../services/Util";
import * as appconst from "../../appconst";

import { withStyles, useTheme } from "@material-ui/core/styles";
import { keyBy } from "lodash";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import { englishLanguage } from "../languages/englishLanguage";
import { sinhalaLanguage } from "../languages/sinhalaLanguage";
import { tamilLanguage } from "../languages/tamilLanguage";

import languageService from "app/services/languageService";
import DateRangeIcon from "@material-ui/icons/DateRange";

function MyComponent() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return <span>{`theme.breakpoints.up('sm') matches: ${matches}`}</span>;
}

const styleSheet = (theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridroot: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: "#f5f5f5",
    width: "100%",
  },
  gridList: {
    // overflow: "unset",
    // height:500,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },

  mainPadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 70,
      paddingRight: 70,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  titlePadding: {
    [theme.breakpoints.down("md")]: {
      marginLeft: 15,
    },
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  // newsImage: {
  //     width: 90,
  //     height: 60,
  //     paddingRight: theme.spacing(1),
  // },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },

  //Main News Image
  featurednewsImage: {
    width: "100%",
    height: 400,
    borderRadius: 6,

    // padding: 18,
    boxShadow:
      " 0 8px 20px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",

    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      height: 230,
    },
    // paddingRight: theme.spacing(1),
  },

  mainNewsTitle: {
    margin: "5px 0 ",
    fontWeight: 700,
    color: "#000",
    fontSize: 18,

    // [theme.breakpoints.down("md")]: {
    //   marginLeft: 17,
    //   marginRight: 15,
    // },
  },
  //Main news Title and Descrption Grid
  mainNewsTitleGrid: {
    // marginTop: 8,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: 0,
    },
  },
  divider: {
    backgroundColor: "white",
    margin: "10px -33px 10px 0",
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
  },
  mainNewDescription: {
    marginTop: -8,
    marginBottom: 10,
    fontWeight: 100,
    color: "#000",
    // margin: "10px -33px 10px 0",
    // [theme.breakpoints.down("md")]: {
    //   margin: 20,
    // },
  },
  newsGridList: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  //News list item Styles
  listItem: {
    cursor: "pointer",
    // backgroundColor: "#1f1f2e",
    color: "black",
    // boxShadow:
    //   " 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",
    borderRadius: 10,
    paddingRight: "unset",
    margin: "5px 20px 5px 22px",
    width: "100%",
    transition: "all ease 200ms",
    "&:hover": {
      color: "acent",
    },
  },
  listItemTitle: {
    cursor: "pointer",
    margin: "5px 0 2px 0",
    fontSize: 20,
    fontWeight: 700,
    color: "black",
    //fontFamily: "Source Sans Pro",
  },
  listItemSubTitle: {
    cursor: "pointer",
    fontWeight: 400,
    color: "#808080",
  },

  //List news Image
  newsImage: {
    width: 100,
    height: 83,
    borderRadius: 6,
    marginLeft: -38,
    marginRight: 10,
  },

  imageContainer: {
    position: "relative",
  },
  btnMoreOverlay: {
    borderRadius: 6,
    position: "absolute",
    top: 0,
    left: 0,
    background: "#02b0f5",

    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
    transition: "opacity 0.25s",
    color: "white",

    "&:hover": {
      opacity: 0.9,
    },
    "&:hover > *": {
      transform: "translateY(0)",
    },
  },
  btnMore: {
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    color: "#ffff",
    border: "1px solid #ffff",
    padding: "10px 20px",
    transform: "translateY(20px)",
    transition: "transform 0.25s",
  },
  chipLable: {
    backgroundColor: "#02b0f5",

    "& .MuiChip-label": {
      // paddingTop: 4,
      color: "white",

    },
  },
  pageTitlePadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 116,
      paddingTop: 60,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 30,
      paddingTop: 60,
    },
  },


});

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: englishLanguage,
      windowWidth: null,
      news: [],
      pagination: {
        current_page: 1,
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        next_page_url: "",
        path: "",
        per_page: 10,
        to: 0,
        total: 0,
      },
      filterData: {
        // reference_number: null,
        published: "1",
      },
      errorTitle: "",
      error: "",
      errorDialog: false,
    };
  }

  async loadLanguageDetails() {
    var lng = await languageService.getLanguageDetails();
    // console.log("language",lng);

    if (lng == "Sinhala") {
      this.setState({
        language: sinhalaLanguage,
      });
    } else if (lng == "Tamil") {
      this.setState({
        language: tamilLanguage,
      });
    }
  }

  async componentDidMount() {
    this.loadLanguageDetails();

    this.loadData(1);
    this.setState({
      windowWidth: window.innerWidth,
    });

    window.addEventListener("resize", () => {
      this.setState({
        windowWidth: window.innerWidth,
      });
    });
  }

  async loadData(page) {
    let filter = this.state.filterData;
    let news = await NewsServices.getNewswithFilters(filter, page);
    console.log("News", news);

    // console.log("pagination",news.data.news)

    if (news.status == 200) {
      this.setState({
        news: news.data.news.data,
        pagination: news.data.news,
      });
    } else if (news.status == 205) {
      this.setState({
        news: [],
        errorTitle: "Wrong Reference Number",
        error:
          "You entered reference number is not there please enter correct reference number.",
        errorDialog: true,
      });
    } else if (news.status == 206) {
      this.setState({
        news: [],
        errorTitle: "Not Published",
        error: "Your incident is under the review. Please try again later.",
        errorDialog: true,
      });
    }
  }

  finishDialogBoxRefClose = () => {
    this.setState({
      errorDialog: false,
    });
  };

  getGridListCols() {
    let width = this.state.windowWidth;
    var noOfItems = this.state.news.length;

    // console.log(width)

    if (width > 1250) {
      if (noOfItems > 3) {
        return 4;
      } else if (noOfItems > 2) {
        return 3;
      } else if (noOfItems > 1) {
        return 2;
      } else if (noOfItems == 1) {
        return 1;
      }
    } else if (width > 930) {
      if (noOfItems > 2) {
        return 3;
      } else if (noOfItems > 1) {
        return 2;
      } else if (noOfItems == 1) {
        return 1;
      }
    } else if (width > 600) {
      if (noOfItems > 2) {
        return 2;
      } else if (noOfItems == 1) {
        return 1;
      }
    } else if (width < 600 && noOfItems > 0) {
      return 1;
    }
  }

  setReferenceNo(val) {
    var filterData = this.state.filterData;
    filterData["reference_number"] = val;
    this.setState({ filterData });
  }

  // async searchByReference() {
  //     this.loadData(1);
  // }
  // handleChangePage = async (event,page) => {
  //     console.log(page)
  //     this.loadData(newPage + 1);
  // }

  render() {
    const { classes, theme } = this.props;

    return (
      <Fragment>
        {/* App bar component */}
        <AppAppBar />
        <div
          className={classes.pageTitlePadding}
          style={{
            backgroundImage: `url(/assets/images/statBackground.png)`,
            // backgroundImage: `url(/assets/images/incidents_slide_image.jpg)`,
            // background: "linear-gradient(45deg, #ff0081, #6827b0)",
            width: "100%",
            height: 160,
            backgroundSize: "cover",
            display: "flex",
            // justifyContent: "center",
            float: "left",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            style={{ fontSize: 34, color: "white", fontWeight: 600 }}
          >
            {/* News */}
            {this.state.language.newsPage_Title}
          </Typography>
        </div>
        <Grid
          container
          spacing={1}
          className={[classes.mainPadding, "py-10"]}
          style={{ backgroundColor: "white" }}
        >
          {/* Grid that contain both news grids */}
          <Grid
            // className={classes.mainPadding}
            direction="row"
            container
            justifyContent="space-around"
          >
            {/* Main news list(LARGE) by shammika */}
            <Grid
              item
              container
              className={classes.resPad}
              xs={12}
              md={7}
              style={{
                backgroundColor: "white",
              }}
            >
              {/* <div
                className={classes.titlePadding}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",

                  fontSize: 22,
                  fontWeight: 700,

                }}
              >
                <span>All News</span>
                <ArrowRightIcon />
              </div> */}

              {/* Grid that contain Main Image and News Header */}
              {this.state.news.map((row, key) => (
                <Grid
                  item
                  onClick={() => {
                    window.location.href = "/news/" + row.id;
                  }}
                  key={key}
                  container
                  xs={12}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#ffff",
                    marginTop: 10,
                    marginBottom: 10,
                    borderRadius: 10,
                  }}
                  className={classes.resPad}
                >
                  <Grid container justifyContent="space-around">
                    <Grid item xs={12}>
                      <div className={classes.imageContainer}>
                        {row.attachments == "[]" || row.attachments == null ? (
                          <div
                            className={classes.featurednewsImage}
                            style={{
                              backgroundImage:
                                "url(/assets/images/default_incident.png)",
                              backgroundSize: "cover",
                              backgroundPosition: 'center'
                            }}
                          ></div>
                        ) : (
                          <div
                            className={classes.featurednewsImage}
                            style={{
                              backgroundImage: `url(` + appconst.ATTACHMENTS_LOCATION + "news/" + JSON.parse(row.attachments)[0] + `)`,
                              backgroundSize: "cover",
                              backgroundPosition: 'center'
                            }}
                          ></div>

                          // <img
                          //   alt="newsImg"
                          //   src={
                          //     appconst.ATTACHMENTS_LOCATION +
                          //     "news/" +
                          //     JSON.parse(row.attachments)[0]
                          //   }
                          //   className={classes.featurednewsImage}
                          // />
                        )}
                        <div className={classes.btnMoreOverlay}>
                          <button
                            onClick={() => {
                              window.location.href = "/news/" + row.id;
                            }}
                            className={classes.btnMore}
                          >
                            Read More
                            {/* {this.state.language.newsPage_Button} */}
                          </button>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} style={{ margin: "14px 0 -4px" }}>
                      {/* Main News Title for Large Screens */}
                      <Grid item className={classes.mainNewsTitle}>
                        <Grid
                          item
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Chip
                            className={["mr-3 p-3", classes.chipLable]}
                            display="inline"
                            size="small"
                            label="Published"
                          // label={this.state.language.newsPage_published_Txt}//"Published"
                          />

                          <Typography
                            display="inline"
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {Util.getParsedDate(row.created_at)}
                          </Typography>
                        </Grid>
                        <Grid item className={classes.mainNewsTitle}>
                          <Typography
                            variant="body2"
                            component="p"
                            className="py-1"
                            style={{ fontWeight: 700, fontSize: 23 }}
                          >
                            {String(row.title).length > 100
                              ? (row.title + " ")
                                .toUpperCase()
                                .substring(0, 100)
                                .concat("...")
                              : row.title.toUpperCase()}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item className={classes.mainNewDescription}>
                        <Typography
                          variant="p"
                          component="p"
                          gutterBottom
                          className={classes.listItemSubTitle}
                          align="justify"
                        >
                          {String(row.description).length > 170
                            ? row.description.substring(0, 170).concat("...")
                            : row.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            {/* News List by shammika */}
            <Grid
              container
              className={[classes.resPad, classes.newsGridList]}
              xs={12}
              md={4}
              style={{
                backgroundColor: "white",
              }}
              alignItems="flex-start"
            >
              <Grid container>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexWrap: "wrap",

                    fontSize: 22,
                    fontWeight: 700,
                  }}
                >
                  <span>
                    Latest News
                    {/* {this.state.language.newsPage_latestText} */}
                  </span>
                  <ArrowRightIcon style={{ fontSize: 33 }} />
                </div>
                <GridList cols={1} className={classes.gridList}>
                  {this.state.news.map((row, key) =>
                    key <= 3 ? (
                      <Grid
                        key={key}
                        style={{
                          height: "fitContent",
                          padding: "0 38px 0 0 ",
                          margin: "5px 0"
                        }}
                      >
                        {console.log(row)}
                        <ListItem
                          onClick={() => {
                            window.location.href = "/news/" + row.id;
                          }}
                          button
                          className={classes.listItem}
                        >
                          {row.attachments == "[]" ||
                            row.attachments == null ? (
                            <div
                              className={classes.newsImage}
                              style={{
                                backgroundImage: "url(/assets/images/default_incident.png)",
                                backgroundSize: "cover",
                                backgroundPosition: 'center'
                              }}
                            ></div>
                          ) : (
                            // <div
                            //   className={classes.newsImage}
                            //   style={{
                            //     backgroundImage:
                            //       "url(/assets/images/default_incident.png)",
                            //     backgroundSize: "500px",
                            //   }}
                            // ></div>
                            // <img
                            //   alt="incidentImg"
                            //   src="/assets/images/default_incident.png"
                            //   className={classes.newsImage}
                            // />
                            <div
                              className={classes.newsImage}
                              style={{
                                backgroundImage: `url(` + appconst.ATTACHMENTS_LOCATION + "news/" + JSON.parse(row.attachments)[0] + `)`,
                                backgroundSize: "cover",
                                backgroundPosition: 'center'
                              }}
                            ></div>
                            // <img
                            //   alt="newsImg"
                            //   src={
                            //     appconst.ATTACHMENTS_LOCATION +
                            //     "news/" +
                            //     JSON.parse(row.attachments)[0]
                            //   }
                            //   className={classes.newsImage}
                            // />
                          )}
                          <Grid item xs={12}>
                            <Grid
                              item
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Chip
                                className={["mr-3", classes.chipLable]}
                                display="inline"
                                size="small"
                                label="Published"
                              // label={this.state.language.newsPage_published_Txt} //"Published"
                              />

                              <Typography
                                display="inline"
                                variant="body2"
                                color="textSecondary"
                                component="p"
                              >
                                {Util.getParsedDate(row.created_at)}
                              </Typography>
                            </Grid>
                            <Typography
                              variant="p"
                              component="h6"
                              // gutterBottom
                              className={classes.listItemTitle}
                              style={{ fontSize: 16 }}
                            >
                              {String(row.title).length > 20
                                ? (row.title + " ")
                                  .toUpperCase()
                                  .substring(0, 30)
                                  .concat("...")
                                : row.title.toUpperCase()}
                            </Typography>
                            <Typography
                              variant="p"
                              component="p"
                              gutterBottom
                              className={classes.listItemSubTitle}
                            >
                              {String(row.description).length > 40
                                ? row.description.substring(0, 40).concat("...")
                                : row.description}
                            </Typography>
                          </Grid>
                        </ListItem>
                        <Divider></Divider>
                      </Grid>
                    ) : (
                      <Grid></Grid>
                    )
                  )}
                  {/* ))} */}
                </GridList>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className="p-8" justify="flex-end">
            <Pagination
              count={Number(this.state.pagination.last_page)}
              //rowsPerPage={Number(this.state.pagination.per_page)}
              page={this.state.pagination.current_page}
              onChange={this.handleChangePage}
              variant="outlined"
              color="primary"
            />
          </Grid>
        </Grid>

        <Dialog
          open={this.state.errorDialog}
          onClose={() => {
            this.finishDialogBoxRefClose();
          }}
        >
          <DialogContent>
            <Grid item xs={12} sm={6}></Grid>
            <Alert severity="error">
              <strong>{this.state.errorTitle}</strong>
              <p>{this.state.error}</p>
            </Alert>
          </DialogContent>

          <DialogActions></DialogActions>
        </Dialog>

        {/* Footer */}
        <Footer />
      </Fragment>
    );
  }
}

export default withStyles(styleSheet)(News);
