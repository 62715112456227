import LanguageSelect from "./LanguageSelect";
import HommePage from "./HomePage";
import HomePage from "./HomePage";
import ContactUs from "./ContactUs";
import Incidents from "./Incidents";
import Stories from "./Stories";
import AboutUs from "./AboutUs";
import PrivacyPolicy from "./PrivacyPolicy";
import Disclaimer from "./Disclaimer";
import FaQPage from "./FaQPage";
import Statistics from './Statistics';
import MapPage from './MapPage';
import ViewNews from "./components/ViewNews";
import ViewIncident from "./components/ViewIncident";
import News from "./News";
import SignIn from "../sessions/SignIn";
import SignUp from "../sessions/SignUp";
import ForgotPassword from "../sessions/ForgotPassword";
import ReportElectionIncident from '../publicPages/components/reportInsidentComponents/ReportElectionIncident'
import ReportIncident from '../publicPages/components/reportInsidentComponents/ReportIncident'
import ShareStoryComponent from '../publicPages/components/ShareStoryComponent'
import CountDown from "./CountDown";
import CommingSoon from "./CommingSoon";

const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false
    },
    leftSidebar: {
      show: false,
      mode: "close"
    }
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: true
    },
    navbar: { show: false }
  },
  secondarySidebar: { show: false },
  footer: { show: false }
};

const publicRoutes = [
  {
    path: "/session/signup/:id",
    component: SignUp,
    settings
  },
  {
    path: "/session/signin",
    component: SignIn,
    settings
  },
  {
    path: "/session/forgot-password",
    component: ForgotPassword,
    settings
  },
  {
    path: "/languageSelect",
    component: LanguageSelect,
    settings
  }, {
    path: "/home",
    component: HomePage,
    settings
  },
  {
    path: "/contact_us",
    component: ContactUs,
    settings
  },
  {
    path: "/incidents",
    exact: true,
    component: Incidents,
    settings
  },
  {
    path: "/incidents/:id",
    component: ViewIncident,
    settings
  },
  {
    path: "/stories",
    component: Stories,
    settings
  },
  {
    path: "/aboutUs",
    component: AboutUs,
    settings
  },
  {
    path: "/privacyPolicy",
    component: PrivacyPolicy,
    settings
  },
  {
    path: "/disclaimer",
    component: Disclaimer,
    settings
  },
  {
    path: "/faq",
    component: FaQPage,
    settings
  },
  {
    path: "/statistics",
    component: Statistics,
    settings
  },
  {
    path: "/incidentMap",
    component: MapPage,
    settings
  },
  {
    path: "/news",
    exact: true,
    component: News,
    settings
  },
  {
    path: "/news/:id",
    component: ViewNews,
    settings
  },
  {
    path: "/shareStory",
    component: ShareStoryComponent,
    settings
  },
  {
    path: "/reportCorruptionIncident",
    component: ReportIncident,
    settings
  },
  {
    path: "/reportElectionIncident",
    component: ReportElectionIncident,
    settings
  },
  /*  {
     path: "/launch",
     component: CountDown,
     settings
   },
   {
     path: "/comingSoon",
     component: CommingSoon,
     settings
   },
  */


];

export default publicRoutes;
