import React, { Component, Fragment, useEffect } from "react";
import {
    Grid,
    Card,
    Button,
    Typography,
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel,
    RadioGroup,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText
} from "@material-ui/core";

import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { SimpleCard } from "matx";
import Box from '@material-ui/core/Box';
import { withStyles } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import * as appVariables from '../../../../appVariables';
import ReactEcharts from "echarts-for-react";
import { themeColors } from "app/MatxLayout/MatxTheme/themeColors";


import { englishLanguage } from '../../../languages/englishLanguage';
import { sinhalaLanguage } from '../../../languages/sinhalaLanguage';
import { tamilLanguage } from '../../../languages/tamilLanguage';

import languageService from "app/services/languageService";
import * as appconst from './../../../../appconst';
import StatisticsServices from '../../../../services/StatisticsServices';
/* data:[
    {
        "value": 600.58,
        "name": "Data Point 1",
        "itemStyle": {
            "normal": {
                "color": "#f845f1"
            }
        }
    },
    {
        "value": 1100.58,
        "name": "Data Point 2",
        "itemStyle": {
            "normal": {
                "color": "#ad46f3"
            }
        }
    },
    {
        "value": 1200.58,
        "name": "Data Point 3",
        "itemStyle": {
            "normal": {
                "color": "#5045f6"
            }
        }
    },
    {
        "value": 1300.58,
        "name": "Data Point 4",
        "itemStyle": {
            "normal": {
                "color": "#4777f5"
            }
        }
    },
    {
        "value": 1400.58,
        "name": "Data Point 5",
        "itemStyle": {
            "normal": {
                "color": "#44aff0"
            }
        }
    },
    {
        "value": 1500.58,
        "name": "Data Point 6",
        "itemStyle": {
            "normal": {
                "color": "#45dbf7"
            }
        }
    },
    {
        "value": 1500.58,
        "name": "Data Point 7",
        "itemStyle": {
            "normal": {
                "color": "#f6d54a"
            }
        }
    },
    {
        "value": 1600.58,
        "name": "Data Point 8",
        "itemStyle": {
            "normal": {
                "color": "#f69846"
            }
        }
    },
    {
        "value": 1800,
        "name": "Data Point 9",
        "itemStyle": {
            "normal": {
                "color": "#ff4343"
            }
        }
    }
] */
class AllIncidentChart extends Component {
    //scrollbar-container
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            language: englishLanguage,
            languageName: 'English',
        }

    }

    async loadLanguageDetails() {

        var lng = await languageService.getLanguageDetails();

        if (lng == 'Sinhala') {
            this.setState({
                language: sinhalaLanguage,
                languageName: 'Sinhala',
            })
        }
        else if (lng == 'Tamil') {
            this.setState({
                language: tamilLanguage,
                languageName: 'Tamil',
            })
        }
        // console.log("Home Incident Chart Section:", this.state);

    }

    componentDidMount() {
        this.loadData();
        this.loadLanguageDetails();
    }

    loadData() {
        // let color=["#5045f6","#ad46f3","#5045f6","#4777f5","#44aff0","#45dbf7","#f6d54a","#f69846","#ff4343"]
        let color = ["#ad46f3", "#4777f5", "#44aff0", "#45dbf7", "#f6d54a", "#f69846", "#ff4343"]
        StatisticsServices.getAllCorruptions().then(res => {
            console.log("res", res.data.insidents)
            var data = [];

            for (let index = 0; index < res.data.insidents.length; index++) {

                var name = res.data.insidents[index].type_of_corruption;

                if (name == "Bribery") {
                    name = this.state.language.incidentsChart_bribery;
                }
                else if (name == "Sexual Favours") {
                    name = this.state.language.incidentsChart_sexualFavours;
                }
                else if (name == "Election") {
                    name = this.state.language.incidentsChart_election;
                }
                else if (name == "Misuse of Public Funds or Property") {
                    name = this.state.language.incidentsChart_misuseOfProperty;
                }
                else if (name == "Misuse of Public Position") {
                    name = this.state.language.incidentsChart_misuseOfPosition;
                }
                else if (name == "Public Procurement Irregularities") {
                    name = this.state.language.incidentsChart_procurement;
                }
                else if (name == "Conflict of Interest") {
                    name = this.state.language.incidentsChart_conflict;
                }
                else if (name == "Other") {
                    name = this.state.language.incidentsChart_other;
                }
                else {
                    // name = this.state.language.incidentsChart_askToContact;
                    name = ""
                }

                data.push({
                    "value": res.data.insidents[index].total,
                    "name": name,
                    "itemStyle": {
                        "normal": {
                            "color": color[index]
                        }
                    }
                })

            }

            this.setState({ data })

        })
            .catch((error) => {
                console.log("error", error);
            })
    }



    render() {
        let { theme } = this.props;

        const dataNames = this.state.data.map(i => i.name);
        let option = {
            backgroundColor: "white",
            toolbox: {
                show: true,
                feature: {
                    mark: {
                        show: true
                    },
                    magicType: {
                        show: true,
                        type: ["pie", "funnel"]
                    },
                    restore: {
                        show: false,
                        title: "Restore"
                    },
                    saveAsImage: {
                        show: true,
                        title: this.state.language.statisticsPage_Chart_SaveImage,//"Save Image"
                    }
                }
            },
            graphic: [
                /* {
                     type: "group",
                     rotation: Math.PI / 4,
                     bounding: "raw",
                     right: 200,
                     bottom: 100,
                     z: 100,
                     children: [
                         {
                             type: "rect",
                             left: "center",
                             top: "center",
                             z: 100,
                             shape: {
                                 width: 600,
                                 height: 50
                             },
                             style: {
                                 fill: "rgba(0,0,0,0.3)"
                             }
                         },
                         {
                             type: "text",
                             left: "center",
                             top: "center",
                             z: 100,
                             style: {
                                 fill: "#fff",
                                 text: "jkdhskfls",
                                 font: "bold 26px Microsoft YaHei"
                             }
                         }
                     ]
                 },*/

                /* {
                     type: "group",
                     left: "0",
                     top: "bottom",
                     children: [
                         {
                             type: "rect",
                             z: 100,
                             left: "center",
                             top: "middle",
                             shape: {
                                 width: 190,
                                 height: 90
                             },
                             style: {
                                 fill: "#fff",
                                 stroke: "#555",
                                 lineWidth: 2,
                                 shadowBlur: 8,
                                 shadowOffsetX: 3,
                                 shadowOffsetY: 3,
                                 shadowColor: "rgba(0,0,0,0.3)"
                             }
                         },
                         {
                             type: "text",
                             z: 100,
                             left: "center",
                             top: "middle",
                             style: {
                                 fill: "#333",
                                 text: ["This is a note.", "With multiple lines."].join("\n"),
                                 font: "14px Arial"
                             }
                         }
                     ]
                 }*/
            ],
            // Hover Tooltip
            // {a} = series:[{name:}]
            // {b} = series:[{data: [{name:}]}]
            // {c} = series:[{data: [{value:}]
            tooltip: {
                trigger: "item",
                formatter: this.state.languageName === 'English'
                    ? "{a}<br/><strong>{b}</strong>: {c} Incidents"
                    : this.state.languageName === 'Sinhala'
                        ? "{a}<br/><strong>{b}</strong>: සිදුවීම් {c}"
                        : "{a}<br/><strong>{b}</strong>: சம்பவ {c}"
                ,  //Incidents",

                textStyle: {
                    color: "white", fontFamily: appconst.FONT
                }
            },
            title: {
                text: this.state.language.incidentsChart_title,   // All Incidents by Category
                left: "center",
                top: 5,
                textStyle: {
                    fontSize: this.state.languageName === "Tamil" ? 20 : (this.state.languageName === "Sinhala" ? 20 : 30),
                    color: 'black',
                    fontWeight: 700,
                    fontFamily: appconst.FONT
                }
            },
            calculable: true,
            legend: {
                icon: "circle",
                x: "center",
                y: "45px",
                data: dataNames,
                textStyle: {
                    color: "black", fontFamily: appconst.FONT
                }
            },
            series: [
                {
                    name: this.state.language.statisticsPage_CategoryChartChart_hoverText, //"Incident type",
                    type: "pie",
                    animationDuration: 2000,
                    animationEasing: "quarticInOut",
                    radius: [0, 120],
                    y: "160px",
                    avoidLabelOverlap: false,
                    startAngle: 90,
                    hoverOffset: 5,
                    center: ["50%", "50%"],
                    roseType: "area",
                    selectedMode: "multiple",
                    label: {
                        normal: {
                            show: true,
                            formatter: this.state.languageName === 'English' ? "{c} Incident(s)" : (this.state.languageName === 'Sinhala' ? "සිදුවීම් {c}" : "{c} சம்பவ"),
                            //formatter: "{c} " + this.state.language.incidentsChart_noOfIncidents, // {c} data: [{value:},]
                            textStyle: {
                                fontFamily: appconst.FONT
                            }
                        },
                        emphasis: {
                            show: true
                        },
                    },
                    labelLine: {
                        normal: {
                            show: true,
                            smooth: false,
                            length: 20,
                            length2: 10
                        },
                        emphasis: {
                            show: true
                        },

                    },
                    data: this.state.data
                }
            ]
        };

        return (
            <Fragment>

                <div className="w-full ">
                    <Grid item align='center' className=" w-full pb-10" >
                        <ReactEcharts option={option} style={{
                            height: 520,
                            width: "100%",
                        }} className="pie-chart" />
                    </Grid>
                </div>

            </Fragment>
        );
    }
}

export default withStyles({}, { withTheme: true })(AllIncidentChart);
