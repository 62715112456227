import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {
    AppBar, Toolbar, Typography, List, ListItem, Link,
    Grid, SwipeableDrawer, Avatar, Icon, IconButton, MenuItem, Button,
    GridList, GridListTile, GridListTileBar, Divider, ListItemText
} from '@material-ui/core';
import ReactPlayer from "react-player";

import { withStyles } from "@material-ui/core/styles";

const styleSheet = ((theme) => ({
    videoContainer: {
        //borderRadius: 15,
        overflow: "hidden",

    },
}));


class CountDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: false,
            onDuration: 0
        };

    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ playing: true, onDuration: 1 })
        }, 1000);

    }
    componentWillUnmount() {

    }


    render() {
        const { classes } = this.props
        const preventDefault = (event) => event.preventDefault();
        return (
            <Fragment>
                <div style={{ width: '100%', height: '100%', position: 'fixed', backgroundColor: '#00041b' }}>
                    <ReactPlayer
                        className={classes.videoContainer}
                        width="100%"
                        height="100%"
                        url="/assets/video/Countdown.mp4"
                        controls={false}
                        playing={this.state.playing}
                        light={true}
                        playIcon={<Button style={{ color: 'white', marginTop: 182 }} className="button-danger" variant="contained"
                        >LAUNCH</Button>}
                        light="/assets/images/launchBackground.jpg"
                        //onPlay={true}
                        //onReady={true}
                        onDuration={this.state.onDuration}

                        onEnded={() => { window.location.replace("/languageSelect") }}
                    />
                </div>
            </Fragment>
        );
    }
}





export default withStyles(styleSheet)(CountDown);