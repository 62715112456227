import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {
    AppBar, Toolbar, Typography, List, ListItem, Link,
    Grid, SwipeableDrawer, Avatar, Icon, IconButton, MenuItem, Button,
    GridList, GridListTile, GridListTileBar, Divider, ListItemText
} from '@material-ui/core';
import { SimpleCard, MatxProgressBar } from "matx";
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Footer from './components/Footer';
import AppAppBar from './components/AppAppBar';

import { withStyles } from "@material-ui/core/styles";

import { englishLanguage } from '../languages/englishLanguage';
import { sinhalaLanguage } from '../languages/sinhalaLanguage';
import { tamilLanguage } from '../languages/tamilLanguage';

import languageService from "app/services/languageService";

const styleSheet = ((theme) => ({
    gridList: {
        height: 275,
    },

    mainPadding: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 112,
            paddingRight: 120,
            paddingTop: 50,
            paddingBottom: 50
        },
        [theme.breakpoints.down('md')]: {
            padding: 20,
            paddingTop: 30,
            paddingBottom: 30

        },
        backgroundColor: 'white',
    },
    card: {

        [theme.breakpoints.up('md')]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
    },
    newsImage: {
        width: 90,
        height: 60,
        paddingRight: theme.spacing(1),
    },
    newsHrLine: {
        backgroundColor: '#0074e1',
        height: 3,
    },
    listItem: {
        borderBottomColor: "#dbdcdc",
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
    },
    pageTitlePadding: {
        [theme.breakpoints.up("md")]: {
            paddingLeft: 116,
            paddingTop: 60,
        },
        [theme.breakpoints.down("md")]: {
            paddingLeft: 30,
            paddingTop: 60,
        },
    },
}));


class Disclaimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: englishLanguage,
        };

    }

    async loadLanguageDetails() {

        var lng = await languageService.getLanguageDetails();

        if (lng == 'Sinhala') {
            this.setState({
                language: sinhalaLanguage,
            })
        }
        else if (lng == 'Tamil') {
            this.setState({
                language: tamilLanguage,
            })
        }
        // console.log("FAQ Page:", this.state);

    }

    componentDidMount() {
        this.loadLanguageDetails();

    }


    render() {
        const { classes } = this.props
        const preventDefault = (event) => event.preventDefault();
        return (
            <Fragment>
                {/* App bar component */}
                <AppAppBar />

                <div className={classes.pageTitlePadding}
                    style={{
                        backgroundImage: `url(/assets/images/statBackground.png)`,
                        width: '100%',
                        height: 160,
                        backgroundSize: 'cover',
                        display: 'flex',
                        float: "left",
                        alignItems: 'center',
                        marginBottom: 20,
                    }}>
                    <Typography variant="h1" component="h2" gutterBottom style={{ fontSize: 34, color: 'white', fontWeight: 600 }}>
                        {/* Disclaimer */}
                        {this.state.language.disclaimer_mainTitle}
                    </Typography>
                </div>

                <Fade in={true}>
                    <div>
                        <Grid spacing={1} className={classes.mainPadding}>

                            <Typography align="justify" variant="p" component="p" gutterBottom style={{ fontWeight: 500, }}>
                                {/* The information contained on this website is for general information purposes only. The information is provided by the general public and while we endeavor to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk. */}
                                {this.state.language.disclaimer_descriptionP1}
                            </Typography>

                            <br />
                            <Typography align="justify" variant="p" component="p" gutterBottom style={{ fontWeight: 500 }}>
                                {/* Transparency International Sri Lanka makes all reasonable efforts to ensure that the information presented on this website is accurate at the time it is published, but accepts no liability whatsoever for its correctness, reliability, topicality or completeness. Transparency International Sri Lanka assumes no responsibility for any direct or indirect loss suffered by users or third parties in connection with the use of the website. */}
                                {this.state.language.disclaimer_descriptionP2}
                            </Typography>
                            <br />
                            <Typography align="justify" variant="p" component="p" gutterBottom style={{ fontWeight: 500 }}>
                                {/* Transparency International Sri Lanka will only take legal or other necessary action based on complaints/ stories submitted by users after a thorough follow-up and verification of the complaint, and if it is deemed that there is a viable cause of action. Transparency International Sri Lanka takes no responsibility for, and will not be liable for, not taking any action in relation to submissions made to the website. Transparency International Sri Lanka reserves the right to not take action based on submissions made to the website. Transparency International Sri Lanka takes no responsibility for, and will not be liable for, any delays on the part of government authorities in taking action against any complaint. */}
                                {this.state.language.disclaimer_descriptionP3}
                            </Typography>
                            <br />
                            <Typography align="justify" variant="p" component="p" gutterBottom style={{ fontWeight: 500 }}>
                                {/* Every effort is made to keep the website up and running smoothly. However, Transparency International Sri Lanka takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control. */}
                                {this.state.language.disclaimer_descriptionP4}
                            </Typography>
                            <br />
                            <Typography align="justify" variant="p" component="p" gutterBottom style={{ fontWeight: 500 }}>
                                {/* Through this website you may be able to link to other websites which are not under the control of Transparency International Sri Lanka. We have no control over the nature, content and availability of those websites. The inclusion of any links does not necessarily imply a recommendation or an endorsement of the views expressed within them. Transparency Sri Lanka is neither a governmental nor law enforcement agency. */}
                                {this.state.language.disclaimer_descriptionP5}
                            </Typography>

                        </Grid>

                    </div>
                </Fade>
                {/* Footer */}
                <Footer />
            </Fragment>
        );
    }
}





export default withStyles(styleSheet)(Disclaimer);