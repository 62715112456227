export const district = [
    { title: 'Jaffna' },
    { title: 'Kilinochchi' },
    { title: 'Mannar' },
    { title: 'Mullaitivu' },
    { title: 'Vavuniya' },
    { title: 'Puttalam' },
    { title: 'Kurunegala' },
    { title: 'Gampaha' },
    { title: 'Colombo' },
    { title: 'Kalutara' },
    { title: 'Anuradhapura' },
    { title: 'Polonnaruwa' },
    { title: 'Matale' },
    { title: 'Kandy' },
    { title: 'Nuwara Eliya' },
    { title: 'Kegalle' },
    { title: 'Ratnapura' },
    { title: 'Trincomalee' },
    { title: 'Batticaloa' },
    { title: 'Ampara' },
    { title: 'Badulla' },
    { title: 'Monaragala' },
    { title: 'Hambantota' },
    { title: 'Matara' },
    { title: 'Galle' }
];
export const province = [
    { title: 'Northern' },
    { title: 'North Central' },
    { title: 'Eastern' },
    { title: 'North Western' },
    { title: 'Central' },
    { title: 'Uva' },
    { title: 'Western' },
    { title: 'Sabaragamuwa' },
    { title: 'Southern' }
];


export const districtsTypes = [
    { value: "Ampara", lat: 7.3018, lng: 81.6747, }, { value: "Anuradhapura", lat: 8.3114, lng: 80.4037 }, { value: "Badulla", lat: 6.9934, lng: 81.0550 },
    { value: "Batticaloa", lat: 7.7310, lng: 81.6747 }, { value: "Colombo", lat: 6.9271, lng: 79.8612 }, { value: "Galle", lat: 6.0535, lng: 80.2210 },
    { value: "Gampaha", lat: 7.0840, lng: 80.0098 }, { value: "Hambantota", lat: 6.1429, lng: 81.1212 }, { value: "Jaffna", lat: 9.6615, lng: 80.0255 },
    { value: "Kalutara", lat: 6.5854, lng: 79.9607 }, { value: "Kandy", lat: 7.2906, lng: 80.6337 }, { value: "Kegalle", lat: 7.2513, lng: 80.3464 },
    { value: "Kilinochchi", lat: 9.3803, lng: 80.3770 }, { value: "Kurunegala", lat: 7.4818, lng: 80.3609 }, { value: "Mannar", lat: 8.9810, lng: 79.9044 },
    { value: "Matale", lat: 7.4675, lng: 80.6234 }, { value: "Matara", lat: 5.9549, lng: 80.5550 }, { value: "Monaragala", lat: 6.8728, lng: 81.3507 },
    { value: "Mullaitivu", lat: 9.2671, lng: 80.8142 }, { value: "Nuwara Eliya", lat: 6.9497, lng: 80.7891 }, { value: "Polonnaruwa", lat: 7.9403, lng: 81.0188 },
    { value: "Puttalam", lat: 8.0408, lng: 79.8394 }, { value: "Ratnapura", lat: 6.7056, lng: 80.3847 },
    { value: "Trincomalee", lat: 8.5874, lng: 81.2152 }, { value: "Vavuniya", lat: 8.7542, lng: 80.4982 },
];


export const Detailstype = [
    {
        key: "type1",
        name: "Anonymous",
        value: 1,
    },
    {
        key: "type2",
        name: "With Information",
        value: 0,
    }
];

export const statusTypes = [
    {
        key: "s_type2",
        value: "Received"
    },
    {
        key: "s_type3",
        value: "Work in Progress"
    },
    {
        key: "s_type4",
        value: "Action Taken"
    },
    {
        key: "s_type5",
        value: "Reject/Duplicate"
    },

];

export const ageRanges = [
    {
        key: "age_type2",
        value: '15-24',
    },
    {
        key: "age_type3",
        value: '25-34',
    },
    {
        key: "age_type4",
        value: '35-44',
    },
    {
        key: "age_type5",
        value: '45-54',
    },
    {
        key: "age_type6",
        value: '55-64',
    },
    {
        key: "age_type7",
        value: '65+',
    },

];

export const genderSelection = [
    {
        key: "gender_type2",
        value: "Male",
    },
    {
        key: "gender_type3",
        value: "Female",
    },
    {
        key: "gender_type4",
        value: "Other",
    },
    {
        key: "gender_type5",
        value: "Prefer not to say",
    },
];

export const public_authority_involve_Types = [
    {
        key: "publicAutIn_type2",
        name: "Yes",
        value: true,
    },
    {
        key: "publicAutIn_type3",
        name: "No",
        value: false,
    },
];


export const reportIncidentTypes = [
    {
        key: 1,
        value: "Has Happened",
    },
    {
        key: 2,
        value: "Is Happening",
    },
    {
        key: 3,
        value: "Will Happen in the future",
    },
];


// export const categorySelection = [
//     {
//         key:1,
//         value:"Bribery",
//     },
//     {
//         key:2,
//         value:"Sexual Favours",
//     },
//     {
//         key:3,
//         value:"Misuse of Public Funds or Property",
//     },
//     {
//         key:4,
//         value:"Misuse of Public Position",
//     },
//     {
//         key:5,
//         value:"Conflict of Interest",
//     },
//     {
//         key:6,
//         value:"Public Procurement Irregularities",
//     },
//     {
//         key:7,
//         value:"Other",
//     },
// ];


export const categorySelection = [
    {
        key: 1,
        value: "Bribery",
        desc: "The offering, promising, giving, accepting or soliciting of an advantage as an inducement for an action which is illegal, unethical or a breach of trust. Inducements can take the form of gifts, loans, fees, rewards or other advantages (taxes, services, donations, etc.) (TI plain language guide)",
    },
    {
        key: 2,
        value: "Sexual Favours",
        desc: "when an individual who goes to a public, judicial, peace or police officer for a public service is required to perform a sexual favour in return for the service that they are entitled to by the law (Article by Shyamala Gomez)",
    },
    {
        key: 3,
        value: "Misuse of Public Funds or Property",
        desc: "When a person holding office in an institution, organisation or company dishonestly and illegally appropriates, uses or traffics the funds and goods they have been entrusted with for personal enrichment or other activities. (TI plain language guide, slightly amended the Embezzlement definition to match this)",

    },
    {
        key: 4,
        value: "Misuse of Public Position",
        desc: "A person who uses his office to improperly benefit oneself or another person. (Ethics and Anti-Corruption Commission Kenya definition)"
    },
    {
        key: 5,
        value: "Conflict of Interest",
        desc: "Situation where an individual or the entity for which they work, whether a government, business, media outlet or civil society organization, is confronted with choosing between the duties and demands of their position and their own private interests (TI plain language guide)",
    },
    {
        key: 6,
        value: "Public Procurement Irregularities",
        desc: "Any irregularities in multi-step process of established procedures to acquire goods and services by any individual, company or organization — from the initial needs assessment to the contract’s award and service delivery (Ethics and Anti-Corruption Commission Kenya definition)"
     },
    {
        key: 7,
        value: "Other",
        desc: "other",
    },
];


export const person_youAre = [
    {
        key: 1,
        value: "Agent",
    },
    {
        key: 2,
        value: "Giver",
    },
    {
        key: 3,
        value: "Recipient",
    },
    {
        key: 4,
        value: "Unknown",
    },
];

export const briberyType = [
    { key: 1, value: "Appointment" }, { key: 2, value: "Cash" }, { key: 3, value: "Property or interest in property" },
    { key: 4, value: "Commission" }, { key: 5, value: "Valuable Security" }, { key: 6, value: "Failure to take action" },
    { key: 7, value: "Favor" }, { key: 8, value: "Free" }, { key: 9, value: "Gift" }, { key: 10, value: "Loan" },
    { key: 11, value: "Promise" }, { key: 12, value: "Reward" }, { key: 13, value: "Service" },
];

export const propertyTypes = [
    { key: 1, value: "Money" }, { key: 2, value: "Land" }, { key: 3, value: "Buildings" }, { key: 4, value: "Vehicle" },
    { key: 5, value: "Equipment" }, { key: 6, value: " Public officials/Human resources " }, { key: 7, value: "Other" }
];

export const natureOfInterest_Types = [
    { key: 1, value: "Self-Dealing" }, { key: 2, value: "Outside Employment" }, { key: 3, value: "Family Interests" },
    { key: 4, value: "Gifts" }, { key: 5, value: "Unknown" },
];

export const procurement_irregularityType = [
    { key: 1, value: "Absence of open tendering" }, { key: 2, value: "Collusion" }, { key: 3, value: "Conflict of Interest" },
    { key: 4, value: "Lack of qualification" }, { key: 5, value: "Unknown" },
];

export const misused_types = [
    {
        key: 1,
        value: "Use of State Vehicles for Election Campaign Purposes "
    },
    {
        key: 2,
        value: "Use of State Building & Premises/ Lands for Election Campaign Purposes "
    },
    {
        key: 3,
        value: "Appointments, Transfers & Promotions for State Officials targeting Election "
    },
    {
        key: 4,
        value: "Misusing State sponsored Development Activities "
    },
    {
        key: 5,
        value: "State Officers acting in favour of Political Parties & in Breach of Official Duties "
    },
    {
        key: 6,
        value: "Giving Incentives/Equipment with Political Objectives "
    },
    {
        key: 7,
        value: "Use of State Officials & Security Resources for Election Campaign During the Period of an Election State Officers using Social Media for the Promotion of or in Prejudice to Any Party, Group or Candidate "
    },
    {
        key: 9,
        value: "Manipulation of State sponsored Livelihood Grants & Allowances for Election Purposes "
    },
    {
        key: 10,
        value: "Using Equipment and Facilities of State Media Institutions Without Paying "
    },
    {
        key: 11,
        value: "Participating in Opening Ceremonies/ Government Funded Functions "
    },
    {
        key: 12,
        value: "Other Illegal Election Campaigns Using State Resources "
    },
];

export const userRoles = [
    {
        key: 1,
        value: "Moderator",
    },
    {
        key: 2,
        value: "Data Entry",
    },
    {
        key: 3,
        value: "Report Analysis",
    }
];

export const divisionalSecretariats = [
    { value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 }, { value: 9 }, { value: 10 }, { value: 11 }, { value: 12 }, { value: 13 },
    { value: 14 }, { value: 15 }, { value: 16 }, { value: 17 }, { value: 18 }, { value: 19 }, { value: 20 }, { value: 21 }, { value: 22 }, { value: 23 }, { value: 24 }, { value: 25 },
    { value: 26 }, { value: 27 }, { value: 28 }, { value: 29 }, { value: 30 }
];

export const public_authority_type = [
    { value: "Public Sector Organizations" }, { value: "Private Sector Organizations" }, { value: "Non-governmental Organizations" }
];


export const misused_description_types = [
    {
        key: "misused_description_types 1",
        value: "Use of State Vehicles to put up posters/ organize political rallies and meetings/ transport voters and similar election related activities",
    },
    {
        key: "misused_description_types 2",
        value: "Use of State buildings, premises or lands for election rallies/meetings, to maintain offices for election candidates and to display posters/cutouts without making the necessary payments ",
    },
    {
        key: "misused_description_types 3",
        value: "Using State Funds to purchase and distribute sports equipment, agricultural equipment, and similar welfare aid during election times.",
    }
];


export const reportDetailsDialogSelection = [
    {
        value: "Ask our staff to contact you",
    },
    {
        value: "Answer the questionnaire",
    }
];


export const reportTypes = [
    {
        key: 1,
        title: 'Shared Stories',
        value: 'Share Story',
    },
    {
        key: 2,
        title: 'Reported Incidents',
        value: 'Corruption Fighter',
    },

];


export const corruptionReporterTypes = [

    {
        key: 1,
        value: "Bribery",
    },
    {
        key: 2,
        value: "Sexual Favours",
    },
    {
        key: 3,
        value: "Misuse of Public Funds or Property",
    },
    {
        key: 4,
        value: "Misuse of Public Position",
    },
    {
        key: 5,
        value: "Conflict of Interest",
    },
    {
        key: 6,
        value: "Public Procurement Irregularities",
    },
    {
        key: 7,
        value: "Other",
    },
    {
        key: 8,
        value: "Election",
    },

];

export const complaints_received_types = [
    { key: 1, value: "public" }, { key: 2, value: "users" },
];

export const storyAllFields = [

    {
        key:1,
        title: "All",
        value: "All"
    },
    {
        key:2,
        title: "Type(Story/Corruption Fighter)",
        value: "type"
    },
    {
        key:3,
        title: "District",
        value: "district"
    },
    {
        key:4,
        title: "Status",
        value: "status"
    },
    {
        key:5,
        title: "Published",
        value: "published"
    },
    {
        key:6,
        title: "Reference Number",
        value: "reference_number"
    },
    {
        key:7,
        title: "Anonymous",
        value: "anonymous"
    },
    {
        key:8,
        title: "Title",
        value: "title"
    },
    {
        key:9,
        title: "Authority Involved",
        value: "name_of_authority"
    },
    {
        key:10,
        title: "Name and the Designation",
        value: "designation_of_authority"
    },

    // "public_authority_involved",
    // "archive",

];

export const corruptionAllFields = [

    {
        key:1,
        title: "All",
        value: "All"
    },
    {
        key:2,
        title: "Type",
        value: "type"
    },
    {
        key:3,
        title: "Type of Corruption",
        value: "type_of_corruption"
    },
    {
        key:4,
        title: "District",
        value: "district"
    },
    {
        key:5,
        title: "Status(Received/Work In Progress/Take Action)",
        value: "status"
    },
    {
        key:6,
        title: "Published",
        value: "published"
    },
    {
        key:7,
        title: "Reference Number",
        value: "reference_number"
    },
    {
        key:8,
        title: "Date and Time of Incident",
        value: "incident_date_time"
    },
    {
        key:9,
        title: "Name of the Reported Person",
        value: "name"
    },
    {
        key:10,
        title: "Contact Number of the Reported Person",
        value: "contact_number"
    },
    {
        key:11,
        title: "Email of the Reported Person",
        value: "email"
    },
    {
        key:12,
        title: "Age of the Reported Person",
        value: "age"
    },
    {
        key:13,
        title: "Gender of the Reported Person",
        value: "gender"
    },
    {
        key:14,
        title: "Address of the Reported Person",
        value: "address"
    },
    {
        key:15,
        title: "Name and the Designation",
        value: "designation_of_authority"
    },
    {
        key:16,
        title: "Divisional Secretariat",
        value: "divisional_secretariat"
    },
    {
        key:17,
        title: "Incident Status",
        value: "incident_status"
    },
    {
        key:18,
        title: "Anonymous or Not",
        value: "anonymous"
    },
    {
        key:19,
        title: "You Are(Bribery and Sexual Favours)",
        value: "you_are"
    },
    {
        key:20,
        title: "Bribe Form",
        value: "bribe_form"
    },
    {
        key:21,
        title: "Type of Property",
        value: "type_of_property"
    },
    {
        key:22,
        title: "Nature of Interest",
        value: "nature_of_interest"
    },
    {
        key:23,
        title: "Ask to Contact",
        value: "ask_to_contact"
    },
    {
        key:24,
        title: "Benefit",
        value: "benefit"
    },
    {
        key:25,
        title: "Private Interest",
        value: "private_interest"
    },
    {
        key:26,
        title: "Involved",
        value: "involved"
    },
    {
        key:27,
        title: "Disclose Interest",
        value: "disclose_interest"
    },
    {
        key:28,
        title: "Authorized Participate",
        value: "authorized_participate"
    },
    {
        key:29,
        title: "Type of Procurement",
        value: "type_of_procurement"
    },
    {
        key:30,
        title: "Created By",
        value: "created_by"
    },
    {
        key:31,
        title: "Authority Involved",
        value: "name_of_authority"
    },
    {
        key:32,
        title: "Other Details",
        value: "other_details"
    },
    // "public_authority_involved",
    // "archive",

    // "divisional_secretariat",

];

export const electionAllFields = [
    {
        key:1,
        title: "All",
        value: "All"
    },
    {
        key:2,
        title: "Type",
        value: "type"
    },
    {
        key:3,
        title: "Type of Corruption",
        value: "type_of_corruption"
    },
    {
        key:4,
        title: "District",
        value: "district"
    },
    {
        key:5,
        title: "Status(Received/Work In Progress/Take Action)",
        value: "status"
    },
    {
        key:6,
        title: "Published",
        value: "published"
    },
    {
        key:7,
        title: "Reference Number",
        value: "reference_number"
    },
    {
        key:8,
        title: "Date and Time of Incident",
        value: "incident_date_time"
    },

    {
        key:9,
        title: "Name of the Reported Person",
        value: "name"
    },
    {
        key:10,
        title: "Contact Number of the Reported Person",
        value: "contact_number"
    },
    {
        key:11,
        title: "Email of the Reported Person",
        value: "email"
    },
    {
        key:12,
        title: "Age of the Reported Person",
        value: "age"
    },
    {
        key:13,
        title: "Gender of the Reported Person",
        value: "gender"
    },
    {
        key:14,
        title: "Address of the Reported Person",
        value: "address"
    },

    {
        key:15,
        title: "Name and the Designation",
        value: "designation_of_authority"
    },
    {
        key:16,
        title: "Divisional Secretariat",
        value: "divisional_secretariat"
    },
    {
        key:17,
        title: "Incident Status",
        value: "incident_status"
    },
    {
        key:18,
        title: "Authority Involved",
        value: "name_of_authority"
    },
    {
        key:19,
        title: "Misused",
        value: "misused"
    },
    {
        key:20,
        title: "Nature of Misuse",
        value: "nature_of_misuse"
    },
    {
        key:21,
        title: "Political Party",
        value: "political_party"
    },
    {
        key:22,
        title: "Created By",
        value: "created_by"
    },

    // "public_authority_involved",

    // "anonymous",
    // "archive",


];

export const trueFalseTypes = [
    {
        key: "type1",
        name: "Yes",
        value: 1,
    },
    {
        key: "type2",
        name: "No",
        value: 0,
    }
];

export const conflictOfInterestRadioSelections = [
    {
        key: "type1",
        value: "Yes",
    },
    {
        key: "type2",
        value: "No",
    },
    {
        key: "type3",
        value: "Unknown",
    }
];

export const newsWordLimit = 3000;
export const storyWordLimit = 2500;
export const electionPartyWordLimit = 190;

//theme colors
export const incident_report_theme = "#007FFF"; //"#FB3640";
export const incident_report_theme_dark = "#1779DC"; //"#CC0D17"; 

export const share_story_theme = "#1c6190"; //"#fd902a";
export const share_story_theme_dark = "#215A80"; //"#e61d1d",

export const election_report_theme = "#0047ab"; //"#FFD523";
export const election_report_theme_dark = "#003F98"; //"#d4b00e",

export const incident_report_theme_home_border = "#007FFF"; ///"#e52d27";
export const share_story_theme_home_border = "#1c6190"; //"#f15111";
export const election_report_theme_home_border = "#0047ab"; //"#f7cf1f";



