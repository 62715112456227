import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {
    AppBar, Toolbar, Typography, List, ListItem, Link,
    Grid, SwipeableDrawer, Avatar, Icon, IconButton, MenuItem, Button,
    GridList, GridListTile, GridListTileBar, Divider, ListItemText
} from '@material-ui/core';

import { withStyles } from "@material-ui/core/styles";

const styleSheet = ((theme) => ({
    gridList: {
        height: 275,
    },

    newsImage: {
        width: 90,
        height: 60,
        paddingRight: theme.spacing(1),
    },
    newsHrLine: {
        backgroundColor: '#0074e1',
        height: 3,
    },
    listItem: {
        borderBottomColor: "#dbdcdc",
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
    }
}));


class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: [
                { title: "Title one", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
                { title: "Title one", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
                { title: "Title one", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
                { title: "Title one", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
                { title: "Title one", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
                { title: "Title one", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
                { title: "Title one", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
                { title: "Title one", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
                { title: "Title one", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." }]
        };

    }




    render() {
        const { classes } = this.props
        return (
            <div >
                        <Typography className="ml-3" variant="h6" style={{ fontSize: 30, fontWeight: 700, color: "#574033" }}>News</Typography>

                <GridList cols={1} cellHeight={90} className={classes.gridList}>

                    {
                        this.state.news.map((item) => (


                            <Grid container spacing={1}>

                                <ListItem button className={classes.listItem} >
                                    <img src="/assets/images/news.jpg" className={classes.newsImage} />
                                    
                                    <Grid  item xs={9} md={9}>
                                       
                                    <ListItemText primary={item.title} secondary={item.content} />
                                    <Button size="small" color="primary" style={{left:-4,top:-7}}>
                                        Read more
                                    </Button>

                                    </Grid>
                                </ListItem>

                            </Grid>

                        ))
                    }

                </GridList>
            </div>
        );
    }
}





export default withStyles(styleSheet)(News);