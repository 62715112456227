import React, { Component, Fragment, useEffect } from "react";
import { Grid, Card, Button, Typography, Paper,Dialog,DialogActions,DialogContent,IconButton } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import { withStyles } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';
import CloseIcon from '@material-ui/icons/Close';

import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ShareStoryComponent from '../ShareStoryComponent';
import ReportElectionIncident from '../reportInsidentComponents/ReportElectionIncident';
import ReportIncident from '../reportInsidentComponents/ReportIncident';

const styleSheet = ((theme) => ({
    root: {
        //maxWidth: 345,
        backgroundColor: '#d9eded'
    },
    media: {
        height: 120,
    },
    // > 950 px
    formCard:{
        paddingLeft: 150,
        paddingRight: 150,
    },

}));


class Cards_body extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shareStoryDialog:false,
            incidentReportDialog:false,
            electionDialog:false,
            windowActive:false,
        }

    }

    componentDidMount() {
        if(window.innerWidth <= 950){
            this.setState({
                windowActive: true,
            })
        }

        window.addEventListener('resize', ()=>{
            if(window.innerWidth <= 950){
                this.setState({
                    windowActive: true,
                })
            }
            else{
                this.setState({
                    windowActive: false,
                })
            }
        });
    }


    render() {
        const { classes } = this.props
        return (
            <div >

                <Grid container  spacing={1} >

                    <Grid item xs={12} md={4}  >
                        <Card className={classes.root}>
                            <CardActionArea onClick={() =>
                                    this.setState({shareStoryDialog:true})
                                }>
                                <CardMedia
                                    className={classes.media}
                                    image="/assets/images/shere_story.jpg"
                                    title="Contemplative Reptile"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        If you see something, say something
                                    </Typography>

                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Report Incident of Bribery and Corruption to TISL
                                    </Typography>
                                </CardContent>

                                <CardActions>
                                    <Button size="small" color="primary">
                                        Share Story
                                    </Button>

                                </CardActions>
                            </CardActionArea>

                        </Card>

                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Card className={classes.root}>
                            <CardActionArea
                            onClick={() =>
                                this.setState({incidentReportDialog:true})
                            }>
                                <CardMedia
                                    className={classes.media}
                                    image="/assets/images/corruption.jpeg"
                                    title="Contemplative Reptile"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Words move hearts, hearts move limbs.
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Share Your Experience and Views on Bribery and Corruption.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" color="primary">
                                        Report Incident
                                    </Button>

                                </CardActions>
                            </CardActionArea>

                        </Card>

                    </Grid>

                    <Grid item xs={12} md={4} >
                        <Card className={classes.root}>
                            <CardActionArea
                            onClick={() =>
                                this.setState({electionDialog:true})
                            }>
                                <CardMedia
                                    className={classes.media}
                                    image="/assets/images/election_violation.jpg"
                                    title="Contemplative Reptile"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Report Election Violations
                                    </Typography>

                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                                        across all continents except Antarctica
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" color="primary">
                                        Report Election Violations
                                    </Button>

                                </CardActions>
                            </CardActionArea>

                        </Card>

                    </Grid>

                </Grid>


                <Dialog 
                    // onClose={()=>{this.setState({shareStoryDialog:false})}}
                    fullScreen
                    maxWidth={"md"}
                    aria-labelledby="simple-dialog-title" open={this.state.shareStoryDialog}>
                    <DialogContent>
                        <div style={{float:"right",}}>
                            <IconButton onClick={()=>{this.setState({shareStoryDialog:false})}} >
                                <CloseIcon/>
                            </IconButton>
                        </div>
                        
                        { this.state.windowActive ? 
                            <div>
                                <ShareStoryComponent />
                            </div> : 
                            <div className={classes.formCard}>
                                <ShareStoryComponent />
                            </div>
                        }
                    </DialogContent>

                </Dialog>

                <Dialog
                    // onClose={()=>{this.setState({incidentReportDialog:false})}}
                    fullScreen
                    maxWidth={"md"}
                    aria-labelledby="simple-dialog-title" open={this.state.incidentReportDialog}>
                    <DialogContent>
                        <div style={{float:"right",}}>
                            <IconButton onClick={()=>{this.setState({incidentReportDialog:false})}} >
                                <CloseIcon/>
                            </IconButton>
                        </div>
                        
                        { this.state.windowActive ? 
                            <div>
                                <ReportIncident />
                            </div> : 
                            <div className={classes.formCard}>
                                <ReportIncident />
                            </div>
                        }
                    </DialogContent>

                </Dialog>

                <Dialog
                    // onClose={()=>{this.setState({electionDialog:false})}}
                    fullScreen
                    maxWidth={"md"}
                    aria-labelledby="simple-dialog-title" open={this.state.electionDialog}>
                    <DialogContent>
                        <div style={{float:"right",}}>
                            <IconButton onClick={()=>{this.setState({electionDialog:false})}} >
                                <CloseIcon/>
                            </IconButton>
                        </div>

                        { this.state.windowActive ? 
                            <div>
                                <ReportElectionIncident />
                            </div> : 
                            <div className={classes.formCard}>
                                <ReportElectionIncident />
                            </div>
                        }

                    </DialogContent>

                </Dialog>

            </div>
        );
    }
}





export default withStyles(styleSheet)(Cards_body);