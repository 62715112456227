import React, { Component } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Alert from '@material-ui/lab/Alert';
import { connect } from "react-redux";
import jwtAuthService from '../../services/jwtAuthService';


class SignUp extends Component {
  state = {
    params: {
      userId: null,
      expires: null,
      hash: null,
      signature: null,
    },
    password: "",
    repeatPassword: "",
    agreement: "",
    alertType: "error",
    alertText: "sample",
    alertVisible: false
  };

  componentDidMount() {

    let paramId = this.props.match.params.id;
    const query = new URLSearchParams(this.props.location.search);

    const expires = query.get('expires');
    const hash = query.get('hash');
    const signature = query.get('signature');

    var params = {
      userId: paramId,
      expires: expires,
      hash: hash,
      signature: signature,
    }
    this.setState({
      params
    })

    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    // remove rule when it is not needed
    ValidatorForm.removeValidationRule('isPasswordMatch');
  }

  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleFormSubmit = async (event) => {


    jwtAuthService.firstTimeSetPassword(this.state.params, this.state.password).then(res => {
      console.log("res", res)
      if (res.data.success) {
        this.setState({
          alertType: "success",
          alertText: res.data.msg,
          alertVisible: true
        }, () => {
          setTimeout(() => {
            window.location.replace("../signin");
          }, 2000);
        })
      } else {
        this.setState({
          alertType: "error",
          alertText: res.data.msg,
          alertVisible: true
        })

      }

    })
      .catch((error) => {
        console.log(error);
        this.setState({
          alertType: "error",
          alertText: "Something wrong",
          alertVisible: true
        })
      })

  };
  render() {
    let { username, email, password, repeatPassword } = this.state;
    return (
      <div className="signup flex justify-center w-full h-full-screen">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-8 flex justify-center bg-light-gray items-center h-full">
                  <img
                    src="/assets/images/illustrations/posting_photo.svg"
                    alt=""
                  />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-9 h-full">
                  <Alert style={{ display: this.state.alertVisible ? "flex" : "none" }} className="mb-4" severity={this.state.alertType}>{this.state.alertText}</Alert>

                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <TextValidator
                      className="mb-6 w-full"
                      variant="outlined"
                      label="Password"
                      onChange={this.handleChange}
                      name="password"
                      type="password"
                      validators={['required']}
                      errorMessages={['this field is required']}
                      value={password}
                    />
                    <TextValidator
                      className="mb-6 w-full"
                      variant="outlined"
                      label="Repeat password"
                      onChange={this.handleChange}
                      name="repeatPassword"
                      type="password"
                      validators={['isPasswordMatch', 'required']}
                      errorMessages={['password mismatch', 'this field is required']}
                      value={repeatPassword}
                    />

                    {/* <FormControlLabel
                      className="mb-4"
                      name="agreement"
                      onChange={this.handleChange}
                      control={<Checkbox />}
                      label="I have read and agree to the terms of service."
                   />*/}
                    <div className="flex items-center">
                      <Button
                        className="capitalize"
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Set password
                      </Button>
                      {/*
                      <span className="mx-2 ml-5">or</span>
                      <Button
                        className="capitalize"
                        onClick={() =>
                          this.props.history.push("/session/signin")
                        }
                      >
                        Sign in
                      </Button>
                      */}
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // setUser: PropTypes.func.isRequired
});

export default connect(mapStateToProps, {})(SignUp);
