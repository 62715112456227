export const authRoles = {
  dashboard: ['Super Admin', 'Admin', 'Moderator', 'Report Analysis', 'Data Entry'],
  usercreate: ['Super Admin', 'Admin', 'Moderator', 'Data Entry'],
  userview: ['Super Admin', 'Admin', 'Moderator', 'Data Entry'],
  incidentview: ['Super Admin', 'Admin', 'Moderator', 'Report Analysis', 'Data Entry'],
  newsview: ['Super Admin', 'Admin', 'Moderator', 'Report Analysis'],
  newscreate: ['Super Admin', 'Admin', 'Moderator', 'Report Analysis'],
  electionChange: ['Super Admin', 'Admin', 'Moderator'],
  reports: ['Super Admin', 'Admin', 'Moderator', 'Report Analysis'],
}

// Check out app/views/dashboard/DashboardRoutes.js
// Only SA & Admin has dashboard access

// const dashboardRoutes = [
//   {
//     path: "/dashboard/analytics",
//     component: Analytics,
//     auth: authRoles.admin <----------------
//   }
// ];