import React, { Component, Fragment } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Card, Box, Chip, Menu, MenuItem, Fab, CardActionArea, CardContent, Typography, CardMedia, InputAdornment, IconButton } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Carousel from "react-material-ui-carousel";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Close from '@material-ui/icons/Close';
import StatisticsServices from '../../../services/StatisticsServices';
import Util from '../../../services/Util';
import * as appconst from '../../../appconst';
import L from 'leaflet';

const myIcon = new L.Icon({
    iconUrl: 'assets/images/location_picker.png',
    iconRetinaUrl: 'assets/images/location_picker.png',
    popupAnchor: [-0, -0],
    iconSize: [25, 40],
});

class ClusterMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations_incident: []
        };

    }

    async componentDidMount() {

        await StatisticsServices.getAllCorruptionsForMap().then(res => {
            console.log("res for map", res.data.insidents)
            var data = [];
            for (let index = 0; index < res.data.insidents.length; index++) {
                var attactment = [];
                if (res.data.insidents[index].incident_report.other_details != null) {
                    attactment = JSON.parse(res.data.insidents[index].incident_report.other_details.attachments);

                } else {
                    attactment = null;
                }
                data.push({
                    "row_id": index,
                    "lat": parseFloat(res.data.insidents[index].incident_report.latitude),
                    "lng": parseFloat(res.data.insidents[index].incident_report.longitude),
                    "is_show": false,
                    "story": res.data.insidents[index].story,
                    "date": res.data.insidents[index].created_at,
                    "status": "Published",
                    "attachment": attactment,
                    "type": res.data.insidents[index].type,
                    "title": res.data.insidents[index].title,
                    "district": res.data.insidents[index].district,
                    "id": res.data.insidents[index].id

                })

            }

            this.setState({
                locations_incident: data,
                //show:true
            }, () => { })
        })
            .catch((error) => {
                console.log("error", error);
            })




    }


    render() {
        return (
            <MapContainer
                className="markercluster-map"
                style={{ height: "100%" }}
                center={[7.749163, 80.637539]}
                zoom={7.4}
                maxZoom={18}
                scrollWheelZoom={false}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />

                <MarkerClusterGroup>

                    {this.state.locations_incident.map((row) => (
                        <Marker position={[row.lat, row.lng]} icon={myIcon} >
                            <Popup>
                                <Grid style={{ width: 230 }}>
                                    <Grid container>
                                        <Grid item xs={4} sm={4}>
                                            <Chip size="small" label="Published" style={{ alignItems: 'flex-end' }} />

                                        </Grid>
                                        <Grid item xs={8} sm={8}>
                                            <Typography color="textSecondary" style={{ marginTop: 2, paddingTop: 2 }}>
                                                {Util.getParsedDate(row.date)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid style={{ width: 230 }}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12}>
                                            <Typography color="textSecondary" style={{ marginTop: 2, paddingTop: 2 }}>
                                                {row.type + ", " + row.district}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {
                                    row.attachment != null ?
                                        <Carousel autoPlay={true} indicators={true} navButtonsAlwaysVisible={false} animation={"fade"} >

                                            {row.attachment.map((attachment) => (
                                                <img src={appconst.ATTACHMENTS_LOCATION + attachment} style={{ width: "100%" }} />

                                            ))}
                                        </Carousel>
                                        : null
                                }


                                < Button className="capitalize" onClick={() => { window.location.href = "/incidents/" + row.id }} color="primary">Read More...</Button>

                            </Popup>
                        </Marker >
                    ))
                    }


                </MarkerClusterGroup>
            </MapContainer >
        )
    }
}

export default ClusterMap;
