import React, { Component } from "react";
import "./Optin.css";

class Optin extends Component {
    modal() {
        const modal = document.getElementById("modal");
        modal.classList.toggle("is_open");
    }

    render() {
        return (
            <div className="optin">
                <p>We will be live after 9th of December</p>


            </div>
        );
    }
}

export default Optin;
