import React, { Component, Fragment, useEffect } from "react";
import { Grid, Card, Button, Typography, Link } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
//import backgroundimage from '../../../../public/assets/images/white-concrete-wall.jpg'; // Import using relative path
import languageService from "app/services/languageService";
import Slide from "@material-ui/core/Slide";
import { isMobile } from "utils";

const styles = (theme) => ({
  slideshowImageContainer: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "50vh",
      //minHeight: 690,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
  },
  ContainerBottom: {
    height: "5vh",
  },
  mainTitle: {
    fontWeight: 700,
    fontSize: 60,
    color: "white",
    //fontFamily: "Source sans pro",
  },
  bottomItemButton: {
    width: 100,
    height: 100,
    borderRadius: 100,
    backgroundColor: "#02b0f5",
    border: "5px solid white",

    cursor: "pointer",
    // border: "1px solid #0074E1
    textAlign: "center",

    // added by shammika
    boxShadow:
      " 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",
    transition: "all ease 200ms",

    "&:hover": {
      filter: "brightness(1)",
      transform: "scale(1.2)",
      boxShadow:
        "0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02)",
    },
    [theme.breakpoints.down("md")]: {
      width: 70,
      height: 70,
    },
  },
  butonLang: {
    display: "flex",
    justifyContent: "center",
  },
  buttonContainer: {
    [theme.breakpoints.up("md")]: {
      marginTop: -60,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: -43,
    },
  },
  btnText: {
    cursor: "pointer",
    fontWeight: 500,
    fontSize: 20,
    color: "white",
  },
  apesalliLogo: {
    width: 200,
    marginBottom: 60,
    [theme.breakpoints.down("md")]: {
      width: 150,
      marginBottom: 60,
      marginTop: 30,
    },
  },

  tislLogo: {
    marginTop: 100,
    [theme.breakpoints.down("md")]: {
      marginTop: 50,
    },
  },
});

class LanguageSelect extends Component {
  //scrollbar-container
  constructor(props) {
    super(props);
    this.state = {};
    //creates a reference for your element to use
    this.myDivToFocus = React.createRef();
  }
  scrooll() {
    this.myDivToFocus.current.scrollIntoView({
      block: "start",
    });
  }

  openHomePage(language) {
    languageService.setLanguageDetails(language);
    this.props.history.push("/home");
  }

  render() {
    let { classes, theme } = this.props;
    var mobSize = isMobile();
    return (
      <Fragment className="mainDiv">
        {/* Set Carousal BackGround Color */}

        <Grid>
          <div
            style={{
              backgroundColor: "#02b0f5",
            }}
            className={classes.slideshowImageContainer}
          >
            <Grid container justifyContent="center" className="w-full px-5">
              <Grid justifyContent="center" item xs={12} container>
                <img
                  className={classes.apesalliLogo}
                  alt="apesalli Logo"
                  src="/assets/images/logoLarge.png"
                />
              </Grid>
              {/* <Grid justifyContent="center" item xs={12} md={6} container>
                <Typography className={classes.mainTitle}>
                  Apesalli.lk
                </Typography>
              </Grid> */}
            </Grid>
          </div>
          <Slide timeout={2500} in={true} direction="up">
            <Grid
              container
              className={classes.buttonContainer}
              spacing={2}
              alignItems="center"
              justify="center"
              direction="row"
              style={{ margingTop: "20px" }}
            >
              {/* Button English */}

              <Grid
                className={classes.butonLang}
                item
                xs={3}
                md={1}
                onClick={() => this.openHomePage("English")}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  elevation={5}
                  className={[classes.bottomItemButton]}
                >
                  <Typography className={classes.btnText}>
                    {mobSize == false ? "English" : "En"}
                  </Typography>
                </Grid>
              </Grid>

              {/* Button sinhala */}

              <Grid
                className={classes.butonLang}
                item
                xs={3}
                md={1}
                onClick={() => this.openHomePage("Sinhala")}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  elevation={5}
                  className={[classes.bottomItemButton]}
                >
                  <Typography className={classes.btnText}>
                    {" "}
                    {mobSize == false ? "සිංහල" : "සිං"}
                  </Typography>
                </Grid>
              </Grid>
              {/* Button tamil */}
              <Grid
                className={classes.butonLang}
                item
                xs={3}
                md={1}
                onClick={() => this.openHomePage("Tamil")}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  elevation={5}
                  className={[classes.bottomItemButton]}
                >
                  <Typography className={classes.btnText}>
                    {" "}
                    {mobSize == false ? "தமிழ்" : "த"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Slide>
          <Grid item xs={12}>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              elevation={5}
              // style={{margin:"30px 0"}}
            >
              <Link href="https://www.tisrilanka.org/">
                <img
                  className={classes.tislLogo}
                  alt="TISL_logo"
                  src="/assets/images/TISL_logo.png"
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            backgroundColor: "#02b0f5",
            height: 30,
            position: "fixed",
            left: 0,
            bottom: 0,
          }}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <div className=" flex flex-middle">
            <div
              className="flex flex-middle container px-sm-30 w-full"
              justify="flex-end"
              alignItems="center"
            >
              <span className="m-auto"></span>
              <p className="m-0" style={{ color: "white" }}>
                Copyright © 2021 Transparency International Sri Lanka
              </p>
            </div>
          </div>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(LanguageSelect);
