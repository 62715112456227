import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {
  useMediaQuery,
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  Link,
  Chip,
  Grid,
  SwipeableDrawer,
  Avatar,
  Icon,
  IconButton,
  MenuItem,
  Button,
  GridList,
  GridListTile,
  GridListTileBar,
  Divider,
  ListItemText,
  CardActionArea,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Fade from "@material-ui/core/Fade";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { Alert, AlertTitle } from "@material-ui/lab";
import Footer from "./components/Footer";
import AppAppBar from "./components/AppAppBar";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import IncidentService from "../../services/IncidentServices";
import Util from "../../services/Util";
import * as appconst from "../../appconst";

import { withStyles, useTheme } from "@material-ui/core/styles";

import { englishLanguage } from "../languages/englishLanguage";
import { sinhalaLanguage } from "../languages/sinhalaLanguage";
import { tamilLanguage } from "../languages/tamilLanguage";

import languageService from "app/services/languageService";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
function MyComponent() {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return <span>{`theme.breakpoints.up('sm') matches: ${matches}`}</span>;
}

const styleSheet = (theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginTop: "-75px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridroot: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: "#ffffff",
    width: "100%",
  },
  gridList: {
    // overflow: "unset",
    height: "100%",
    margin: "revert !important",
  },
  mobview: {
    // marginTop:-144,
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },

  mainPadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 110,
      paddingRight: 30,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 20,
      // paddingRight: 20,
    },
  },
  card: {
    borderRadius: 8,
    height: 280,
    overflow: "hidden",
    cursor: "pointer",
    // boxShadow:
    //   " 0 8px 20px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",
    transition: "all ease 200ms",

    "&:hover": {
      filter: "brightness(1)",
      transform: "scale(1.05)",
      boxShadow:
        "0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02)",
    },
  },
  cardPadding: {
    padding: "40px 30px",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    // [theme.breakpoints.up("md")]: {
    //   width: "100%",
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    // },
  },

  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  imageContainer: {
    position: "relative",
  },
  btnMoreOverlay: {
    borderRadius: 6,
    position: "absolute",
    top: 0,
    left: 0,
    background: "#02b0f5",

    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
    transition: "opacity 0.25s",
    color: "white",

    "&:hover": {
      opacity: 0.9,
    },
    "&:hover > *": {
      transform: "translateY(0)",
    },
  },
  btnMore: {
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    color: "#ffff",
    border: "1px solid #ffff",
    padding: "10px 20px",
    transform: "translateY(20px)",
    transition: "transform 0.25s",
  },
  chipLable: {
    backgroundColor: "#02b0f5",

    "& .MuiChip-label": {
      color: "white",
    },
  },
  pageTitlePadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 116,
      paddingTop: 20,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 30,
      paddingTop: 20,
    },
  },
  //Main story Image
  featurednewsImage: {
    width: "100%",
    height: 400,
    borderRadius: 6,

    // padding: 18,
    boxShadow:
      " 0 8px 20px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",

    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      height: 230,
    },
    // paddingRight: theme.spacing(1),
  },
  storyGridList: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  //News list item Styles
  listItem: {
    cursor: "pointer",
    // backgroundColor: "#1f1f2e",
    color: "black",
    // boxShadow:
    //   " 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",
    borderRadius: 10,
    paddingRight: "unset",
    margin: "5px 20px 5px 22px",
    width: "100%",
    transition: "all ease 200ms",
    "&:hover": {
      color: "acent",
    },
  },
  listItemTitle: {
    cursor: "pointer",
    margin: "5px 0 2px 0",
    fontSize: 20,
    fontWeight: 700,
    color: "black",
    //fontFamily: "Source Sans Pro",
  },
  listItemSubTitle: {
    cursor: "pointer",
    fontWeight: 400,
    color: "#808080",
  },
  //List news Image
  newsImage: {
    width: 100,
    height: 83,
    borderRadius: 6,
    marginLeft: -38,
    marginRight: 10,
  },
  mainNewsTitle: {
    margin: "5px 0 2px ",
    fontWeight: 700,
    color: "#000",
    fontSize: 18,

    // [theme.breakpoints.down("md")]: {
    //   marginLeft: 17,
    //   marginRight: 15,
    // },
  },
});

class Stories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storyDes: "",
      language: englishLanguage,
      windowWidth: null,
      incidents: [],
      pagination: {
        current_page: 1,
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        next_page_url: "",
        path: "",
        per_page: 10,
        to: 0,
        total: 0,
      },
      filterData: {
        reference_number: null,
        type: "Share Story",
      },
      errorTitle: "",
      error: "",
      errorDialog: false,
    };
  }

  async loadLanguageDetails() {
    var lng = await languageService.getLanguageDetails();
    // console.log("language",lng);

    if (lng == "Sinhala") {
      this.setState({
        language: sinhalaLanguage,
      });
    } else if (lng == "Tamil") {
      this.setState({
        language: tamilLanguage,
      });
    }
  }

  async componentDidMount() {
    this.loadLanguageDetails();

    this.loadData(1);

    this.setState({
      windowWidth: window.innerWidth,
    });

    window.addEventListener("resize", () => {
      this.setState({
        windowWidth: window.innerWidth,
      });
    });
  }

  async loadData(page) {
    let filter = this.state.filterData;
    let corruptions = await IncidentService.getPublicIncidentWithFilters(
      filter,
      page
    );
    console.log("stories", corruptions);
    //console.log("pagination",corruptions.data.insidents)
    if (corruptions.status == 200) {
      this.setState({
        incidents: corruptions.data.insidents.data,
        pagination: corruptions.data.insidents,
      });
    } else if (corruptions.status == 205) {
      this.setState({
        incidents: [],
        errorTitle: "Wrong Reference Number",
        error:
          "You entered reference number is not there please enter correct reference number.",
        errorDialog: true,
      });
    } else if (corruptions.status == 206) {
      this.setState({
        incidents: [],
        errorTitle: "Not Published",
        error: "Your incident is under the review. Please try again later.",
        errorDialog: true,
      });
    }
  }

  getGridListCols() {
    let width = this.state.windowWidth;
    var noOfItems = this.state.incidents.length;

    // console.log(width)

    if (width > 1250) {
      if (noOfItems > 3) {
        return 4;
      } else if (noOfItems > 2) {
        return 3;
      } else if (noOfItems > 1) {
        return 2;
      } else if (noOfItems == 1) {
        return 1;
      }
    } else if (width > 930) {
      if (noOfItems > 2) {
        return 3;
      } else if (noOfItems > 1) {
        return 2;
      } else if (noOfItems == 1) {
        return 1;
      }
    } else if (width > 600) {
      if (noOfItems > 2) {
        return 2;
      } else if (noOfItems == 1) {
        return 1;
      }
    } else if (width < 600 && noOfItems > 0) {
      return 1;
    }
  }

  finishDialogBoxRefClose = () => {
    this.setState({
      errorDialog: false,
    });
  };

  setReferenceNo(val) {
    var filterData = this.state.filterData;
    filterData["reference_number"] = val;
    this.setState({ filterData });
  }

  async searchByReference() {
    this.loadData(1);
  }
  handleChangePage = async (event, newPage) => {
    // console.log(page)
    this.loadData(newPage);
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <Fragment>
        {/* App bar component */}
        <AppAppBar />
        <div
          className={classes.pageTitlePadding}
          style={{
            backgroundImage: `url(/assets/images/statBackground.png)`,
            // backgroundImage: `url(/assets/images/incidents_slide_image.jpg)`,
            // background: "linear-gradient(45deg, #ff0081, #6827b0)",
            width: "100%",
            height: 220,
            backgroundSize: "cover",
            display: "flex",
            // justifyContent: "center",
            float: "left",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            style={{
              fontSize: 34,
              color: "white",
              fontWeight: 600,
            }}
          >
            {/* Stories */}
            {this.state.language.story_appBar}
          </Typography>
        </div>
        <Grid
          container
          className={["px-2 pb-5 mt-2"]}
          spacing={1}
          justifyContent="center"
          style={{ backgroundColor: "white" }}
        >
          <Grid item xs={12} sm={6} lg={4} className="py-0 ">

            <ValidatorForm
              onSubmit={() => this.searchByReference()}

            >
              <Paper className={classes.root}>

                <InputBase
                  onChange={(e) => {
                    console.log("event", e)
                    this.setReferenceNo(e.target.value);
                  }}
                  className={classes.input}
                  placeholder={this.state.language.searchbar_placeholder} //"Search by Reference Number"
                  inputProps={{ "aria-label": "Search by Reference Number" }}
                />
                <IconButton
                  onClick={() => this.searchByReference()}
                  type="button"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </ValidatorForm>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          className={[classes.mainPadding, "py-10"]}
          style={{ backgroundColor: "white" }}
        >
          {/* Main Story Grid */}
          <Grid item container className={classes.mainPadding} spacing={4}>
            {/* Top Story Grid */}
            <Grid item container xs={12} md={7} alignItems="flex-start">
              {/* Grid that contain Top Story Image and Story Header */}
              {this.state.incidents.length > 0 ? (
                <Grid

                  item
                  onClick={() => {
                    window.location.href =
                      "/incidents/" + this.state.incidents[0].id;
                  }}
                  container
                  xs={12}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#ffff",
                    borderRadius: 10,

                  }}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <div className={classes.imageContainer}>
                      {this.state.incidents[0].attachments == "[]" ||
                        this.state.incidents[0].attachments == null ? (
                        <div
                          className={classes.featurednewsImage}
                          style={{
                            backgroundImage:
                              "url(/assets/images/default_incident.png)",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        ></div>
                      ) : (
                        <div
                          className={classes.featurednewsImage}
                          style={{
                            backgroundImage:
                              "url(" +
                              appconst.ATTACHMENTS_LOCATION +
                              "news/" +
                              JSON.parse(
                                this.state.incidents[0].incident_report
                                  .other_details.attachments
                              )[0] +
                              ")",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        ></div>

                        // <img
                        //   alt="newsImg"
                        //   src={
                        //     appconst.ATTACHMENTS_LOCATION +
                        //     "news/" +
                        //     JSON.parse(row.attachments)[0]
                        //   }
                        //   className={classes.featurednewsImage}
                        // />
                      )}
                      <div className={classes.btnMoreOverlay}>
                        <button
                          onClick={() => {
                            window.location.href =
                              "/incidents/" + this.state.incidents[0].id;
                          }}
                          className={classes.btnMore}
                        >
                          {/* Read More */}
                          {
                            this.state.incidents[0].language === "Sinhala"
                              ? sinhalaLanguage.readMoreButton
                              : this.state.incidents[0].language === "Tamil"
                                ? tamilLanguage.readMoreButton
                                : englishLanguage.readMoreButton
                          }
                        </button>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    {/* Main News Title for Large Screens */}
                    <Grid item className={classes.mainNewsTitle}>
                      <Grid
                        item
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Chip
                          className={["mr-3", classes.chipLable]}
                          display="inline"
                          size="small"
                          label={
                            this.state.incidents[0].language === "Sinhala"
                              ? sinhalaLanguage.newsPage_published_Txt
                              : this.state.incidents[0].language === "Tamil"
                                ? tamilLanguage.newsPage_published_Txt
                                : englishLanguage.newsPage_published_Txt
                          }//"Published"
                          color="primary"
                        />

                        <Typography
                          display="inline"
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {Util.getParsedDate(
                            this.state.incidents[0].created_at
                          )}
                        </Typography>
                      </Grid>
                      <Grid item className={classes.mainNewsTitle}>
                        <Typography
                          variant="body2"
                          component="p"
                          style={{ fontWeight: 700, fontSize: 23 }}
                        >
                          {Util.getcurruptionType(
                            this.state.incidents[0].language,
                            this.state.incidents[0].incident_report
                          )}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item className={classes.mainNewDescription}>
                      <Typography
                        variant="body2"
                        // color="textSecondary"
                        component="p"
                        gutterBotttom
                        style={{ marginBottom: 5 }}
                      >
                        {/* {row.district} */}
                        {Util.getDistrict_Language_Label(
                          this.state.incidents[0].language,
                          this.state.incidents[0].district
                        )}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        align="justify"
                      >
                        {this.state.incidents[0].story != null
                          ? String(this.state.incidents[0].story).length > 150
                            ? this.state.incidents[0].story
                              .substring(0, 150)
                              .concat("...")
                            : this.state.incidents[0].story
                          : ""}
                        {/* {this.state.storyDes.length > 1000
                          ? this.state.storyDes.substring(0, 1000)
                          : this.state.storyDes} */}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            {/* List Story Grid */}
            <Grid item container xs={12} md={4} >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexWrap: "wrap",

                  fontSize: 22,
                  fontWeight: 700,
                }}
              >
                <span>
                  {/* Latest Stories */}
                  {this.state.language.incidents_latestStories}
                </span>
                <ArrowRightIcon style={{ fontSize: 33 }} />
              </div>
              <Grid container className={classes.mobview}>
                <GridList cols={1} className={classes.gridList}>
                  {this.state.incidents.map((row, key) =>
                    key == 0 ? null : (
                      <Grid
                        key={key}
                        style={{
                          height: "fitContent",
                          padding: "0 38px 0 0 ",
                          margin: "5px 0",
                        }}
                      >
                        {console.log(row)}
                        <ListItem
                          onClick={() => {
                            window.location.href = "/incidents/" + row.id;
                          }}
                          button
                          className={classes.listItem}
                        >
                          {row.incident_report == undefined ? (
                            <div
                              className={classes.newsImage}
                              style={{
                                backgroundImage:
                                  "url(/assets/images/default_incident.png)",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            ></div>
                          ) : row.incident_report.other_details == null ? (
                            <div
                              className={classes.newsImage}
                              style={{
                                backgroundImage:
                                  "url(/assets/images/default_incident.png)",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            ></div>
                          ) : (
                            // <div
                            //   className={classes.newsImage}
                            //   style={{
                            //     backgroundImage:
                            //       "url(/assets/images/default_incident.png)",
                            //     backgroundSize: "500px",
                            //   }}
                            // ></div>
                            // <img
                            //   alt="incidentImg"
                            //   src="/assets/images/default_incident.png"
                            //   className={classes.newsImage}
                            // />
                            <div
                              className={classes.newsImage}
                              style={{
                                backgroundImage:
                                  "url(" +
                                  appconst.ATTACHMENTS_LOCATION +
                                  "news/" +
                                  JSON.parse(
                                    row.incident_report.other_details
                                      .attachments
                                  )[0] +
                                  ")",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            ></div>

                            // <img
                            //   alt="newsImg"
                            //   src={
                            //     appconst.ATTACHMENTS_LOCATION +
                            //     "news/" +
                            //     JSON.parse(row.attachments)[0]
                            //   }
                            //   className={classes.newsImage}
                            // />
                          )}
                          <Grid item xs={12}>
                            <Grid
                              item
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Chip
                                className={["mr-3", classes.chipLable]}
                                display="inline"
                                size="small"
                                label={
                                  row.language === "Sinhala"
                                    ? sinhalaLanguage.newsPage_published_Txt
                                    : row.language === "Tamil"
                                      ? tamilLanguage.newsPage_published_Txt
                                      : englishLanguage.newsPage_published_Txt
                                }
                                //"Published"
                                color="primary"
                              />

                              <Typography
                                display="inline"
                                variant="body2"
                                color="textSecondary"
                                component="p"
                              >
                                {Util.getParsedDate(row.created_at)}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body2"
                                component="p"
                                style={{ fontWeight: 700, fontSize: 15 }}
                              >
                                {Util.getcurruptionType(
                                  row.language,
                                  row.incident_report
                                )}
                              </Typography>
                            </Grid>
                            <Typography
                              variant="body2"
                              // color="textSecondary"
                              component="p"
                            >
                              {/* {row.district} */}
                              {Util.getDistrict_Language_Label(
                                row.language,
                                row.district
                              )}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {row.story != null
                                ? String(row.story).length > 20
                                  ? row.story.substring(0, 20).concat("...")
                                  : row.story
                                : ""}
                            </Typography>
                          </Grid>
                        </ListItem>
                        <Divider></Divider>
                      </Grid>
                    )
                  )}
                  {/* ))} */}
                </GridList>
              </Grid>
            </Grid>
          </Grid>
          {/* Story Cards */}

          {/* <Grid
            className={classes.cardPadding}
            container
            alignItems="center"
            direction="row"
          >
            {this.state.incidents.map((row, key) =>
              key == 0 ? null : (
                <Fade timeout={1000 * key} in={true}>
                  <Grid item style={{ width: 340, minWidth: 340, margin: 10 }}>
                    <Card
                      className={classes.card}
                      onClick={() => {
                        window.location.href = "/incidents/" + row.id;
                      }}
                      elevation={3}
                    >
                      <CardActionArea>
                        <div className={classes.imageContainer}>
                          {row.incident_report == undefined ? (
                            <CardMedia
                              className={classes.media}
                              image="/assets/images/default_incident.png"
                            />
                          ) : row.incident_report.other_details == null ? (
                            <CardMedia
                              className={classes.media}
                              image="/assets/images/default_incident.png"
                            />
                          ) : (
                            <CardMedia
                              className={classes.media}
                              image={
                                appconst.ATTACHMENTS_LOCATION +
                                JSON.parse(
                                  row.incident_report.other_details.attachments
                                )[0]
                              }
                            />
                          )}
                          <div className={classes.btnMoreOverlay}>
                            <button
                              onClick={() => {
                                window.location.href = "/incidents/" + row.id;
                              }}
                              className={classes.btnMore}
                            >
                              {/* Read More *
                              {row.language === "Sinhala"
                                ? sinhalaLanguage.readMoreButton
                                : row.language === "Tamil"
                                ? tamilLanguage.readMoreButton
                                : englishLanguage.readMoreButton}
                            </button>
                          </div>
                        </div>
                        <CardContent>
                          <Typography
                            display="inline"
                            gutterBottom
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {Util.getParsedDate(row.created_at)}
                          </Typography>

                          <Chip
                            className="ml-3"
                            display="inline"
                            size="small"
                            label="Published"
                            color="primary"
                          />
                          <Typography variant="body2" component="p">
                            {row.incident_report == undefined
                              ? "Share Story"
                              : Util.getcurruptionType(
                                  row.incident_report.type_of_corruption
                                )}
                            , {row.district}
                          </Typography>

                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {row.story != null
                              ? String(row.story).length > 30
                                ? row.story.substring(0, 30).concat("...")
                                : row.story
                              : ""}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      {/* <CardActions style={{ padding: 0, paddingLeft: 8 }}>
                      <Button
                        onClick={() => {
                          window.location.href = "/incidents/" + row.id;
                        }}
                        color="primary"
                      >
                        Read More...
                      </Button>
                    </CardActions>
                    </Card>
                  </Grid>
                </Fade>
              )
            )}
          </Grid> */}

          <Grid container className="p-8" justify="flex-end">
            <Pagination
              count={Number(this.state.pagination.last_page)}
              //rowsPerPage={Number(this.state.pagination.per_page)}
              page={this.state.pagination.current_page}
              onChange={this.handleChangePage}
              variant="outlined"
              color="primary"
            />
          </Grid>
        </Grid>

        <Dialog
          open={this.state.errorDialog}
          onClose={() => {
            this.finishDialogBoxRefClose();
          }}
        >
          <DialogContent>
            <Grid item xs={12} sm={6}></Grid>
            <Alert severity="error">
              <strong>{this.state.errorTitle}</strong>
              <p>{this.state.error}</p>
            </Alert>
          </DialogContent>

          <DialogActions></DialogActions>
        </Dialog>

        {/* Footer */}
        <Footer />
      </Fragment>
    );
  }
}

export default withStyles(styleSheet)(Stories);
