import axios from 'axios';
import * as appconst from './../appconst';
import localStorageService from "./localStorageService";

class  UserServices {

  //create new user
  createNewUser = async(data) => {

    const user = await localStorageService.getItem('auth_user');

    const res_data = new Promise((resolve,reject) => {

        axios.post(appconst.CREATE_USER, data,{
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }
        ).then( res =>{
            // console.log(res);
            return resolve(res);
        }).catch((error) => {
            console.log(error);
            return reject(error);
        });

    });

    return await res_data;

  }

  //get all users
  getAllUsers = async(data,page) => {

    const user = await localStorageService.getItem("auth_user");

    const getAll = new Promise((resolve, reject) => {
      axios.get(appconst.ALL_USERS+"?page=" + page, 
        {
            headers: { Authorization:`Bearer ${user.token}` 
        }
      })
      .then(res=> {
        return resolve(res);
      })
      .catch(error => {
        console.log(error);
      })
    })

    return await getAll;

  }

  getMyDetail = async() => {

    const user = await localStorageService.getItem("auth_user");

    const profile = new Promise((resolve, reject) => {
      axios.get(appconst.MY_PROFILE, 
        {
            headers: { Authorization:`Bearer ${user.token}` 
        }
      })
      .then(res=> {
        return resolve(res);
      })
      .catch(error => {
        console.log(error);
      })
    })

    return await profile;

  }

   //get user single view
   getSingleUserByID = async(id) => {
    
    const user = await localStorageService.getItem("auth_user");

    const a = new Promise((resolve, reject) => {
      axios.get(appconst.GET_SINGLE_USER + id, {
          headers: { Authorization:`Bearer ${user.token}` }
      }).then(res => {
        return resolve(res);
      })
      .catch((error)=>{
        console.log("Error:"+error);
      })
    });
  
      return await a;
  }

   //edit user details
   editUserDetails = async(data, id) => {
    
    const user = await localStorageService.getItem("auth_user");

    const a = new Promise((resolve, reject) => {
      axios.put(appconst.EDIT_USER + id, data,{
          headers: { Authorization:`Bearer ${user.token}` }
      }).then(res => {
        return resolve(res);
      })
      .catch((error)=>{
        console.log("Error:"+error);
      })
    });
  
      return await a;
  }

   //upload profile pic
   uploadProfilePicture = async(data, id) => {
    
    const user = await localStorageService.getItem("auth_user");

    const a = new Promise((resolve, reject) => {
      axios.post(appconst.UPLOAD_PROFILE_PICTURE, data,{
          headers: { Authorization:`Bearer ${user.token}` }
      }).then(res => {
        return resolve(res);
      })
      .catch((error)=>{
        console.log("Error:"+error);
      })
    });
  
      return await a;
  }

   //change user active Status
   changeUserActiveStatus = async(data, id) => {
    
    const user = await localStorageService.getItem("auth_user");

    const a = new Promise((resolve, reject) => {
      axios.put(appconst.USER_ACTIVE_STATUS_CHANGE + id, data,{
          headers: { Authorization:`Bearer ${user.token}` }
      }).then(res => {
        return resolve(res);
      })
      .catch((error)=>{
        console.log("Error:"+error);
      })
    });
  
      return await a;
  }

}

export default new UserServices();