import React, { Component } from "react";
import PropTypes from "prop-types";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  Link,
  Grid,
  SwipeableDrawer,
  Avatar,
  Icon,
  IconButton,
  MenuItem,
  Button,
  GridList,
  GridListTile,
  GridListTileBar,
  Divider,
  ListItemText,
} from "@material-ui/core";
import ReactPlayer from "react-player";
import { themeColors } from "app/MatxLayout/MatxTheme/themeColors";

import { withStyles } from "@material-ui/core/styles";

import { englishLanguage } from "../../languages/englishLanguage";
import { sinhalaLanguage } from "../../languages/sinhalaLanguage";
import { tamilLanguage } from "../../languages/tamilLanguage";

import languageService from "app/services/languageService";
import * as appconst from '../../../appconst';
const styleSheet = (theme) => ({
  gridList: {
    height: 275,
  },

  newsImage: {
    width: 90,
    height: 60,
    paddingRight: theme.spacing(1),
  },
  newsHrLine: {
    backgroundColor: "#0074e1",
    height: 3,
  },
  listItem: {
    borderBottomColor: "#dbdcdc",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
  mainPadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 70,
      paddingRight: 70,
      paddingTop: 50,
      paddingBottom: 50,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  reactPlayer: {

    borderRadius: 30,


  },
});

class VideoSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: englishLanguage,
      languageName: "English"
    };
  }

  async loadLanguageDetails() {
    var lng = await languageService.getLanguageDetails();

    if (lng == "Sinhala") {
      this.setState({
        language: sinhalaLanguage,
        languageName: "Sinhala"
      });
    } else if (lng == "Tamil") {
      this.setState({
        language: tamilLanguage,
        languageName: "Tamil"
      });
    }
    // console.log("FAQ Video Section:", this.state);
  }

  componentDidMount() {
    this.loadLanguageDetails();
  }

  render() {
    const { classes } = this.props;
    return (
      <div

      >
        {/* <Grid
          container
          
        > */}
        {/* <Grid item md={6}>
         how to use apesalli
          </Grid> */}
        {/* 
          <Grid item xs={12} md={6} > */}
        <ReactPlayer
          width="100%"
          height="100%"
          className={classes.reactPlayer}
          url={this.state.languageName === "English" ? appconst.HOMEPAGE_VIDEO_LOCATION_ENGLISH : (this.state.languageName === "Sinhala" ? appconst.HOMEPAGE_VIDEO_LOCATION_SINHALA : appconst.HOMEPAGE_VIDEO_LOCATION_TAMIL)}

          controls={true}
        />
        {/* </Grid>
        </Grid> */}
      </div>
    );
  }
}

export default withStyles(styleSheet)(VideoSection);
