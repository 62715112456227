import React, { Component } from "react";
import PropTypes from "prop-types";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  Link,
  Grid,
  SwipeableDrawer,
  Avatar,
  Icon,
  IconButton,
  MenuItem,
  Button,
  GridList,
  GridListTile,
  GridListTileBar,
  Divider,
  ListItemText,
} from "@material-ui/core";
import { themeColors } from "app/MatxLayout/MatxTheme/themeColors";

import { withStyles } from "@material-ui/core/styles";

import { englishLanguage } from "../../languages/englishLanguage";
import { sinhalaLanguage } from "../../languages/sinhalaLanguage";
import { tamilLanguage } from "../../languages/tamilLanguage";

import languageService from "app/services/languageService";

const styleSheet = (theme) => ({
  gridList: {
    height: 275,
  },
  mainPadding: {
    paddingTop: 40,
    paddingBottom: 40,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 80,
      paddingRight: 50,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  newsImage: {
    width: 90,
    height: 60,
    paddingRight: theme.spacing(1),
  },
  newsHrLine: {
    backgroundColor: "#0074e1",
    height: 3,
  },
  listItem: {
    borderBottomColor: "#dbdcdc",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
});

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: englishLanguage,
    };
  }

  async loadLanguageDetails() {
    var lng = await languageService.getLanguageDetails();

    if (lng == "Sinhala") {
      this.setState({
        language: sinhalaLanguage,
      });
    } else if (lng == "Tamil") {
      this.setState({
        language: tamilLanguage,
      });
    }
    // console.log("Footer Section:", this.state);
  }

  componentDidMount() {
    this.loadLanguageDetails();
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container spacing={1}>
          <Grid
            container
            spacing={1}
            className={classes.mainPadding}
            style={{
              backgroundColor: "white",

            }}
          >
            {/* Colombo Office */}
            <Grid item xs={12} sm={4} className="py-5">
              <div className={classes.card}>
                <div>

                  <Typography
                    variant="h6"
                    component="h6"
                    // gutterBottom
                    style={{ fontWeight: 900 }}
                  >
                    {/* Colombo Office */}
                    {this.state.language.footer_addressColomboTitle}
                  </Typography>
                  <Typography
                    variant="p"
                    component="h6"
                    gutterBottom
                    style={{ fontWeight: 500 }}
                  >
                    {/* 5/1, Elibank Road, Colombo 05 */}
                    {this.state.language.footer_addressColomboDescription}
                  </Typography>
                  <div>
                    <Typography
                      display="inline"
                      variant="p"
                      component="h6"
                      //   gutterBottom
                      style={{ fontWeight: 800 }}
                    >
                      {/* Phone: */}
                      {this.state.language.footer_addressPhoneName}
                    </Typography>

                    <Typography
                      display="inline"
                      variant="p"
                      component="h6"
                      //   gutterBottom
                      style={{ fontWeight: 500 }}
                    >
                      {/* (+94) 0114369781 | 0114369782 | 0114369783 */}
                      {this.state.language.footer_addressColomboPhoneValue}
                    </Typography>
                  </div>

                  <div className="mt-0">
                    <Typography
                      display="inline"
                      variant="p"
                      component="h6"
                      //gutterBottom
                      style={{ fontWeight: 800 }}
                    >
                      {/* Fax: */}
                      {this.state.language.footer_addressFaxName}
                    </Typography>

                    <Typography
                      display="inline"
                      variant="p"
                      component="h6"
                      //gutterBottom
                      style={{ fontWeight: 500 }}
                    >
                      {/* (+94) 011 2 501 707 */}
                      {this.state.language.footer_addressColomboFaxValue}
                    </Typography>
                  </div>

                  <div >
                    <Typography
                      display="inline"
                      variant="p"
                      component="h6"
                      gutterBottom
                      style={{ fontWeight: 800 }}
                    >
                      {/* Email: */}
                      {this.state.language.footer_addressEmailName}
                    </Typography>

                    <Typography
                      display="inline"
                      variant="p"
                      component="h6"
                      gutterBottom
                      style={{ fontWeight: 500 }}
                    >
                      {/* tisl@tisrilanka.org */}
                      {this.state.language.footer_addressColomboEmailValue}
                    </Typography>
                  </div>
                  <div className="mt-0">
                    {/* <Typography
                      display="inline"
                      variant="p"
                      component="h6"
                      gutterBottom
                      style={{ fontWeight: 800 }}
                    > */}
                    {/* Web: */}
                    {/* {this.state.language.footer_addressWebName}
                    </Typography> */}

                    {/* <Typography
                      display="inline"
                      variant="p"
                      component="h6"
                      gutterBottom
                      style={{ fontWeight: 500 }}
                    > */}
                    {/* <Link
                        href={
                          this.state.language.footer_addressColomboWebHrefValue
                        }
                      > */}
                    {/* www.tisrilanka.org */}
                    {/* {this.state.language.footer_addressColomboWebValue}
                      </Link>
                    </Typography> */}
                  </div>
                </div>
              </div>
            </Grid>

            {/* Matara Office */}
            <Grid item xs={12} sm={4} className="py-5 ">
              <div className={classes.card}>
                <div>
                  <Typography
                    variant="h6"
                    component="h6"
                    // gutterBottom
                    style={{ fontWeight: 900 }}
                  >
                    {/* Matara Office */}
                    {this.state.language.footer_addressMataraTitle}
                  </Typography>
                  <Typography
                    variant="p"
                    component="h6"
                    gutterBottom
                    style={{ fontWeight: 500 }}
                  >
                    {/* 17, Kalidasa Road, Matara */}
                    {this.state.language.footer_addressMataraDescription}
                  </Typography>
                  <div >
                    <Typography
                      display="inline"
                      variant="p"
                      component="h6"
                      gutterBottom
                      style={{ fontWeight: 800 }}
                    >
                      {/* Phone: */}
                      {this.state.language.footer_addressPhoneName}
                    </Typography>

                    <Typography
                      display="inline"
                      variant="p"
                      component="h6"
                      gutterBottom
                      style={{ fontWeight: 500 }}
                    >
                      {/* (+94) 041 22 33 522 */}
                      {this.state.language.footer_addressMataraPhoneValue}
                    </Typography>
                  </div>

                  <div className="mt-0">
                    <Typography
                      display="inline"
                      variant="p"
                      component="h6"
                      gutterBottom
                      style={{ fontWeight: 800 }}
                    >
                      {/* Email: */}
                      {this.state.language.footer_addressEmailName}
                    </Typography>

                    <Typography
                      display="inline"
                      variant="p"
                      component="h6"
                      gutterBottom
                      style={{ fontWeight: 500 }}
                    >
                      {/* alac-matara@tisrilanka.org */}
                      {this.state.language.footer_addressMataraEmailValue}
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>


            <Grid item xs={12} sm={4} className="py-5 ">
              <div className={classes.card}>
                <div>
                  <Typography
                    variant="h6"
                    component="h6"
                    // gutterBottom
                    style={{ fontWeight: 900 }}
                  >
                    {/* Vavuniya Office */}
                    {this.state.language.footer_addressVavuniyaTitle}
                  </Typography>
                  <Typography
                    variant="p"
                    component="h6"
                    gutterBottom
                    style={{ fontWeight: 500 }}
                  >
                    {/* SLRCS Building , Off Park Road , Vavuniya */}
                    {this.state.language.footer_addressVavuniyaDescription}
                  </Typography>
                  <div >
                    <Typography
                      display="inline"
                      variant="p"
                      component="h6"
                      gutterBottom
                      style={{ fontWeight: 800 }}
                    >
                      {/* Phone: */}
                      {this.state.language.footer_addressPhoneName}
                    </Typography>

                    <Typography
                      display="inline"
                      variant="p"
                      component="h6"
                      gutterBottom
                      style={{ fontWeight: 500 }}
                    >
                      {/* (+94) 024 222 76 76 */}
                      {this.state.language.footer_addressVavuniyaPhoneValue}
                    </Typography>
                  </div>

                  <div className="mt-0">
                    <Typography
                      display="inline"
                      variant="p"
                      component="h6"
                      gutterBottom
                      style={{ fontWeight: 800 }}
                    >
                      {/* Email: */}
                      {this.state.language.footer_addressEmailName}
                    </Typography>

                    <Typography
                      display="inline"
                      variant="p"
                      component="h6"
                      gutterBottom
                      style={{ fontWeight: 500 }}
                    >
                      {/* alac-vavuniya@tisrilanka.org */}
                      {this.state.language.footer_addressVavuniyaEmailValue}
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>



          <Grid
            container
            spacing={2}
            className="py-5"
            style={{
              justifyContent: "center",
              backgroundColor: "#f5f5f5",
            }}
          >
            <div className="mt-10 pt-2" style={{ textAlign: "center" }}>
              <Typography
                variant="p"
                component="h6"
                gutterBottom
                style={{ fontWeight: 800 }}
              >
                Other websites related to Transparency International Sri Lanka
              </Typography>


              <Grid container spacing={1}>
                <Grid item xs={12} sm={3} className="py-5 " style={{ background: "cover" }}>
                  <Link href="https://www.tisrilanka.org/">
                    <img
                      src="/assets/images/TISL_logo.png"
                      style={{ height: 30, }}
                    />
                  </Link>
                </Grid>
                <Grid item xs={12} sm={3} className="py-5 ">
                  <Link href="https://www.rtiwatch.lk">
                    <img
                      src="/assets/images/rtiwatch.png"
                      style={{ height: 40 }}
                    />
                  </Link>
                </Grid>
                <Grid item xs={12} sm={3} className="py-5 ">
                  <Link href="http://www.integrityicon.lk">
                    <img
                      src="/assets/images/intergrity.png"
                      style={{ height: 40 }}
                    />
                  </Link>
                </Grid>
                <Grid item xs={12} sm={3} className="py-5 ">
                  <Link href="https://www.peps.lk/">
                    <img
                      src="/assets/images/peplogo.png"
                      style={{ height: 40 }}
                    />
                  </Link>
                </Grid>
              </Grid>
              <div className="mt-5"></div>

              <Typography
                variant="p"
                component="h6"
                gutterBottom
                style={{ fontWeight: 800 }}
              >
                Follow us
              </Typography>
              <Grid
                container
                spacing={2}
                className="py-5"
                style={{ justifyContent: "center" }}
              >
                <Link
                  className="pl-2"
                  href="https://www.facebook.com/tisrilanka"
                >
                  <img
                    src="/assets/images/fb.png"
                    style={{ width: 40, height: 40 }}
                  />
                </Link>

                <Link className="pl-2" href="https://twitter.com/tisrilanka">
                  <img
                    src="/assets/images/twiter.png"
                    style={{ width: 40, height: 40 }}
                  />
                </Link>

                <Link
                  className="pl-2"
                  href="https://www.instagram.com/transparency_sri_lanka/"
                >
                  <img
                    src="/assets/images/instagram.png"
                    style={{ width: 40, height: 40 }}
                  />
                </Link>

                <Link
                  className="pl-2"
                  href="https://www.linkedin.com/company/tisrilanka/"
                >
                  <img
                    src="/assets/images/linkin.png"
                    style={{ width: 40, height: 40 }}
                  />
                </Link>
              </Grid>
            </div>

            <Grid item xs={12} md={12}>
              <Grid
                container
                spacing={2}
                className="py-0"
                style={{ justifyContent: "center" }}
              >
                <Link className="pl-2" href="/disclaimer">
                  <Typography
                    display="inline"
                    variant="p"
                    component="h6"
                    gutterBottom
                    style={{ fontWeight: 800 }}
                  >
                    {/* Disclaimer */}
                    {this.state.language.footer_disclaimer_btn}
                  </Typography>
                </Link>

                <Link className="pl-2" href="/privacyPolicy">
                  <Typography
                    display="inline"
                    variant="p"
                    component="h6"
                    gutterBottom
                    style={{ fontWeight: 800 }}
                  >
                    {/* Privacy Policy */}
                    {this.state.language.footer_privacyPolicy_btn}
                  </Typography>
                </Link>

                {/* <Grid item> */}

                {/* </Grid> */}

              </Grid>

              <div>
                <img
                  alt="apesalli Logo"
                  src="/assets/images/apesalli_logo.png"
                  style={{ width: 80, height: 80, float: 'right', marginRight: 20, }}
                />
              </div>

            </Grid>

          </Grid>
        </Grid>

        <Grid
          container
          style={{ backgroundColor: "#1e2935", height: 50 }}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <div className=" flex flex-middle">
            <div
              className="flex flex-middle container px-sm-30 w-full"
              justify="flex-end"
              alignItems="center"
            >
              <span className="m-auto"></span>
              <p className="m-0" style={{ color: "white" }}>
                Created with ♥ by <a href="https://loonslab.com">Loons Lab </a>
                2021
              </p>
            </div>
          </div>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styleSheet)(Footer);
