import React, { Component, Fragment, useEffect } from "react";
import { Grid, Card, Button, Typography, Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import Container from "@material-ui/core/Container";
import { themeColors } from "app/MatxLayout/MatxTheme/themeColors";
import Fade from "@material-ui/core/Fade";

import AppAppBar from "./components/AppAppBar";
import Carousel_home from "./components/homePageComponents/Carousel_home";
import News from "./components/homePageComponents/News";
import Cards from "./components/homePageComponents/Cards_body";
import StatisticsBar from "./components/Charts/StatisticsBar";
import AllIncidentsChart from "./components/Charts/AllIncidentsChart";
import ClusterMap from "./components/ClusterMap";
import Footer from "./components/Footer";
import VideoSection from "./components/VideoSection";
import AboutUsSection from "./components/AboutUsSection";
import NewsSection2 from "./components/NewsSection2";

import IncidentsSection from "./components/IncidentsSection";
import StatisticsSection from "./components/StatisticsSection";

import { englishLanguage } from "../languages/englishLanguage";
import { sinhalaLanguage } from "../languages/sinhalaLanguage";
import { tamilLanguage } from "../languages/tamilLanguage";

import languageService from "app/services/languageService";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const styleSheet = (theme) => ({
  root: {
    maxWidth: 345,
    backgroundColor: "#d9eded",
  },
  media: {
    height: 120,
  },
  mainPadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 70,
      paddingRight: 112,
      paddingTop: 50,
      paddingBottom: 50,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 60,
      paddingBottom: 40,
      marginTop: 30
    },

  },
  mainPadding2: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 112,
      paddingRight: 70,
      paddingTop: 50,
      paddingBottom: 50,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 10,
      paddingBottom: 40,
      // marginTop:30
    },

  },
  //  //styles for Incidents Section grid -- by shammika
  //  chartStyle: {
  //   backgroundColor: "white",
  //   // padding:10,
  //   // borderRadius:10,
  //   boxShadow:
  //     " 0 0px 0px 0px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",
  // },

  //View More More Button by shammika
  viewMoreButton: {
    color: "#02b0f5",
    borderRadius: 6,
    backgroundColor: "#fff",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#02b0f5",
      borderColor: "#02b0f5",
    },
  },
  //Buttons alignments
  btnAlign: {
    display: "grid",
    float: "left",
    width: "20%",


    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "10px !important",

    },
  },
});

class HomePge extends Component {
  //scrollbar-container
  constructor(props) {
    super(props);
    this.state = {
      languageVariables: englishLanguage,
      languageName: "English",
    };
    //creates a reference for your element to use
    this.myDivToFocus = React.createRef();
  }

  async componentDidMount() {
    var lng = await languageService.getLanguageDetails();
    // console.log("language",lng);

    if (lng == "Sinhala") {
      this.setState({
        languageVariables: sinhalaLanguage,
        languageName: "Sinhala",
      });
    } else if (lng == "Tamil") {
      this.setState({
        languageVariables: tamilLanguage,
        languageName: "Tamil",
      });
    }

    // console.log("Home",this.state);
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        {/* App bar component */}
        <AppAppBar />

        {/* Carousel component */}
        <Carousel_home />

        <Fade in={true}>
          <Grid container style={{ backgroundColor: "#ffff" }}>
            <Grid
              item
              xs={12}
              md={6}
              className={[classes.mainPadding2, classes.chartStyle]}
              style={{ height: 580 }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: 17,
                }}
              >
                {/*<Typography className="ml-3" variant="h2" style={{ fontSize: 20, fontWeight: 800, color: "rgb(107 99 99)" }}>Incidents In Sri Lanka</Typography>*/}
                <Typography
                  variant="h1"
                  component="h2"
                  // gutterBottom
                  style={{
                    fontSize: this.state.languageName === "Tamil" ? 20 : (this.state.languageName === "Sinhala" ? 20 : 30),
                    color: "#000",
                    fontWeight: 600
                  }}
                >
                  {/* Incidents Map */}
                  {this.state.languageVariables.incidentMap_appBar}
                </Typography>
              </div>

              <ClusterMap />
              <div className={classes.btnAlign} style={{ marginTop: 32 }} >
                {/* <Button
                  variant="contained"
                  size="small"
                  style={{ marginBottom: 10 }}
                  className={[
                    classes.viewMoreButton,
                    "float-end capitalize",
                  ]}
                  onClick={() => {
                    window.location.href = "/incidentMap";
                  }}
                >
                  View More...
                </Button> */}
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={[classes.viewMoreButton]}
                  onClick={() => {
                    window.location.href = "/incidentMap";
                  }}
                // endIcon={<ArrowRightAltIcon />}
                >
                  {/* View More */}
                  {this.state.languageVariables.viewMoreButton}
                </Button>
              </div>
            </Grid>

            <Grid
              justifyContent="flex-end"
              container
              item
              xs={12}
              md={6}
              className={[classes.mainPadding, classes.chartStyle]}
            >
              <AllIncidentsChart />
              {/* <div style={{ float: "right" }}>
                <Button
                  variant="contained"
                  size="small"
                  className={[
                    classes.viewMoreButton,
                    "ml-2 float-end capitalize",
                  ]}
                  onClick={() => {
                    window.location.href = "/statistics";
                  }}
                >
                  View More...
                </Button>
              </div> */}
              <div className={classes.btnAlign}>

                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={[classes.viewMoreButton]}
                  onClick={() => {
                    window.location.href = "/statistics";
                  }}
                // endIcon={<ArrowRightAltIcon />}
                >
                  {/* View More */}
                  {this.state.languageVariables.viewMoreButton}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Fade>
        {/*incidents section */}
        {/* <IncidentsSection /> */}

        {/*News section */}
        <NewsSection2 />

        {/* Footer */}
        <Footer />
        <div></div>
      </Fragment>
    );
  }
}

export default withStyles(styleSheet)(HomePge);
