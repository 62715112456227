import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {
    AppBar, Toolbar, Typography, List, ListItem, Link,
    Grid, SwipeableDrawer, Avatar, Icon, IconButton, MenuItem, Button,
    GridList, GridListTile, GridListTileBar, Divider, ListItemText
} from '@material-ui/core';
import ReactPlayer from 'react-player';
import { themeColors } from "app/MatxLayout/MatxTheme/themeColors";

import { withStyles } from "@material-ui/core/styles";

const styleSheet = ((theme) => ({
    gridList: {
        height: 275,
    },

    newsImage: {
        width: 90,
        height: 60,
        paddingRight: theme.spacing(1),
    },
    newsHrLine: {
        backgroundColor: '#0074e1',
        height: 3,
    },
    listItem: {
        borderBottomColor: "#dbdcdc",
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
    },
    mainPadding: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 70,
            paddingRight: 70,
            paddingTop:50,
            paddingBottom:50
            
        },
        [theme.breakpoints.down('md')]: {
            padding: 20,
            paddingTop:30,
            paddingBottom:30
            
        },
    },
}));


class AboutUsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }




    render() {
        const { classes } = this.props
        return (
            <div style={{ backgroundColor: themeColors.purple1.palette.theme_color.main}}>
                <Grid container  >

                <Grid item xs={12} md={6} className={classes.mainPadding} >
                <img src="/assets/images/aboutUs_Section.png" style={{ width: "100%" }} />
                </Grid>

                    <Grid item xs={12} md={6} className={classes.mainPadding} >
                        <Typography variant="h1" component="h2" gutterBottom style={{ fontSize: 30, color: '#343232', fontWeight: 600 }}>
                            About Us
                            </Typography>

                            <Typography className="mt-10"  variant="p" component="h6" gutterBottom style={{ fontWeight: 500 }}>
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. 
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. 
                            
                            </Typography>

                            <Button className="mt-10" variant="contained" color="primary" disableElevation>
                            Read More
                            </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}





export default withStyles(styleSheet)(AboutUsSection);