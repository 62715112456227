import axios from 'axios';
import * as appconst from './../appconst';
import localStorageService from "./localStorageService";

class ElectionServices {

  getStatus = async () => {
    const user = await localStorageService.getItem("auth_user");
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.GET_ELECTION_STATUS).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  getLaunchStatus = async () => {
    const user = await localStorageService.getItem("auth_user");
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.GET_LAUNCH_STATUS).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  changeStatus = async (data) => {
    const user = await localStorageService.getItem("auth_user");
    const a = new Promise((resolve, reject) => {
      axios.post(appconst.CHANGE_ELECTION_STATUS, data, {
        headers: { Authorization: `Bearer ${user.token}` }
      }).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }
}

export default new ElectionServices();