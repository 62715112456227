import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {
    useMediaQuery,
    AppBar, Toolbar, Typography, List, ListItem, Chip,
    Grid, SwipeableDrawer, Avatar, Icon, IconButton, MenuItem, Button,
    GridList, GridListTile, GridListTileBar, Divider, ListItemText, CardActionArea, Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Alert, AlertTitle } from '@material-ui/lab';
import { themeColors } from "app/MatxLayout/MatxTheme/themeColors";
import Util from '../../../services/Util';
import * as appconst from '../../../appconst';
import { withStyles } from "@material-ui/core/styles";
import IncidentService from '../../../services/IncidentServices';


import { englishLanguage } from '../../languages/englishLanguage';
import { sinhalaLanguage } from '../../languages/sinhalaLanguage';
import { tamilLanguage } from '../../languages/tamilLanguage';

import languageService from "app/services/languageService";

const styleSheet = ((theme) => ({
    gridList: {
        height: 275,
    },

    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    newsHrLine: {
        backgroundColor: '#0074e1',
        height: 3,
    },
    listItem: {
        borderBottomColor: "#dbdcdc",
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
    },
    mainPadding: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 70,
            paddingRight: 70,
            paddingTop: 50,
            paddingBottom: 50

        },
        [theme.breakpoints.down('md')]: {
            padding: 20,
            paddingTop: 30,
            paddingBottom: 30

        },
    },

    carousel_container: {
        height: 340
    }
}));

class IncidentsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: englishLanguage,
            incidents: [],
            pagination: {
                "current_page": 1,
                "first_page_url": "",
                "from": 0,
                "last_page": 0,
                "last_page_url": "",
                "next_page_url": "",
                "path": "",
                "per_page": 10,
                "to": 0,
                "total": 0
            },
            filterData: {
                reference_number: null,
            },
        };

    }

    async loadData(page) {
        let filter = this.state.filterData;
        let corruptions = await IncidentService.getPublicIncidentWithFilters(filter, page);
        // console.log("stories::", corruptions)
        //console.log("pagination",corruptions.data.insidents)
        if (corruptions.status == 200) {
            this.setState({
                incidents: corruptions.data.insidents.data,
                pagination: corruptions.data.insidents,

            })
        }
    }

    async loadLanguageDetails() {

        var lng = await languageService.getLanguageDetails();

        if (lng == 'Sinhala') {
            this.setState({
                language: sinhalaLanguage,
            })
        }
        else if (lng == 'Tamil') {
            this.setState({
                language: tamilLanguage,
            })
        }
        // console.log("Incident Section:", this.state);

    }

    async componentDidMount() {
        this.loadData(1);
        this.loadLanguageDetails();

    }


    render() {
        const { classes } = this.props;
        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 5,
                slidesToSlide: 3 // optional, default to 1.
            },
            tablet: {
                breakpoint: { max: 1024, min: 641 },
                items: 3,
                slidesToSlide: 2 // optional, default to 1.
            },
            tablet2: {
                breakpoint: { max: 641, min: 464 },
                items: 2,
                slidesToSlide: 2 // optional, default to 1.
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1 // optional, default to 1.
            }
        };
        return (
            <div className={classes.mainPadding} style={{ backgroundColor: themeColors.purple1.palette.theme_color.main }}>

                <Typography variant="h1" component="h2" gutterBottom style={{ fontSize: 30, color: '#343232', fontWeight: 600 }}>
                    {/* Incidents */}
                    {this.state.language.incidents_title}
                </Typography>
                <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass={classes.carousel_container}
                    removeArrowOnDeviceType={["desktop", "tablet", "tablet1", "mobile"]}
                    deviceType={this.props.deviceType}
                    // dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >

                    {this.state.incidents.map((row, key) => (
                        <div key={key}>
                            <Card onClick={() => { window.location.href = "/incidents/" + row.id }}
                                elevation={3}
                                className={"mx-3 my-3"}
                                style={{ minHeight: 285 }}>

                                <CardActionArea>

                                    {row.incident_report == undefined ? <CardMedia
                                        className={classes.media}
                                        image='/assets/images/default_incident.png'

                                    /> : row.incident_report.other_details == null ?
                                        <CardMedia
                                            className={classes.media}
                                            image='/assets/images/default_incident.png'

                                        />
                                        :
                                        <CardMedia
                                            className={classes.media}
                                            image={appconst.ATTACHMENTS_LOCATION + JSON.parse(row.incident_report.other_details.attachments)[0]}

                                        />
                                    }
                                    <CardContent>

                                        <Typography display="inline" gutterBottom variant="body2" color="textSecondary" component="p">
                                            {Util.getParsedDate(row.created_at)}

                                        </Typography>

                                        <Chip className="ml-3" display="inline" size="small" label="Published" />
                                        <Typography variant="body2" component="p">

                                            {row.incident_report == undefined ? "Share Story" :
                                                Util.getcurruptionType(row.incident_report.type_of_corruption)}, {row.district}
                                        </Typography>

                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {row.story != null ? (row.story).substring(0, 50).concat('...') : ""}
                                        </Typography>

                                    </CardContent>

                                </CardActionArea>

                                <CardActions style={{ padding: 0, paddingLeft: 8 }}>
                                    <Button color="primary" onClick={() => { window.location.href = "/incidents/" + row.id }}>
                                        {/* Read More... */}
                                        {this.state.language.readMoreButton}
                                    </Button>
                                </CardActions>

                            </Card>

                        </div>
                    ))}

                </Carousel>

                <Grid container className="p-0" justify="center">
                    <Button onClick={() => { window.location.href = "/incidents" }} className="mt-5" variant="contained" color="primary" disableElevation>
                        {/* Read More Reports */}
                        {this.state.language.readMoreReportsButton}

                    </Button>
                </Grid>
            </div>
        );
    }
}





export default withStyles(styleSheet)(IncidentsSection);