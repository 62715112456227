import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  Link,
  Grid,
  SwipeableDrawer,
  Avatar,
  Icon,
  IconButton,
  MenuItem,
  Button,
  GridList,
  GridListTile,
  GridListTileBar,
  Divider,
  ListItemText,
} from "@material-ui/core";
import { SimpleCard, MatxProgressBar } from "matx";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import Footer from "./components/Footer";
import AppAppBar from "./components/AppAppBar";

import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { englishLanguage } from "../languages/englishLanguage";
import { sinhalaLanguage } from "../languages/sinhalaLanguage";
import { tamilLanguage } from "../languages/tamilLanguage";
import languageService from "app/services/languageService";

const styleSheet = (theme) => ({
  gridList: {
    height: 275,
  },

  mainPadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 70,
      paddingRight: 70,
      paddingTop: 50,
      paddingBottom: 50,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  newsImage: {
    width: 90,
    height: 60,
    paddingRight: theme.spacing(1),
  },
  newsHrLine: {
    backgroundColor: "#0074e1",
    height: 3,
  },
  listItem: {
    borderBottomColor: "#dbdcdc",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
  accordionStyle: {
    backgroundColor: "#ffff",
  },
  pageTitlePadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 116,
      paddingTop: 60,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 30,
      paddingTop: 60,
    },
  },
});

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: englishLanguage,
      date: null,
    };
  }

  async loadLanguageDetails() {
    var lng = await languageService.getLanguageDetails();

    if (lng == "Sinhala") {
      this.setState({
        language: sinhalaLanguage,
      });
    } else if (lng == "Tamil") {
      this.setState({
        language: tamilLanguage,
      });
    }
    // console.log("FAQ Page:", this.state);
  }

  componentDidMount() {
    this.loadLanguageDetails();

    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    this.setState({
      date: date,
    });
  }

  render() {
    const { classes } = this.props;
    const preventDefault = (event) => event.preventDefault();
    return (
      <Fragment>
        {/* App bar component */}
        <AppAppBar />

        <div className={classes.pageTitlePadding} style={{ backgroundImage: `url(/assets/images/statBackground.png)`, width: '100%', height: 160, backgroundSize: 'cover', display: 'flex', float: "left", alignItems: 'center' }}>

          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            style={{ fontSize: 34, color: "white", fontWeight: 600 }}
          >
            {/* Privacy Policy */}
            {this.state.language.privacyPolicy_Title}
          </Typography>
        </div>

        <Fade in={true}>
          <div>
            <Grid item xs={12} sm={12} className="py-0 "></Grid>
            <Grid
              spacing={1}
              className={[classes.mainPadding, "pt-30"]}
              style={{ backgroundColor: "white", marginTop: 140 }}
            >
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* This website is a platform for you to post your stories or to submit your complaints. Whilst we, at Apesalli.lk, welcome all viewpoints, we request you to submit constructive input. */}
                {this.state.language.privacyPolicy_Description1}
              </Typography>

              <br />
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* Users are responsible for all content posted by them and the website shall not be responsible for the accuracy or legality of any material posted by users on this website. Please do not report incidents or submit stories that are false, defamatory, abusive, threatening, harmful, obscene, or profane. */}
                {this.state.language.privacyPolicy_Description2}
              </Typography>

              <br />
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* By using this website, you consent to our collection and use of information relating to your use of the website as described in this Privacy Policy. This privacy policy explains how we treat your personal data and protect your privacy when you use Apesalli.lk. We do not collect, use or disclose your personal information for any purpose other than as detailed below, to further or promote the objectives of Apesalli.lk or our other projects. */}
                {this.state.language.privacyPolicy_Description3}
              </Typography>

              <br />
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* We are committed to protecting your privacy as a visitor to our website and take the privacy and security of your information very seriously. */}
                {this.state.language.privacyPolicy_Description4}
              </Typography>

              <br />
              <br />
              {/* Qu 1 */}
              <Accordion>
                <AccordionSummary
                  className={classes.accordionStyle}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    variant="p"
                    component="p"
                    gutterBottom
                    style={{ fontWeight: 500 }}
                  >
                    <strong>
                      {/* 1.	INFORMATION COLLECTION: */}
                      {this.state.language.privacyPolicy_subTitle1}
                    </strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="p"
                    component="p"
                    gutterBottom
                    style={{ fontWeight: 500, lineHeight: 2 }}
                    align="justify"
                  >
                    {/* When you visit the site, you may provide two types of information: (a) personal information that you knowingly choose to disclose, and which is collected on an individual basis; and (b) website use information collected on an aggregate basis as you and other users browse the website. */}
                    {this.state.language.privacyPolicy_subTitle1Description}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* <Typography variant="h6" component="h6" gutterBottom style={{ fontWeight: 500 }}>
                                {/* 1.	INFORMATION COLLECTION: 
                                {this.state.language.privacyPolicy_subTitle1}
                            
                            </Typography> */}


              {/* <Typography variant="p" component="p" gutterBottom style={{ fontWeight: 500 }}>
                                {/* When you visit the site, you may provide two types of information: (a) personal information that you knowingly choose to disclose, and which is collected on an individual basis; and (b) website use information collected on an aggregate basis as you and other users browse the website.
                                {this.state.language.privacyPolicy_subTitle1Description}
                            
                            </Typography> */}


              {/* <Typography variant="h6" component="h6" gutterBottom style={{ fontWeight: 500 }}>
                                {/* 1.1.	 Personal Information 
                                {this.state.language.privacyPolicy_subTitle1P1}

                            
                            </Typography>

                            <Typography variant="p" component="p" gutterBottom style={{ fontWeight: 500 }}>
                                 Personal information about an individual may include the following: Name, Address, Email, Phone number, Age, Gender. You may post reports, perform searches and queries, share and transmit information and use the interactive features of the website. In doing this we receive different types of information about you including information that is required when you submit a report/complaint or share your stories, as well as personal information that you may choose to share publicly. 
                                 
                                {this.state.language.privacyPolicy_subTitle1P1DescriptionP1}
                            
                            </Typography> */}

              {/* Qu 1.1 */}
              <Accordion>
                <AccordionSummary
                  className={classes.accordionStyle}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    variant="p"
                    component="p"
                    gutterBottom
                    style={{ fontWeight: 500 }}
                  >
                    <strong>
                      {/* 1.1.	 Personal Information */}
                      {this.state.language.privacyPolicy_subTitle1P1}
                    </strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="p"
                    component="p"
                    gutterBottom
                    style={{ fontWeight: 500, lineHeight: 2 }}
                    align="justify"
                  >
                    {/* Personal information about an individual may include the following: Name, Address, Email, Phone number, Age, Gender. You may post reports, perform searches and queries, share and transmit information and use the interactive features of the website. In doing this we receive different types of information about you including information that is required when you submit a report/complaint or share your stories, as well as personal information that you may choose to share publicly. */}
                    {this.state.language.privacyPolicy_subTitle1P1DescriptionP1}
                    <br></br>
                    <br></br>
                    {/* By choosing to submit your personal information, you are giving TISL permission to the use said personal information for further fact finding pertaining to the complaint/story submitted and to contact you if required to obtain further information. The personal information submitted will not be displayed on the website interface without your express consent. Personal information will not be submitted to third parties without express consent or in an anonymized or aggregated manner, unless it is with regard to a legal action. */}
                    {this.state.language.privacyPolicy_subTitle1P1DescriptionP2}
                  </Typography>


                </AccordionDetails>
              </Accordion>


              {/* <Typography
                variant="h6"
                component="h6"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* 1.2.	Geo-tagging location information
                {this.state.language.privacyPolicy_subTitle1P2}
              </Typography>
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* When you submit the location of the alleged incident of bribery or corruption, the website will use geo-tagging or location data to approximately identify the location where the incident happened. This location information is used for displaying the approximate location that the reported incident of bribery or corruption took place on the heat map provided on the website. This location information could also be used for fact finding, information gathering and complaint verification conducted by TISL. Information about the exact location as reported or submitted by you will only be disclosed to necessary authorities or other third parties only with your express consent and awareness, when the person who submitted the information is identifiable. 
                {this.state.language.privacyPolicy_subTitle1P2Description}
              </Typography> */}

              {/* Qu 1.2 */}
              <Accordion>
                <AccordionSummary
                  className={classes.accordionStyle}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    variant="p"
                    component="p"
                    gutterBottom
                    style={{ fontWeight: 500 }}
                  >
                    <strong>
                      {/* 1.2.	Geo-tagging location information */}
                      {this.state.language.privacyPolicy_subTitle1P2}
                    </strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="p"
                    component="p"
                    gutterBottom
                    style={{ fontWeight: 500, lineHeight: 2 }}
                    align="justify"
                  >
                    {/* When you submit the location of the alleged incident of bribery or corruption, the website will use geo-tagging or location data to approximately identify the location where the incident happened. This location information is used for displaying the approximate location that the reported incident of bribery or corruption took place on the heat map provided on the website. This location information could also be used for fact finding, information gathering and complaint verification conducted by TISL. Information about the exact location as reported or submitted by you will only be disclosed to necessary authorities or other third parties only with your express consent and awareness, when the person who submitted the information is identifiable. */}
                    {this.state.language.privacyPolicy_subTitle1P2Description}

                    <br></br>
                    <br></br>
                    {/* By choosing to submit your personal information, you are giving TISL permission to the use said personal information for further fact finding pertaining to the complaint/story submitted and to contact you if required to obtain further information. The personal information submitted will not be displayed on the website interface without your express consent. Personal information will not be submitted to third parties without express consent or in an anonymized or aggregated manner, unless it is with regard to a legal action. */}
                    {this.state.language.privacyPolicy_subTitle1P1DescriptionP2}
                  </Typography>


                </AccordionDetails>
              </Accordion>


              {/* <Typography
                variant="h6"
                component="h6"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* 1.3.	Website Use Information 
                {this.state.language.privacyPolicy_subTitle1P3}
              </Typography>
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* Similar to other websites, this website utilizes a standard technology called 'cookies' and server logs and may collect information about how the website is used by you. 
                {this.state.language.privacyPolicy_subTitle1P3DescriptionP1}
              </Typography>
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* A cookie is a very small text document which often includes an anonymous unique identifier. When you visit a website, that site's computer seeks certain electronic, automated permissions from your computer, to store this file in a part of your hard drive specifically designated for cookies. Each website can send its own cookie to your browser if your browser's preferences allow it. 
                {this.state.language.privacyPolicy_subTitle1P3DescriptionP2}
              </Typography>
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* Information gathered through cookies and server logs may include the date and time of visits, the pages viewed, the time spent on our website, and the websites visited just before and just after the website. 
                {this.state.language.privacyPolicy_subTitle1P3DescriptionP3}
              </Typography>
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* As you use the website, the website uses its cookies to differentiate you from other users so your preferences are unique to you. In some cases, the website also uses cookies to prevent you from seeing unnecessary advertisements or requiring you to log in more than is necessary for security. Cookies, in conjunction with our server log files, allow us to calculate the aggregate number of people visiting our website and parts of the website which are most popular with our users. This helps us gather feedback in order to constantly improve the website and our services. Cookies do not allow us to gather any personal information about you and we do not generally store any personal information that you provide to us, in your cookies. 
                {this.state.language.privacyPolicy_subTitle1P3DescriptionP4}
              </Typography>
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* Please note that, to protect your privacy, your browser will only permit the website to access the cookies it has already sent to you, not the cookies sent to you by other websites. This means that our website can neither  access cookies sent to you by other websites, nor can other websites access or recognize cookies sent by our website to your computer. 
                {this.state.language.privacyPolicy_subTitle1P3DescriptionP5}
              </Typography> */}


              {/* Qu 1.3 */}
              <Accordion>
                <AccordionSummary
                  className={classes.accordionStyle}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    variant="p"
                    component="p"
                    gutterBottom
                    style={{ fontWeight: 500 }}
                  >
                    <strong>
                      {/* 1.3.	Website Use Information */}
                      {this.state.language.privacyPolicy_subTitle1P3}
                    </strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="p"
                    component="p"
                    gutterBottom
                    align="justify"
                    style={{ fontWeight: 500, lineHeight: 2 }}
                  >
                    {/* Similar to other websites, this website utilizes a standard technology called 'cookies' and server logs and may collect information about how the website is used by you. */}
                    {this.state.language.privacyPolicy_subTitle1P3DescriptionP1}
                    <br></br>
                    <br></br>
                    {/* A cookie is a very small text document which often includes an anonymous unique identifier. When you visit a website, that site's computer seeks certain electronic, automated permissions from your computer, to store this file in a part of your hard drive specifically designated for cookies. Each website can send its own cookie to your browser if your browser's preferences allow it. */}
                    {this.state.language.privacyPolicy_subTitle1P3DescriptionP2}
                    <br></br>
                    <br></br>
                    {/* Information gathered through cookies and server logs may include the date and time of visits, the pages viewed, the time spent on our website, and the websites visited just before and just after the website. */}
                    {this.state.language.privacyPolicy_subTitle1P3DescriptionP3}
                    <br></br>
                    <br></br>
                    {/* As you use the website, the website uses its cookies to differentiate you from other users so your preferences are unique to you. In some cases, the website also uses cookies to prevent you from seeing unnecessary advertisements or requiring you to log in more than is necessary for security. Cookies, in conjunction with our server log files, allow us to calculate the aggregate number of people visiting our website and parts of the website which are most popular with our users. This helps us gather feedback in order to constantly improve the website and our services. Cookies do not allow us to gather any personal information about you and we do not generally store any personal information that you provide to us, in your cookies. */}
                    {this.state.language.privacyPolicy_subTitle1P3DescriptionP4}
                    <br></br>
                    <br></br>
                    {/* Please note that, to protect your privacy, your browser will only permit the website to access the cookies it has already sent to you, not the cookies sent to you by other websites. This means that our website can neither  access cookies sent to you by other websites, nor can other websites access or recognize cookies sent by our website to your computer. */}
                    {this.state.language.privacyPolicy_subTitle1P3DescriptionP5}
                  </Typography>

                </AccordionDetails>
              </Accordion>

              {/*               
              <Typography
                variant="h6"
                component="h6"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* 2.	SHARING YOUR INFORMATION WITH THIRD PARTIES: 
                {this.state.language.privacyPolicy_subTitle2}
              </Typography>
              <br />

              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* We will not share any information provided by you, without your express consent, with any third parties, except as detailed herein below. 
                {this.state.language.privacyPolicy_subTitle2DescriptionP1}
              </Typography>
              <br />
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* When a complaint is submitted to this website, TISL will follow-up on actions that can be taken in relation to the alleged incident reported. In such instances, you may be required to share your personal information. Please note that we will obtain your confirmation before we undertake any action on the request submitted by you, in this regard. When a story or report is submitted to the website, TISL will moderate and post them as content on the website.
                {this.state.language.privacyPolicy_subTitle2DescriptionP2}
              </Typography>
              <br />
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* We do not share any information collected through cookies, as detailed above, with any third parties. We may be required to disclose information relating to your use of the website under applicable law or if we believe that such action is necessary to (a) conform to the law or comply with legal documents served upon us; (b) protect and defend our rights and property, the website, and/or the users of the website; or (c) act under circumstances to protect the safety of users of this website, our security or that of third parties.
                {this.state.language.privacyPolicy_subTitle2DescriptionP3}
              </Typography> */}


              {/* Qu 2 */}
              <Accordion>
                <AccordionSummary
                  className={classes.accordionStyle}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    variant="p"
                    component="p"
                    gutterBottom
                    style={{ fontWeight: 500 }}
                  >
                    <strong>
                      {/* 2.	SHARING YOUR INFORMATION WITH THIRD PARTIES: */}
                      {this.state.language.privacyPolicy_subTitle2}
                    </strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <Typography
                    variant="p"
                    component="p"
                    gutterBottom
                    align="justify"
                    style={{ fontWeight: 500, lineHeight: 2 }}
                  >
                    {/* We will not share any information provided by you, without your express consent, with any third parties, except as detailed herein below. */}
                    {this.state.language.privacyPolicy_subTitle2DescriptionP1}
                    <br></br>
                    <br></br>
                    {/* When a complaint is submitted to this website, TISL will follow-up on actions that can be taken in relation to the alleged incident reported. In such instances, you may be required to share your personal information. Please note that we will obtain your confirmation before we undertake any action on the request submitted by you, in this regard. When a story or report is submitted to the website, TISL will moderate and post them as content on the website. */}
                    {this.state.language.privacyPolicy_subTitle2DescriptionP2}
                    <br></br>
                    <br></br>
                    {/* We do not share any information collected through cookies, as detailed above, with any third parties. We may be required to disclose information relating to your use of the website under applicable law or if we believe that such action is necessary to (a) conform to the law or comply with legal documents served upon us; (b) protect and defend our rights and property, the website, and/or the users of the website; or (c) act under circumstances to protect the safety of users of this website, our security or that of third parties. */}
                    {this.state.language.privacyPolicy_subTitle2DescriptionP3}
                  </Typography>



                </AccordionDetails>
              </Accordion>



              {/* <Typography
                variant="h6"
                component="h6"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* 3.	SECURITY FOR YOUR DATA: 
                {this.state.language.privacyPolicy_subTitle3}
              </Typography>
              <br />
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* We will take all the appropriate precautions and the requisite security measures that are technically feasible to protect your information to prevent any unauthorized use, loss, misuse or alteration/abuse of your information. We may use the information we receive about you as part of our efforts to keep the website safe and secure, for internal operations including troubleshooting, data analysis, testing, research and service improvement. In any case, we restrict access of your personal information to select employees who need to know this information in order to operate, develop or improve our services, and who are contractually bound to protect such information. 
                {this.state.language.privacyPolicy_subTitle3Description}
              </Typography> */}


              {/* Qu 3 */}
              <Accordion>
                <AccordionSummary
                  className={classes.accordionStyle}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    variant="p"
                    component="p"
                    gutterBottom
                    style={{ fontWeight: 500 }}
                  >
                    <strong>
                      {/* 3.	SECURITY FOR YOUR DATA: */}
                      {this.state.language.privacyPolicy_subTitle3}
                    </strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    align="justify"
                    variant="p"
                    component="p"
                    gutterBottom
                    style={{ fontWeight: 500, lineHeight: 2 }}
                  >
                    {/* We will take all the appropriate precautions and the requisite security measures that are technically feasible to protect your information to prevent any unauthorized use, loss, misuse or alteration/abuse of your information. We may use the information we receive about you as part of our efforts to keep the website safe and secure, for internal operations including troubleshooting, data analysis, testing, research and service improvement. In any case, we restrict access of your personal information to select employees who need to know this information in order to operate, develop or improve our services, and who are contractually bound to protect such information. */}
                    {this.state.language.privacyPolicy_subTitle3Description}
                  </Typography>
                </AccordionDetails>
              </Accordion>


              {/* <Typography
                variant="h6"
                component="h6"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* 4.	THIRD PARTY LINKS: 
                {this.state.language.privacyPolicy_subTitle4}
              </Typography>
              <br />
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* This website may contain links to third party websites that are not controlled by TISL. These third party links are available to you as a convenience and you agree to use these links at your own risk. Please be aware that neither Apesalli.lk nor TISL is responsible for the practices employed by third party websites linked to Apesalli.lk or content contained therein.
                {this.state.language.privacyPolicy_subTitle4DescriptionP1}
              </Typography>
              <br />
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* Often hyperlinks to other websites are provided solely as pointers to information on topics that may be useful to the users of the website. Please remember that when you use a link to go from our website to another web site, this Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including websites which contain further links, is subject to that third party website's own rules and policies and we have no liability whatsoever, in such circumstances. 
                {this.state.language.privacyPolicy_subTitle4DescriptionP2}
              </Typography> */}


              {/* Qu 4 */}
              <Accordion>
                <AccordionSummary
                  className={classes.accordionStyle}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    variant="p"
                    component="p"
                    gutterBottom
                    style={{ fontWeight: 500 }}
                  >
                    <strong>
                      {/* 4.	THIRD PARTY LINKS: */}
                      {this.state.language.privacyPolicy_subTitle4}
                    </strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    align="justify"
                    variant="p"
                    component="p"
                    gutterBottom
                    style={{ fontWeight: 500, lineHeight: 2 }}
                  >
                    {/* This website may contain links to third party websites that are not controlled by TISL. These third party links are available to you as a convenience and you agree to use these links at your own risk. Please be aware that neither Apesalli.lk nor TISL is responsible for the practices employed by third party websites linked to Apesalli.lk or content contained therein. */}
                    {this.state.language.privacyPolicy_subTitle4DescriptionP1}
                    <br></br>
                    <br></br>
                    {/* Often hyperlinks to other websites are provided solely as pointers to information on topics that may be useful to the users of the website. Please remember that when you use a link to go from our website to another web site, this Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including websites which contain further links, is subject to that third party website's own rules and policies and we have no liability whatsoever, in such circumstances. */}
                    {this.state.language.privacyPolicy_subTitle4DescriptionP2}
                  </Typography>


                </AccordionDetails>
              </Accordion>


              {/* <Typography
                variant="h6"
                component="h6"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* 5.	CHANGES TO THIS PRIVACY POLICY: 
                {this.state.language.privacyPolicy_subTitle5}
              </Typography>
              <br />
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* This Privacy Policy is effective as of __________________. 
                {this.state.language.privacyPolicy_subTitle5Description1P1}{" "}
                {this.state.date + " "}
                {/* In the event we change our Privacy Policy and procedures, we will post those changes on the website to keep you aware of what information we collect, how such information is used and under what circumstances we may disclose it. 
                {this.state.language.privacyPolicy_subTitle5Description1P2}
              </Typography>
              <br />
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* However, changes to the privacy policy may be made without prior notice, and by accessing this website you are deemed to be aware of the new/changed Privacy Policy; and to have consented to the same. You are requested to regularly visit this page to update yourself of the current and prevailing Privacy Policy.
                {this.state.language.privacyPolicy_subTitle5Description2}
              </Typography>
              <br />
              <Typography
                variant="p"
                component="p"
                gutterBottom
                style={{ fontWeight: 500 }}
              >
                {/* Note: Please do not post fraudulent or defamatory reports or stories for which you may be liable. If a law enforcement agency challenges such report on a legal basis, we will be required to share your IP address. If successfully sued in relation to any content posted or submitted by you, you could be held liable. 
                {this.state.language.privacyPolicy_subTitle5Description3}
              </Typography> */}

              {/* Qu 5 */}
              <Accordion>
                <AccordionSummary
                  className={classes.accordionStyle}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    variant="p"
                    component="p"
                    gutterBottom
                    style={{ fontWeight: 500 }}
                  >
                    <strong>
                      {/* 5.	CHANGES TO THIS PRIVACY POLICY: */}
                      {this.state.language.privacyPolicy_subTitle5}
                    </strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    align="justify"
                    variant="p"
                    component="p"
                    gutterBottom
                    style={{ fontWeight: 500, lineHeight: 2 }}
                  >
                    {/* This Privacy Policy is effective as of __________________. */}
                    {this.state.language.privacyPolicy_subTitle5Description1P1}{" "}
                    {this.state.date + " "}
                    {/* In the event we change our Privacy Policy and procedures, we will post those changes on the website to keep you aware of what information we collect, how such information is used and under what circumstances we may disclose it. */}
                    {this.state.language.privacyPolicy_subTitle5Description1P2}
                    <br></br>
                    <br></br>
                    {/* However, changes to the privacy policy may be made without prior notice, and by accessing this website you are deemed to be aware of the new/changed Privacy Policy; and to have consented to the same. You are requested to regularly visit this page to update yourself of the current and prevailing Privacy Policy. */}
                    {this.state.language.privacyPolicy_subTitle5Description2}
                    <br></br>
                    <br></br>
                    {/* Note: Please do not post fraudulent or defamatory reports or stories for which you may be liable. If a law enforcement agency challenges such report on a legal basis, we will be required to share your IP address. If successfully sued in relation to any content posted or submitted by you, you could be held liable.  */}
                    {this.state.language.privacyPolicy_subTitle5Description3}
                  </Typography>

                </AccordionDetails>
              </Accordion>
            </Grid>
          </div>
        </Fade>
        {/* Footer */}
        <Footer />
      </Fragment>
    );
  }
}

export default withStyles(styleSheet)(PrivacyPolicy);
