import React, { Component, Fragment, useEffect } from "react";
import {
    Grid,
    Card,
    Button,
    Typography,
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel,
    RadioGroup,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText
} from "@material-ui/core";

import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { SimpleCard } from "matx";
import Box from '@material-ui/core/Box';
import { withStyles } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import ReactEcharts from "echarts-for-react";
import { themeColors } from "app/MatxLayout/MatxTheme/themeColors";
import echarts from "echarts/lib/echarts";

import { englishLanguage } from '../../../languages/englishLanguage';
import { sinhalaLanguage } from '../../../languages/sinhalaLanguage';
import { tamilLanguage } from '../../../languages/tamilLanguage';

import languageService from "app/services/languageService";
import * as appconst from './../../../../appconst';
import StatisticsServices from '../../../../services/StatisticsServices';
/* data:[
    {
        "value": 600.58,
        "name": "Data Point 1",
        "itemStyle": {
            "normal": {
                "color": "#f845f1"
            }
        }
    },
    {
        "value": 1100.58,
        "name": "Data Point 2",
        "itemStyle": {
            "normal": {
                "color": "#ad46f3"
            }
        }
    },
    {
        "value": 1200.58,
        "name": "Data Point 3",
        "itemStyle": {
            "normal": {
                "color": "#5045f6"
            }
        }
    },
    {
        "value": 1300.58,
        "name": "Data Point 4",
        "itemStyle": {
            "normal": {
                "color": "#4777f5"
            }
        }
    },
    {
        "value": 1400.58,
        "name": "Data Point 5",
        "itemStyle": {
            "normal": {
                "color": "#44aff0"
            }
        }
    },
    {
        "value": 1500.58,
        "name": "Data Point 6",
        "itemStyle": {
            "normal": {
                "color": "#45dbf7"
            }
        }
    },
    {
        "value": 1500.58,
        "name": "Data Point 7",
        "itemStyle": {
            "normal": {
                "color": "#f6d54a"
            }
        }
    },
    {
        "value": 1600.58,
        "name": "Data Point 8",
        "itemStyle": {
            "normal": {
                "color": "#f69846"
            }
        }
    },
    {
        "value": 1800,
        "name": "Data Point 9",
        "itemStyle": {
            "normal": {
                "color": "#ff4343"
            }
        }
    }
] */
class GenderWise extends Component {
    //scrollbar-container
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            lable: [],
            language: englishLanguage,
            languageName: 'English',
        }

    }


    async componentDidMount() {

        this.loadData();
        var lng = await languageService.getLanguageDetails();

        if (lng == 'Sinhala') {
            this.setState({
                language: sinhalaLanguage,
                languageName: 'Sinhala',
            })
        }
        else if (lng == 'Tamil') {
            this.setState({
                language: tamilLanguage,
                languageName: 'Tamil',
            })
        }
        // console.log("Home Incident Chart Section:", this.state);

    }

    loadData() {
        // let color=["#5045f6","#ad46f3","#5045f6","#4777f5","#44aff0","#45dbf7","#f6d54a","#f69846","#ff4343"]
        let color = ["#ad46f3", "#4777f5", "#44aff0", "#45dbf7", "#f6d54a", "#f69846", "#ff4343"]
        StatisticsServices.getInsidentStatisticsByGender().then(res => {
            console.log("res", res.data.insidents)
            var data = [];
            var lable = [];

            for (let index = 0; index < res.data.insidents.length; index++) {
                data.push(res.data.insidents[index].count)

                //set language to label 
                const labelEng = res.data.insidents[index].title;
                const genderArr = this.state.language.lng_genders;

                for (var i = 0; i < 4; i++) {
                    if (labelEng === genderArr[i].value) {
                        labelEng = genderArr[i].label;
                    }
                }

                lable.push(labelEng);

            }

            this.setState({ data, lable })

        })
            .catch((error) => {
                console.log("error", error);
            })
    }



    render() {
        let { theme } = this.props;

        const dataNames = this.state.data.map(i => i.name);
        let option = {
            backgroundColor: "white",
            toolbox: {
                show: true,
                feature: {
                    mark: {
                        show: true
                    },
                    magicType: {
                        show: true,
                        type: ['line', 'bar'],
                        title: {
                            line: this.state.language.statisticsPage_Chart_Tytpe1, //'line view',
                            bar: this.state.language.statisticsPage_Chart_Tytpe2, //'bar view',
                            tiled: this.state.language.statisticsPage_Chart_Tytpe3, //'tiled view',
                        },

                    },
                    restore: {
                        show: false,
                        title: "Restore"
                    },
                    saveAsImage: {
                        show: true,
                        title: this.state.language.statisticsPage_Chart_SaveImage, //"Save Image"
                    }
                }
            },
            title: {
                text: this.state.language.statisticsPage_GenderChart_Title, //"Incidents Gender Wise",
                left: "center",
                top: 5,
                textStyle: {
                    fontSize: this.state.languageName === "Tamil" ? 20 : (this.state.languageName === "Sinhala" ? 20 : 30),
                    color: 'black',
                    fontWeight: 700, //Edited By shammika
                    fontFamily: appconst.FONT

                }
            },
            tooltip: {
                textStyle: {
                    fontFamily: appconst.FONT
                }
            },
            grid: {
                top: "10%"
            },

            xAxis: {
                data: this.state.lable,
                name: this.state.language.statisticsPage_GenderChart_XAxis, //"Gender",
                textStyle: {
                    fontFamily: appconst.FONT
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    rotate: 25,
                    textStyle: {
                        fontFamily: appconst.FONT
                    }
                }
            },
            yAxis: {
                minInterval: 1,
                axisTick: {
                    show: false
                }
            },
            series: [
                {
                    name: this.state.language.statisticsPage_Chart_hoverText1, //"Incidents",
                    type: "bar",
                    data: this.state.data,
                    barMaxWidth: 60,
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: "#0074e1"
                                },
                                {
                                    offset: 0.5,
                                    color: "#00a1e1"
                                },
                                {
                                    offset: 1,
                                    color: "#00bfe1"
                                }
                            ])
                        },
                        emphasis: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: "#0074e1"
                                },
                                {
                                    offset: 0.5,
                                    color: "#00a1e1"
                                },
                                {
                                    offset: 1,
                                    color: "#00bfe1"
                                }
                            ])
                        }
                    }
                }
            ]
        };

        return (
            <Fragment>


                <div className="w-full">

                    <Grid item align='center' className=" w-full  " >
                        <ReactEcharts option={option} style={{
                            height: 480,
                            width: "98%",
                        }} className="bar-chart" />
                    </Grid>
                </div>

            </Fragment>
        );
    }
}

export default withStyles({}, { withTheme: true })(GenderWise);
