import React from "react";
import { authRoles } from "../../auth/authRoles";

const dashboardRoutes = [

  //----------Analytics------------
  {
    path: "/dashboard/analytics",
    component: React.lazy(() => import("./Analytics")),
    auth: authRoles.dashboard
  },
  //-----------Report----------------------
  {
    path: "/dashboard/report",
    component: React.lazy(() => import("./Incidents/Report")),
    auth: authRoles.reports
  },

  //----------Users------------
  {
    path: "/dashboard/user/create",
    component: React.lazy(() => import("./User/UserCreate")),
    auth: authRoles.usercreate
  },
  {
    path: "/dashboard/user/all",
    component: React.lazy(() => import("./User/AllUsers")),
    auth: authRoles.userview
  },
  {
    path: "/dashboard/user/profile",
    component: React.lazy(() => import("./User/Profile")),
    auth: authRoles.userview
  },
  {
    path: "/dashboard/user/view/:id",
    component: React.lazy(() => import("./User/UserSingleView")),
    auth: authRoles.userview
  },
  {
    path: "/dashboard/user/edit/:id",
    component: React.lazy(() => import("./User/UserEdit")),
    auth: authRoles.userview
  },
  {
    path: "/dashboard/user/district_coordinator/create",
    component: React.lazy(() => import("./User/ElectionAgentCreate")),
    auth: authRoles.usercreate
  },

  //----------Incidents All------------
  {
    path: "/dashboard/Incidents/allstories",
    component: React.lazy(() => import("./Incidents/ViewAllStories")),
    auth: authRoles.incidentview
  },
  {
    path: "/dashboard/Incidents/allreports",
    component: React.lazy(() => import("./Incidents/ViewAllReportedCorruptions")),
    auth: authRoles.incidentview
  },
  {
    path: "/dashboard/Incidents/allelection",
    component: React.lazy(() => import("./Incidents/ViewAllElectionViolations")),
    auth: authRoles.incidentview
  },
  //----------Incidents Single------------
  {
    path: "/dashboard/Incidents/story/:id",
    exact: true,
    component: React.lazy(() => import("./Incidents/StorySingleView")),
    auth: authRoles.incidentview
  },
  {
    path: "/dashboard/Incidents/corruption/:id",
    exact: true,
    component: React.lazy(() => import("./Incidents/CorruptionSingleView")),
    auth: authRoles.incidentview
  },
  {
    path: "/dashboard/Incidents/election/:id",
    exact: true,
    component: React.lazy(() => import("./Incidents/ElectionSingleView")),
    auth: authRoles.incidentview
  },

  //----------Incidents Edit------------
  {
    path: "/dashboard/Incident/editStory/:id",
    exact: true,
    component: React.lazy(() => import("./Incidents/EditStory")),
    auth: authRoles.incidentview
  },
  {
    path: "/dashboard/Incidents/editCorruption/:id",
    exact: true,
    component: React.lazy(() => import("./Incidents/EditCorruption")),
    auth: authRoles.incidentview
  },
  {
    path: "/dashboard/Incidents/editElection/:id",
    exact: true,
    component: React.lazy(() => import("./Incidents/EditElection")),
    auth: authRoles.incidentview
  },

  //----------All News------------
  {
    path: "/dashboard/News/allnews",
    component: React.lazy(() => import("./Incidents/ViewAllNews")),
    auth: authRoles.newsview
  },
  {
    path: "/dashboard/News/create",
    component: React.lazy(() => import("./Incidents/CreateNews")),
    auth: authRoles.newscreate
  },
  {
    path: "/dashboard/News/:id",
    exact: true,
    component: React.lazy(() => import("./Incidents/NewsSingleView")),
    auth: authRoles.newsview
  },
  {
    path: "/dashboard/News/Edit/:id",
    exact: true,
    component: React.lazy(() => import("./Incidents/EditNews")),
    auth: authRoles.newscreate
  },
  {
    path: "/dashboard/election/changeStatus",
    exact: true,
    component: React.lazy(() => import("./Elections/ElectionCreate")),
    auth: authRoles.electionChange
  }

];

export default dashboardRoutes;
