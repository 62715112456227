import React, { Fragment, useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppContext from "app/appContext";

const redirectRoute = props => {
  const { location, history } = props;
  const { pathname } = location;

  history.push({
    pathname: "/",
    state: { redirectUrl: pathname }
  });
};



const getAuthStatus = (props, routes) => {
  const { location, user } = props;
  const { pathname } = location;
  const matched = routes.find(r => r.path === pathname);

  let authenticated = false;

  /*   const authenticated =
      matched && matched.auth && matched.auth.length
        ? matched.auth.includes(user.role)
        : true; */

  if (matched !== undefined) {
    if (matched.auth !== undefined) {
      if (user.roles !== undefined) {
        const userpermission = user.roles.map(roles => roles).flat();

        matched.auth.forEach(permission => {
          if (userpermission.find(p => p === permission)) {
            authenticated = true;
            //console.log(access, "pp")
          }
        })

      }
    } else {
      authenticated = false;
    }
  }
  if (!(matched && matched.auth && matched.auth.length)) {
    authenticated = true;
  }


  return authenticated;
};

const AuthGuard = ({ children, ...props }) => {
  const { routes } = useContext(AppContext);

  let [authenticated, setAuthenticated] = useState(
    getAuthStatus(props, routes)
  );

  useEffect(() => {
    if (!authenticated) {
      redirectRoute(props);
    }
    setAuthenticated(getAuthStatus(props, routes));
  }, [setAuthenticated, authenticated, routes, props]);

  return authenticated ? <Fragment>{children}</Fragment> : null;
};

const mapStateToProps = state => ({
  user: state.user
});

export default withRouter(connect(mapStateToProps)(AuthGuard));
