import React, { Component } from "react";
import Carousel from "react-material-ui-carousel";

import {
  Button,
  Typography,
  Paper,
  Grid,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import ReactPlayer from "react-player";
import ShareStoryComponent from "../ShareStoryComponent";
import ReportElectionIncident from "../reportInsidentComponents/ReportElectionIncident";
import ReportIncident from "../reportInsidentComponents/ReportIncident";
import { themeColors } from "app/MatxLayout/MatxTheme/themeColors";

import { withStyles } from "@material-ui/core/styles";

import { englishLanguage } from "../../../languages/englishLanguage";
import { sinhalaLanguage } from "../../../languages/sinhalaLanguage";
import { tamilLanguage } from "../../../languages/tamilLanguage";

import languageService from "app/services/languageService";
import ElectionServices from "app/services/ElectionServices";

import {
  incident_report_theme_home_border, share_story_theme_home_border,
  election_report_theme_home_border,
}
  from "../../../../appVariables";
import * as appconst from '../../../../appconst';
//play and pause video
function playVideo() {
  let video = document.getElementById("background-video");

  if (video.paused) {
    video.play();
  } else {
    video.pause();
  }
}

const styles = (theme) => ({
  slideshowImageContainer: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "75vh",
      //minHeight: 690,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "100vh",
      minHeight: 690,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
  },
  sliderHeaderEnglish: {
    //Changed font size and color - by shammika
    // fontFamily: "Source Sans Pro",
    fontSize: "4rem",
    color: "white",
    fontWeight: 700,
    //textAlign: 'center',
    // textShadow: "0px 0px 5px #000000"
    [theme.breakpoints.up("sm")]: {
      fontSize: "4rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "4rem",
    },
  },

  sliderHeaderSinhalaAndTamil: {
    //Changed font size and color - by shammika
    // fontFamily: "Source Sans Pro",
    fontSize: "2.5rem",
    lineHeight: 1.3,
    color: "white",
    fontWeight: 700,
    //textAlign: 'center',
    // textShadow: "0px 0px 5px #000000"
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
  },

  //added by shammika - How to Use
  sliderHeaderTop: {
    marginTop: 30,
    //fontFamily: "Source Sans Pro",
    fontSize: "1.5rem",
    color: "#574033",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
    },
  },
  sliderSubHeader: {
    // fontFamily: "Source Sans Pro",
    fontSize: 35,
    color: "white",
    fontWeight: 500,
    //textAlign: 'center',
    textShadow: "0px 0px 5px #000000",
  },

  slideshowButtonContainer: {
    // border: "2px solid #7E7E7E",
    backgroundColor: "rgb(126, 126, 126, 0.5)",
    position: "absolute",
    //bottom: "0",
    padding: 10,
    width: "100%",
  },

  bottomItemButton: {

    cursor: "pointer",
    // border: "1px solid #0074E1
    textAlign: "center",
    height: '100%',
    backgroundColor: "white", // added by shammika
    boxShadow:
      " 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",
    transition: "all ease 200ms",

    "&:hover": {
      filter: "brightness(1)",
      transform: "scale(1.05)",
      boxShadow:
        "0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02)",
    },
    [theme.breakpoints.down("sm")]: {
      margin: 30,
    },
  },

  butonSection: {

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonIcon: {
    width: 100,
    height: 100,
    boxShadow:
      " 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",

    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    marginTop: -50,
    borderRadius: "100px 100px 100px 100px",
    // [theme.breakpoints.up("sm")]: {
    //   width: "30%",
    // },
    [theme.breakpoints.down("sm")]: {
      width: 70,
      height: 70,
      marginTop: -40,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 60,
      height: 60,
      marginTop: -50,
    },
  },
  buttonTitle: {
    //fontFamily: "Source Sans Pro",
    textAlign: "center",
    color: "#000", // Changed by shammika",
    fontSize: 18,
    fontWeight: 700,
  },
  buttonSubTitle: {
    // fontFamily: "Source Sans Pro",
    fontStyle: "italic",
    textAlign: "center",
    color: "#000", // changed by shammika",
    fontWeight: 400,
    fontSize: 14,
  },
  root: {
    //maxWidth: 345,
    backgroundColor: "#d9eded",
  },
  media: {
    height: 120,
  },
  // > 950 px
  formCard: {
    paddingLeft: 150,
    paddingRight: 150,
  },
  mainPadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 70,
      paddingRight: 70,
      paddingTop: 50,
      paddingBottom: 50,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 30,
      paddingBottom: 30,
    },
  },

  video_left: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 100,
      paddingRight: 20,
      paddingTop: 85,
      paddingBottom: 50,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 30,
      paddingBottom: 30,
    },
  },

  video_right: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 20,
      paddingRight: 100,
      // paddingTop: 50,
      paddingBottom: 50,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  buttonContainer: {
    [theme.breakpoints.up("md")]: {
      marginTop: -33,
      display: 'flex'
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
      display: 'block'
    },
    height: 'max-content',

  },
  singleButton: {
    //height: '100%'
    flex: 1,
  },
  innerSingleButton: {
    //height: '100%'
  },



  buttonIncide: {
    // height: '100%'
  },
  videoContainer: {
    marginTop: 30,
    backgroundColor: "white",
    borderRadius: 15,
    overflow: "hidden",
    padding: 7,
    paddingBottom: 5,
    boxShadow: theme.shadows[9],
  },


  row: {
    display: 'flex', /* equal height of the children */
  },

  col: {
    flex: 1, /* additionally, equal width */

    padding: '1em',
    border: 'solid',
  }

});

class Carousel_home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareStoryDialog: false,
      incidentReportDialog: false,
      electionDialog: false,
      windowActive: false,
      language: englishLanguage,
      electionStatus: 'deactive',

      languageName: "English",
    };
  }

  btnClick = () => {
    console.log("aaaaaaaaaaaa");
  };

  async loadLanguageDetails() {
    var lng = await languageService.getLanguageDetails();

    if (lng == "Sinhala") {
      this.setState({
        language: sinhalaLanguage,
        languageName: "Sinhala",
      });
    } else if (lng == "Tamil") {
      this.setState({
        language: tamilLanguage,
        languageName: "Tamil",
      });
    }
    // console.log("Home Carousal Section:", this.state);
  }

  async getElectionTimeStatus() {
    let electionstatus = await ElectionServices.getStatus();
    this.setState({ electionStatus: electionstatus.data.election.election_mode })
  }



  componentDidMount() {
    this.loadLanguageDetails();
    this.getElectionTimeStatus();

    if (window.innerWidth <= 950) {
      this.setState({
        windowActive: true,
      });
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 950) {
        this.setState({
          windowActive: true,
        });
      } else {
        this.setState({
          windowActive: false,
        });
      }
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        className="w-full"
        style={{ marginTop: 0, backgroundColor: "white" }}
      >
        {/* Carousel */}
        <Carousel
          autoPlay={false}
          indicators={false}
          navButtonsAlwaysVisible={false}
          timeout={400}
          animation={"fade"}
        >
          <Grid
            className="pb-10"
            style={
              {
                // backgroundColor: themeColors.purple1.palette.theme_color.main,
              }
            }
          >
            {/* Set Carousal BackGround Color */}
            <div
              style={{
                // background: "linear-gradient(to right, #1D2671, #C33764)",
                // background:"linear-gradient(to right, #f7ff00, #db36a4)",
                background: "linear-gradient(to bottom, #02b0f5, #02b0f5, #ffffff)",
                // background: "linear-gradient(120deg, #f6d365 0%, #fda085 100%)"
                // backgroundImage: "linear-gradient(to right, #fa709a 0%, #fee140 100%)",
                // backgroundImage: "linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)"
                // backgroundImage: "linear-gradient(to right, #f9d423 0%, #ff4e50 100%)"
                //backgroundImage: "linear-gradient(to right, #f83600 0%, #f9d423 100%)",
                //backgroundImage: "linear-gradient(to right, #434343 0%, black 100%)"
              }}
              className={classes.slideshowImageContainer}
            >
              <Grid className="w-full px-5">
                <Fade timeout={2000} in={true}>
                  <Grid container>
                    <Grid item xs={12} md={6} className={classes.video_left}>

                      <Typography className={classes.sliderHeaderTop}>
                        {this.state.language.mainTopTitle_SlideShow}
                      </Typography>
                      <Grid container alignItems="center">
                        <Grid item xs={10} md={2}>
                          <img
                            src="/assets/images/apesalli_logo.png"
                            alt="company-logo"
                            style={{ width: 90 }}
                          />
                        </Grid>
                        <Grid item xs={10} md={10}>
                          <Typography
                            variant="h1"
                            component="h2"
                            gutterBottom
                            className={this.state.languageName == "English" ?
                              classes.sliderHeaderEnglish : classes.sliderHeaderSinhalaAndTamil
                            }
                          >
                            {/* WHAT IS APESALLI => How To Use ApeSalli.lk*/}
                            {this.state.language.mainTitle_SlideShow}
                          </Typography>
                        </Grid>
                      </Grid>
                      {/* <Typography className="mt-10" variant="p" component="h6" gutterBottom className={classes.sliderSubHeader} > */}
                      {/* Watch VIDEO and learn more about Apesalli and how to lodge complain */}
                      {/* {this.state.language.mainDescription_SlideShow} */}
                      {/* </Typography> */}
                    </Grid>

                    <Grid item xs={12} md={6} className={classes.video_right}>
                      <Grid>
                        <ReactPlayer
                          className={classes.videoContainer}
                          width="100%"
                          height="100%"
                          url={this.state.languageName === "English" ? appconst.HOMEPAGE_VIDEO_LOCATION_ENGLISH : (this.state.languageName === "Sinhala" ? appconst.HOMEPAGE_VIDEO_LOCATION_SINHALA : appconst.HOMEPAGE_VIDEO_LOCATION_TAMIL)}
                          controls={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Fade>
              </Grid>
            </div>

            <Slide timeout={2000} in={true} direction="up">
              <Grid
                container
                className={classes.buttonContainer}
                spacing={2}
                //alignItems="center"
                justify="center"
                direction="row"
                style={{ margingTop: "20px" }}
              >
                {/* Bottom Item Button 01 */}
                {/* <Grid elevation={5} item xs={11} md={3} onClick={() => { this.setState({ shareStoryDialog: true }) }} > */}
                {/* <Grid item xs={11} md={3} onClick={() => { window.location.href = "/shareStory" }}  > */}
                <Grid className={classes.singleButton} item xs={12} md={3} sm={12} onClick={() => { window.location.href = "/reportCorruptionIncident" }}  >

                  <Grid
                    elevation={5}
                    className={[classes.bottomItemButton]}
                    // style={{ borderRadius: 10,background: "linear-gradient(to right, #e52d27, #b31217)"}}
                    style={{
                      borderRadius: 10,
                      background: "white",
                      // borderBottom: "12px solid #e52d27",
                      borderBottom: `12px solid ${incident_report_theme_home_border}`
                    }}
                  >
                    <div className={classes.butonSection}>

                      <div
                        className={classes.buttonIcon}
                        style={{
                          background: incident_report_theme_home_border, //"#e52d27",
                        }}
                      >
                        <img
                          alt="say something"
                          src="/assets/images/saySomething.png"
                          style={{ width: "100%", paddingTop: 1 }}
                        />
                      </div>
                    </div>

                    <Grid
                      container
                      className={classes.buttonIncide}
                      alignItems="center"
                      justify="center"
                      direction="column" // changed row to column by shammika
                    >
                      <Grid item xs={7}>

                        {
                          this.state.languageName === "English" &&
                          <Typography
                            variant="h5"
                            className={["py-3", classes.buttonTitle]}
                          >
                            {/* If you see something, say something */}
                            {this.state.language.incidentReportButtonTitle_Story.toUpperCase()}
                          </Typography>
                        }
                        {
                          this.state.languageName === "Sinhala" &&
                          <Typography
                            variant="h5"
                            style={{
                              fontSize: 18,
                              // color: 'red',
                            }}
                            className={["py-3", classes.buttonTitle]}
                          >
                            {/* If you see something, say something */}
                            {this.state.language.incidentReportButtonTitle_Story.toUpperCase()}
                          </Typography>
                        }
                        {
                          this.state.languageName === "Tamil" &&
                          <Typography
                            variant="h5"
                            style={{
                              fontSize: 12,
                              // color: 'red',
                            }}
                            className={["py-3", classes.buttonTitle]}
                          >
                            {/* If you see something, say something*/}
                            {this.state.language.incidentReportButtonTitle_Story}
                          </Typography>
                        }

                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      {
                        this.state.languageName === "English" &&
                        <Typography
                          variant="h6"
                          className={["px-5", classes.buttonSubTitle]}
                        >
                          {/* Share your experience and views on bribery and corruption */}
                          {
                            this.state.language
                              .incidentReportButtonDescription_Story
                          }
                        </Typography>
                      }
                      {
                        this.state.languageName === "Sinhala" &&
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: 12,
                            // color: 'red',
                          }}
                          className={["px-5", classes.buttonSubTitle]}
                        >
                          {/* Share your experience and views on bribery and corruption */}
                          {
                            this.state.language
                              .incidentReportButtonDescription_Story
                          }
                        </Typography>
                      }
                      {
                        this.state.languageName === "Tamil" &&
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: 10,
                            // color: 'red',
                          }}
                          className={[classes.buttonSubTitle]}
                        >
                          {/* Share your experience and views on bribery and corruption */}
                          {
                            this.state.language
                              .incidentReportButtonDescription_Story
                          }
                        </Typography>
                      }

                    </Grid>
                  </Grid>
                </Grid>

                {/* Bottom Item Button 02 */}
                {/* <Grid item xs={11} md={3} onClick={() => { this.setState({ incidentReportDialog: true }) }}  > */}
                {/* <Grid item xs={11} md={3} onClick={() => { window.location.href = "/reportCorruptionIncident" }}  > */}
                <Grid className={classes.singleButton} item xs={12} md={3} sm={12} onClick={() => { window.location.href = "/shareStory" }}  >


                  <Grid
                    elevation={5}
                    className={[classes.bottomItemButton]}
                    // style={{ borderRadius: 10,background: 'linear-gradient(to right, #f15111, #eb4b0c)'}}
                    style={{
                      borderRadius: 10,
                      background: "white",
                      // borderBottom: "12px solid #f15111"
                      borderBottom: `12px solid ${share_story_theme_home_border}`
                    }}
                  >
                    <Grid className={classes.butonSection}>
                      <Grid
                        className={classes.buttonIcon}
                        style={{
                          background: share_story_theme_home_border, //"#f15111"
                        }}
                      >
                        <img
                          alt="share Img"
                          // src="/assets/images/share.png"
                          src="/assets/images/share.png"
                          style={{ width: "100%", paddingTop: 1 }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className={classes.buttonIncide}
                      alignItems="center"
                      justify="center"
                      direction="column" // changed row to column by shammika
                    >
                      <Grid item xs={7}>

                        {
                          this.state.languageName === "English" &&
                          <Typography
                            variant="h5"
                            className={["py-3", classes.buttonTitle]}
                          >
                            {/* Words move hearts, hearts move limbs */}
                            {
                              this.state.language
                                .incidentReportButtonTitle_Corruption.toUpperCase()
                            }
                          </Typography>
                        }
                        {
                          this.state.languageName === "Sinhala" &&
                          <Typography
                            variant="h5"
                            style={{
                              fontSize: 18,
                              // color: 'red',
                            }}
                            className={["py-3", classes.buttonTitle]}
                          >
                            {/* Words move hearts, hearts move limbs */}
                            {
                              this.state.language
                                .incidentReportButtonTitle_Corruption.toUpperCase()
                            }
                          </Typography>
                        }
                        {
                          this.state.languageName === "Tamil" &&
                          <Typography
                            variant="h5"
                            style={{
                              fontSize: 12,
                              // color: 'red',
                            }}
                            className={["py-3", classes.buttonTitle]}
                          >
                            {/* Words move hearts, hearts move limbs */}
                            {
                              this.state.language
                                .incidentReportButtonTitle_Corruption.toUpperCase()
                            }
                          </Typography>
                        }

                      </Grid>
                    </Grid>
                    <Grid item xs={12}>

                      {
                        this.state.languageName === "English" &&
                        <Typography
                          variant="h6"
                          className={["px-5", classes.buttonSubTitle]}
                        >
                          {/* Report incidents of bribery and corruption to TISL */}
                          {
                            this.state.language
                              .incidentReportButtonDescription_Corruption
                          }
                        </Typography>
                      }
                      {
                        this.state.languageName === "Sinhala" &&
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: 10,
                            // color: 'red',
                          }}
                          className={[classes.buttonSubTitle]}
                        >
                          {/* Report incidents of bribery and corruption to TISL */}
                          {
                            this.state.language
                              .incidentReportButtonDescription_Corruption
                          }
                        </Typography>
                      }
                      {
                        this.state.languageName === "Tamil" &&
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: 10,
                            // color: 'red',
                          }}
                          className={[classes.buttonSubTitle]}
                        >
                          {/* Report incidents of bribery and corruption to TISL */}
                          {
                            this.state.language
                              .incidentReportButtonDescription_Corruption
                          }
                        </Typography>
                      }


                    </Grid>
                  </Grid>
                </Grid>

                {/* Bottom Button Item 03 */}
                {/* <Grid item xs={11} md={3} onClick={() => { this.setState({ electionDialog: true }) }}  > */}

                {this.state.electionStatus == "active" ?
                  <Grid className={classes.singleButton} item xs={12} md={3} sm={12} onClick={() => { window.location.href = "/reportElectionIncident" }}  >


                    <Grid
                      elevation={5}
                      className={[classes.bottomItemButton]}
                      // style={{ borderRadius: 10, background: "linear-gradient(to right, #f7cf1f, #eec302)" }}
                      style={{
                        borderRadius: 10,
                        background: "white",
                        borderBottom: `12px solid ${election_report_theme_home_border}`
                      }}
                    >
                      <Grid className={classes.butonSection}>
                        <Grid
                          className={classes.buttonIcon}
                          style={{ background: election_report_theme_home_border }}
                        >
                          <img
                            alt="electionReportLogo"
                            src="/assets/images/btnElectionLogo.png"
                            style={{ width: "100%", paddingTop: 1 }}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        className={classes.buttonIncide}
                        alignItems="center"
                        justify="center"
                        direction="column" // changed row to column by shammika
                      >
                        <Grid item xs={7}>


                          {
                            this.state.languageName === "English" &&
                            <Typography
                              variant="h5"
                              className={["py-3", classes.buttonTitle]}
                            >
                              {/* Election Violations */}
                              {
                                this.state.language
                                  .incidentReportButtonTitle_Election.toUpperCase()
                              }
                            </Typography>
                          }

                          {
                            this.state.languageName === "Sinhala" &&
                            <Typography
                              variant="h5"
                              className={["py-3", classes.buttonTitle]}
                              style={{
                                fontSize: 18,
                                // color: 'red',
                              }}
                            >
                              {/* Election Violations */}
                              {
                                this.state.language
                                  .incidentReportButtonTitle_Election.toUpperCase()
                              }
                            </Typography>
                          }
                          {
                            this.state.languageName === "Tamil" &&
                            <Typography
                              variant="h5"
                              className={["pt-3", classes.buttonTitle]}
                              style={{
                                fontSize: 12,
                                // color: 'red',
                              }}
                            >
                              {/* Election Violations */}
                              {
                                this.state.language
                                  .incidentReportButtonTitle_Election.toUpperCase()
                              }
                            </Typography>
                          }

                          {this.state.language == sinhalaLanguage ? null : <div style={{ height: 21 }}></div>}

                        </Grid>

                      </Grid>
                      <Grid item xs={12}>
                        {
                          this.state.languageName === "English" &&
                          <Typography
                            variant="h6"
                            className={["px-5", classes.buttonSubTitle]}
                          >
                            {/* If you see violations, Report election violations to TISL */}
                            {
                              this.state.language
                                .incidentReportButtonDescription_Election
                            }
                          </Typography>
                        }
                        {
                          this.state.languageName === "Sinhala" &&
                          <Typography
                            variant="h6"
                            style={{
                              fontSize: 12,
                              // color: 'red',
                            }}
                            className={["px-5", classes.buttonSubTitle]}
                          >
                            {/* If you see violations, Report election violations to TISL */}
                            {
                              this.state.language
                                .incidentReportButtonDescription_Election
                            }
                          </Typography>
                        }
                        {
                          this.state.languageName === "Tamil" &&
                          <Typography
                            variant="h6"
                            style={{
                              fontSize: 10,
                              // color: 'red',
                            }}
                            className={["px-5", classes.buttonSubTitle]}
                          >
                            {/* If you see violations, Report election violations to TISL */}
                            {
                              this.state.language
                                .incidentReportButtonDescription_Election
                            }
                          </Typography>
                        }
                      </Grid>
                    </Grid>
                  </Grid> : <Grid></Grid>
                }


              </Grid>






            </Slide>


          </Grid>
        </Carousel>
        <div>
          {/* <Dialog
            // onClose={()=>{this.setState({shareStoryDialog:false})}}
            fullScreen
            maxWidth={"md"}
            aria-labelledby="simple-dialog-title"
            open={this.state.shareStoryDialog}
          >
            <DialogContent>
              <div style={{ float: "right" }}>
                <IconButton
                  onClick={() => {
                    this.setState({ shareStoryDialog: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              {this.state.windowActive ? (
                <div>
                  <ShareStoryComponent />
                </div>
              ) : (
                <div className={classes.formCard}>
                  <ShareStoryComponent />
                </div>
              )}
            </DialogContent>
          </Dialog> */}

          {/* <Dialog
            // onClose={()=>{this.setState({incidentReportDialog:false})}}
            fullScreen
            maxWidth={"md"}
            aria-labelledby="simple-dialog-title"
            open={this.state.incidentReportDialog}
          >
            <DialogContent>
              <div style={{ float: "right" }}>
                <IconButton
                  onClick={() => {
                    this.setState({ incidentReportDialog: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              {this.state.windowActive ? (
                <div>
                  <ReportIncident />
                </div>
              ) : (
                <div className={classes.formCard}>
                  <ReportIncident />
                </div>
              )}
            </DialogContent>
          </Dialog> */}

          {/* <Dialog
            // onClose={()=>{this.setState({electionDialog:false})}}
            fullScreen
            maxWidth={"md"}
            aria-labelledby="simple-dialog-title"
            open={this.state.electionDialog}
          >
            <DialogContent>
              <div style={{ float: "right" }}>
                <IconButton
                  onClick={() => {
                    this.setState({ electionDialog: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              {this.state.windowActive ? (
                <div>
                  <ReportElectionIncident />
                </div>
              ) : (
                <div className={classes.formCard}>
                  <ReportElectionIncident />
                </div>
              )}
            </DialogContent>
          </Dialog> */}
        </div>
      </div>
    );
  }
}

Carousel_home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Carousel_home);
