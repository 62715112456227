import moment from "moment";

import { englishLanguage } from "../views/languages/englishLanguage";
import { sinhalaLanguage } from "../views/languages/sinhalaLanguage";
import { tamilLanguage } from "../views/languages/tamilLanguage";
class Util {

    getParsedDate(date) {//to convert date to local
        var gmtDateTime = moment.utc(date, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm:ss');
        var stillUtc = moment.utc(gmtDateTime).toDate();
        //var local = moment(stillUtc).local().format('YYYY-MM-DD hh:mm A');
        var local = moment(stillUtc).local().format('YYYY-MM-DD');
        return local;
    }

    getParsedTime(date) {
        var gmtDateTime = moment.utc(date, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm:ss');
        var stillUtc = moment.utc(gmtDateTime).toDate();
        var local = moment(stillUtc).local().format('hh:mm A');
        return local;
    }

    getcurruptionType(language, value) {

        var sinhala_corruptionTypes = sinhalaLanguage.lng_corruption_types;
        var tamil_corruptionTypes = tamilLanguage.lng_corruption_types;

        var final_value = value;

        if (value == undefined) { //"Share Story"

            final_value = "Share Story";

            if (language === "Sinhala") {
                final_value = sinhalaLanguage.story_appBar;

            }
            else if (language === "Tamil") {
                final_value = tamilLanguage.story_appBar;

            }

        }
        else if (value !== null && value !== "Election") { //corruption

            if (language === "Sinhala") {

                sinhala_corruptionTypes.forEach(item => {

                    if (item.value === value) {
                        final_value = item.label;
                    }

                });

            }
            else if (language === "Tamil") {

                tamil_corruptionTypes.forEach(item => {

                    if (item.value === value) {
                        final_value = item.label;
                    }

                });

            }

        }
        else { //Election

            final_value = "Election Violation";

            if (language === "Sinhala") {
                final_value = sinhalaLanguage.electionReport_title;

            }
            else if (language === "Tamil") {
                final_value = tamilLanguage.electionReport_title;

            }

        }

        return final_value;

    }

    getDistrict_Language_Label(language, district) {

        // console.log("getDistrict_Language_Label");

        var sinhala_district = sinhalaLanguage.lng_Districts;
        var tamil_district = tamilLanguage.lng_Districts;

        var district_value = district;

        if (language === "Sinhala") {

            sinhala_district.forEach(dis => {

                if (dis.value === district) {
                    district_value = dis.label;
                }

            });

        }
        else if (language === "Tamil") {

            tamil_district.forEach(dis => {

                if (dis.value === district) {
                    district_value = dis.label;
                }

            });

        }

        return district_value;
    }


}
export default new Util();