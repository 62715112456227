/*global google*/
import React, { Fragment } from "react";
import { compose, withProps, withHandlers } from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";
import Box from '@material-ui/core/Box';
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer"
import { Card, Chip, Menu, MenuItem, Fab, CardActionArea, CardContent, Typography, CardMedia, InputAdornment, IconButton } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Carousel from "react-material-ui-carousel";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Close from '@material-ui/icons/Close';
import StatisticsServices from '../../../services/StatisticsServices';
import Util from '../../../services/Util';
import * as appconst from '../../../appconst';

var MyMapComponent = compose(
    withProps({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyCL6g9mcTszQRG_7fMOLTBvBCzYlD71ai4&libraries=geometry,drawing,places",
        loadingElement: <div className="h-full" />,
        containerElement: <Box className=" p-2" style={{ height: "100%" }} />,//please add this style in sccss
        mapElement: <div className="h-full" />
    }),
    withHandlers({
        onMarkerClustererClick: () => (markerClusterer) => {
            const clickedMarkers = markerClusterer.getMarkers()
            console.log(`Current clicked markers length: ${clickedMarkers.length}`)
            console.log(clickedMarkers)
        },
    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap
        onClick={() => props.onMarkerClick(null, null)}
        defaultZoom={props.default_zoom}
        options={{ scrollwheel: false }}
        defaultCenter={{
            lat: props.dafault_lat,
            lng: props.dafault_lng,
        }}

        // search function
        center={{
            lat: props.searchLocation.lat != null ? props.searchLocation.lat : null,
            lng: props.searchLocation.lng != null ? props.searchLocation.lng : null,
        }}
    >
        <MarkerClusterer
            onClick={props.onMarkerClustererClick}
            averageCenter
            enableRetinaIcons
            gridSize={60}
        >
            {props.sold.map((row) => (
                <Marker
                    key={row.row_id}
                    isMarkerShown={props.isMarkerShown}
                    position={{ lat: row.lat, lng: row.lng }}
                    onClick={() => props.onMarkerClick(row.row_id)}
                    icon="/assets/images/location_picker.png"

                >
                    {row.is_show ?
                        <InfoBox options={{ closeBoxURL: ``, enableEventPropagation: true }}>
                            <Card
                                style={{ maxWidth: 350, minWidth: 350 }}
                            >
                                <CardActionArea>
                                    <CardContent>
                                        <Box display="flex" bgcolor="background.paper">
                                            <Box flexGrow={1} >
                                                <Chip size="small" label="Published" />
                                            </Box>
                                            <Box >
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    {Util.getParsedDate(row.date)}
                                                </Typography>
                                            </Box>

                                        </Box>
                                        <Grid container >
                                            <Grid item xs={6}>

                                            </Grid>
                                            <Box item xs={6} className="pt-1" display="flex" justifyContent="flex-end">

                                            </Box >
                                        </Grid>

                                        <Typography gutterBottom variant="h5" component="h2">

                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {row.story}
                                        </Typography>

                                        {row.attachment != null ?
                                            <Carousel autoPlay={true} indicators={true} navButtonsAlwaysVisible={false} animation={"fade"} >

                                                {row.attachment.map((attachment) => (
                                                    <img src={appconst.ATTACHMENTS_LOCATION + attachment} style={{ width: "100%" }} />

                                                ))}
                                            </Carousel>
                                            : null
                                        }
                                        <Button className="capitalize" onClick={() => { window.location.href = "/incidents/" + row.id }} color="primary">Read More...</Button>

                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </InfoBox>
                        : null}
                </Marker>
            ))}


        </MarkerClusterer>
    </GoogleMap >
));

class MarkerMap extends React.PureComponent {


    constructor(props) {
        super(props);
        this.autocompleteInput = React.createRef();
        this.autocomplete = null;
        // /this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    }


    handlePlaceChanged() {

        const place = this.autocomplete.getPlace();
        let lat = null
        let lng = null
        console.log(place)

        if (place.geometry) {
            lat = place.geometry.location.lat()
            lng = place.geometry.location.lng()
        }


        this.setState({
            show: false
        })

        this.setState({
            searchLocation: {
                lat: lat,
                lng: lng
            }
        })

        this.setState({
            show: true
        })

    }

    timer;

    state = {
        search: null,
        show: false,
        isMarkerShown: false,
        default_locations_incident: [
            { row_id: 0, lat: 7.100345, lng: 80.059519, is_show: false },
            { row_id: 1, lat: 7.800345, lng: 80.059519, is_show: false },

        ],
        default_locations_sale: [

        ],
        locations_sale: [],
        locations_incident: [],
        clicked_button: "All",
        dafault_lat: 7.697728,
        dafault_lng: 80.4661353,
        default_zoom: 7.2,
        searchLocation: {
            lat: 7.697728,
            lng: 80.4661353,
            is_show: false
        }
    };

    async componentDidMount() {
        this.delayedShowMarker();

        this.setState({
            locations_incident: this.state.default_locations_incident
        })
        await StatisticsServices.getAllCorruptionsForMap().then(res => {
            console.log("res for map", res.data.insidents)
            var data = [];
            for (let index = 0; index < res.data.insidents.length; index++) {
                var attactment = [];
                if (res.data.insidents[index].incident_report.other_details != null) {
                    attactment = JSON.parse(res.data.insidents[index].incident_report.other_details.attachments);

                } else {
                    attactment = null;
                }
                data.push({
                    "row_id": index,
                    "lat": parseFloat(res.data.insidents[index].incident_report.latitude),
                    "lng": parseFloat(res.data.insidents[index].incident_report.longitude),
                    "is_show": false,
                    "story": res.data.insidents[index].story,
                    "date": res.data.insidents[index].created_at,
                    "status": "Published",
                    "attachment": attactment,
                    "id": res.data.insidents[index].id

                })

            }

            this.setState({
                locations_incident: data,
                //show:true
            }, () => { })
        })
            .catch((error) => {
                console.log("error", error);
            })




    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    delayedShowMarker = () => {
        this.timer = setTimeout(() => {
            this.setState({ isMarkerShown: true });
        }, 5000);
    };

    handleMarkerClick = (id) => {
        if (id != null) {
            this.setState({
                show: false,

            })

            let locations = this.state.locations_incident


            locations[id].is_show = !locations[id].is_show

            for (let i = 0; i < locations.length; i++) {
                if (locations[i].row_id !== id) {
                    locations[i].is_show = false
                }
            }

            var searchLocation = {
                lat: locations[id].lat,
                lng: locations[id].lng,
                is_show: false
            }


            this.setState({
                dafault_lat: locations[id].lat,
                dafault_lng: locations[id].lng,
                locations_incident: locations,
                searchLocation,
                show: true,

            })
        }
    };


    handleButtonClick = () => {
        console.log("ok")
    }

    clear() {
        window.location.reload(false)
    }

    handleChange = event => {
        event.persist();
        this.setState({
            search: event.target.value
        });
    };


    render() {
        return (
            <Fragment>

                <Grid style={{ overflow: 'hidden', borderRadius: 17, height: "100%" }}>

                    {
                        this.state.show ?
                            <MyMapComponent
                                default_zoom={this.state.default_zoom}
                                dafault_lat={this.state.dafault_lat}
                                dafault_lng={this.state.dafault_lng}
                                sold={this.state.locations_incident}

                                searchLocation={this.state.searchLocation}
                                isMarkerShown={this.state.isMarkerShown}
                                onMarkerClick={(id, type) => this.handleMarkerClick(id)}
                                onButtonClick={() => this.handleButtonClick()}
                            />
                            :
                            <MyMapComponent
                                default_zoom={this.state.default_zoom}
                                dafault_lat={this.state.dafault_lat}
                                dafault_lng={this.state.dafault_lng}
                                sold={this.state.locations_incident}

                                searchLocation={this.state.searchLocation}
                                isMarkerShown={this.state.isMarkerShown}
                                onMarkerClick={(id, type) => this.handleMarkerClick(id)}
                                onButtonClick={() => this.handleButtonClick()}
                            />
                    }

                </Grid>
            </Fragment >
        );
    }
}

export default MarkerMap;
