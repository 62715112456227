import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  Link,
  Grid,
  SwipeableDrawer,
  Avatar,
  Icon,
  IconButton,
  MenuItem,
  Button,
  GridList,
  GridListTile,
  GridListTileBar,
  Divider,
  ListItemText,
} from "@material-ui/core";
import { SimpleCard, MatxProgressBar } from "matx";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import Footer from "./components/Footer";
import AppAppBar from "./components/AppAppBar";
import VideoSection from "./components/VideoSection";
import { withStyles } from "@material-ui/core/styles";

import { englishLanguage } from "../languages/englishLanguage";
import { sinhalaLanguage } from "../languages/sinhalaLanguage";
import { tamilLanguage } from "../languages/tamilLanguage";

import languageService from "app/services/languageService";

//added by shammika
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ScaleUtils } from "react-vis";

const styleSheet = (theme) => ({
  gridList: {
    height: 275,
  },

  mainPadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 246,
      paddingRight: 246,
      paddingTop: 135,
      paddingBottom: 50,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  mainPadding2: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 120,
      paddingRight: 246,
      paddingTop: 0,
      paddingBottom: 50,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  newsImage: {
    width: 90,
    height: 60,
    paddingRight: theme.spacing(1),
  },
  newsHrLine: {
    backgroundColor: "#0074e1",
    height: 3,
  },
  listItem: {
    borderBottomColor: "#dbdcdc",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  mobileViewVedio: {
    marginBottom: 10,

    // [theme.breakpoints.up(960)]: {

    //   display: "none",

    // },
  },
  largeScreenViewVedio: {
    [theme.breakpoints.down(960)]: {
      display: "none",
    },
  },
  accordionStyle: {
    backgroundColor: "#ffff",
  },
  pageTitlePadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 116,
      paddingTop: 60,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 30,
      paddingTop: 60,
    },
  },
});

class FaQPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: englishLanguage,
    };
  }

  async loadLanguageDetails() {
    var lng = await languageService.getLanguageDetails();

    if (lng == "Sinhala") {
      this.setState({
        language: sinhalaLanguage,
      });
    } else if (lng == "Tamil") {
      this.setState({
        language: tamilLanguage,
      });
    }
    // console.log("FAQ Page:", this.state);
  }

  componentDidMount() {
    this.loadLanguageDetails();
  }

  render() {
    const { classes } = this.props;
    const preventDefault = (event) => event.preventDefault();
    return (
      <Fragment>
        {/* App bar component */}
        <AppAppBar />
        {/* Header Component */}
        <div
          className={classes.pageTitlePadding}
          style={{
            backgroundImage: `url(/assets/images/statBackground.png)`,
            // backgroundImage: `url(/assets/images/incidents_slide_image.jpg)`,
            // background: "linear-gradient(45deg, #ff0081, #6827b0)",
            width: "100%",
            height: 160,
            backgroundSize: "cover",
            display: "flex",
            // justifyContent: "center",
            float: "left",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            style={{ fontSize: 34, color: "white", fontWeight: 600 }}
          >
            {/* FAQs */}
            {this.state.language.faq_mainTitle}
          </Typography>
        </div>
        <div style={{ backgroundColor: "white" }} className={classes.mainPadding2}>
          <Fade in={true}>
            {/* FAQ Grid */}
            <div
              className={[classes.root,]}

            >
              <Grid
                alignItems="center"
                container

                spacing={1}
              >

                <div
                  style={{
                    backgroundColor: "transparent"
                  }}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignContent="center"
                    item
                    xs={12}
                    style={{
                      // backgroundColor: "#fbfbfb",
                      marginBottom: 10,
                      // padding: 30,
                    }}
                  >
                    <Typography
                      variant="h1"
                      component="h2"
                      style={{
                        fontSize: 30,
                        color: "#000",
                        fontWeight: 600,
                        padding: "20px 5px",
                        marginTop: 10
                      }}
                    >
                      {/* How Can I Use? */}
                      {this.state.language.faq_subTitle}
                    </Typography>
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Grid
                      xs={12}
                      sm={5}
                      justifyContent="center"
                      alignContent="center"
                      className={classes.mobileViewVedio}
                      container
                      item
                    >
                      <VideoSection />
                    </Grid>
                  </div>

                  {/* Faq's Container */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginTop: 18
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      md={12}
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      {/* Questions */}
                      <div>
                        {/* Qu 1 */}
                        <Accordion>
                          <AccordionSummary
                            className={classes.accordionStyle}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography
                              variant="p"
                              component="p"
                              gutterBottom
                              style={{ fontWeight: 500 }}
                            >
                              <strong>
                                {/* 1.	What does this website do? */}
                                {this.state.language.faq_question1}
                              </strong>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography
                              variant="p"
                              component="p"
                              gutterBottom
                              style={{ fontWeight: 500 }}
                              align="justify"
                            >
                              {/* Apesalli.lk is an exposure and discussion platform for all things on anti-corruption. You can report incidents of bribery and corruption and also share your experiences on bribery and corruption in government, private or civil society sector using Apesalli.lk. This is a platform for both activism and advocacy on anti-corruption in Sri Lanka. */}
                              {this.state.language.faq_q1Answer}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        {/* Qu 2 */}
                        <Accordion>
                          <AccordionSummary
                            className={classes.accordionStyle}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography
                              variant="p"
                              component="p"
                              gutterBottom
                              style={{ fontWeight: 500 }}
                            >
                              <strong>
                                {/* 2.   Why should I be interested? */}
                                {this.state.language.faq_question2}
                              </strong>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography
                              variant="p"
                              component="p"
                              gutterBottom
                              style={{ fontWeight: 500 }}
                              align="justify"
                            >
                              {/* Bribery and corruption have become a norm in our society and most of us turn a blind eye to it. This vicious cycle can only be broken by taking a stand against this very problem and apesalli.lk gives Sri Lankans an opportunity to do just that. */}
                              {this.state.language.faq_q2AnswerPara1}
                              <br></br>
                              <br></br>
                              {/* We know that everyone leads a busy lifestyle, and taking a stand on a cause might consume time that you do not have to spare! This platform simplifies the process of taking a stand against bribery and corruption. Report an incident or share your story, and the platform will help propagate the message and on possible occasions, take action on behalf of you. */}
                              {this.state.language.faq_q2AnswerPara2}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        {/* Qu 3 */}
                        <Accordion>
                          <AccordionSummary
                            className={classes.accordionStyle}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography
                              variant="p"
                              component="p"
                              gutterBottom
                              style={{ fontWeight: 500 }}
                            >
                              <strong>
                                {/* 3.	What steps are taken if I report an incident? */}
                                {this.state.language.faq_question3}
                              </strong>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography
                              variant="p"
                              component="p"
                              gutterBottom
                              style={{ fontWeight: 500 }}
                              align="justify"
                            >
                              {/* We will, */}
                              {this.state.language.faq_q3AnswerPart1}
                              <br></br>
                              {/* - Conduct an initial filtering of complaints received */}
                              {this.state.language.faq_q3AnswerPart2}
                              <br></br>
                              {/* - Independently verify the facts of the reported incident */}
                              {this.state.language.faq_q3AnswerPart3}
                              <br></br>
                              {/* - Collect more evidence if necessary */}
                              {this.state.language.faq_q3AnswerPart4}
                              <br></br>
                              {/* - Assist the complainant to submit the complaint or directly submit the complaint to the relevant authorities */}
                              {this.state.language.faq_q3AnswerPart5}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        {/* Qu 4 */}
                        <Accordion>
                          <AccordionSummary
                            className={classes.accordionStyle}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography
                              variant="p"
                              component="p"
                              gutterBottom
                              style={{ fontWeight: 500 }}
                            >
                              <strong>
                                {/* 4.	What happens when I share my experience? */}
                                {this.state.language.faq_question4}
                              </strong>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography
                              variant="p"
                              component="p"
                              gutterBottom
                              align="justify"
                              style={{ fontWeight: 500 }}
                            >
                              {/* We may post the story on the TISL’s website and/or social media for millions of other people to read, share and inspire. We may take necessary legal action based on the stories, after informing the submitter and based on the availability of information. */}
                              {this.state.language.faq_q4Answer}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        {/* Qu 5 */}
                        <Accordion>
                          <AccordionSummary
                            className={classes.accordionStyle}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography
                              variant="p"
                              component="p"
                              gutterBottom
                              style={{ fontWeight: 500 }}
                            >
                              <strong>
                                {/* 5.	What difference I can make if I share my experience on bribery and corruption? */}
                                {this.state.language.faq_question5}
                              </strong>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography
                              variant="p"
                              component="p"
                              gutterBottom
                              style={{ fontWeight: 500 }}
                              align="justify"
                            >
                              {/* Sharing your experience will inspire and motivate others to share their stories as well. Also, it will help us find corruption trends and hotspots. Most importantly sharing your story, no matter how small you think it is, will contribute to the fight against corruption! */}
                              {this.state.language.faq_q5Answer}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        {/* Qu 6 */}
                        <Accordion>
                          <AccordionSummary
                            className={classes.accordionStyle}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography
                              variant="p"
                              component="p"
                              gutterBottom
                              style={{ fontWeight: 500 }}
                            >
                              <strong>
                                {/* 6.What are the types of corruption you can report?*/}
                                {this.state.language.faq_question6}
                              </strong>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography
                              variant="p"
                              component="p"
                              gutterBottom
                              style={{ fontWeight: 500 }}
                              align="justify"
                            >
                              <ul>
                                {
                                  this.state.language.lng_corruption_types.map((row, key) => (
                                    <>
                                      <li>
                                        <strong>
                                          {this.state.language.faq_q6AnswerP1Title}
                                        </strong>

                                        {
                                          key == 6 ? 
                                          row.label
                                          :
                                          row.label +"-" +row.desc
                                        }

                                        
                                      </li>
                                      <br />
                                    </>
                                  ))
                                }
                              </ul>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>

                        {/* Qu 7 */}
                        <Accordion>
                          <AccordionSummary
                            className={classes.accordionStyle}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography
                              variant="p"
                              component="p"
                              gutterBottom
                              style={{ fontWeight: 500 }}
                            >
                              <strong>
                                {/* 7.Why do I have to disclose my personal information? */}
                                {this.state.language.faq_question7}
                              </strong>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography
                              variant="p"
                              component="p"
                              gutterBottom
                              style={{ fontWeight: 500 }}
                              align="justify"
                            >
                              {/* You do not have to and reports and stories can be shared anonymously through the website. However, if the personal information is disclosed, it makes it easier for us to look into and gather information about the incident, and to help you follow up. */}
                              {this.state.language.faq_q7Answer}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </Grid>
                  </div>
                </div>
                {/* Left Container */}
                {/* <Grid
                item
                alignItems="flex-start"
                xs={12}
                md={5}
                container
                className={classes.largeScreenViewVedio}
                // style={{
                //   backgroundColor: "#ffffff",
                // }}
              >
               
                <VideoSection />
              </Grid> */}
              </Grid>
            </div>
          </Fade>
        </div>
        <Divider variant="middle"></Divider>
        {/* Footer */}
        <Footer />
      </Fragment>
    );
  }
}

export default withStyles(styleSheet)(FaQPage);
