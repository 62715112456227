import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {
  Typography,
  List,
  ListItem,
  Link,
  Chip,
  Grid,
  Button,
  GridList,
  Divider,
} from "@material-ui/core";
import "react-multi-carousel/lib/styles.css";
import * as appconst from "../../../appconst";
import { withStyles } from "@material-ui/core/styles";
import NewsServices from "../../../services/NewsServices";
import IncidentService from "../../../services/IncidentServices";
import Util from "../../../services/Util";
import Paper from "@material-ui/core/Paper";

import { englishLanguage } from "../../languages/englishLanguage";
import { sinhalaLanguage } from "../../languages/sinhalaLanguage";
import { tamilLanguage } from "../../languages/tamilLanguage";
import { isMobile } from "utils";
import languageService from "app/services/languageService";

const styleSheet = (theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  gridList: {
    height: 430,
    margin: "revert !important",
    padding: "5px 0 0 15px",
    width: '100%'
  },

  newsTitle: {
    color: "white",
    padding: "30px 83px 0px",
    fontFamily: "Source Sans Pro",
    fontWeight: 700,
    fontSize: 30,
    margin: "30px 0",
    // [theme.breakpoints.down("md")]: {
    //   // paddingLeft: 25,
    //   margin: 0,
    // },
  },
  newsHrLine: {
    backgroundColor: "#0074e1",
    height: 3,
  },
  mainPadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 124,
      paddingRight: 120,
      paddingTop: 15,
      paddingBottom: 30,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  //Main News Image
  featurednewsImage: {
    cursor: "pointer",
    width: "100%",
    height: 334,
    borderRadius: 12,
    padding: 18,
    marginLeft: -36,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      height: 240,
    },
    // paddingRight: theme.spacing(1),
  },

  imageLarge: {
    padding: 5,
    [theme.breakpoints.up("md")]: { paddingRight: 14, margin: "45px 0px 49px" },
  },

  //Main News Title Style in smaller screen
  mainNewsTitle: {
    cursor: "pointer",
    marginRight: -35,
    // paddingBottom: 5,
    // backgroundColor: "#1f1f2e",

    color: "#000",

    marginTop: 40,
    [theme.breakpoints.down("md")]: {
      marginLeft: 17,
      marginRight: 15,
    },
  },
  //Main news Title and Descrption Grid
  mainNewsTitleGrid: {
    marginLeft: -35,
    paddingRight: 10,
    marginTop: 8,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: -18,
      paddingRight: 0,
    },
  },
  divider: {
    // backgroundColor:"white",
    margin: "10px -33px 10px 0",
    [theme.breakpoints.down("md")]: {
      margin: 10,
    },
  },
  mainNewDescription: {
    cursor: "pointer",
    fontWeight: 100,
    color: "#EEEE",
    margin: "10px -33px 10px 0",
    paddingRight: 12,
    [theme.breakpoints.down("md")]: {
      margin: 20,
      paddingRight: 0,
    },
  },

  //News list item Styles
  listItem: {
    cursor: "pointer",
    backgroundColor: "#ebebeb",
    borderRadius: 10,
    border: "1px solid #fff",
    color: "black",
    // boxShadow:
    //   " 0 13px 27px 0px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",

    // paddingRight: "unset",
    margin: "5px 20px 5px 32px",
    width: "90%",
    transition: "all ease 200ms",
    "&:hover": {
      border: "1px solid #fff",
      // backgroundColor: "transparent",
      backgroundColor: "#02b0f5",

      color: "#ffff",
      //filter: "brightness(1)",
      transform: "scale(1.035)",

      "& .MuiChip-root": {
        backgroundColor: "white",
        "& .MuiChip-label": {
          color: "#02b0f5",
        },
      },
      //   boxShadow:
      //     "0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02)",
    },
  },
  listItemTitle: {
    cursor: "pointer",
    fontSize: 20,
    fontWeight: 600,
  },
  listItemSubTitle: {
    cursor: "pointer",
    fontWeight: 400,
  },

  newsGrid: {
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: -30,
    },
  },

  //List news Image
  newsImage: {
    width: 150,
    height: 110,
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: 10,
    marginLeft: -38,
    marginRight: 10,
    [theme.breakpoints.down("md")]: {
      marginLeft: -49,
      width: 75,
      height: 75,
    },
  },

  storyGrid: {
    borderRadius: 10,
    // marginLeft:60,
    paddingLeft: 30,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      paddingLeft: 0,
    },
  },
  newsPadding: {
    paddingRight: 30,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      paddingRight: 0,
    },
  },

  gridItem: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "10px !important",
    },
  },
  chipLable: {
    backgroundColor: "#02b0f5",

    "& .MuiChip-label": {
      color: "white",
    },
  },
  //padding for smaller screens
  resPad: {
    [theme.breakpoints.down("md")]: {
      margin: 15,
    },
  },
  newsSection: {
    [theme.breakpoints.up("md")]: {
      //paddingBottom: 20
    },
    [theme.breakpoints.down("md")]: {
      // paddingTop: 20,
      paddingBottom: 10,
    },
  },

  carousel_container: {
    height: 340,
  },

  //Read More Button
  btnReadMore: {
    color: "#02b0f5",
    borderRadius: 6,
    backgroundColor: "#fff",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#02b0f5",
      borderColor: "#02b0f5",
    },
  },
  //Buttons alignments
  btnAlign: {
    display: "grid",
    float: "left",
    margin: " 10px 0 20px 27px",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: " 10px 25px 20px 27px",
    },
  },
});

class NewsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: englishLanguage,
      languageName: 'English',
      filterData: {
        from: null,
        to: null,
        status: null,
        type: "Share Story"
      },
      news: [1, 2, 3, 4, 5],
      incidents: [1, 2, 3, 4, 5],
      id: 50,
    };
  }

  async loadData(page) {
    let filter = this.state.filterData;
    let res_data = await NewsServices.getNewswithFilters(filter, page);
    let corruptions = await IncidentService.getPublicIncidentWithFilters(
      filter,
      page
    );
    // console.log("News", res_data)
    //console.log("pagination",news.data.news)
    this.setState({
      news: res_data.data.news.data,
      paginationNews: res_data.data.news,
      incidents: corruptions.data.insidents.data,
    });
  }

  async loadLanguageDetails() {
    var lng = await languageService.getLanguageDetails();

    if (lng == "Sinhala") {
      this.setState({
        language: sinhalaLanguage,
        languageName: 'Sinhala'
      });
    } else if (lng == "Tamil") {
      this.setState({
        language: tamilLanguage,
        languageName: 'Tamil'
      });
    }
    console.log("News Section:", this.state);
  }

  async componentDidMount() {
    this.loadData(1);
    this.loadLanguageDetails();
  }

  render() {
    var mobSize = isMobile();

    const { classes } = this.props;
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        slidesToSlide: 3, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 641 },
        items: 3,
        slidesToSlide: 2, // optional, default to 1.
      },
      tablet2: {
        breakpoint: { max: 641, min: 464 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };
    return (
      <div
        className="pb-12"
        style={{
          backgroundColor:
            "#02b0f5" /* themeColors.purple1.palette.theme_color.main */,
        }}
      >
        <Grid container>
          {/* <Grid style={{ width: "100%", textAlign: "center" }}>
            <Typography className={classes.newsTitle}>
              
              {this.state.language.newsPage_Title}
            </Typography>
          </Grid> */}

          {/* Grid that contain news head and news list */}
          <Grid container className={classes.mainPadding} spacing={2}>
            {/* Grid that contain Main Image and News Header */}
            {/* <Grid
              container
              xs={12}
              lg={7}
              style={{
                borderRadius: 10,
                backgroundColor: "#ffff",
              }}
              className={classes.resPad}
            >
              {this.state.news.length > 0 ? (
                <Grid
                  container

                  onClick={() => {
                    window.location.href = "news/" + this.state.news[0].id;
                  }}
                >
                  <Grid item xs={12} lg={8} className={classes.imageLarge}>
                    {this.state.news[0].attachments == null ? (
                      <img
                        alt="defaulicidentImg"
                        src="/assets/images/default_incident.png"
                        className={classes.featurednewsImage}
                      />
                    ) : (
                      <div
                        className={classes.featurednewsImage}
                        style={{
                          backgroundImage:
                            "url(" +
                            appconst.ATTACHMENTS_LOCATION +
                            "news/" +
                            JSON.parse(this.state.news[0].attachments)[0] +
                            ")",
                          backgroundSize: "cover",
                        }}
                      ></div>
                      // <img
                      //   style={{ resizeMode: "contain" }}
                      //   alt="newsimage"
                      //   src={
                      //     appconst.ATTACHMENTS_LOCATION +
                      //     "news/" +
                      //     JSON.parse(this.state.news[0].attachments)[0]
                      //   }
                      //   className={classes.featurednewsImage}
                      // />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={4}
                    className={classes.mainNewsTitleGrid}
                  >
                    <div className={classes.mainNewsTitle}>
                      <Typography
                        align="left"
                        variant="h5"
                        component="h6"
                        gutterBottom
                        style={{ fontSize: 27, fontWeight: 900 }}
                      >
                        {(this.state.news[0].title + "").toUpperCase()}
                      </Typography>
                    </div>
                    <Divider
                      variant="middle"
                      className={classes.divider}
                    ></Divider>
                    <div className={classes.mainNewDescription}>
                      <Typography
                        variant="p"
                        component="h6"
                        gutterBottom
                        align="justify"
                        style={{ fontWeight: 100, color: "grey" }}
                      >
                        {String(this.state.news[0].description).length > 400
                          ? (this.state.news[0].description + " ")
                            .substring(0, 400)
                            .concat("...")
                          : this.state.news[0].description}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Grid></Grid>
              )}
            </Grid> */}

            {/* News List by shammika */}
            <Grid
              container
              className={[classes.resPad, classes.newsPadding]}
              xs={12}
              md={6}
              style={{
                // backgroundColor: "#ffff",
                borderRadius: 10,
                // marginLeft: 15,
              }}
            >
              <Grid className={classes.newsGrid}>
                <Typography
                  style={{
                    fontSize: this.state.languageName === "Tamil" ? 20 : (this.state.languageName === "Sinhala" ? 20 : 30),
                  }}
                  className={classes.newsTitle}>
                  {/* News */}
                  {this.state.language.news_appBar}
                </Typography>
              </Grid>
              <Grid
                container
                style={{
                  backgroundColor: "#ffff",
                  borderRadius: 10,
                  // paddingBottom: 10,
                }}
              >
                <GridList cols={1} className={classes.gridList}>
                  {this.state.news.map((item, key) =>
                    key <= 4 ? (
                      <Grid
                        className={classes.gridItem}
                        key={item.id}
                        /* onClick={() => {
                          window.location.href = "news/" + item.id;
                        }} */
                        style={{
                          height: "fitContent",
                          // backgroundColor: "#e4e4e4",
                          borderRadius: 10,
                        }}
                      >
                        <ListItem button
                          onClick={() => {
                            window.location.href = "news/" + item.id;
                          }} className={classes.listItem}>
                          {item.attachments == null ? (
                            <div
                              className={classes.newsImage}
                              style={{
                                backgroundImage:
                                  "url(/assets/images/default_incident.png)",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            ></div>
                          ) : (
                            // <img

                            //   alt="incidentImg"
                            //   src="/assets/images/default_incident.png"
                            //   className={classes.newsImage}
                            //   resizeMode="contain"
                            // />
                            <div
                              className={classes.newsImage}
                              style={{
                                backgroundImage:
                                  "url(" +
                                  appconst.ATTACHMENTS_LOCATION +
                                  "news/" +
                                  JSON.parse(item.attachments)[0] +
                                  ")",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            ></div>
                            // <img

                            //   alt="newsImg"
                            //   src={
                            //     appconst.ATTACHMENTS_LOCATION +
                            //     "news/" +
                            //     JSON.parse(item.attachments)[0]
                            //   }
                            //   className={classes.newsImage}
                            //   resizeMode="contain"
                            // />
                          )}
                          <Grid item xs={9} md={9}>
                            <Typography
                              variant="p"
                              component="h6"
                              // gutterBottom
                              className={classes.listItemTitle}
                            >
                              {mobSize == false
                                ? String(item.title).length > 20
                                  ? (item.title + " ")
                                    .substring(0, 20)
                                    .concat("...")
                                  : item.title
                                : (item.title + " ")
                                  .substring(0, 10)
                                  .concat("...")}
                            </Typography>
                            <Typography
                              align="justify"
                              variant="p"
                              component="p"
                              gutterBottom
                              className={classes.listItemSubTitle}
                            >
                              {mobSize == false
                                ? String(item.description).length > 100
                                  ? (item.description + " ")
                                    .substring(0, 100)
                                    .concat("...")
                                  : item.description
                                : (item.description + " ")
                                  .substring(0, 35)
                                  .concat("...")}
                            </Typography>
                            {/* <div style={{ float: "right", display: "flex" }}>
                            <Button
                              onClick={() => {
                                window.location.href = "news/" + item.id;
                              }}
                              className={[classes.btnReadMore, "float-end"]}
                              variant="contained"
                              size="small"
                              color="primary"
                            >
                              {/* Read more 
                              {this.state.language.readMoreButton}
                            </Button>
                          </div> */}
                          </Grid>
                        </ListItem>
                      </Grid>
                    ) : (
                      console.log("End Grid")
                    )
                  )}
                </GridList>

                <div className={classes.btnAlign}>
                  <Button
                    onClick={() => {
                      window.location.href = "/news";
                    }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.btnReadMore}
                  >
                    {/* Read More... */}
                    {this.state.language.readMoreButton}
                  </Button>
                </div>
              </Grid>
            </Grid>

            {/* Stories List by shammika */}
            <Grid
              container
              className={[classes.resPad, classes.storyGrid]}
              xs={12}
              md={6}
            >
              <Grid className={classes.newsGrid}>
                <Typography
                  style={{
                    fontSize: this.state.languageName === "Tamil" ? 20 : (this.state.languageName === "Sinhala" ? 20 : 30),
                  }}

                  className={classes.newsTitle}>
                  {/* Stories */}
                  {this.state.language.story_appBar}
                </Typography>
              </Grid>
              <Grid
                container
                style={{
                  backgroundColor: "#ffff",
                  borderRadius: 10,
                  // paddingBottom: 10,
                }}
              >
                <GridList cols={1} className={classes.gridList}>
                  {this.state.incidents.map((item, key) =>
                    key <= 4 ? (
                      <Grid
                        className={classes.gridItem}
                        key={item.id}
                        /*  onClick={() => {
                           window.location.href = "incidents/" + item.id;
                         }} */
                        style={{
                          height: "fitContent",
                          // backgroundColor: "#ffff",
                          borderRadius: 10,
                        }}
                      >
                        <ListItem button
                          onClick={() => {
                            window.location.href = "incidents/" + item.id;
                          }} className={classes.listItem}>
                          {item.attachments == null ? (
                            <div
                              className={classes.newsImage}
                              style={{
                                backgroundImage:
                                  "url(/assets/images/default_incident.png)",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            ></div>
                          ) : (
                            // <img

                            //   alt="incidentImg"
                            //   src="/assets/images/default_incident.png"
                            //   className={classes.newsImage}
                            //   resizeMode="contain"
                            // />
                            <div
                              className={classes.newsImage}
                              style={{
                                backgroundImage:
                                  "url(" +
                                  appconst.ATTACHMENTS_LOCATION +
                                  JSON.parse(
                                    item.incident_report.other_details
                                      .attachments
                                  )[0] +
                                  ")",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            ></div>
                            // <img

                            //   alt="newsImg"
                            //   src={
                            //     appconst.ATTACHMENTS_LOCATION +
                            //     "news/" +
                            //     JSON.parse(item.attachments)[0]
                            //   }
                            //   className={classes.newsImage}
                            //   resizeMode="contain"
                            // />
                          )}
                          <Grid item xs={9} md={9}>
                            <Typography
                              display="inline"
                              gutterBottom
                              variant="body2"
                              // color="textSecondary"
                              component="p"
                            >
                              {Util.getParsedDate(item.created_at)}
                            </Typography>
                            <Chip
                              className={["ml-3", classes.chipLable]}
                              display="inline"
                              size="small"
                              label="Published"
                              color="primary"
                            />
                            <Typography
                              variant="body2"
                              component="p"
                              style={{ fontWeight: 700, fontSize: 15 }}
                            >
                              {item.incident_report == undefined
                                ? "Share Story"
                                : Util.getcurruptionType(
                                  this.state.languageName, item.incident_report.type_of_corruption
                                )}
                            </Typography>
                            {mobSize == false ? (
                              <Typography
                                variant="body2"
                                // color="textSecondary"
                                component="p"
                                display="inline"
                              >
                                {item.district}
                              </Typography>
                            ) : null}

                            <Typography
                              variant="body2"
                              // color="textSecondary"
                              component="p"
                            >
                              {item.story != null
                                ? String(item.story).length > 20
                                  ? item.story.substring(0, 20).concat("...")
                                  : item.story
                                : ""}
                            </Typography>
                          </Grid>
                        </ListItem>
                      </Grid>
                    ) : (
                      console.log("End Grid")
                    )
                  )}
                </GridList>

                <div className={classes.btnAlign}>
                  <Button
                    onClick={() => {
                      window.location.href = "/stories";
                    }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.btnReadMore}
                  >
                    {/* Read More... */}
                    {this.state.language.readMoreButton}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styleSheet)(NewsSection);
