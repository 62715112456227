import axios from "axios";
import * as appconst from "../appconst";
import localStorageService from "./localStorageService";

class NewsServices {
    
    //create news
    createNews = async(data) => {
        
        const user = await localStorageService.getItem("auth_user");
        // console.log(data.newsLinks)
        var links = null;

        if(data.news_links){
          links =  data.news_links
        }

        const a = new Promise((resolve, reject) => {
            axios.post(appconst.CREATE_NEWS, {
              "title": data.title,
              "description": data.description,
              "news_links": links,
            }, 
            {
              headers: { Authorization:`Bearer ${user.token}` }

            }).then(res => {
              // console.log(res);
              return resolve(res);
            })
              .catch((error) => {
                // console.log("error", error);
                return reject(error);
              })
          });
          return await a;
    }

    //upload files
    uploadFiles = async(dataNews) => {

      const user = await localStorageService.getItem("auth_user");
      const a = new Promise((resolve,reject) => {
          axios.post(appconst.UPLOAD_NEWS_FILES, dataNews,{
            headers: { Authorization:`Bearer ${user.token}` }
          })
            .then(res =>{
              return resolve(res);
            })
            .catch((error) =>{
              // console.log("Upload files error",error);
              return reject(error);
            })
      });

      return await a;

    }

    //get news
    getNewswithFilters = async(params, page) => {
    
      const user = await localStorageService.getItem("auth_user");

      const a = new Promise((resolve, reject) => {
        axios.get(appconst.GET_NEWS_WITH_FILTERS + "?page="+page,{
          params: params
        }).then(res => {
          return resolve(res);
        })
        .catch((error)=>{
          console.log("Error:"+error);
        })
      });
    
        return await a;
  }

   //get news single view
   getSingleNews = async(id) => {
    
    // const user = await localStorageService.getItem("auth_user");

    const a = new Promise((resolve, reject) => {
      axios.get(appconst.GET_SINGLE_NEWS + id, 
      // {
          // headers: { Authorization:`Bearer ${user.token}` }
      // }
      ).then(res => {
        return resolve(res);
      })
      .catch((error)=>{
        console.log("Error:"+error);
      })
    });
  
      return await a;
  }
  
  //change state of news
  changeNewsState = async (data,id) => {

    const user = await localStorageService.getItem("auth_user");

    const a = new Promise((resolve, reject) => {
      axios.put(appconst.UPDATE_NEWS_STATE + id , 
          {
            "status": data.status,
          }, {
            headers: { Authorization:`Bearer ${user.token}` }
          }
        ).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log(error);
        })
    });
    return await a;
  }

  //publish news
  updatePublishNews = async (data,id) => {

    const user = await localStorageService.getItem("auth_user");

    const a = new Promise((resolve, reject) => {
      axios.put(appconst.UPDATE_NEWS_PUBLISH + id , 
          {
            "published": data.published,
          }, {
            headers: { Authorization:`Bearer ${user.token}` }
          }
        ).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log(error);
        })
    });
    return await a;
  }

  //edit news
  editNewsDetails = async (data,id) => {

   const user = await localStorageService.getItem("auth_user");

   const a = new Promise((resolve, reject) => {
     axios.put(appconst.EDIT_NEWS + id , data, {
           headers: { Authorization:`Bearer ${user.token}` }
         }
       ).then(res => {
       return resolve(res);
     })
       .catch((error) => {
         console.log(error);
         return reject(error);
       })
   });
   return await a;
 }
  
  uploadNewFiles = async (data) => {

    const user = await localStorageService.getItem("auth_user");

    const a = new Promise((resolve, reject) => {
      axios.post(appconst.UPLOAD_NEWS_NEW_FILES, data,
        {
          headers: { Authorization:`Bearer ${user.token}` }
        }).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  //delete file
  deleteFile = async (data) => {

    const user = await localStorageService.getItem("auth_user");

    const a = new Promise((resolve, reject) => {
      axios.post(appconst.DELETE_NEWS_FILES, data,
        {
          headers: { Authorization:`Bearer ${user.token}` }
        }).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

}

export default new NewsServices();
