
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {
    AppBar, Toolbar, Typography, List, ListItem, Link,
    Grid, SwipeableDrawer, Avatar, Icon, IconButton, MenuItem, Button,
    GridList, GridListTile, GridListTileBar, Divider, ListItemText
} from '@material-ui/core';
import { SimpleCard, MatxProgressBar } from "matx";
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Footer from './components/Footer';
import AppAppBar from './components/AppAppBar';
import MarkerMap from './components/MarkerMap';
import ClusterMap from './components/ClusterMap';
import { withStyles } from "@material-ui/core/styles";

const styleSheet = ((theme) => ({
    gridList: {
        height: 275,
    },

    mainPadding: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 70,
            paddingRight: 70,
            paddingTop: 50,
            paddingBottom: 50

        },
        [theme.breakpoints.down('md')]: {
            padding: 20,
            paddingTop: 30,
            paddingBottom: 30

        },
    },
    card: {

        [theme.breakpoints.up('md')]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
    },
    newsImage: {
        width: 90,
        height: 60,
        paddingRight: theme.spacing(1),
    },
    newsHrLine: {
        backgroundColor: '#0074e1',
        height: 3,
    },
    listItem: {
        borderBottomColor: "#dbdcdc",
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
    }
}));


class MapPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }




    render() {
        const { classes } = this.props
        const preventDefault = (event) => event.preventDefault();
        return (
            <Fragment>
                {/* App bar component */}
                <AppAppBar />

                <Grid

                    style={{
                        backgroundColor: "#02b0f5",
                        // backgroundImage: `url(/assets/images/incidents_slide_image.jpg)`,
                        // background: "linear-gradient(45deg, #ff0081, #6827b0)",
                        width: "100%",
                        height: 70,
                        backgroundSize: "cover",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >

                </Grid>

                <Fade in={true}>
                    <div>
                        <Grid spacing={1} style={{ backgroundColor: 'white', height: "90vh" }}>
                            <ClusterMap />

                        </Grid>

                    </div>
                </Fade>
                {/* Footer */}
                <Footer />
            </Fragment >
        );
    }
}





export default withStyles(styleSheet)(MapPage);