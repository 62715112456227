import React, { Component, Fragment, useEffect } from "react";
import { Grid, Card, Button, Typography, Paper, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import { withStyles } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';
import CloseIcon from '@material-ui/icons/Close';
import Zoom from '@material-ui/core/Zoom';

import SpeedIcon from '@material-ui/icons/Speed';
import BarChartIcon from '@material-ui/icons/BarChart';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { themeColors } from "app/MatxLayout/MatxTheme/themeColors";


import { englishLanguage } from '../../../languages/englishLanguage';
import { sinhalaLanguage } from '../../../languages/sinhalaLanguage';
import { tamilLanguage } from '../../../languages/tamilLanguage';

import languageService from "app/services/languageService";

const styleSheet = ((theme) => ({
    root: {
        //maxWidth: 345,
        //backgroundColor: "rgb(43, 51, 59)",
        backgroundColor: themeColors.purple1.palette.theme_color.main,

        marginTop: -6
    },
    singleCard: {
        height: 100,
    },
    icon_style: {
        height: 70,
        width: 70,
        color: themeColors.purple1.palette.secondary.main,
    },
    value_style: {
        fontSize: 40,
        fontWeight: 400,
        marginLeft: 5,
        color: themeColors.purple1.palette.primary.main,

    },
    sub_title: {
        fontSize: 15,
        fontWeight: 500,
        color: '#9b9b9b',
        marginLeft: 5
    },
    text_tile: {
        width: 130
    }
}));


class StatisticsBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shareStoryDialog: false,
            language: englishLanguage,
            languageName: 'English',
        }

    }

    async componentDidMount() {
        
        var lng = await languageService.getLanguageDetails();

        if (lng == 'Sinhala') {
            this.setState({
                language: sinhalaLanguage,
                languageName: 'Sinhala',
            })
        }
        else if (lng == 'Tamil') {
            this.setState({
                language: tamilLanguage,
                languageName: 'Tamil',
            })
        }
        // console.log("Home Incident Chart Section:", this.state);

    }


    render() {
        const { classes } = this.props
        return (
            <div className={classes.root} >
                <Grid container className="px-10" >

                    <Zoom in={true} style={{ transitionDelay: '500ms' }}>
                        <Grid
                            className={classes.singleCard}
                            container
                            xs={12} md={3}
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >

                            <BarChartIcon className={classes.icon_style} />
                            <Grid className={classes.text_tile}>
                                <Typography className={classes.value_style} variant="h2" >150</Typography>

                                <Grid>
                                    <Typography className={classes.sub_title} variant="h2" >
                                        {this.state.language.statisticsPage_button1}{/* Total Incidents */}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Zoom>


                    <Zoom in={true} style={{ transitionDelay: '700ms' }}>
                        <Grid
                            className={classes.singleCard}
                            container
                            xs={12} md={3}
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <SpeedIcon className={classes.icon_style} />
                            <Grid className={classes.text_tile}>
                                <Typography className={classes.value_style} variant="h2" >50</Typography>

                                <Grid>
                                    <Typography className={classes.sub_title} variant="h2" >
                                    {this.state.language.statisticsPage_button2}
                                    {/* Action Taken */}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Zoom>


                    <Zoom in={true} style={{ transitionDelay: '900ms' }}>
                        <Grid
                            className={classes.singleCard}
                            container
                            xs={12} md={3}
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <RecordVoiceOverIcon className={classes.icon_style} />
                            <Grid className={classes.text_tile}>
                                <Typography className={classes.value_style} variant="h2" >150</Typography>

                                <Grid>
                                    <Typography className={classes.sub_title} variant="h2" >
                                        {this.state.language.statisticsPage_button3}
                                        {/* Total Stories */}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Zoom>



                    <Zoom in={true} style={{ transitionDelay: '1100ms' }}>
                        <Grid
                            className={classes.singleCard}
                            container
                            xs={12} md={3}
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <PostAddIcon className={classes.icon_style} />
                            <Grid className={classes.text_tile}>
                                <Typography className={classes.value_style} variant="h2" >150</Typography>

                                <Grid>
                                    <Typography className={classes.sub_title} variant="h2" >
                                        {/* Election Violations */}
                                        {this.state.language.statisticsPage_button4}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Zoom>

                </Grid>


            </div >
        );
    }
}





export default withStyles(styleSheet)(StatisticsBar);