import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Carousel from "react-material-ui-carousel";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  Link,
  Popover,
  Grid,
  Chip,
  SwipeableDrawer,
  Avatar,
  Icon,
  IconButton,
  MenuItem,
  Button,
  GridList,
  GridListTile,
  GridListTileBar,
  Divider,
  ListItemText,
  Popper,
  Paper,
} from "@material-ui/core";
import { SimpleCard, MatxProgressBar } from "matx";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import Footer from "./Footer";
import AppAppBar from "./AppAppBar";

import { withStyles } from "@material-ui/core/styles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ShareIcon from "@material-ui/icons/Share";
import Menu from "@material-ui/core/Menu";
import NewsServices from "app/services/NewsServices";
import Util from "../../../services/Util";
import * as appconst from "../../../appconst";

import { englishLanguage } from "../../languages/englishLanguage";
import { sinhalaLanguage } from "../../languages/sinhalaLanguage";
import { tamilLanguage } from "../../languages/tamilLanguage";

import languageService from "app/services/languageService";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
} from "react-share";

const styleSheet = (theme) => ({
  mainPadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 110,
      paddingRight: 70,
      paddingTop: 92,
      paddingBottom: 50,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 100,
      paddingBottom: 30,
    },
  },
  mainPadding2: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 110,
      paddingRight: 70,
      paddingTop: 50,
      paddingBottom: 50,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  newsShareIcon: {
    color: "#398B03",
    backgroundColor: "#EAEBE9",
    padding: 2,
    "&:hover": {
      color: "#BA0000",
      backgroundColor: "#FFECA7",
    },
  },
  refNewsLinks: {
    "&:hover": {
      color: "#004192",
      textDecoration: "underline",
    },
  },
  socialIconsBack: {
    margin: 8,
  },
  socialIcons: {
    transition: "all ease 200ms",
    "&:hover": {
      //   opacity: "0.5",
      transform: "scale(1.4)",
    },
  },
  shareIconStyle: {
    color: "white",
    // "&:hover": {
    //   color: "red",
    //   width: "25px",
    //   height: "25px",
    // },
  },

  newsTitle: {
    marginTop: 20,
    // fontFamily: "ReithSerif,Helvetica,Arial,freesans,sans-serif",
    fontWeight: " bold",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
      marginTop: -26
    },
  },

  //Buttons styles
  btnShare: {
    display: "grid",
    float: "right",
    width: "20%",
    transition: "all ease 200ms",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    padding: "7px 10px",
    color: "white",
    backgroundColor: "#FB3640",
    "&:hover": {
      color: "white",
      backgroundColor: "#e61d1d",
      transform: "scale(1.02)",
    },
  },
  //List news Image
  newsImage: {
    width: 100,
    height: 83,
    borderRadius: 6,
    backgroundSize: "cover",
    backgroundPosition: 'center',
    marginLeft: -38,
    marginRight: 10,
  },
  newsGridList: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  //News list item Styles
  listItem: {
    cursor: "pointer",
    // backgroundColor: "#1f1f2e",
    color: "black",
    // boxShadow:
    //   " 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",
    borderRadius: 10,
    paddingRight: "unset",
    margin: "5px 20px 5px 22px",
    width: "100%",
    transition: "all ease 200ms",
    "&:hover": {
      color: "acent",
    },
  },
  listItemTitle: {
    cursor: "pointer",
    margin: "5px 0 2px 0",
    fontSize: 20,
    fontWeight: 700,
    color: "black",
    //fontFamily: "Source Sans Pro",
  },
  listItemSubTitle: {
    cursor: "pointer",
    fontWeight: 400,
    color: "#808080",
  },
  //Main News Image
  featurednewsImage: {
    cursor: "pointer",
    backgroundSize: "cover",
    width: "100%",
    height: 350,
    borderRadius: 6,
    boxShadow:
      " 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",

    // padding: 18,

    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      height: 210,
    },
    // paddingRight: theme.spacing(1),
  },
  news: {
    marginTop: 20,
  },
  chipLable: {
    backgroundColor: "#02b0f5",

    "& .MuiChip-label": {
      paddingTop: 4,
      color: "white",

    },
  },
});
class ViewNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: englishLanguage,

      shareMenuOpen: false,
      anchorEl: null,

      title: "",
      description: "",
      updatedDate: "",
      haveAttachments: false,
      haveLinks: false,
      news_attachments: [],
      news_Links: [],
      news_id: null,

      news: [],
    };

    this.getNewsDetails = this.getNewsDetails.bind(this);
    this.openShareMenu = this.openShareMenu.bind(this);
    this.handleCloseShareMenu = this.handleCloseShareMenu.bind(this);
  }

  async loadLanguageDetails() {
    var lng = await languageService.getLanguageDetails();
    // console.log("language",lng);

    if (lng == "Sinhala") {
      this.setState({
        language: sinhalaLanguage,
      });
    } else if (lng == "Tamil") {
      this.setState({
        language: tamilLanguage,
      });
    }
  }

  getNewsDetails = async (paramId) => {
    // console.log("id:"+paramId);

    await NewsServices.getSingleNews(paramId)
      .then((res) => {
        if (res.data.news.length > 0) {
          var news = res.data.news[0];
          console.log(news, "News");

          var u_date = Util.getParsedDate(news.updated_at);
          var n_title = news.title;
          var n_attachments = JSON.parse(news.attachments);
          var res_attachments = false;
          var n_id = news.id;

          if (n_attachments != null) {
            res_attachments = true;
          }

          console.log("attach: ", n_attachments);

          var res_links = false;
          var n_Links = JSON.parse(news.news_links);

          if (n_Links != null) {
            res_links = true;
          }

          this.setState({
            title: n_title.toUpperCase(),
            description: news.description,
            updatedDate: u_date,
            haveAttachments: res_attachments,
            news_attachments: n_attachments,
            haveLinks: res_links,
            news_Links: n_Links,
            news_id: n_id,
          });
        } else {
          console.log("Error: News Not Found");
        }
      })
      .catch((error) => {
        console.log("Error:" + error);
      });
  };

  openShareMenu(event) {
    this.setState({
      shareMenuOpen: true,
      anchorEl: event.target,
    });
  }

  handleCloseShareMenu() {
    this.setState({
      anchorEl: null,
    });
  }

  async componentDidMount() {
    // this.loadLanguageDetails();

    let paramId = this.props.match.params.id;
    this.getNewsDetails(paramId);

    this.loadData(1);
  }

  async loadData(page) {
    let filter = this.state.filterData;
    let news = await NewsServices.getNewswithFilters(filter, page);
    console.log("News", news);

    // console.log("pagination",news.data.news)

    if (news.status == 200) {
      this.setState({
        news: news.data.news.data,
        pagination: news.data.news,
      });
    } else if (news.status == 205) {
      this.setState({
        news: [],
        errorTitle: "Wrong Reference Number",
        error:
          "You entered reference number is not there please enter correct reference number.",
        errorDialog: true,
      });
    } else if (news.status == 206) {
      this.setState({
        news: [],
        errorTitle: "Not Published",
        error: "Your incident is under the review. Please try again later.",
        errorDialog: true,
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        {/* App bar component */}
        <AppAppBar />

        <div
          className={[classes.mainPadding]}
          style={{
            // backgroundColor: "#1777c0",
            backgroundImage: "url(/assets/images/storiBackground.png)",
            width: "100%",
            height: 250,
            backgroundSize: "cover",
            display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant="h4"
                className={classes.newsTitle}
              >
                {this.state.title}
                {/* BANNING CHEMICAL FERTILISER - RIGHT THING, WRONG LINE */}
              </Typography>
              <Typography
                className="mt-2"
                style={{
                  fontWeight: 200,
                  color: "#f3f3f3",
                  fontSize: "1.2rem",
                }}
              >
                {/* Published on  */}
                {this.state.language.newsPage_published_date_Title}{" "}
                {this.state.updatedDate}
                &ensp;
                {/* added Icon Button class*/}
                <IconButton>
                  <ShareIcon
                    id="share_Icon"
                    fontSize="inherit"
                    className={classes.shareIconStyle}
                    onClick={(e) => this.openShareMenu(e)}
                  />
                </IconButton>
                <Popover
                  open={Boolean(this.state.anchorEl)}
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={this.handleCloseShareMenu}
                >
                  <FacebookShareButton
                    url={String(window.location)}
                    className={classes.socialIconsBack}
                  >
                    <FacebookIcon
                      size={32}
                      round={true}
                      className={classes.socialIcons}
                    />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={String(window.location)}
                    className={classes.socialIconsBack}
                  >
                    <TwitterIcon
                      size={32}
                      round={true}
                      className={classes.socialIcons}
                    />
                  </TwitterShareButton>
                  <LinkedinShareButton
                    url={String(window.location)}
                    className={classes.socialIconsBack}
                  >
                    <LinkedinIcon
                      size={32}
                      round={true}
                      className={classes.socialIcons}
                    />
                  </LinkedinShareButton>
                  <WhatsappShareButton
                    url={String(window.location)}
                    className={classes.socialIconsBack}
                  >
                    <WhatsappIcon
                      size={32}
                      round={true}
                      className={classes.socialIcons}
                    />
                  </WhatsappShareButton>
                  <TelegramShareButton
                    url={String(window.location)}
                    className={classes.socialIconsBack}
                  >
                    <TelegramIcon
                      size={32}
                      round={true}
                      className={classes.socialIcons}
                    />
                  </TelegramShareButton>
                </Popover>
              </Typography>
            </Grid>
          </Grid>
        </div>

        <Fade in={true}>
          <Grid
            container
            spacing={1}
            className={classes.mainPadding2}
            style={{ backgroundColor: "white" }}
            justifyContent="space-between"
          >
            <Grid item container xs={12} md={7}>
              <Grid item xs={12}>
                {this.state.haveAttachments &&
                  this.state.news_attachments.length > 1 ? (
                  <Carousel
                    autoPlay={true}
                    indicators={false}
                    timeout={800}
                    animation={"fade"}
                  >
                    {/* <img src="/assets/images/carousel_pic6.jpg" style={{ width: "100%" , height: 350,}} />
                                <img src="/assets/images/carousel_pic5.jpg" style={{ width: "100%", height: 350, }} /> */}

                    {this.state.news_attachments.map((item) => (
                      <div
                        className={classes.featurednewsImage}
                        style={{
                          backgroundImage:
                            "url(" +
                            appconst.NEWS_ATTACHMENTS_LOCATION +
                            item +
                            ")",

                        }}
                      ></div>
                      // <img
                      //   src={appconst.NEWS_ATTACHMENTS_LOCATION + item}
                      //   style={{ height: 350}}
                      // />
                    ))}
                  </Carousel>
                ) : this.state.haveAttachments &&
                  this.state.news_attachments.length == 1 ? (
                  <div
                    className={classes.featurednewsImage}
                    style={{
                      backgroundImage:
                        "url(" +
                        appconst.NEWS_ATTACHMENTS_LOCATION +
                        this.state.news_attachments[0] +
                        ")",

                    }}
                  ></div>
                ) : (
                  // <img
                  //   src={
                  //     appconst.NEWS_ATTACHMENTS_LOCATION +
                  //     this.state.news_attachments[0]
                  //   }
                  //   style={{ width: "100%", height: 350 }}
                  // />
                  <div
                    className={classes.featurednewsImage}
                    style={{
                      backgroundImage:
                        "url(/assets/images/default_incident.png)",

                    }}
                  ></div>
                  // <img
                  //   src="/assets/images/default_incident.png"
                  //   style={{ width: "100%", height: 350 }}
                  // />
                )}
                <Typography
                  className={classes.news}
                  Typography
                  variant="p"
                  component="p"
                  gutterBottom
                  align="justify"
                  style={{ lineHeight: 2 }}
                >
                  {this.state.description}
                </Typography>

                {this.state.haveLinks && (
                  <Grid item xs={12} md={12} className="pt-3">
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        color: "#1777c0",
                        fontWeight: "bold",
                      }}
                    >
                      Click to Read More...
                      {/* {this.state.language.newsPage_links_title}  */}
                    </Typography>
                    <ul style={{ color: "#696969", fontWeight: "bold" }}>
                      {this.state.news_Links.map((link) => (
                        <li key={link}>
                          <a href={link} className={classes.refNewsLinks}>
                            {link.length > 50 ? link.substr(0, 50) : link}
                            ...
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                )}

              </Grid>




            </Grid>
            {/* News List by shammika */}
            <Grid
              container
              className={[classes.resPad, classes.newsGridList]}
              xs={12}
              md={4}
              style={{
                backgroundColor: "white",
              }}
              alignItems="flex-start"
            >
              <Grid container>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexWrap: "wrap",

                    fontSize: 22,
                    fontWeight: 700,
                  }}
                >
                  <span>Other News</span>
                  <ArrowRightIcon style={{ fontSize: 27 }} />
                </div>
                <GridList cols={1} className={classes.gridList}>
                  {this.state.news.map((row, key) =>
                    key <= 4 ? (
                      row.id == this.state.news_id ? null : (
                        <Grid
                          key={key}
                          style={{
                            height: "fitContent",
                            padding: "0 38px 0 0 ",
                            margin: "5px 0",
                          }}
                        >
                          {console.log(row)}
                          <ListItem
                            onClick={() => {
                              window.location.href = "/news/" + row.id;
                            }}
                            button
                            className={classes.listItem}
                          >
                            {row.attachments == "[]" ||
                              row.attachments == null ? (
                              <div
                                className={classes.newsImage}
                                style={{
                                  backgroundImage:
                                    "url(/assets/images/default_incident.png)",
                                  backgroundSize: "cover",
                                }}
                              ></div>
                            ) : (
                              // <div
                              //   className={classes.newsImage}
                              //   style={{
                              //     backgroundImage:
                              //       "url(/assets/images/default_incident.png)",
                              //     backgroundSize: "500px",
                              //   }}
                              // ></div>
                              // <img
                              //   alt="incidentImg"
                              //   src="/assets/images/default_incident.png"
                              //   className={classes.newsImage}
                              // />
                              <div
                                className={classes.newsImage}
                                style={{
                                  backgroundImage:
                                    "url(" +
                                    appconst.ATTACHMENTS_LOCATION +
                                    "news/" +
                                    JSON.parse(row.attachments)[0] +
                                    ")",
                                  backgroundSize: "cover",
                                }}
                              ></div>
                              // <img
                              //   alt="newsImg"
                              //   src={
                              //     appconst.ATTACHMENTS_LOCATION +
                              //     "news/" +
                              //     JSON.parse(row.attachments)[0]
                              //   }
                              //   className={classes.newsImage}
                              // />
                            )}
                            <Grid item xs={12}>
                              <Grid
                                item
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <Chip
                                  className={["mr-3", classes.chipLable]}
                                  display="inline"
                                  size="small"
                                  label="Published"
                                />

                                <Typography
                                  display="inline"
                                  variant="body2"
                                  color="textSecondary"
                                  component="p"
                                >
                                  {Util.getParsedDate(row.created_at)}
                                </Typography>
                              </Grid>
                              <Typography
                                variant="p"
                                component="h6"
                                // gutterBottom
                                className={classes.listItemTitle}
                                style={{ fontSize: 16 }}
                              >
                                {String(row.title).length > 17
                                  ? (row.title + " ")
                                    .toUpperCase()
                                    .substring(0, 35)
                                    .concat("...")
                                  : row.title.toUpperCase()}
                              </Typography>
                              <Typography
                                variant="p"
                                component="p"
                                gutterBottom
                                className={classes.listItemSubTitle}
                              >
                                {String(row.description).length > 43
                                  ? row.description
                                    .substring(0, 43)
                                    .concat("...")
                                  : row.description}
                              </Typography>
                            </Grid>
                          </ListItem>
                          <Divider></Divider>
                        </Grid>
                      )
                    ) : (
                      <Grid></Grid>
                    )
                  )}
                  {/* ))} */}
                </GridList>
              </Grid>
            </Grid>
          </Grid>
        </Fade>

        {/* Footer */}
        <Footer />
      </Fragment>
    );
  }
}

export default withStyles(styleSheet)(ViewNews);
