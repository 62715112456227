import React from "react";
import { Redirect } from "react-router-dom";

import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";
import publicRoutes from "./views/publicPages/PublicRoutes";

const publicRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/languageSelect" />
  }
  /* {
    path: "/",
    exact: true,
    component: () => <Redirect to="/comingSoon" />
  } */
];
const redirectRoute = [
  {
    path: "/admin",
    exact: true,
    component: () => <Redirect to="/dashboard/analytics" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

const routes = [
  ...sessionRoutes,
  ...publicRoute,
  ...publicRoutes,
  ...dashboardRoutes,
  ...redirectRoute,
  ...errorRoute
];

export default routes;
