import axios from 'axios';
import * as appconst from './../appconst';
import localStorageService from "./localStorageService";

class StatisticsServices {
  ls = window.localStorage

  getAllCorruptions = async (data) => {//all incident
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.ALL_CORRUPTIONS, data).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  getAllCorruptionsByDistrict = async (data) => {//all incident by district
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.ALL_STATISTICS_BY_DISTRICT, data).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  getAllCorruptionsForMap = async (data) => {//all incident for map
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.ALL_CORRUPTIONS_FOR_MAP, data).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  getAllStatistics = async () => {//all incident for map
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.ALL_STATISTICS).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }


  getInsidentStatisticsByYearly = async () => {//all incident for map
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.ALL_STATISTICS_BY_YEARLY).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  getInsidentStatisticsByGender = async () => {//all incident for map
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.ALL_STATISTICS_BY_GENDER).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  getInsidentStatisticsByAge = async () => {//all incident for map
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.ALL_STATISTICS_BY_AGE).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }


  getInsidentStatisticsByOrganization = async () => {//all incident by organization
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.ALL_STATISTICS_BY_ORGANIZATION).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }
  //get all users
  getAllUsers = async (page) => {

    const user = await localStorageService.getItem("auth_user");

    const getAll = new Promise((resolve, reject) => {
      axios.get(appconst.ALL_USERS + "?page=" + page)
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          console.log(error);
        })
    })

    return await getAll;

  }

  //generate Report
  generateReport = async (data) => {

    console.log("Data:", data);

    const a = new Promise((resolve, reject) => {
      axios.post(appconst.GENERATE_REPORT,
        {
          "fields": data.fields,
          "filters": data.filters,
        }
      ).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log(error);
          return reject(error);
        })
    });
    return await a;

  }

}

export default new StatisticsServices();