import React, { Component, Fragment, } from "react";
import PropTypes, { arrayOf } from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Autocomplete, Alert, AlertTitle } from "@material-ui/lab";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import AppAppBar from "../AppAppBar";
import IncidentService from "../../../../services/IncidentServices";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import LocationPicker from 'react-location-picker';
import {
  StepLabel,
  Stepper,
  Step,
  Button,
  Typography,
  StepConnector,
  Checkbox,
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Tooltip,
  CircularProgress,
  MenuItem,
  Snackbar,
  IconButton,
  FormGroup,
  Box,
} from "@material-ui/core";

import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import LinearProgress from "@material-ui/core/LinearProgress";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AppsIcon from "@material-ui/icons/Apps";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import PanToolIcon from "@material-ui/icons/PanTool";
import { compose, withProps } from "recompose";
import Geocode from "react-geocode";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  ageRanges,
  divisionalSecretariats,
  electionPartyWordLimit,
  election_report_theme,
  election_report_theme_dark,
} from "../../../../appVariables";

import jwtAuthService from "../../../../services/jwtAuthService";
import { loginWithEmailAndPassword } from "../../../../redux/actions/LoginActions";

import { englishLanguage } from "../../../languages/englishLanguage";
import { sinhalaLanguage } from "../../../languages/sinhalaLanguage";
import { tamilLanguage } from "../../../languages/tamilLanguage";

import languageService from "app/services/languageService";
import CloseIcon from "@material-ui/icons/Close";

import * as appconst from "../../../../appconst";

// import { GoogleMapsAPI } from './';
//Geocode.setApiKey("AIzaSyCL6g9mcTszQRG_7fMOLTBvBCzYlD71ai4");
//Geocode.enableDebug();

//stepper line styles
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 20,
  },
  active: {
    "& $line": {
      backgroundColor: election_report_theme, //"#FFD523",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: election_report_theme_dark, //"#d4b00e",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#EAEAF0",
    borderRadius: 1,
  },
})(StepConnector);

//stepper icon styles
const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: election_report_theme, //"#FFD523",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: election_report_theme_dark, //"#d4b00e",
  },
  checkboxStyle: {
    "& .MuiCheckbox-colorPrimary": {
      color: "#FB3640",
    },
  },
});

//All styles
const styles = (theme) => ({
  root: {
    // marginTop: 80,
    paddingLeft: 100,
    paddingRight: 100,
    paddingTop: 30,
    paddingBottom: 30,
  },
  mainPadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 100,
      paddingRight: 100,
      paddingTop: 30,
      paddingBottom: 30,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 30,
      paddingBottom: 30,
    },
  },

  heading: {
    marginBottom: 20,
  },

  button: {
    margin: theme.spacing(1),
  },

  // Report form styles
  formStyles_root: {
    // padding: theme.spacing(2),
    paddingTop: 0,
    width: "100%",
    // margin: theme.spacing(2),
  },

  //form style for small screens
  formStyle1: {
    marginLeft: 30,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
  formStyle2: {
    paddingLeft: 60,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },

  deleteTrashIcon: {
    marginTop: 2,
    color: "#CC1D1D",
    "&:hover": {
      backgroundColor: "#CC1D1D",
      color: "#fff",
      borderRadius: 50,
    },
    // left: "45%",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  //TextField styles
  textStyles: {
    "& label": {
      color: "#25282c",
      fontSize: 15,
      fontFamily: "Source Sans Pro",
      fontWeight: 400,

      "& hover": {
        fontWeight: "bold",
      },
    },
    "& label.Mui-focused": {
      color: "#25282c",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#25282c",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: 'red',
      },
      "&:hover fieldset": {
        // borderColor: 'yellow',
      },
      "&.Mui-focused fieldset": {
        borderColor: "#25282c",
      },
    },
  },
  //share Button
  btnNext: {
    color: "white",
    backgroundColor: election_report_theme, //"#FFD523",
    transition: "all ease 200ms",
    "&:hover": {
      color: "white",
      backgroundColor: election_report_theme_dark, //"#d4b00e",
      transform: "scale(1.02)",
    },
  },
  //Buttons alignments
  btnAlign: {
    marginTop: 20,
    display: "grid",
    float: "right",
    width: "15%",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  radioButton: {
    "& .MuiRadio-colorPrimary": {
      color: election_report_theme, //"#FFD523"
    },
  },
  checkboxStyle: {
    "& .MuiCheckbox-colorPrimary": {
      color: election_report_theme, //"#FFD523",
    },
  },
});

//assign stepper icons
function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <PanToolIcon />,
    2: <AppsIcon />,
    3: <InfoIcon />,
    4: <CheckCircleIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  //step is active
  active: PropTypes.bool,

  //mark step as completed
  completed: PropTypes.bool,

  //label displayed in the step icon
  icon: PropTypes.node,
};

//linear progress bar
function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

class ReportElectionIncident extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //stepper active state(active step = Stepper Step + 1)
      submittingForm: false,
      instruction: null,
      activeStep: 0,
      steps: englishLanguage.electionReport_stepperSteps,
      stepperHidden: false,

      //step 1 dialog box radio selections
      reportDetailsDialogSelection:
        englishLanguage.corruptionReport_nextDialogBoxSelection,

      //language
      language: englishLanguage,
      languageName: "English",

      //genders
      genderSelection: englishLanguage.lng_genders,

      //step 2 districts
      districtsTypes: englishLanguage.lng_Districts,

      //step 2 public authorities
      public_authority_type: englishLanguage.lng_publicAuthorities,

      //step 2 reported incident types
      reportIncidentTypes: englishLanguage.lng_incidentTypes,

      //step 2 divisional secretariats
      divisionalSecretariats: [],
      divisionalSecretariatsAll:
        englishLanguage.lng_report_divisional_secretariats,

      //step 3 misused types
      misused_types: englishLanguage.lan_election_misusedTypes,

      //step 3 misused nature types
      misused_description_types: englishLanguage.lng_misused_natureTypes,

      //message
      message: null,

      //date time
      incident_date: "",
      incident_time: "",

      //id returned by database
      incident_id: null,

      // map
      mapPosition: {
        lat: 6.927,
        lng: 79.861,
      },

      //dialog box
      dialogBox: false,

      //dialog box ref number
      refNumber: null,

      //snack bar
      snackbar: false,
      severity: null,

      //misused cases
      misusedCaseArray: [],

      //Upload Image
      image_urls: [],
      noOfFiles: 0,
      uploadedFileSize: 0,

      //Upload video
      video_urls: [],
      noOfVideoFiles: 0,
      uploadedVideoFileSize: 0,
      loadingProgress: 0,

      attachmentUploading: true,
      attachmentUploadingError: null,
      haveUploadAttachments: false,
      //user logged in
      // user_loggedIn: false,

      //className
      styleClassName: true,

      //word limit
      countWords: electionPartyWordLimit,

      //form data
      formdata: {
        title: null,
        reported_language: "English",
        public_authority_involved: true,
        name_of_authority: null,
        designation_of_authority: null,
        divisional_secretariat: null,
        district: null,
        incident_date_time: null,
        story: null,
        anonymous: false,
        name: null,
        contact_number: null,
        email: null,
        agreement: true,
        type: "Corruption Fighter",
        age: null,
        gender: null,
        address: null,
        type_of_corruption: "Election",
        latitude: null,
        longitude: null,
        incident_status: null,

        corruption_details: {
          you_are: null,
          bribe_form: null,
          monetary_value: null,
          type_of_property: null,
          benefit: null,
          private_interest: null,
          nature_of_interest: null,
          involved: null,
          disclose_interest: null,
          authorized_participate: null,
          type_of_procurement: null,
          misused: null,
          nature_of_misuse: null,
          political_party: null,
          other_details: null,
          attachments: [],
          videoAttachments: [],
        },
      },

      // login
      email: "",
      password: "",
      agreement: "",
      loginsuccess: true,
      loginerrortitle: "",
    };
    this.handleLocationChange = this.handleLocationChange.bind(this);

  }

  //login
  handleFormSubmitLogin = (event) => {
    // this.props.loginWithEmailAndPassword({ ...this.state });
    jwtAuthService
      .loginWithEmailAndPassword(this.state.email, this.state.password)
      .then((user) => {
        console.log("user login action", user);
        if (user.hasOwnProperty("Error")) {
          this.setState({
            loginsuccess: false,
            loginerrortitle: user.Error,
          });
        } else {
          this.setState({
            dialogBox: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loginsuccess: false,
          loginerrortitle: "User Login Unsuccessful",
        });
      });
  };

  //login
  handleFormSubmitLogin = (event) => {
    // this.props.loginWithEmailAndPassword({ ...this.state });
    jwtAuthService
      .loginWithEmailAndPassword(this.state.email, this.state.password)
      .then((user) => {
        console.log("user login action", user);
        if (user.hasOwnProperty("Error")) {
          this.setState({
            loginsuccess: false,
            loginerrortitle: user.Error,
          });
        } else {
          this.setState({
            dialogBox: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loginsuccess: false,
          loginerrortitle: "User Login Unsuccessful",
        });
      });
  };

  //stepper functions
  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };
  handleBack = (val) => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  //add to misused
  setMisusedType = async (e) => {
    var value = e.target.value;
    var arr = this.state.misusedCaseArray;
    var len = arr.length;
    var newArr = [];

    if (len == 0) {
      newArr.push(value);
    } else {
      var indexNo = arr.indexOf(value);
      newArr = arr;

      if (indexNo == -1) {
        newArr.push(value);
      } else {
        newArr.splice(indexNo, 1);
      }
    }

    this.setState({
      misusedCaseArray: newArr,
    });
    // console.log(newArr);
    // console.log(this.state);
  };

  setMisusedCasesToString() {
    var misused = [];
    var data = this.state.formdata;
    var valArray = this.state.misusedCaseArray;

    misused = valArray.toString();

    data.corruption_details["misused"] = misused;

    this.setState({
      formdata: data,
    });

    console.log(this.state.formdata);
  }

  // uploading images
  uploadFiles(data) {
    var bodyFormData = new FormData();

    for (
      let size = 0;
      size < data["corruption_details"]["attachments"].length;
      size++
    ) {
      bodyFormData.append(
        "attachments[" + size + "]",
        data["corruption_details"]["attachments"][size]
      );
    }

    bodyFormData.append("incident_id", this.state.incident_id);

    IncidentService.uploadFiles(bodyFormData)
      .then((res) => {
        console.log("response", res);
        if (res.status == 200) {
          setTimeout(() => {
            this.setState({
              message: res.data.message,
              snackbar: true,
              severity: "success",
              submittingForm: false
            });
          }, 3000);
        } else {
          this.setState({
            message: res.data.message,
            snackbar: true,
            severity: "error",
            submittingForm: false
          });
        }
      })
      .catch((error) => {
        console.log("error in uploading election files:", error);
        this.setState({ submittingForm: false })
      });
  }

  // uploading video files
  uploadVideoFiles(data) {
    var bodyFormData = new FormData();

    for (
      let size = 0;
      size < data["corruption_details"]["videoAttachments"].length;
      size++
    ) {
      bodyFormData.append(
        "otherFiles[" + size + "]",
        data["corruption_details"]["videoAttachments"][size]
      );
    }

    bodyFormData.append("incident_id", this.state.incident_id);
    bodyFormData.append("incident_reference_number", this.state.refNumber);

    //progress function
    const configProgress = {
      onUploadProgress: (progressEvent) => {
        console.log("PRogress..................");
        console.log(progressEvent);

        let loaded = progressEvent.loaded;
        let total = progressEvent.total;
        let loadedPercentage = Math.floor((loaded * 100) / total);

        console.log(loadedPercentage);

        if (loadedPercentage < 100) {
          this.setState({
            loadingProgress: loadedPercentage,
          });
        } else {
          this.setState({
            loadingProgress: 95,
          });
        }
      },
    };

    axios
      .post(appconst.UPLOAD_OTHER_FILES, bodyFormData, configProgress)
      .then((res) => {
        console.log("response", res);

        if (res.status == 200) {
          setTimeout(() => {
            this.setState({
              message: res.data.message,
              snackbar: true,
              severity: "success",
              loadingProgress: 100,
              attachmentUploading: false,
              submittingForm: false
            });
          }, 1000);
        } else {
          this.setState({
            essage: res.data.message,
            snackbar: true,
            severity: "error",
            attachmentUploading: false,
            attachmentUploadingError: "Video File Upload Error",
            submittingForm: false
          });
        }
      })
      .catch((error) => {
        console.log("error in uploading corruption files:", error);
        this.setState({
          attachmentUploading: false,
          attachmentUploadingError: "Video Upload Error",
          submittingForm: false
        });
      });
  }

  //form Submission
  formSubmit = (event) => {
    event.preventDefault();
    this.setState({ submittingForm: true })
    //set misused values
    this.setMisusedCasesToString();

    console.log("Form Data: ", this.state.formdata);
    var data = this.state.formdata;

    IncidentService.createIncident(data)
      .then((res) => {
        console.log("response", res);
        if (res.status == 200) {
          this.setState(
            {
              message: res.data.message,
              severity: "success",
              snackbar: true,
              dialogBox: true,
              refNumber: res.data.reference_number,
              incident_id: res.data.incident_id,
            },
            () => {
              if (
                this.state.formdata.corruption_details.attachments.length > 0
              ) {
                this.uploadFiles(data);
                this.setState({
                  haveUploadAttachments: true,
                });
              } else {
                this.setState({ submittingForm: false })
              }

              if (
                this.state.formdata.corruption_details.videoAttachments.length >
                0
              ) {
                this.uploadVideoFiles(data);
                this.setState({
                  haveUploadAttachments: true,
                });
              } else {
                this.setState({
                  attachmentUploading: false,
                  submittingForm: false
                });
              }
              // else
              // {
              //   setTimeout({}, 2000);
              // }
            }
          );
        } else {
          this.setState({
            message: res.data.message || "Incident Report Unsuccessful.Please Try Again!",
            snackbar: true,
            severity: "error",
            submittingForm: false
          });
        }
      })
      .catch((error) => {
        // this.setState({ message: "Error", snackbar: true, severity: "error" })
        this.setState({ message: "Incident Report Unsuccessful.Please Try Again!", snackbar: true, severity: "error", submittingForm: false })

        console.log("Error in Election form:", error);
      });


  };

  //reload after adding
  reloadWindow = () => {
    window.location.reload(false);
  };

  //dialog box
  handleClose = () => {
    this.setState({
      dialogBox: false,
    });

    this.closeSnackBar();

    window.location.href = "/home";
    // window.location.reload(false);
  };

  //snackbar
  closeSnackBar = (event, response) => {
    if (response === "clickaway") {
      return;
    }

    this.setState({
      snackbar: false,
    });
  };

  handleChange = async (event) => {
    event.persist();
    var data = this.state.formdata;

    if (
      event.target.name == "checkbox_Anonymous" ||
      event.target.name == "checkbox_NotAnonymous"
    ) {
      var is_anonymous = false;

      //anonymous
      if (event.target.name == "checkbox_Anonymous") {
        // console.log(event.target.name, event.target.value);

        if (event.target.value == "false") {
          is_anonymous = true;
          // console.log(event.target.name, event.target.value);
        } else {
          is_anonymous = false;
        }

        //not anonymous
      } else {
        if (event.target.value == "true") {
          is_anonymous = false;
        } else {
          is_anonymous = true;
        }
        // console.log(event.target.name, event.target.value);
      }

      //set correct anonymous state
      data["anonymous"] = is_anonymous;

      //Prevent switch between forms with different data to same state
      if (is_anonymous) {
        data["name"] = null;
        data["email"] = null;
        data["age"] = null;
        data["gender"] = null;
        data["address"] = null;
        data["contact_number"] = null;

        this.setState({
          instruction: "step1_Anonymousform_dialog",
          askToContact: "no",
        });
      } else {
        data["age"] = null;
        data["gender"] = null;
      }

      this.setState({
        formdata: data,
      });
    } else {
      let eventName = event.target.name;
      let eventValue = event.target.value;

      if (eventValue == "true" || eventValue == "false") {
        data[eventName] = eventValue == "true" ? true : false;
      } else {
        data[eventName] = eventValue;
      }

      this.setState({
        formdata: data,
      });
    }
    console.log(this.state.formdata);
  };

  handleChangeLogin = (event) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCorruptionChange = (event) => {
    event.persist();
    var data = this.state.formdata;

    let eventName = event.target.name;
    let eventValue = event.target.value;

    if (eventValue == "true" || eventValue == "false") {
      data.corruption_details[eventName] = eventValue == "true" ? true : false;
    } else if (eventName === "political_party") {
      data.corruption_details[eventName] = eventValue;

      var finalCount = electionPartyWordLimit;

      if (this.state.formdata.corruption_details.political_party != null) {
        var count =
          this.state.formdata.corruption_details.political_party.length;
        finalCount = electionPartyWordLimit - count;
      }

      this.setState({
        formdata: data,
        countWords: finalCount,
      });
    } else {
      data.corruption_details[eventName] = eventValue;
    }

    this.setState({
      formdata: data,
    });

    // console.log(this.state.formdata);
  };

  //change date time
  onChangeTime = (e) => {
    var data = this.state.formdata;
    var eName = e.target.name;
    var eVal = e.target.value;
    var incidentStatus = this.state.formdata.incident_status;

    var resSnackbar = false;
    var resMessage = null;

    var resDate = this.state.incident_date;
    var resTime = this.state.incident_time;

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    console.log(today);

    var date1 = Date.parse(today);
    var date2 = Date.parse(eVal);

    if (eName == "incident_time") {
      resTime = eVal;
    } else {
      if (incidentStatus === "Has Happened") {
        if (date1 >= date2) {
          resDate = eVal;
        } else {
          resSnackbar = true;
          resMessage = "Please Select Valid Date";
        }
      } else if (incidentStatus === "Is Happening") {
        if (date1 === date2) {
          resDate = eVal;
        } else {
          resSnackbar = true;
          resMessage = "Please Select Valid Date";
        }
      } else if (incidentStatus === "Will Happen in the future") {
        if (date1 <= date2) {
          resDate = eVal;
        } else {
          resSnackbar = true;
          resMessage = "Please Select Valid Date";
        }
      } else {
        resDate = "";
        resSnackbar = true;
        resMessage = "Please Select Incident State Before Select Date";
      }
    }

    data["incident_date_time"] = resDate + " " + resTime;

    this.setState({
      formdata: data,
      incident_date: resDate,
      incident_time: resTime,
      snackbar: resSnackbar,
      message: resMessage,
      severity: "error",
    });

    console.log(this.state);
  };

  //set divisional secretariat array
  setDivisionalSecretariats(selectedDis) {
    var mainArray = this.state.divisionalSecretariatsAll;
    var sec_arr = [];

    for (var i = 0; i < 25; i++) {
      var dis = mainArray[i].district;

      if (dis === selectedDis) {
        sec_arr = mainArray[i].divisional_secretariats;
      }
    }

    console.log(sec_arr);

    this.setState({
      divisionalSecretariats: sec_arr,
    });
  }

  //set values from selections (Autocomplete)
  setSelectedValue(key, value) {
    var data = this.state.formdata;
    var latValue, lngValue;
    var foundDistrict = false;

    // map
    if (key == "district") {
      for (let i = 0; i <= 25; i++) {
        if (this.state.districtsTypes[i].value == value) {
          latValue = this.state.districtsTypes[i].lat;
          lngValue = this.state.districtsTypes[i].lng;
          foundDistrict = true;
          console.log(latValue, lngValue);
        }
      }

      if (foundDistrict) {
        data["district"] = value;
        data["latitude"] = latValue;
        data["longitude"] = lngValue;

        this.setState({
          formdata: data,
          mapPosition: {
            lat: latValue,
            lng: lngValue,
          },
        });
      }

      //set the value for the divisional secretariats (lng_report_divisional_secretariats)
      this.setDivisionalSecretariats(value);
    } else if (key == "incident_status") {
      data["incident_status"] = value;
      data["incident_date_time"] = null;

      this.setState({
        formdata: data,
        incident_time: "",
        incident_date: "",
      });
    } else {
      data[key] = value;

      this.setState({
        formdata: data,
      });
    }

    console.log(this.state);
  }

  setSelectedCorruptionValue(key, value) {
    var data = this.state.formdata;

    data.corruption_details[key] = value;

    this.setState({
      formdata: data,
    });
    // console.log(this.state.formdata);
  }

  setSelectedCorruptionValueMisused(key, val) {
    console.log("Key and Value:", key, val);

    var type = typeof val;

    if (type == "object" && val != null) {
      // console.log(type);
      val = val.value;
    }

    var data = this.state.formdata;

    data.corruption_details[key] = val;

    this.setState({
      formdata: data,
    });
    console.log(this.state.formdata);
  }

  //Image Upload choose action
  handlefileUpload = (event) => {
    event.persist();

    var data = this.state.formdata;
    var imageURLS = [];
    var imageFiles = [];
    var totalFileSize = 0;
    var totalFiles = 0;
    var alreadyUploadedFiles =
      this.state.formdata.corruption_details.attachments;
    var alreadyUploadedNoOFFiles = alreadyUploadedFiles.length;
    var alreadyUploadedTotalFileSize = this.state.uploadedFileSize;

    if (alreadyUploadedNoOFFiles > 0) {
      imageFiles = alreadyUploadedFiles;
      imageURLS = this.state.image_urls;
    }

    if (event.target.files[0] !== undefined) {
      totalFiles = alreadyUploadedNoOFFiles + event.target.files.length;

      if (totalFiles < 6) {
        for (let j = 0; j < event.target.files.length; j++) {
          let file = event.target.files[j];
          totalFileSize = alreadyUploadedTotalFileSize + file.size;

          if (totalFileSize > 5000000) {
            this.setState({
              message: "Total File Size Must Be Less Than 5MB",
              severity: "error",
              snackbar: true,
            });
            break;
          } else {
            // console.log('Selected file:', file);

            // if(file.size > 1000000){
            //     this.setState({
            //         message: "For each file size must be less than 1MB.",
            //         severity: "error",
            //         snackbar: true,
            //     });
            //     break;
            // }
            // else

            if (file.type == "image/jpeg" || file.type == "image/png") {
              alreadyUploadedTotalFileSize = totalFileSize;

              //formdata array
              imageFiles.push(file);

              //add local URL to preview
              imageURLS.push(URL.createObjectURL(file));
              // console.log(file);
            } else {
              this.setState({
                message: "You can only upload Images(JPEG,PNG)",
                severity: "error",
                snackbar: true,
              });
            }
          }
        }
      } else {
        this.setState({
          message: "Can't Upload More Than 5 Files.",
          severity: "error",
          snackbar: true,
        });
      }

      //No of files
    }

    //set to from data
    data["corruption_details"]["attachments"] = imageFiles;

    this.setState({
      image_urls: imageURLS,
      noOfFiles: imageFiles.length,
      formdata: data,
      uploadedFileSize: alreadyUploadedTotalFileSize,
    });
    console.log(this.state);
  };

  removeUploadSelectedItem = (val) => {
    // console.log("object:",val);
    var arr = this.state.image_urls;
    var data = this.state.formdata;
    var attach = this.state.formdata.corruption_details.attachments;
    var index = -1;

    for (let j = 0; j < arr.length; j++) {
      if (arr[j] === val) {
        index = j;
      }
    }

    if (index > -1) {
      var noFiles = this.state.noOfFiles - 1;
      var resTotalSize = 0;

      arr.splice(index, 1);
      // console.log(arr);
      // this.state.image_urls = arr;

      //remove from attachments
      attach.splice(index, 1);

      if (noFiles == 0) {
        document.getElementById("contained-button-file").value = "";
        data["corruption_details"]["attachments"] = [];
        this.setState({
          noOfFiles: 0,
          image_urls: [],
          formdata: data,
          uploadedFileSize: 0,
        });
      } else {
        data["corruption_details"]["attachments"] = attach;

        for (var i = 0; i < attach.length; i++) {
          resTotalSize = resTotalSize + attach[i].size;
        }

        this.setState({
          noOfFiles: noFiles,
          image_urls: arr,
          formdata: data,
          uploadedFileSize: resTotalSize,
        });
      }
    }
    // console.log(index);
    console.log(this.state);
  };

  openAttachment = (event) => {
    var url = event.target.getAttribute("value");
    // console.log("url",url);
    window.open(url);
  };

  //Video Select and Upload
  handleVideoUpload = (event) => {
    event.persist();

    var data = this.state.formdata;
    var videoURLS = [];
    var videoFiles = [];
    var totalFileSize = 0;
    var totalFiles = 0;
    var alreadyUploadedFiles =
      this.state.formdata.corruption_details.videoAttachments;
    var alreadyUploadedNoOFFiles = alreadyUploadedFiles.length;
    var alreadyUploadedTotalFileSize = this.state.uploadedFileSize;

    if (alreadyUploadedNoOFFiles > 0) {
      videoFiles = alreadyUploadedFiles;
      videoURLS = this.state.video_urls;
    }

    if (event.target.files[0] !== undefined) {
      totalFiles = alreadyUploadedNoOFFiles + event.target.files.length;

      if (totalFiles < 4) {
        for (let j = 0; j < event.target.files.length; j++) {
          let file = event.target.files[j];
          totalFileSize = alreadyUploadedTotalFileSize + file.size;

          if (totalFileSize > 50000000) {
            this.setState({
              message: "Total File Size Must Be Less Than 50MB",
              severity: "error",
              snackbar: true,
            });
            break;
          } else {
            // console.log('Selected file:', file);

            // if(file.size > 1000000){
            //     this.setState({
            //         message: "For each file size must be less than 1MB.",
            //         severity: "error",
            //         snackbar: true,
            //     });
            //     break;
            // }
            // else

            if (
              file.type == "video/mp4" ||
              file.type == "video/avi" ||
              file.type == "video/m4v" ||
              file.type == "video/mpg"
            ) {
              alreadyUploadedTotalFileSize = totalFileSize;

              //formdata array
              videoFiles.push(file);

              //add local URL to preview
              videoURLS.push(URL.createObjectURL(file));
              // console.log(file);
            } else {
              this.setState({
                message: "You can only upload Videos(mp4,avi,m4v,mpg)",
                severity: "error",
                snackbar: true,
              });
            }
          }
        }
      } else {
        this.setState({
          message: "Can't Upload More Than 3 Files.",
          severity: "error",
          snackbar: true,
        });
      }

      //No of files
    }

    //set to from data
    data["corruption_details"]["videoAttachments"] = videoFiles;

    this.setState({
      video_urls: videoURLS,
      noOfVideoFiles: videoFiles.length,
      formdata: data,
      uploadedVideoFileSize: alreadyUploadedTotalFileSize,
    });

    console.log(this.state);
  };

  removeUploadSelectedVideoItem = (val) => {
    // console.log("object:",val);
    var arr = this.state.video_urls;
    var data = this.state.formdata;
    var attach = this.state.formdata.corruption_details.videoAttachments;
    var index = -1;

    for (let j = 0; j < arr.length; j++) {
      if (arr[j] === val) {
        index = j;
      }
    }

    if (index > -1) {
      var noFiles = this.state.noOfVideoFiles - 1;
      var resTotalSize = 0;

      arr.splice(index, 1);
      // console.log(arr);
      // this.state.image_urls = arr;

      //remove from attachments
      attach.splice(index, 1);

      if (noFiles == 0) {
        document.getElementById("contained-button-Video-Upload").value = "";
        data["corruption_details"]["videoAttachments"] = [];
        this.setState({
          noOfVideoFiles: 0,
          video_urls: [],
          formdata: data,
          uploadedVideoFileSize: 0,
        });
      } else {
        data["corruption_details"]["videoAttachments"] = attach;

        for (var i = 0; i < attach.length; i++) {
          resTotalSize = resTotalSize + attach[i].size;
        }

        this.setState({
          noOfVideoFiles: noFiles,
          video_urls: arr,
          formdata: data,
          uploadedVideoFileSize: resTotalSize,
        });
      }
    }
    // console.log(index);
    console.log(this.state);
  };

  // map function
  onMarkerDragEnd = (e) => {
    let newLatitude = e.latLng.lat(),
      newLongitude = e.latLng.lng();

    // console.log(newLatitude,newLongitude);

    var data = this.state.formdata;
    data["latitude"] = newLatitude;
    data["longitude"] = newLongitude;

    Geocode.fromLatLng(newLatitude, newLongitude).then(
      (response) => {
        this.setState({
          formdata: data,
          mapPosition: {
            lat: newLatitude,
            lng: newLongitude,
          },
        });
      },
      (error) => {
        // console.log("Map Error:"+error);
      }
    );
  };

  async loadLanguageDetails() {
    var lng = await languageService.getLanguageDetails();

    var data = this.state.formdata;
    var reported_language = "English";

    if (lng == "Sinhala") {

      data['reported_language'] = "Sinhala";

      // reported_language
      this.setState({
        formdata: data,
        languageName: "Sinhala",
        language: sinhalaLanguage,
        steps: sinhalaLanguage.electionReport_stepperSteps,
        districtsTypes: sinhalaLanguage.lng_Districts,
        divisionalSecretariatsAll: sinhalaLanguage.lng_report_divisional_secretariats,
        genderSelection: sinhalaLanguage.lng_genders,
        public_authority_type: sinhalaLanguage.lng_publicAuthorities,
        reportIncidentTypes: sinhalaLanguage.lng_incidentTypes,
        misused_types: sinhalaLanguage.lan_election_misusedTypes,
        misused_description_types: sinhalaLanguage.lng_misused_natureTypes,
      });
    } else if (lng == "Tamil") {

      // reported_language
      data['reported_language'] = "Tamil";

      this.setState({
        formdata: data,
        language: tamilLanguage,
        languageName: "Tamil",
        steps: tamilLanguage.electionReport_stepperSteps,
        districtsTypes: tamilLanguage.lng_Districts,
        divisionalSecretariatsAll: tamilLanguage.lng_report_divisional_secretariats,
        genderSelection: tamilLanguage.lng_genders,
        categorySelection: tamilLanguage.lng_corruption_types,
        public_authority_type: tamilLanguage.lng_publicAuthorities,
        reportIncidentTypes: tamilLanguage.lng_incidentTypes,
        misused_types: tamilLanguage.lan_election_misusedTypes,
        misused_description_types: tamilLanguage.lng_misused_natureTypes,
      });
    }
    console.log("Corruption Section:", this.state);
  }

  //dialog
  // dialogBoxRefOpen = (name) => {

  //   this.setState({
  //     instruction: name,
  //   });
  // }

  dialogBoxRefClose = () => {
    this.setState({
      instruction: "",
    });
  };

  async componentDidMount() {
    //select language
    this.loadLanguageDetails();

    var data = this.state.formdata;
    data["latitude"] = 6.927;
    data["longitude"] = 79.861;

    var user = await jwtAuthService.getUser();
    console.log("logined user", user);
    var loggedIn_res = false;
    // var dialogBox_res = true;

    if (user != null) {
      loggedIn_res = true;
      // dialogBox_res = false;
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 900) {
        this.setState({
          stepperHidden: true,
          styleClassName: false,
        });
      } else {
        this.setState({
          stepperHidden: false,
          styleClassName: true,
        });
      }
    });
  }

  handleLocationChange({ position, address }) {
    // Set new location
    var data = this.state.formdata;
    data['latitude'] = position.lat;
    data['longitude'] = position.lng;
    this.setState({ mapPosition: position, formdata: data });
  }

  render() {
    const { classes } = this.props;
    let { email, password } = this.state;

    // google map
    /* const MapComponent = compose(
      withProps({
        googleMapURL:
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyCL6g9mcTszQRG_7fMOLTBvBCzYlD71ai4&libraries=places",
        // "https://maps.googleapis.com/maps/api/js?key=AIzaSyAGePQeaEd7glCHJUQINRs-1YviE8rCub8&libraries=places",
        loadingElement: <div style={{ height: "100%" }} />,
        containerElement: <div style={{ height: "400px" }} />,
        mapElement: <div style={{ height: "100%" }} />,
      }),
      withScriptjs,
      withGoogleMap
    )((props) => (
      <GoogleMap
        google={props.google}
        defaultZoom={12}
        defaultCenter={{
          lat: this.state.mapPosition.lat,
          lng: this.state.mapPosition.lng,
        }}
      >
        <Marker
          google={props.google}
          name={"Sample Map Marker"}
          draggable={true}
          onDragEnd={(e) => this.onMarkerDragEnd(e)}
          position={{
            lat: this.state.formdata.latitude,
            lng: this.state.formdata.longitude,
          }}
        ></Marker>
      </GoogleMap>
    )); */

    return (
      // app bar
      <div
        style={{
          minHeight: "100vh",
          background: ` radial-gradient(circle at -35% -35%, ${election_report_theme} 15%, #fffcef 50%, #ffffff 100%)`,
        }}
      >
        <div
          className={this.state.styleClassName ? classes.mainPadding : "p-10"}
        >
          {/* Main Report Form */}
          <div className={classes.formStyles_root}>
            <Grid container spacing={5}>
              <Grid xs={12} item align="center" className=" w-full pb-5">
                <div style={{ float: "right" }}>
                  <IconButton
                    onClick={() => {
                      window.location.href = "/home";
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </Grid>

              {/* Heading and the Icon */}

              <Grid
                className={classes.heading}
                item
                alignItems="center"
                container
                xs={12}
                lg={3}
                direction="column"
              // justifyContent="space-around"
              >
                <Grid item align="center" className=" w-full pb-10 mt-10 ">
                  <img alt="share" src="/assets/images/reportElection.png" />
                </Grid>
                <Grid item align="center" className=" w-full ">
                  <Typography
                    // className="mt-5"
                    variant="h1"
                    style={{ fontSize: 30, fontWeight: 600, color: "black" }}
                  >
                    {/* Report Election Violations */}
                    {this.state.language.electionReport_title}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container xs={12} lg={9} className=" mt--10">
                <div className={classes.formStyles_root}>
                  {/* Stepper */}
                  <div
                    className={classes.stepperStyles}
                    hidden={this.state.stepperHidden}
                  >
                    <Stepper
                      alternativeLabel
                      style={{ backgroundColor: "transparent" }}
                      activeStep={this.state.activeStep}
                      connector={<ColorlibConnector />}
                    >
                      {this.state.steps.map((label) => (
                        <Step key={label}>
                          <StepLabel StepIconComponent={ColorlibStepIcon}>
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>

                  {/*-------------Stepper Step 1-----------------*/}
                  {/* 
                  {this.state.activeStep == 0 ? (
                    <div>
                      <ValidatorForm
                        onSubmit={() => {
                          this.handleNext();
                        }}
                      >
                        <Grid container spacing={3}>
  
                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              // "Please Enter Your Name"
                              title={
                                this.state.language
                                  .electionReport_step1_nameFieldTitle
                              }
                              arrow
                            >
                              <TextValidator
                                type="text"
                                // "Name"
                                label={
                                  this.state.language
                                    .electionReport_step1_nameFieldPlaceholder
                                }
                                fullWidth
                                size="small"
                                name="name"
                                value={this.state.formdata.name}
                                onChange={(e) => {
                                  this.handleChange(e);
                                }}
                                validators={["required"]}
                                errorMessages={[
                                  // "Name is required"
                                  this.state.language
                                    .electionReport_step1_nameFieldError,
                                ]}
                                // variant="outlined"
                                className={classes.textStyles}
                              />
                            </Tooltip>
                          </Grid>


                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              arrow
                              // "Please Select Your Age"
                              title={
                                this.state.language
                                  .electionReport_step1_ageFieldTitle
                              }
                            >
                              <Autocomplete
                                className="w-full"
                                options={ageRanges}
                                getOptionLabel={(opt) => opt.value}
                                name="age"
                                size="small"
                                onChange={(e, v) =>
                                  this.setSelectedValue(
                                    "age",
                                    v == null ? null : v.value
                                  )
                                }
                                renderInput={(params) => (
                                  <TextValidator
                                    {...params}
                                    // variant="outlined"
                                    className={classes.textStyles}
                                    // "Select Age"
                                    label={
                                      this.state.language
                                        .electionReport_step1_ageFieldPlaceholder
                                    }
                                    validators={["required"]}
                                    errorMessages={[
                                      // "Age is required!"
                                      this.state.language
                                        .electionReport_step1_ageFieldError,
                                    ]}
                                    value={
                                      this.state.formdata.age == null
                                        ? ""
                                        : this.state.formdata.age
                                    }
                                  />
                                )}
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <Tooltip
                              title={
                                this.state.language
                                  .electionReport_step1_addressFieldTitle
                              }
                              // "Please Enter Your Address"
                              arrow
                            >
                              <TextValidator
                                type="text"
                                // "Address"
                                label={
                                  this.state.language
                                    .electionReport_step1_addressFieldPlaceholder
                                }
                                variant="outlined"
                                className={classes.textStyles}
                                multiline={true}
                                rows={4}
                                name="address"
                                size="small"
                                value={this.state.formdata.address}
                                onChange={(e) => {
                                  this.handleChange(e);
                                }}
                                validators={["required"]}
                                errorMessages={[
                                  // "Address is required*"
                                  this.state.language
                                    .electionReport_step1_addressFieldError,
                                ]}
                                fullWidth
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              // "Please Enter Your Contact Number"
                              title={
                                this.state.language
                                  .electionReport_step1_contactNumberFieldTitle
                              }
                              arrow
                            >
                              <TextValidator
                                type="text"
                                // "Contact Number"
                                label={
                                  this.state.language
                                    .electionReport_step1_contactNumberFieldPlaceholder
                                }
                                // variant="outlined"
                                className={classes.textStyles}
                                size="small"
                                value={this.state.formdata.contact_number}
                                name="contact_number"
                                onChange={(e) => {
                                  this.handleChange(e);
                                }}
                                validators={[
                                  "required",
                                  "matchRegexp:^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$",
                                ]}
                                errorMessages={[
                                  // "Contact Number is required!",
                                  this.state.language
                                    .electionReport_step1_contactNumberFieldError1,
                                  // "Please Enter valid Phone Number(Eg:0772345678)"
                                  this.state.language
                                    .electionReport_step1_contactNumberFieldError2,
                                ]}
                                fullWidth
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              // "Please Enter Your Email"
                              title={
                                this.state.language
                                  .electionReport_step1_emailFieldTitle
                              }
                              arrow
                            >
                              <TextValidator
                                type="text"
                                // "Email"
                                label={
                                  this.state.language
                                    .electionReport_step1_emailFieldPlaceholder
                                }
                                size="small"
                                // variant="outlined"
                                className={classes.textStyles}
                                value={this.state.formdata.email}
                                name="email"
                                onChange={(e) => {
                                  this.handleChange(e);
                                }}
                                validators={["required", "isEmail"]}
                                errorMessages={[
                                  // "Email is required*",
                                  this.state.language
                                    .electionReport_step1_emailFieldError1,
                                  // "Please Enter valid Email!",
                                  this.state.language
                                    .electionReport_step1_emailFieldError2,
                                ]}
                                fullWidth
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset">
                              <Typography variant="caption" style={{ fontFamily: "Source Sans Pro", fontSize: 15 }}>
                                {
                                  this.state.language
                                    .electionReport_step1_genderFieldTitle
                                }
                              </Typography>

                              <RadioGroup className={classes.radioButton} row>
                                {this.state.genderSelection.map((item) => (
                                  <FormControlLabel
                                    key={item.key}
                                    value={item.value}
                                    control={
                                      <Radio
                                        style={{ fontFamily: "Source Sans Pro", fontSize: 15 }}
                                        color="primary"
                                        required={
                                          this.state.formdata.gender === null &&
                                            item.key === "gender_type2"
                                            ? true
                                            : false
                                        }
                                      />
                                    }
                                    checked={
                                      this.state.formdata.gender == item.value
                                        ? true
                                        : false
                                    }
                                    name="gender"
                                    onChange={(e) => {
                                      this.handleChange(e);
                                    }}
                                    label={item.label}
                                    display="inline"
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Button
                          className={["mt-5", classes.btnNext, classes.btnAlign]}
                          variant="contained"

                          type="submit"
                        // style={{ float: "right", marginTop: 10 }}
                        // onClick={this.handleNext}
                        >
                          {this.state.language.electionReport_step1_NextButton}
                        </Button>
                      </ValidatorForm>
                    </div>
                  ) : (
                    <div></div>
                  )} */}

                  {this.state.activeStep === 0 ? (
                    <div>
                      {/* Checkbox */}

                      <Grid
                        className={classes.checkboxStyle}
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                      >
                        <Grid item xs={12} sm={6}>
                          <Checkbox
                            className={classes.checkboxStyle}
                            name="checkbox_NotAnonymous"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            value={this.state.formdata.anonymous}
                            checked={
                              this.state.formdata.anonymous == false
                                ? true
                                : false
                            }
                            color="primary"
                          />
                          <label>
                            {/* I want to share my details. */}
                            {
                              this.state.language
                                .corruptionReport_ShareDetailsCheckbox1
                            }
                          </label>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Checkbox
                            name="checkbox_Anonymous"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            color="primary"
                            value={this.state.formdata.anonymous}
                            checked={
                              this.state.formdata.anonymous == true
                                ? true
                                : false
                            }
                          />
                          <label>
                            {/* I want to stay anonymous. */}
                            {
                              this.state.language
                                .corruptionReport_ShareDetailsCheckbox2
                            }
                          </label>
                        </Grid>
                      </Grid>

                      {/* ----------------Normal form ------------------*/}
                      {this.state.formdata.anonymous == false ? (
                        <div>
                          <ValidatorForm
                            onSubmit={() => {
                              this.handleNext();
                            }}
                          >
                            <Grid container spacing={3}>
                              {/* Name */}
                              <Grid item xs={12} sm={6}>
                                <Tooltip
                                  // "Please Enter Your Name"
                                  title={
                                    this.state.language
                                      .corruptionReport_step1NameFieldTooltip
                                  }
                                  arrow
                                >
                                  <TextValidator
                                    className={classes.textStyles}
                                    type="text"
                                    // "Name"
                                    label={
                                      this.state.language
                                        .corruptionReport_step1NameFieldPlaceholder
                                    }
                                    fullWidth
                                    name="name"
                                    size="small"
                                    value={this.state.formdata.name}
                                    onChange={(e) => {
                                      this.handleChange(e);
                                    }}
                                    validators={["required", 'matchRegexp:^[\p{L}\u0D80-\u0DFF\u0B80-\u0BFFa-zA-Z ]+$']}
                                    errorMessages={[
                                      // "Name is required!"
                                      this.state.language
                                        .corruptionReport_step1NameFieldErrorMessage,
                                      this.state.language.corruptionReport_step1NameFieldIsString]}
                                  //   variant="outlined"
                                  />
                                </Tooltip>
                              </Grid>

                              {/* Age */}
                              <Grid item xs={12} sm={6}>
                                <Tooltip
                                  arrow
                                  // "Please Select Your Age"
                                  title={
                                    this.state.language
                                      .corruptionReport_step1AgeFieldTooltip
                                  }
                                >
                                  <Autocomplete
                                    className="w-full"
                                    options={ageRanges}
                                    getOptionLabel={(opt) => opt.value}
                                    name="age"
                                    size="small"
                                    value={{ value: this.state.formdata.age }}
                                    onChange={(e, v) =>
                                      this.setSelectedValue(
                                        "age",
                                        v == null ? null : v.value
                                      )
                                    }
                                    renderInput={(params) => (
                                      <TextValidator
                                        {...params}
                                        //   variant="outlined"
                                        className={classes.textStyles}
                                        // "Select Age"
                                        label={
                                          this.state.language
                                            .corruptionReport_step1AgeFieldPlaceholder
                                        }
                                        value={
                                          this.state.formdata.age == null
                                            ? ""
                                            : this.state.formdata.age
                                        }
                                        validators={["required"]}
                                        errorMessages={[
                                          // "Age is required!"
                                          this.state.language
                                            .corruptionReport_step1AgeFieldErrorMessage,
                                        ]}
                                      />
                                    )}
                                  />
                                </Tooltip>
                              </Grid>

                              {/* <Grid item xs={12} sm={6} >
                            <Tooltip title="Please Enter Your Age" arrow>
                                <TextValidator 
                                    type="number" 
                                    label="Age"
                                    size='small'
                                    variant="outlined"
                                    value={this.state.formdata.age}
                                    name="age"
                                    onChange={(e) =>{ this.handleChange(e) }}
                                    validators={["required", "minNumber:10"]}
                                    errorMessages={["Age is required!","Please enter valid Age"]}
                                    fullWidth/>
                            </Tooltip>
                            </Grid> */}

                              {/* Address */}
                              <Grid item xs={12} sm={12}>
                                <Tooltip
                                  // "Please Enter Your Address"
                                  title={
                                    this.state.language
                                      .corruptionReport_step1AddressFieldTooltip
                                  }
                                  arrow
                                >
                                  <TextValidator
                                    type="text"
                                    // "Address"
                                    label={
                                      this.state.language
                                        .corruptionReport_step1AddressFieldPlaceholder
                                    }
                                    variant="outlined"
                                    className={classes.textStyles}
                                    multiline={true}
                                    rows={4}
                                    size="small"
                                    name="address"
                                    value={this.state.formdata.address}
                                    onChange={(e) => {
                                      this.handleChange(e);
                                    }}
                                    validators={["required"]}
                                    errorMessages={[
                                      // "Address is required!"
                                      this.state.language
                                        .corruptionReport_step1AddressFieldErrorMessage,
                                    ]}
                                    fullWidth
                                  />
                                </Tooltip>
                              </Grid>

                              {/* PhoneNumber */}
                              <Grid item xs={12} sm={6}>
                                <Tooltip
                                  // "Please Enter Your Contact Number"
                                  title={
                                    this.state.language
                                      .corruptionReport_step1ContactNumberFieldTooltip
                                  }
                                  arrow
                                >
                                  <TextValidator
                                    type="text"
                                    // "Contact Number"
                                    label={
                                      this.state.language
                                        .corruptionReport_step1ContactNumberFieldPlaceholder
                                    }
                                    //   variant="outlined"
                                    className={classes.textStyles}
                                    size="small"
                                    value={this.state.formdata.contact_number}
                                    name="contact_number"
                                    onChange={(e) => {
                                      this.handleChange(e);
                                    }}
                                    validators={[
                                      "required",
                                      "matchRegexp:^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$",
                                    ]}
                                    errorMessages={[
                                      // "Phone Number is required!",
                                      this.state.language
                                        .corruptionReport_step1ContactNumberFieldErrorMessage1,
                                      // "Please enter valid Phone Number(Eg:0772345678)"
                                      this.state.language
                                        .corruptionReport_step1ContactNumberFieldErrorMessage2,
                                    ]}
                                    fullWidth
                                  />
                                </Tooltip>
                              </Grid>

                              {/* Email */}
                              <Grid item xs={12} sm={6}>
                                <Tooltip
                                  // "Please Enter Your Email"
                                  title={
                                    this.state.language
                                      .corruptionReport_step1EmailFieldTooltip
                                  }
                                  arrow
                                >
                                  <TextValidator
                                    type="email"
                                    // "Email"
                                    label={
                                      this.state.language
                                        .corruptionReport_step1EmailFieldPlaceholder
                                    }
                                    helperText=""
                                    //   variant="outlined"
                                    className={classes.textStyles}
                                    value={this.state.formdata.email}
                                    name="email"
                                    size="small"
                                    onChange={(e) => {
                                      this.handleChange(e);
                                    }}
                                    validators={[/* "required", */ "isEmail"]}
                                    errorMessages={[
                                      // "Email is required",
                                      /*  this.state.language
                                         .corruptionReport_step1EmailFieldErrorMessage1, */
                                      // "Please enter valid email"
                                      this.state.language
                                        .corruptionReport_step1EmailFieldErrorMessage2,
                                    ]}
                                    fullWidth
                                  />
                                </Tooltip>
                              </Grid>

                              {/* Gender */}
                              <Grid item xs={12} sm={12}>
                                <FormControl component="fieldset">
                                  <Typography
                                    style={{
                                      fontSize: 15,
                                      fontFamily: "Source Sans Pro",
                                    }}
                                    variant="caption"
                                  >
                                    {/* Select Gender */}
                                    {
                                      this.state.language
                                        .corruptionReport_step1GenderFieldTooltip
                                    }
                                  </Typography>

                                  <RadioGroup
                                    className={classes.radioButton}
                                    row
                                  >
                                    {this.state.genderSelection.map((item) => (
                                      <FormControlLabel
                                        key={item.key}
                                        value={item.value}
                                        control={
                                          <Radio
                                            style={{
                                              fontSize: 15,
                                              fontFamily: "Source Sans Pro",
                                            }}
                                            color="primary"
                                            required={
                                              this.state.formdata.gender ===
                                                null &&
                                                item.key === "gender_type2"
                                                ? true
                                                : false
                                            }
                                          />
                                        }
                                        checked={
                                          this.state.formdata.gender ==
                                            item.value
                                            ? true
                                            : false
                                        }
                                        name="gender"
                                        onChange={(e) => {
                                          this.handleChange(e);
                                        }}
                                        label={item.label}
                                        display="inline"
                                      />
                                    ))}
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                            </Grid>

                            {/* next button normal form */}
                            <div>
                              <Button
                                className={[
                                  "mt-5",
                                  classes.btnNext,
                                  classes.btnAlign,
                                ]}
                                variant="contained"
                                // color="primary"
                                // size="medium"

                                type="submit"
                              // onClick={() => { this.dialogBoxRefOpen("step1_Normalform_dialog") }}
                              // onClick={this.handleNext}
                              >
                                {/* Next */}
                                {
                                  this.state.language
                                    .corruptionReport_step1NextButton
                                }
                              </Button>
                            </div>
                          </ValidatorForm>
                        </div>
                      ) : (
                        <div>
                          {/* ----------------Anonymous form ------------------*/}
                          <Grid>
                            <ValidatorForm
                              onSubmit={() => {
                                this.handleNext();
                              }}
                            >
                              <Grid
                                container
                                justify="center"
                                alignItems="center"
                                direction="row"
                                spacing={3}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  className={classes.formStyles_girdItem}
                                >
                                  <Tooltip
                                    arrow
                                    // "Please Select Your Age"
                                    title={
                                      this.state.language
                                        .corruptionReport_step1AgeFieldTooltip
                                    }
                                  >
                                    <Autocomplete
                                      className="w-full"
                                      options={ageRanges}
                                      getOptionLabel={(opt) => opt.value}
                                      name="age"
                                      size="small"
                                      onChange={(e, v) =>
                                        this.setSelectedValue(
                                          "age",
                                          v == null ? null : v.value
                                        )
                                      }
                                      renderInput={(params) => (
                                        <TextValidator
                                          {...params}
                                          // variant="outlined"
                                          className={classes.textStyles}
                                          fullWidth
                                          // "Select Age"
                                          label={
                                            this.state.language
                                              .corruptionReport_step1AgeFieldPlaceholder
                                          }
                                          validators={["required"]}
                                          errorMessages={[
                                            // "Age is required!"
                                            this.state.language
                                              .corruptionReport_step1AgeFieldErrorMessage,
                                          ]}
                                          value={
                                            this.state.formdata.age == null
                                              ? ""
                                              : this.state.formdata.age
                                          }
                                        />
                                      )}
                                    />
                                  </Tooltip>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  className={classes.formStyles_girdItem}
                                >
                                  <Tooltip
                                    arrow
                                    // "Please Select Your Gender"
                                    title={
                                      this.state.language
                                        .corruptionReport_step1AnonymousFormGenderFieldTooltip
                                    }
                                  >
                                    <Autocomplete
                                      className="w-full"
                                      options={this.state.genderSelection}
                                      getOptionLabel={(opt) => opt.label}
                                      name="gender"
                                      size="small"
                                      onChange={(e, v) =>
                                        this.setSelectedValue("gender", v.value)
                                      }
                                      renderInput={(params) => (
                                        <TextValidator
                                          {...params}
                                          // variant="outlined"
                                          className={classes.textStyles}
                                          fullWidth
                                          // "Select Gender"
                                          label={
                                            this.state.language
                                              .corruptionReport_step1AnonymousFormGenderFieldPlaceholder
                                          }
                                          validators={["required"]}
                                          errorMessages={[
                                            // "Gender is required!"
                                            this.state.language
                                              .corruptionReport_step1AnonymousFormGenderFieldError,
                                          ]}
                                          value={this.state.formdata.gender}
                                        />
                                      )}
                                    />
                                  </Tooltip>
                                </Grid>
                              </Grid>

                              {/* next button anonymous form */}
                              <div>
                                <Button
                                  className={[
                                    "mt-5",
                                    classes.btnNext,
                                    classes.btnAlign,
                                  ]}
                                  variant="contained"
                                  // color="primary"
                                  // size="medium"

                                  type="submit"
                                >
                                  {/* Next */}
                                  {
                                    this.state.language
                                      .corruptionReport_step1AnonymousFormNextButton
                                  }
                                </Button>
                              </div>

                              {/* Dialog box 2*/}
                              <Dialog
                                open={
                                  this.state.instruction ==
                                    "step1_Anonymousform_dialog"
                                    ? true
                                    : false
                                }
                                onClose={this.dialogBoxRefClose}
                              >
                                <DialogContent>
                                  {/* close icon */}
                                  <div
                                    style={{
                                      textAlign: "end",
                                      width: "100%",
                                      height: "0px",
                                    }}
                                  >
                                    <IconButton
                                      style={{ color: "#0e334b" }}
                                      onClick={this.dialogBoxRefClose}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </div>

                                  <Alert severity="warning">
                                    <center>
                                      <Typography variant="h5">
                                        {/* Note */}
                                        {
                                          this.state.language
                                            .corruptionReport_anonymousSwitchDialogBoxTitle
                                        }
                                      </Typography>
                                    </center>
                                    <p>
                                      {/* We encourage you to provide as much personal information as possible, so we have a better chance of verifying the complaint/story. */}
                                      {
                                        this.state.language
                                          .corruptionReport_anonymousSwitchDialogBoxDescription
                                      }
                                    </p>
                                  </Alert>
                                </DialogContent>

                                <DialogActions>
                                  {/* <Button
                                    color="primary"
                                    size="medium"
                                    onClick={ () => {
                                            this.handleNext();
                                            this.setState({
                                                instruction: '',
                                            });
                                        }
                                    } >
                                    Next
                                </Button> */}
                                </DialogActions>
                              </Dialog>
                            </ValidatorForm>
                          </Grid>
                        </div>
                      )}

                      {/* step 1 end div */}
                    </div>
                  ) : (
                    <div> </div>
                  )}

                  {/*-------------Stepper Step 2-----------------*/}

                  {this.state.activeStep == 1 ? (
                    <div className={classes.formStyles_root}>
                      <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        open={this.state.snackbar}
                        autoHideDuration={2500}
                        onClose={this.closeSnackBar}
                        name="snackBar"
                      >
                        <Alert
                          severity={this.state.severity}
                          onClose={this.closeSnackBar}
                        >
                          <p align="justify"> {this.state.message}</p>
                        </Alert>
                      </Snackbar>

                      <ValidatorForm
                        onSubmit={() => {
                          this.handleNext();
                        }}
                      >
                        <Grid container spacing={3}>
                          {/* Public authority involved */}
                          {/* <Grid item xs={12} sm={6}>

                                        <FormControl>

                                            <Typography variant="caption">
                                                // Public authority involved
                                                {this.state.language.electionReport_step2_publicAuthorityInvolvedFieldTitle}
                                            </Typography>

                                            <RadioGroup row>
                                                <FormControlLabel
                                                    name="public_authority_involved"
                                                    value={true}
                                                    control={<Radio color="primary" />}
                                                    checked={this.state.formdata.public_authority_involved === true ? true : false}
                                                    onChange={(e) => { this.handleChange(e) }}
                                                    // "Yes"
                                                    label={this.state.language.electionReport_step2_publicAuthorityInvolvedFieldAns1}
                                                    display="inline"
                                                />

                                                <FormControlLabel
                                                    name="public_authority_involved"
                                                    value={false}
                                                    control={<Radio color="primary" />}
                                                    checked={this.state.formdata.public_authority_involved === false ? true : false}
                                                    onChange={(e) => { this.handleChange(e) }}
                                                    // "No"
                                                    label={this.state.language.electionReport_step2_publicAuthorityInvolvedFieldAns2}
                                                    display="inline"
                                                />
                                            </RadioGroup>

                                        </FormControl>

                                    </Grid> */}

                          {/* authority type */}

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              // "Please Select Authority Involved"
                              title={
                                this.state.language
                                  .electionReport_step2_publicAuthorityFieldTitle
                              }
                              arrow
                            >
                              <Autocomplete
                                className="w-full"
                                size="small"
                                options={this.state.public_authority_type}
                                getOptionLabel={(option) => option.label}
                                onChange={(e, v) =>
                                  this.setSelectedValue(
                                    "name_of_authority",
                                    v == null ? null : v.value
                                  )
                                }
                                renderInput={(params) => (
                                  <TextValidator
                                    {...params}
                                    // variant="outlined"
                                    className={classes.textStyles}
                                    // "Select Authority"
                                    label={
                                      this.state.language
                                        .electionReport_step2_publicAuthorityFieldPlaceholder
                                    }
                                    validators={
                                      this.state.formdata
                                        .public_authority_involved == true
                                        ? ["required"]
                                        : []
                                    }
                                    errorMessages={[
                                      // "Authority field is required!",
                                      this.state.language
                                        .electionReport_step2_publicAuthorityFieldError,
                                    ]}
                                    value={
                                      this.state.formdata.name_of_authority ==
                                        null
                                        ? ""
                                        : this.state.formdata.name_of_authority
                                    }
                                  />
                                )}
                              />
                            </Tooltip>
                          </Grid>

                          {/* name and designation of authority */}
                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              // "Please Enter Name and Designation of the Person Involved"
                              title={
                                this.state.language
                                  .electionReport_step2_publicAuthorityDesignationFieldTitle
                              }
                              arrow
                            >
                              <TextValidator
                                // className="w-full"
                                type="text"
                                // variant="outlined"
                                className={clsx('w-full', classes.textStyles)}
                                //className={classes.textStyles}
                                size="small"
                                value={
                                  this.state.formdata.designation_of_authority
                                }
                                // "Name and Designation"
                                label={
                                  this.state.language
                                    .electionReport_step2_publicAuthorityDesignationFieldPlaceholder
                                }
                                name="designation_of_authority"
                                onChange={(e) => {
                                  this.handleChange(e);
                                }}
                                validators={["maxStringLength:400"]}
                                errorMessages={[this.state.language.designation_of_authority_validation_msg]}
                                // validators={
                                //   this.state.formdata
                                //     .public_authority_involved == true
                                //     ? ["required"]
                                //     : []
                                // }
                                // errorMessages={[
                                //   // "Designation of Authority is required",
                                //   this.state.language
                                //     .electionReport_step2_publicAuthorityDesignationFieldError,
                                // ]}
                                fullWidth
                              />
                            </Tooltip>
                          </Grid>

                          {/* Incident_status */}
                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              // "Please Select Incident"
                              title={
                                this.state.language
                                  .electionReport_step2_incidentTypeFieldTitle
                              }
                              arrow
                            >
                              <Autocomplete
                                className="w-full"
                                options={this.state.reportIncidentTypes}
                                size="small"
                                getOptionLabel={(option) => option.label}
                                onChange={(e, v) =>
                                  this.setSelectedValue(
                                    "incident_status",
                                    v == null ? null : v.value
                                  )
                                }
                                renderInput={(param) => (
                                  <TextValidator
                                    {...param}
                                    // variant="outlined"
                                    className={classes.textStyles}
                                    // "Select Incident Type"
                                    label={
                                      this.state.language
                                        .electionReport_step2_incidentTypeFieldPlaceholder
                                    }
                                    validators={["required"]}
                                    errorMessages={[
                                      // "Incident Type field is required!",
                                      this.state.language
                                        .electionReport_step2_incidentTypeFieldError,
                                    ]}
                                    value={
                                      this.state.formdata.incident_status ===
                                        null
                                        ? ""
                                        : this.state.formdata.incident_status
                                    }
                                  />
                                )}
                              />
                            </Tooltip>
                          </Grid>

                          {/* District */}
                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              // "Please Select District"
                              title={
                                this.state.language
                                  .electionReport_step2_districtFieldTitle
                              }
                              arrow
                            >
                              <Autocomplete
                                className="w-full"
                                size="small"
                                options={this.state.districtsTypes}
                                getOptionLabel={(opt) => opt.label}
                                onChange={(e, v) =>
                                  this.setSelectedValue(
                                    "district",
                                    v == null ? null : v.value
                                  )
                                }
                                renderInput={(params) => (
                                  <TextValidator
                                    {...params}
                                    // variant="outlined"
                                    className={classes.textStyles}
                                    // "Select District"
                                    label={
                                      this.state.language
                                        .electionReport_step2_districtFieldPlaceholder
                                    }
                                    validators={["required"]}
                                    errorMessages={[
                                      // "District field is required!"
                                      this.state.language
                                        .electionReport_step2_districtFieldError,
                                    ]}
                                    value={
                                      this.state.formdata.district === null
                                        ? ""
                                        : this.state.formdata.district
                                    }
                                  />
                                )}
                              />
                            </Tooltip>
                          </Grid>

                          {/* Divisional Secretariats*/}
                          {this.state.formdata.district != "National level" &&
                            <Grid item xs={12} sm={6}>
                              <Tooltip
                                // "Please select divisional secretariat"
                                title={
                                  this.state.language
                                    .electionReport_step2_divisionalSecretariatFieldTitle
                                }
                                arrow
                              >
                                <Autocomplete
                                  className="w-full"
                                  size="small"
                                  options={this.state.divisionalSecretariats}
                                  getOptionLabel={(opt) => String(opt.label)}
                                  onChange={(e, v) =>
                                    this.setSelectedValue(
                                      "divisional_secretariat",
                                      v == null ? null : v.value
                                    )
                                  }
                                  disabled={this.state.formdata.district == null}
                                  renderInput={(params) => (
                                    <TextValidator
                                      {...params}
                                      // variant="outlined"
                                      className={classes.textStyles}
                                      // "Select Divisional Secretariats"
                                      label={
                                        this.state.language
                                          .electionReport_step2_divisionalSecretariatFieldPlaceholder
                                      }
                                      validators={["required"]}
                                      errorMessages={[
                                        // "Divisional Secretariats field is required"
                                        this.state.language
                                          .electionReport_step2_divisionalSecretariatFieldError,
                                      ]}
                                      value={
                                        this.state.formdata
                                          .divisional_secretariat === null
                                          ? ""
                                          : this.state.formdata
                                            .divisional_secretariat
                                      }
                                    />
                                  )}
                                />
                              </Tooltip>
                            </Grid>
                          }
                          {/* Date */}
                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              // "Please Select Date"
                              title={
                                this.state.language
                                  .electionReport_step2_dateFieldTitle
                              }
                              arrow
                            >
                              <TextValidator
                                type="date"
                                size="small"
                                // variant="outlined"
                                className={classes.textStyles}
                                name="incident_date"
                                onChange={(e) => {
                                  this.onChangeTime(e);
                                }}
                                validators={["required"]}
                                errorMessages={[
                                  // 'Date field is required'
                                  this.state.language
                                    .electionReport_step2_DateFieldError,
                                ]}
                                value={
                                  this.state.incident_date === null
                                    ? ""
                                    : this.state.incident_date
                                }
                                fullWidth
                              />
                            </Tooltip>
                          </Grid>

                          {/* Time*/}
                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                this.state.language
                                  .electionReport_step2_timeFieldTitle
                              }
                              arrow
                            >
                              <TextValidator
                                type="time"
                                size="small"
                                // variant="outlined"
                                className={classes.textStyles}
                                name="incident_time"
                                onChange={(e) => {
                                  this.onChangeTime(e);
                                }}
                                // validators={["required"]}
                                // errorMessages={[
                                //   // 'This field is required'
                                //   this.state.language
                                //     .electionReport_step2_TimeFieldError,
                                // ]}
                                value={
                                  this.state.incident_time === null
                                    ? ""
                                    : this.state.incident_time
                                }
                                fullWidth
                              />
                            </Tooltip>
                          </Grid>

                          {/* Map*/}
                          <Grid item xs={12} sm={12}>
                            <div style={{ height: "50vh", width: "100%" }}>
                              {this.state.formdata.district != "National level" && <div>
                                <Typography variant="body2">
                                  {/* Please Select Location */}
                                  {
                                    this.state.language
                                      .electionReport_step2_LocationMapField
                                  }
                                </Typography>

                                <div>
                                  {/* <MapComponent /> */}


                                  <LocationPicker
                                    containerElement={<div style={{ height: "100%" }} />}
                                    mapElement={<div style={{ height: "400px" }} />}
                                    defaultPosition={this.state.mapPosition}
                                    onChange={this.handleLocationChange}
                                  />

                                </div>
                              </div>}

                              <Grid item xs={12} sm={12}>
                                <Button
                                  // onClick={this.handleNext}
                                  className={[
                                    classes.btnNext,
                                    classes.btnAlign,
                                  ]}
                                  variant="contained"
                                  // color="primary"
                                  type="submit"
                                >
                                  {/* Next */}
                                  {
                                    this.state.language
                                      .electionReport_step2_nextButton
                                  }
                                </Button>
                                <Button
                                  className={[classes.btnNext, classes.btnAlign]}
                                  onClick={this.handleBack}
                                  varient="contained"
                                  style={{ float: "right", marginRight: 12 }}
                                >
                                  {/* Back */}
                                  {
                                    this.state.language
                                      .electionReport_step2_backButton
                                  }
                                </Button>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                      </ValidatorForm>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {/*-------------Stepper Step 3-----------------*/}
                  {this.state.activeStep == 2 ? (
                    <div>
                      <div className={classes.formStyles_root}>
                        <ValidatorForm
                          onSubmit={(e) => {
                            !this.state.submittingForm && this.formSubmit(e);
                          }}
                        >
                          <Grid item xs={12} md={12}>
                            <Typography
                              variant="body2"
                              className={classes.formStyle1}
                            >
                              {/*Apesalli.lk only monitors misuse of public property/resources during elections  */}
                              *
                              {
                                this.state.language
                                  .electionReport_step3_noteText
                              }
                            </Typography>
                          </Grid>

                          <Typography
                            variant="h5"
                            style={{ marginBottom: 20, marginTop: 20 }}
                          >
                            {/* More Specific Information */}
                            {this.state.language.electionReport_step3_Title}
                          </Typography>

                          <Grid container spacing={3}>
                            {/* misused type */}

                            <Grid item xs={12} md={12}>
                              <Typography
                                variant="h6"
                                className={classes.formStyle1}
                              >
                                {/* What is being misused? Select one or more from below options. */}
                                {
                                  this.state.language
                                    .electionReport_step3_misusedFieldTitle
                                }
                              </Typography>
                            </Grid>

                            <FormGroup
                              className={[
                                "ml-5 mb-5",
                                classes.formStyle2,
                                classes.checkboxStyle,
                              ]}
                            >
                              {this.state.misused_types.map((opt) => (
                                <FormControlLabel
                                  className="mb-2"
                                  control={
                                    <Checkbox
                                      name={opt.value}
                                      value={opt.value}
                                      onChange={(e) => {
                                        this.setMisusedType(e);
                                      }}
                                      color="primary"
                                      label={opt.value}
                                    />
                                  }
                                  label={opt.label}
                                />
                              ))}
                            </FormGroup>

                            {/* nature of misuse */}
                            <Grid className="w-full" item xs={12} sm={12}>
                              <Tooltip
                                // "Describe the misuse"
                                title={
                                  this.state.language
                                    .electionReport_step3_natureOfMisuseFieldTitle
                                }
                                arrow
                              >
                                {/*  <Autocomplete
                                  className="w-full"
                                  size="small"
                                  freeSolo
                                  options={this.state.misused_description_types}
                                  getOptionLabel={(opt) => opt.label || opt}
                                  onInputChange={(e, v) =>
                                    this.setSelectedCorruptionValueMisused(
                                      "nature_of_misuse",
                                      v
                                    )
                                  }
                                  defaultValue={""}
                                  renderInput={(params) => (
                                    <TextValidator
                                      {...params}
                                      //   variant="outlined"
                                      className={classes.textStyles}
                                      // "Please Describe Nature of Misuse"
                                      label={
                                        this.state.language
                                          .electionReport_step3_natureOfMisuseFieldPlaceholder
                                      }
                                      validators={["required"]}
                                      errorMessages={[
                                        // "Nature of Misuse field is required"
                                        this.state.language
                                          .electionReport_step3_natureOfMisuseFieldError,
                                      ]}
                                      value={
                                        this.state.formdata.corruption_details
                                          .nature_of_misuse
                                      }
                                    />
                                  )}
                                /> */}


                                <TextValidator
                                  size="small"
                                  name='nature_of_misuse'
                                  /* {...params} */
                                  //   variant="outlined"
                                  multiline={true}
                                  rows={4}

                                  className={classes.textStyles}
                                  // "Please Describe Nature of Misuse"
                                  label={this.state.language.electionReport_step3_natureOfMisuseFieldPlaceholder
                                  }
                                  onChange={(e) => {
                                    this.handleCorruptionChange(e);
                                  }}
                                  fullWidth
                                  validators={["required", "maxStringLength:800"]}
                                  errorMessages={[
                                    // "Nature of Misuse field is required"
                                    this.state.language.electionReport_step3_natureOfMisuseFieldError,
                                    this.state.language.designation_of_authority_validation_msg
                                  ]}
                                  value={
                                    this.state.formdata.corruption_details
                                      .nature_of_misuse
                                  }

                                  helperText={
                                    "Word Limit(" +
                                    (800 - Number(this.state.formdata?.corruption_details?.nature_of_misuse?.length == undefined ? 0 : this.state.formdata?.corruption_details?.nature_of_misuse?.length)) +
                                    "/" +
                                    800 +
                                    ")"
                                  }
                                  inputProps={{
                                    maxLength: 800,
                                  }}

                                />

                              </Tooltip>
                            </Grid>

                            {/* political involved */}
                            <Grid item xs={12} sm={12}>
                              <Tooltip
                                // "Details of the Political Party Involved:"
                                title={
                                  this.state.language
                                    .electionReport_step3_politicalPartyFieldTitle
                                }
                                arrow
                              >
                                <TextValidator
                                  type="text"
                                  size="small"
                                  // "Political Party Involved"
                                  label={
                                    this.state.language
                                      .electionReport_step3_politicalPartyFieldPlaceholder
                                  }
                                  //   variant="outlined"
                                  className={classes.textStyles}
                                  name="political_party"
                                  helperText={
                                    "Word Limit(" +
                                    this.state.countWords +
                                    "/" +
                                    electionPartyWordLimit +
                                    ")"
                                  }
                                  inputProps={{
                                    maxLength: electionPartyWordLimit,
                                  }}
                                  onChange={(e) => {
                                    this.handleCorruptionChange(e);
                                  }}
                                  fullWidth
                                />
                              </Tooltip>
                            </Grid>
                          </Grid>

                          {/* common */}
                          <Grid container spacing={3}>
                            {/* Image Attachments */}
                            <Grid item xs={12} sm={6}>
                              <input
                                id="contained-button-file"
                                size="small"
                                name="attachments"
                                multiple
                                type="file"
                                onChange={this.handlefileUpload}
                                style={{ display: "none" }}
                              />

                              <label htmlFor="contained-button-file">
                                <Button
                                  className={classes.btnNext}
                                  variant="contained"
                                  //   style={{
                                  //     backgroundColor: "#0074E1",
                                  //     color: "#fff",
                                  //   }}
                                  startIcon={<CloudUploadIcon />}
                                  component="span"
                                >
                                  {/* Upload Images */}
                                  {
                                    this.state.language
                                      .electionReport_step3_uploadButtonTitle1
                                  }
                                </Button>
                              </label>
                              <label>
                                &ensp;
                                {/* no of images */}
                                {
                                  this.state.languageName === 'Tamil' ?
                                    <Fragment>
                                      <p className="w-full" style={{ display: 'flex' }}>
                                        {this.state.language
                                          .electionReport_step3_uploadButtonTitle2 +
                                          " " +
                                          this.state.noOfFiles}
                                      </p>
                                    </Fragment>
                                    :
                                    <p style={{ display: "inline", marginLeft: 5 }}>
                                      {this.state.language
                                        .electionReport_step3_uploadButtonTitle2 +
                                        " " +
                                        this.state.noOfFiles}
                                    </p>
                                }
                              </label>
                            </Grid>

                            {/* Evidence Attachments Video*/}
                            <Grid item xs={12} sm={6}>
                              <input
                                id="contained-button-Video-Upload"
                                size="small"
                                name="attachments"
                                multiple
                                type="file"
                                onChange={this.handleVideoUpload}
                                style={{ display: "none" }}
                              />

                              <label htmlFor="contained-button-Video-Upload">
                                <div style={{ float: "right" }}>
                                  <Button
                                    className={classes.btnNext}
                                    // style={{
                                    //   color: "white",
                                    //   backgroundColor: "#FB3640",
                                    //   "&:hover": {
                                    //     color: "black",
                                    //     backgroundColor: "#ce6c10",
                                    //   },
                                    // }}
                                    variant="contained"
                                    // color="primary"

                                    startIcon={<PlayCircleFilledIcon />}
                                    component="span"
                                  >
                                    {/* Upload Videos */}
                                    {
                                      this.state.language
                                        .corruptionReport_step4UploadButtonVideo
                                    }
                                    &ensp;
                                  </Button>
                                  {/* <p style={{ display: "inline", marginLeft: 5 }}>
                                    {this.state.language
                                      .corruptionReport_step4UploadButtonText +
                                      " " +
                                      this.state.formdata.corruption_details.videoAttachments.length}
                                  </p> */}
                                </div>
                              </label>
                            </Grid>

                            {/* attachments preview */}
                            <Grid item xs={12} sm={12}>
                              {/* attachments images */}
                              {this.state.noOfFiles > 0 && (
                                <Grid container spacing={2}>
                                  {this.state.image_urls.map((img) => (
                                    <Grid item xs={12} sm={4} key={img}>
                                      <Card
                                        style={{
                                          cursor: "pointer",
                                          backgroundColor: "#f1f1f1",
                                        }}
                                      >
                                        <CardMedia
                                          component="img"
                                          height="150"
                                          image={img}
                                          value={img}
                                          onClick={this.openAttachment}
                                        />
                                        <CardActions>
                                          <Tooltip
                                            // "Remove"
                                            title={
                                              this.state.language
                                                .electionReport_step3_trashButtonTooltip
                                            }
                                            arrow
                                          >
                                            <IconButton
                                              className={
                                                classes.deleteTrashIcon
                                              }
                                              onClick={() =>
                                                this.removeUploadSelectedItem(
                                                  img
                                                )
                                              }
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </CardActions>
                                      </Card>
                                    </Grid>
                                  ))}
                                </Grid>
                              )}

                              {/* attachments Videos */}
                              {this.state.noOfVideoFiles > 0 && (
                                <Grid container spacing={2}>
                                  {this.state.video_urls.map((video) => (
                                    <Grid item xs={12} sm={4} key={video}>
                                      <Card
                                        style={{
                                          cursor: "pointer",
                                          backgroundColor: "#f1f1f1",
                                        }}
                                      >
                                        <CardMedia
                                          component="img"
                                          height="150"
                                          image={
                                            "/assets/images/videoPreview.png"
                                          }
                                          value={video}
                                          onClick={this.openAttachment}
                                        />
                                        <CardActions>
                                          <Tooltip
                                            // "Remove"
                                            title={
                                              this.state.language
                                                .corruptionReport_step4TrashIconTooltip
                                            }
                                            arrow
                                          >
                                            <IconButton
                                              className={
                                                classes.deleteTrashIcon
                                              }
                                              onClick={() =>
                                                this.removeUploadSelectedVideoItem(
                                                  video
                                                )
                                              }
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </CardActions>
                                      </Card>
                                    </Grid>
                                  ))}
                                </Grid>
                              )}
                            </Grid>

                            {/*back / next button */}
                            <Grid item xs={12} sm={12}>
                              <Button
                                disabled={this.state.submittingForm}
                                className={[classes.btnNext, classes.btnAlign]}
                                // onClick={this.handleNext}
                                variant="contained"
                                // color="primary"
                                type="submit"

                              // style={{ float: "right" }}
                              >
                                {/* Finish */}
                                {
                                  this.state.language
                                    .electionReport_step3_finishButton
                                }
                              </Button>
                              <Button
                                className={[classes.btnNext, classes.btnAlign]}
                                disabled={this.state.submittingForm}
                                onClick={this.handleBack}
                                variant="contained"
                                // color="primary"
                                style={{ marginRight: 10 }}
                              >
                                {/* Back */}
                                {
                                  this.state.language
                                    .electionReport_step2_backButton
                                }
                              </Button>
                            </Grid>
                          </Grid>
                        </ValidatorForm>

                        <Dialog
                          open={this.state.dialogBox == true ? true : false}
                          name="dialogBox"
                        >
                          <DialogTitle>
                            <div style={{ textAlign: "center" }}>
                              <Typography variant="h5">
                                {/* Thankyou for standing against corruption ! */}
                                {
                                  this.state.language
                                    .electionReport_step3_DialogBoxTitle1
                                }
                              </Typography>
                              <Typography variant="h4">
                                {/* Your Reference Number is  */}
                                {
                                  this.state.language
                                    .electionReport_step3_DialogBoxTitle2
                                }
                              </Typography>
                              <Typography variant="h3">
                                <strong>{this.state.refNumber}</strong>
                              </Typography>
                            </div>
                          </DialogTitle>

                          <DialogContent>
                            <Grid item xs={12} sm={6}></Grid>
                            <Alert severity="success">
                              <strong>
                                {/* Your Report Submitted Successfully!. */}
                                {
                                  this.state.language
                                    .electionReport_step3_DialogBoxDescriptionP1
                                }
                              </strong>
                              <p>
                                {/* If you wish to submit more supporting documents or evidence, please Fax the documents
                                                anonymously to 0112501707. */}
                                {
                                  this.state.language
                                    .electionReport_step3_DialogBoxDescriptionP2
                                }
                              </p>
                            </Alert>

                            {this.state.attachmentUploading && (
                              <Alert severity="warning">
                                <div style={{ textAlign: "center" }}>
                                  <CircularProgress />
                                </div>
                                <strong>
                                  {/* Please wait. Uploading Attachments.*/}
                                  {
                                    this.state.language
                                      .corruptionReport_step4DialogBoxDescriptionP3
                                  }
                                </strong>
                                <LinearProgressWithLabel
                                  value={this.state.loadingProgress}
                                />
                              </Alert>
                            )}

                            {this.state.attachmentUploading === false &&
                              this.state.attachmentUploadingError == null &&
                              this.state.haveUploadAttachments && (
                                <Alert severity="success">
                                  <strong>
                                    {/* Attachments upload successful!*/}
                                    {
                                      this.state.language
                                        .corruptionReport_step4DialogBoxDescriptionP4
                                    }
                                  </strong>
                                </Alert>
                              )}

                            {this.state.attachmentUploadingError != null && (
                              <Alert severity="error">
                                <strong>
                                  {this.state.attachmentUploadingError}
                                </strong>
                              </Alert>
                            )}
                          </DialogContent>

                          <DialogActions>
                            {this.state.attachmentUploading === false && (
                              <Button
                                // variant="contained"
                                color="primary"
                                type="submit"
                                onClick={this.handleClose}
                              >
                                {/* Ok */}
                                {
                                  this.state.language
                                    .electionReport_step3_DialogBoxButton
                                }
                              </Button>
                            )}
                          </DialogActions>
                        </Dialog>

                        <Snackbar
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={this.state.snackbar}
                          autoHideDuration={2500}
                          onClose={this.closeSnackBar}
                          name="snackBar"
                        >
                          <Alert
                            severity={this.state.severity}
                            onClose={this.closeSnackBar}
                          >
                            {this.state.message}
                          </Alert>
                        </Snackbar>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
          {/* Main div end */}
        </div>
      </div>
    );
  }
}

ReportElectionIncident.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  login: state.login,
});

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, { loginWithEmailAndPassword })(
      ReportElectionIncident
    )
  )
);
