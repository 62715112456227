import axios from 'axios';
import * as appconst from './../appconst';
import localStorageService from "./localStorageService";

class IncidentService {
  ls = window.localStorage

  createIncident = async (data) => {//Create new incident
    const user = await localStorageService.getItem("auth_user");
    console.log("user", user)
    if (user != null) {

      const a = new Promise((resolve, reject) => {
        axios.post(appconst.CREATE_INCIDENT, data,

          {
            headers: { Authorization: `Bearer ${user.token}` }
          }

        ).then(res => {
          return resolve(res);
        })
          .catch((error) => {
            console.log("error", error);
            return resolve(error)
          })
      });
      return await a;

    }
    else {

      const a = new Promise((resolve, reject) => {
        axios.post(appconst.CREATE_INCIDENT, data,)
          .then(res => {
            return resolve(res);
          })
          .catch((error) => {
            console.log("error", error);
            return resolve(error)
          })
      });
      return await a;

    }

  }

  uploadFiles = async (data) => {
    const a = new Promise((resolve, reject) => {
      axios.post(appconst.UPLOAD_FILES, data).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
          return resolve(error)
        })
    });
    return await a;
  }

  uploadNewFiles = async (data) => {
    const a = new Promise((resolve, reject) => {
      axios.post(appconst.UPLOAD_NEW_FILES, data).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
          return resolve(error)
        })
    });
    return await a;
  }

  //delete file
  deleteFile = async (data) => {
    const a = new Promise((resolve, reject) => {
      axios.post(appconst.DELETE_FILES, data).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
          return resolve(error)
        })
    });
    return await a;
  }


  getAllIncidentsWithFilters = async (params, page) => {//get incident with filter

    const a = new Promise((resolve, reject) => {
      axios.get(appconst.All_INCIDENT_WITH_FILTERS + "?page=" + page, {
        params: params

      }).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
          return resolve(error)
        })
    });
    return await a;
  }

  getPublicIncidentWithFilters = async (params, page) => {//get incident with filter

    const a = new Promise((resolve, reject) => {
      axios.get(appconst.All_INCIDENT_WITH_FILTERS_PUBLIC + "?page=" + page, {
        params: params

      }).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }


  getSingleIncident = async (id) => {//get single incident

    // const user = await localStorageService.getItem("auth_user");
    //     const headers = {
    //       headers: {
    //        'Authorization': `Bearer ${user.token}`
    //       },

    //     };
    const a = new Promise((resolve, reject) => {
      axios.get(appconst.GET_SINGLE_INCIDENT + id,
        // headers
      ).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log("error", error);
        })
    });
    return await a;
  }

  //change state of incident
  changeIncidentState = async (data, id) => {

    const user = await localStorageService.getItem("auth_user");

    const a = new Promise((resolve, reject) => {
      axios.put(appconst.UPDATE_INCIDENT_STATE + id,
        {
          "status": data.status,
        }, {
        headers: { Authorization: `Bearer ${user.token}` }
      }
      ).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log(error);
        })
    });
    return await a;
  }

  //publish incident
  updatePublishIncident = async (data, id) => {

    const user = await localStorageService.getItem("auth_user");

    const a = new Promise((resolve, reject) => {
      axios.put(appconst.UPDATE_INCIDENT_PUBLISH + id,
        {
          "published": data.published,
        }, {
        headers: { Authorization: `Bearer ${user.token}` }
      }
      ).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log(error);
        })
    });
    return await a;
  }

  //edit incident
  updateIncidentDetails = async (data, id) => {

    const user = await localStorageService.getItem("auth_user");

    const a = new Promise((resolve, reject) => {
      axios.put(appconst.Edit_INCIDENT + id, data, {
        headers: { Authorization: `Bearer ${user.token}` }
      }
      ).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log(error);
          return reject(error);
        })
    });
    return await a;
  }

}

export default new IncidentService();