import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {
  useMediaQuery,
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  Chip,
  Grid,
  SwipeableDrawer,
  Avatar,
  Icon,
  IconButton,
  MenuItem,
  Button,
  GridList,
  GridListTile,
  GridListTileBar,
  ListItemText,
  CardActionArea,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Fade from "@material-ui/core/Fade";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Alert, AlertTitle } from "@material-ui/lab";
import Footer from "./components/Footer";
import AppAppBar from "./components/AppAppBar";
import Divider from "@material-ui/core/Divider";

import IncidentService from "../../services/IncidentServices";
import Util from "../../services/Util";
import * as appconst from "../../appconst";

import { withStyles, useTheme } from "@material-ui/core/styles";

import { englishLanguage } from "../languages/englishLanguage";
import { sinhalaLanguage } from "../languages/sinhalaLanguage";
import { tamilLanguage } from "../languages/tamilLanguage";

import languageService from "app/services/languageService";

function MyComponent() {
  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));
  return <span>{`theme.breakpoints.up('sm') matches: ${matches}`}</span>;
}

const styleSheet = (theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginTop: "-75px"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridroot: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    // backgroundColor: "#f5f5f5",
    backgroundColor: "white",
    width: "100%",
  },
  gridList: {
    overflow: "unset",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },

  mainPadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  card: {
    borderRadius: 8,
    // height: 279,
    overflow: "hidden",
    cursor: "pointer",
    // boxShadow:
    //   " 0 8px 20px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",
    transition: "all ease 200ms",

    "&:hover": {

      filter: "brightness(1)",
      transform: "scale(1.05)",
      boxShadow:
        "0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02)",
    },
  },

  imageContainer: {
    position: "relative",
  },
  btnMoreOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    background: "#02b0f5",

    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
    transition: "opacity 0.25s",
    color: "white",


    "&:hover": {
      opacity: 0.9,
    },
    "&:hover > *": {
      transform: "translateY(0)",
    },
  },
  btnMore: {
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    color: "#ffff",
    border: "1px solid #ffff",
    padding: "10px 20px",
    transform: "translateY(20px)",
    transition: "transform 0.25s",
  },
  newsImage: {
    width: 90,
    height: 60,
    paddingRight: theme.spacing(1),
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  chipLable: {
    backgroundColor: "#02b0f5",

    "& .MuiChip-label": {
      // paddingTop: 4,
      color: "white",

    },
  },
  pageTitlePadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 116,
      paddingTop: 20,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 30,
      paddingTop: 20,
    },
  },

});

class Incidents extends Component {
  constructor(props) {
    super(props);
    this.state = {

      language: englishLanguage,

      windowWidth: null,
      incidents: [],
      pagination: {
        "current_page": 1,
        "first_page_url": "",
        "from": 0,
        "last_page": 0,
        "last_page_url": "",
        "next_page_url": "",
        "path": "",
        "per_page": 5,
        "to": 0,
        "total": 0
      },
      filterData: {
        reference_number: null,
        type: "Corruption Fighter"
      },
      errorTitle: "",
      error: "",
      errorDialog: false

    };

  }

  async loadLanguageDetails() {
    var lng = await languageService.getLanguageDetails();
    // console.log("language",lng);

    if (lng == 'Sinhala') {
      this.setState({
        language: sinhalaLanguage,
      })
    }
    else if (lng == 'Tamil') {
      this.setState({
        language: tamilLanguage,
      })
    }
  }

  async componentDidMount() {

    this.loadLanguageDetails();

    this.loadData(1);

    this.setState({
      windowWidth: window.innerWidth,
    })

    window.addEventListener('resize', () => {
      this.setState({
        windowWidth: window.innerWidth,
      })
    });
  }

  async loadData(page) {
    let filter = this.state.filterData;
    let corruptions = await IncidentService.getPublicIncidentWithFilters(filter, page);
    console.log("stories", corruptions)
    //console.log("pagination",corruptions.data.insidents)
    if (corruptions.status == 200) {
      this.setState({
        incidents: corruptions.data.insidents.data,
        pagination: corruptions.data.insidents,
      })
      console.log("state", this.state);

    } else if (corruptions.status == 205) {
      this.setState({
        incidents: [],
        errorTitle: "Wrong Reference Number",
        error: "You entered reference number is not there please enter correct reference number.",
        errorDialog: true
      })
    } else if (corruptions.status == 206) {
      this.setState({
        incidents: [],
        errorTitle: "Not Published",
        error: "Your incident is under the review. Please try again later.",
        errorDialog: true
      })
    }
  }

  async searchByReference() {
    this.loadData(1);
  }

  getGridListCols() {
    let width = this.state.windowWidth;
    // console.log(width)
    if (width > 1250) {
      return 4;
    } else if (width > 930) {
      return 3;
    } else if (width > 600) {
      return 2;
    }
    else if (width < 600) {
      return 1;
    }

  }


  getGridListCols() {
    let width = this.state.windowWidth;
    // console.log(width)
    if (width > 1250) {
      return 4;
    } else if (width > 930) {
      return 3;
    } else if (width > 600) {
      return 2;
    } else if (width < 600) {
      return 1;
    }
  }

  finishDialogBoxRefClose = () => {
    this.setState({
      errorDialog: false,
    });
  };

  setReferenceNo(val) {
    var filterData = this.state.filterData;
    filterData["reference_number"] = val;
    this.setState({ filterData });
  }


  handleChangePage = async (event, page) => {
    console.log(page);
    // this.loadData(newPage + 1);
    this.loadData(page);
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <Fragment>
        {/* App bar component */}
        <AppAppBar />
        <div
          className={classes.pageTitlePadding}
          style={{
            backgroundImage: `url(/assets/images/statBackground.png)`,
            // backgroundImage: `url(/assets/images/incidents_slide_image.jpg)`,
            // background: "linear-gradient(45deg, #ff0081, #6827b0)",
            width: "100%",
            height: 220,
            backgroundSize: "cover",
            display: "flex",
            // justifyContent: "center",
            float: "left",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            style={{ fontSize: 34, color: "white", fontWeight: 600, }}
          >
            {/* Reports */}
            {this.state.language.incident_appBar}
          </Typography>
        </div>
        <Grid
          container
          spacing={1}
          className={[classes.mainPadding]}
        //   style={{ backgroundColor: "#f5f5f5" }}
        >
          <Grid
            container
            className={["px-2 pb-5 mt-2"]}
            spacing={1}
            justifyContent="center"
          >
            <Grid item xs={12} sm={6} lg={4} className="py-0 ">
              <ValidatorForm onSubmit={() => this.searchByReference()}>
                <Paper className={classes.root}>
                  <InputBase
                    onChange={(e) => {
                      this.setReferenceNo(e.target.value);
                    }}
                    className={classes.input}
                    placeholder={this.state.language.searchbar_placeholder} //"Search by Reference Number"
                    inputProps={{ "aria-label": "Search by Reference Number" }}
                  />
                  <IconButton
                    onClick={() => this.searchByReference()}
                    type="button"
                    className={classes.iconButton}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </ValidatorForm>
            </Grid>
          </Grid>

          {/*........Card Components grid..........*/}
          <Grid
            container
            className={["px-2 py-2"]}
            spacing={1}
            alignItems="center"
            justify="center"
            direction="row"
          >
            {
              this.state.incidents.map((row, key) => (
                <Fade timeout={1000 * key} in={true}>
                  <Grid item style={{ width: 360, minWidth: 360, margin: 10 }}>
                    <Card
                      onClick={() => {
                        window.location.href = "/incidents/" + row.id;
                      }}
                      elevation={3}
                      className={classes.card}
                    >
                      <CardActionArea>
                        <div className={classes.imageContainer}>
                          {/* className={classes.media} */}

                          {
                            row.incident_report == undefined ?

                              <CardMedia
                                className={classes.media}
                                image='/assets/images/default_incident.png'

                              />
                              :
                              row.incident_report.other_details == null || row.incident_report.other_details.attachments == null ?

                                <CardMedia
                                  className={classes.media}
                                  image='/assets/images/default_incident.png'
                                />
                                :
                                row.incident_report.other_details.attachments.length == 1 ?
                                  <CardMedia
                                    className={classes.media}
                                    image={appconst.ATTACHMENTS_LOCATION + JSON.parse(row.incident_report.other_details.attachments)}
                                  />
                                  :
                                  <CardMedia
                                    className={classes.media}
                                    image={appconst.ATTACHMENTS_LOCATION + JSON.parse(row.incident_report.other_details.attachments)[0]}
                                  />
                          }

                          <div className={classes.btnMoreOverlay}>
                            <button
                              onClick={() => {
                                window.location.href = "/incidents/" + row.id;
                              }}
                              className={classes.btnMore}
                            >
                              {/* Read More */}
                              {
                                row.language === "Sinhala" ?
                                  sinhalaLanguage.readMoreButton

                                  :
                                  row.language === "Tamil" ?
                                    tamilLanguage.readMoreButton

                                    :
                                    englishLanguage.readMoreButton
                              }
                            </button>
                            {/* <Button
                            variant="outlined"
                            size="large"
                            onClick={() => {
                              window.location.href = "/incidents/" + row.id;
                            }}
                            className={classes.btnMore}
                          >
                            Read More...
                          </Button> */}
                          </div>
                        </div>
                        <CardContent style={{ padding: "20px 5px" }}>
                          <Typography
                            display="inline"
                            gutterBottom
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {Util.getParsedDate(row.created_at)}
                          </Typography>

                          <Chip

                            className={["ml-3", classes.chipLable]}
                            display="inline"
                            size="small"
                            color="primary"
                            label={
                              row.language === "Sinhala" ?
                                sinhalaLanguage.newsPage_published_Txt

                                :
                                row.language === "Tamil" ?
                                  tamilLanguage.newsPage_published_Txt

                                  :
                                  englishLanguage.newsPage_published_Txt
                            }
                          />
                          <br></br>
                          <div style={{ marginTop: 10 }}>

                            <Typography
                              variant="body2"
                              component="p"
                              style={{ fontWeight: 700, fontSize: 15 }}
                            >
                              {
                                Util.getcurruptionType(row.language, row.incident_report.type_of_corruption)
                              }

                            </Typography>

                            <Typography
                              variant="body2"
                              // color="textSecondary"
                              component="p"
                            >
                              {Util.getDistrict_Language_Label(row.language, row.district)}
                              {/* {row.district} */}

                            </Typography>

                            <Typography
                              variant="body2"
                              // color="textSecondary"
                              component="p"
                            >
                              {row.story != null
                                ? row.story.substring(0, 50).concat("...")
                                : ""}
                            </Typography>
                          </div>
                        </CardContent>

                      </CardActionArea>

                      <div style={{ display: "flex", float: "right" }}>
                        {/* <CardActions> */}

                        {/* removed this style => style={{ padding: 0, paddingLeft: 8 }} */}
                        {/* <Button
                          size="small"
                          onClick={() => {
                            window.location.href = "/incidents/" + row.id;
                          }}
                          color="primary"
                                               
                        >
                          Read More...
                        </Button> */}
                        {/* </CardActions> */}
                      </div>

                    </Card>
                  </Grid>
                </Fade>
              ))}
          </Grid>

          {/* <div className={classes.gridroot}>
                        <GridList cellHeight={'auto'} className={classes.gridList} cols={this.getGridListCols()}>
                            {this.state.incidents.map((row, key) => (
                                <Fade timeout={1000 * key} in={true}>
                                    <GridListTile key={row.key} cols={this.getGridListCols()} >
                                        <Card onClick={()=>{ window.location.href = "/incidents/"+row.id }} elevation={3} className={["mx-3 my-3"]}>
                                            <CardActionArea>

                                                {row.incident_report == undefined ? <CardMedia
                                                    className={classes.media}
                                                    image='/assets/images/default_incident.png'

                                                /> : row.incident_report.other_details == null ?
                                                    <CardMedia
                                                        className={classes.media}
                                                        image='/assets/images/default_incident.png'

                                                    />
                                                    :
                                                    <CardMedia
                                                        className={classes.media}
                                                        image={appconst.ATTACHMENTS_LOCATION + JSON.parse(row.incident_report.other_details.attachments)[0]}

                                                    />
                                                }
                                                <CardContent>


                                                    <Typography display="inline" gutterBottom variant="body2" color="textSecondary" component="p">
                                                        {Util.getParsedDate(row.created_at)}

                                                    </Typography>

                                                    <Chip className="ml-3" display="inline" size="small" label="Published" />
                                                    <Typography variant="body2" component="p">

                                                        {row.incident_report == undefined ? "Share Story" :
                                                            Util.getcurruptionType(row.incident_report.type_of_corruption)}, {row.district}
                                                    </Typography>

                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                        {row.story != null ? (row.story).substring(0, 50).concat('...') : ""}
                                                    </Typography>

                                                </CardContent>

                                            </CardActionArea>
                                            <CardActions style={{ padding: 0, paddingLeft: 8 }}>
                                                <Button onClick={()=>{ window.location.href = "/incidents/"+row.id }} color="primary">Read More...</Button>
                                            </CardActions>

                                        </Card>
                                    </GridListTile>
                                </Fade>
                            ))}

                        </GridList>
                    </div> */}

          <Grid container className="p-8" justify="flex-end">
            <Pagination
              count={Number(this.state.pagination.last_page)}
              rowsPerPage={Number(this.state.pagination.per_page)}
              page={this.state.pagination.current_page}
              onChange={this.handleChangePage}
              variant="outlined"
              color="primary"
            />
          </Grid>
        </Grid>

        <Dialog
          open={this.state.errorDialog}
          onClose={() => {
            this.finishDialogBoxRefClose();
          }}
        >
          <DialogContent>
            <Grid item xs={12} sm={6}></Grid>
            <Alert severity="error">
              <strong>{this.state.errorTitle}</strong>
              <p>{this.state.error}</p>
            </Alert>
          </DialogContent>

          <DialogActions></DialogActions>
        </Dialog>

        {/* Footer */}
        <Footer />
      </Fragment>
    );
  }
}

export default withStyles(styleSheet)(Incidents);
