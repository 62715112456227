import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {
    AppBar, Toolbar, Typography, List, ListItem, Link,
    Grid, SwipeableDrawer, Avatar, Icon, IconButton, MenuItem, Button,
    GridList, GridListTile, GridListTileBar, Divider, ListItemText
} from '@material-ui/core';
import { SimpleCard, MatxProgressBar } from "matx";
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Footer from './components/Footer';
import AppAppBar from './components/AppAppBar';

import { withStyles } from "@material-ui/core/styles";

const styleSheet = ((theme) => ({
    gridList: {
        height: 275,
    },

    mainPadding: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 50,
            paddingRight: 50
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: 20,
            paddingRight: 20
        },
    },
    card: {

        [theme.breakpoints.up('md')]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
    },
    newsImage: {
        width: 90,
        height: 60,
        paddingRight: theme.spacing(1),
    },
    newsHrLine: {
        backgroundColor: '#0074e1',
        height: 3,
    },
    listItem: {
        borderBottomColor: "#dbdcdc",
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
    }
}));


class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }




    render() {
        const { classes } = this.props
        const preventDefault = (event) => event.preventDefault();
        return (
            <Fragment>
                {/* App bar component */}
                <AppAppBar />

                <div className="pt-15" style={{ backgroundImage: `url(/assets/images/statBackground.png)`, width: '100%', height: 250, backgroundSize: 'cover', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h1" component="h2" gutterBottom style={{ fontSize: 45, color: 'white', fontWeight: 600 }}>
                        Contact Us
                            </Typography>
                </div>

                <Fade in={true}>
                    <div>
                        <Grid item xs={12} sm={12} className="py-0 ">
                            <div className="mt-0 pt-2" style={{ width: '100%', backgroundColor: "#464646", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant="h5" component="h2" gutterBottom style={{ color: 'white', fontWeight: 500 }}>
                                    Hotline: 0763223442
                            </Typography>
                            </div>
                        </Grid>
                        <Grid container spacing={1} className={classes.mainPadding} style={{ backgroundColor: 'white' }}>


                            <Grid item xs={12} sm={4} className="py-5 ">
                                <div className={classes.card}>
                                    <div style={{ width: 'fit-content' }}>
                                        <Typography variant="h6" component="h6" gutterBottom style={{ fontWeight: 500 }}>
                                            Colombo Office
                                    </Typography>
                                        <Typography variant="p" component="h6" gutterBottom style={{ fontWeight: 500 }}>
                                            5/1, Elibank Road, Colombo 05
                                    </Typography>
                                        <div className="mt-5">
                                            <Typography display="inline" variant="p" component="h6" gutterBottom style={{ fontWeight: 800 }}>
                                                Phone:
                                    </Typography>

                                            <Typography display="inline" variant="p" component="h6" gutterBottom style={{ fontWeight: 500 }}>
                                                (+94) 0114369781 | 0114369782 | 0114369783
                                    </Typography>
                                        </div>

                                        <div className="mt-0">
                                            <Typography display="inline" variant="p" component="h6" gutterBottom style={{ fontWeight: 800 }}>
                                                Fax:
                                    </Typography>

                                            <Typography display="inline" variant="p" component="h6" gutterBottom style={{ fontWeight: 500 }}>
                                                (+94) 011 2 501 707
                                    </Typography>
                                        </div>

                                        <div className="mt-5">
                                            <Typography display="inline" variant="p" component="h6" gutterBottom style={{ fontWeight: 800 }}>
                                                Email:
                                    </Typography>

                                            <Typography display="inline" variant="p" component="h6" gutterBottom style={{ fontWeight: 500 }}>
                                                tisl@tisrilanka.org
                                    </Typography>
                                        </div>
                                        <div className="mt-0">
                                            <Typography display="inline" variant="p" component="h6" gutterBottom style={{ fontWeight: 800 }}>
                                                Web:
                                    </Typography>

                                            <Typography display="inline" variant="p" component="h6" gutterBottom style={{ fontWeight: 500 }}>
                                                <Link href="https://www.tisrilanka.org" >
                                                    www.tisrilanka.org
                                        </Link>
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </Grid>




                            <Grid item xs={12} sm={4} className="py-5 ">
                                <div className={classes.card}>
                                    <div style={{ width: 'fit-content' }}>
                                        <Typography variant="h6" component="h6" gutterBottom style={{ fontWeight: 500 }}>
                                            Matara Office
                                    </Typography>
                                        <Typography variant="p" component="h6" gutterBottom style={{ fontWeight: 500 }}>
                                            17, Kalidasa Road, Matara
                                    </Typography>
                                        <div className="mt-5">
                                            <Typography display="inline" variant="p" component="h6" gutterBottom style={{ fontWeight: 800 }}>
                                                Phone:
                                    </Typography>

                                            <Typography display="inline" variant="p" component="h6" gutterBottom style={{ fontWeight: 500 }}>
                                                (+94) 041 22 33 522
                                    </Typography>
                                        </div>



                                        <div className="mt-0">
                                            <Typography display="inline" variant="p" component="h6" gutterBottom style={{ fontWeight: 800 }}>
                                                Email:
                                    </Typography>

                                            <Typography display="inline" variant="p" component="h6" gutterBottom style={{ fontWeight: 500 }}>
                                                alac-matara@tisrilanka.org
                                    </Typography>
                                        </div>
                                    </div>
                                </div>

                            </Grid>




                            <Grid item xs={12} sm={4} className="py-5 ">
                                <div className={classes.card}>
                                    <div style={{ width: 'fit-content' }}>
                                        <Typography variant="h6" component="h6" gutterBottom style={{ fontWeight: 500 }}>
                                            Vavuniya Office
                                    </Typography>
                                        <Typography variant="p" component="h6" gutterBottom style={{ fontWeight: 500 }}>
                                            SLRCS Building , Off Park Road , Vavuniya
                                    </Typography>
                                        <div className="mt-5">
                                            <Typography display="inline" variant="p" component="h6" gutterBottom style={{ fontWeight: 800 }}>
                                                Phone:
                                    </Typography>

                                            <Typography display="inline" variant="p" component="h6" gutterBottom style={{ fontWeight: 500 }}>
                                                (+94) 024 222 76 76
                                    </Typography>
                                        </div>



                                        <div className="mt-0">
                                            <Typography display="inline" variant="p" component="h6" gutterBottom style={{ fontWeight: 800 }}>
                                                Email:
                                    </Typography>

                                            <Typography display="inline" variant="p" component="h6" gutterBottom style={{ fontWeight: 500 }}>
                                                alac-vavuniya@tisrilanka.org
                                    </Typography>
                                        </div>
                                    </div>
                                </div>

                            </Grid>


                        </Grid>

                        <hr />

                        <Grid container spacing={1} className={classes.mainPadding} style={{ backgroundColor: 'white', textAlign: 'center', justifyContent: 'center' }}>
                            <Slide in={true} direction="left">
                                <div className="mt-10 pt-2" style={{ textAlign: 'center' }}>
                                    <Typography  variant="p" component="h6" gutterBottom style={{ fontWeight: 800 }}>
                                        Below are the links to other sites related to TISL which need to go on the site.
                            </Typography>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={4} className="py-5 ">
                                            <Link href="https://www.rtiwatch.lk" >
                                                <img src="/assets/images/rtiwatch.png" style={{ height: 40 }} />
                                            </Link>
                                        </Grid>
                                        <Grid item xs={12} sm={4} className="py-5 ">
                                            <Link href="http://www.integrityicon.lk" >
                                                <img src="/assets/images/intergrity.png" style={{ height: 40 }} />
                                            </Link>
                                        </Grid>
                                        <Grid item xs={12} sm={4} className="py-5 ">
                                            <Link href="https://www.peps.lk/" >
                                                <img src="/assets/images/peplogo.png" style={{ height: 40 }} />
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    <div className="mt-5"></div>

                                    <Typography  variant="p" component="h6" gutterBottom style={{ fontWeight: 800 }}>
                                        Follow us:
                            </Typography>
                            <Grid container spacing={2} className="py-5" style={{justifyContent:'center'}} >
                                            <Link className="pl-2" href="https://www.facebook.com/tisrilanka" >
                                                <img src="/assets/images/fb.png" style={{ width: 40, height: 40 }} />
                                            </Link>

                                            <Link className="pl-2" href="https://twitter.com/tisrilanka" >
                                                <img src="/assets/images/twiter.png" style={{ width: 40, height: 40 }} />
                                            </Link>

                                            <Link className="pl-2" href="https://www.instagram.com/transparency_sri_lanka/" >
                                                <img src="/assets/images/instagram.png" style={{ width: 40, height: 40 }} />
                                            </Link>

                                            <Link className="pl-2" href="https://www.linkedin.com/company/tisrilanka/" >
                                                <img src="/assets/images/linkin.png" style={{ width: 40, height: 40 }} />
                                            </Link>
                                        </Grid>

                                </div>
                            </Slide>
                        </Grid>


                       
                    </div>
                </Fade>
                {/* Footer */}
                <Footer />
            </Fragment>
        );
    }
}





export default withStyles(styleSheet)(ContactUs);