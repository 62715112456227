import React, { Component, Fragment, } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import GoogleMapReact from "google-map-react";
import { Autocomplete, Alert, AlertTitle } from "@material-ui/lab";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import LocationPicker from 'react-location-picker';
import AppAppBar from "../AppAppBar";
import Util from "app/services/Util";
import axios from 'axios';

import {
  StepLabel,
  Stepper,
  Step,
  Button,
  Typography,
  StepConnector,
  Checkbox,
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Snackbar,
  Tooltip,
  Card,
  CardMedia,
  CardActions,
  IconButton,
  Box,
  CircularProgress,

} from "@material-ui/core";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AppsIcon from "@material-ui/icons/Apps";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import PanToolIcon from "@material-ui/icons/PanTool";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import LinearProgress from '@material-ui/core/LinearProgress';

import { compose, withProps } from "recompose";
import Geocode from "react-geocode";
import IncidentService from "../../../../services/IncidentServices";

import { englishLanguage } from "../../../languages/englishLanguage";
import { sinhalaLanguage } from "../../../languages/sinhalaLanguage";
import { tamilLanguage } from "../../../languages/tamilLanguage";

import languageService from "app/services/languageService";
import * as appconst from "../../../../appconst";

import {
  storyWordLimit, ageRanges,
  incident_report_theme,
  incident_report_theme_dark,
}
  from "../../../../appVariables";

// import { GoogleMapsAPI } from './';
//Geocode.setApiKey("AIzaSyCL6g9mcTszQRG_7fMOLTBvBCzYlD71ai4");
//Geocode.enableDebug();

//stepper line styles
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 20,
  },
  active: {
    "& $line": {
      backgroundColor: incident_report_theme, //"#FB3640",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: incident_report_theme_dark, //"#CC0D17",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#EAEAF0",
    borderRadius: 1,
  },
})(StepConnector);

//stepper icon styles
const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: incident_report_theme, //"#FB3640",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: incident_report_theme_dark, //"#CC0D17",
  },
});

//All styles
const styles = (theme) => ({
  root: {
    // marginTop: 80,
    paddingLeft: 100,
    paddingRight: 100,
    paddingTop: 30,
    paddingBottom: 30,
  },
  mainPadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 100,
      paddingRight: 100,
      paddingTop: 30,
      paddingBottom: 30,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 30,
      paddingBottom: 30,
    },
  },

  heading: {
    marginBottom: 10,
  },

  button: {
    margin: theme.spacing(1),
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  // Report form styles
  formStyles_root: {
    // padding: theme.spacing(3),
    paddingTop: 0,
    width: "100%",
    // margin: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginTop: 45,
    },
  },
  formStyles_girdItem: {
    marginBottom: theme.spacing(2),
  },

  //Select category Styles
  categorySelectionPaper: {
    borderRadius: 10,
    width: 150,
    height: 170,
    // border: '2px solid #747373',
    margin: 10,
    padding: 5,
    textAlign: "center",
    // overflow: "hidden",
    cursor: "pointer",
    boxShadow:
      " 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",
    transition: "all ease 200ms",

    "&:hover": {
      filter: "brightness(1)",
      transform: "scale(1.05)",
      boxShadow:
        "0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02)",
    },
    // '&:hover':{
    //     backgroundColor: '#f2f2f2',
    // }
  },
  categorySelectionImage: {
    // borderRadius: '50%',
    marginBottom: 10,
    marginTop: 10,
    width: 80,
    height: 80,
  },
  categorySelectionCardDescription: {

    fontSize: 12,
    // width: 120,
    // height: 80,
    textAlign: "center",
  },

  //TextField styles
  textStyles: {
    "& label": {
      color: "#25282c",
      fontSize: 15,
      fontFamily: "Source Sans Pro",
      fontWeight: 400,

      "& hover": {
        fontWeight: "bold",
      },
    },
    "& label.Mui-focused": {
      color: "#25282c",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#25282c",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: 'red',
      },
      "&:hover fieldset": {
        // borderColor: 'yellow',
      },
      "&.Mui-focused fieldset": {
        borderColor: "#25282c",
      },
    },
  },

  //share Button
  btnNext: {
    // padding: "7px 10px",
    color: "white",
    backgroundColor: incident_report_theme, //"#FB3640",
    transition: "all ease 200ms",
    "&:hover": {
      color: "white",
      backgroundColor: incident_report_theme_dark, //"#CC0D17",
      transform: "scale(1.02)",
    },
    marginTop: 10,
  },
  btnAlign: {
    marginTop: 20,
    display: "grid",
    float: "right",
    width: "15%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",

    },
  },
  radioButton: {
    "& .MuiRadio-colorPrimary": {
      color: incident_report_theme, //"#FB3640",
    },
  },
  checkboxStyle: {
    "& .MuiCheckbox-colorPrimary": {
      color: incident_report_theme, //"#FB3640",
    },
  },
});

//assign stepper icons
function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <PanToolIcon />,
    2: <AppsIcon />,
    3: <InfoIcon />,
    4: <CheckCircleIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  //step is active
  active: PropTypes.bool,

  //mark step as completed
  completed: PropTypes.bool,

  //label displayed in the step icon
  icon: PropTypes.node,
};

//linear progress bar
function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(props.value,)}%`}</Typography>
      </Box>
    </Box>
  );
}



class ReportIncident extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submittingForm: false,
      //stepper / set active state(active step = Stepper Step + 1)
      activeStep: 0,
      setActiveStep: 0,
      steps: englishLanguage.corruptionReport_stepperSteps,
      stepperHidden: false,

      //word limit
      countWords: storyWordLimit,

      //date time
      incident_date: "",
      incident_time: "",

      //id returned by database
      incident_id: null,

      // map
      mapPosition: {
        lat: 6.927,
        lng: 79.861,
      },
      //select category(step 2)
      category_select_activeNow: null,

      //select radio btn step 1
      askToContact: "no",

      //className
      styleClassName: true,

      //Dialog Box
      instruction: "",
      message: null,
      refNumber: null,
      //select category(step 2)
      category_select_activeNow: null,

      //select radio btn step 1
      askToContact: "no",

      //upload image
      image_urls: [],
      uploadedFileSize: 0,
      noOfFiles: 0,

      //Upload video
      video_urls: [],
      noOfVideoFiles: 0,
      uploadedVideoFileSize: 0,
      loadingProgress: 0,

      attachmentUploading: true,
      attachmentUploadingError: null,
      haveUploadAttachments: false,

      //className
      styleClassName: true,

      //form data
      formdata: {
        title: null,
        reported_language: 'English',
        public_authority_involved: true,
        name_of_authority: null,
        designation_of_authority: null,
        divisional_secretariat: null,
        district: null,
        incident_date_time: null,
        story: null,
        anonymous: false,
        name: null,
        contact_number: null,
        email: null,
        agreement: true,
        type: "Corruption Fighter",
        age: '',
        gender: null,
        address: null,
        type_of_corruption: null,
        latitude: 6.927,
        longitude: 79.861,
        incident_status: null,
        ask_to_contact: "No",

        corruption_details: {
          you_are: null,
          bribe_form: null,
          monetary_value: null,
          type_of_property: null,
          benefit: null,
          private_interest: null,
          nature_of_interest: null,
          involved: null,
          disclose_interest: null,
          authorized_participate: null,
          type_of_procurement: null,
          misused: null,
          nature_of_misuse: null,
          political_party: null,
          other_details: null,
          attachments: [],
          videoAttachments: [],
        }
      },

      //language
      language: englishLanguage,
      languageName: "English",

      //genders
      genderSelection: englishLanguage.lng_genders,

      //step 1 dialog box radio selections
      reportDetailsDialogSelection:
        englishLanguage.corruptionReport_nextDialogBoxSelection,

      //step 2 categories
      categorySelection: englishLanguage.lng_corruption_types,

      //step 3 districts
      districtsTypes: englishLanguage.lng_Districts,

      //step 3 public authorities
      public_authority_type: englishLanguage.lng_publicAuthorities,

      //step 3 reported incident types
      reportIncidentTypes: englishLanguage.lng_incidentTypes,

      //step 3 divisional secretariats
      divisionalSecretariats: [],
      divisionalSecretariatsAll:
        englishLanguage.lng_report_divisional_secretariats,

      //step 4 - is the peron reporting types
      reportingPersonTypes: englishLanguage.lng_ReportingPersonTypes,

      //step 4 - bribe types
      briberyType: englishLanguage.lng_Corruption_Bribe_Types,
      briberyTypeSelectionLabel: null,

      //step 4 - misused property types
      propertyTypes: englishLanguage.lng_misused_PropertyTypes,

      //step 4 - conflict of interest answer types
      conflictOfInterestTypes:
        englishLanguage.lng_conflictOFInterest_AnswerTypes,

      //step 4 - conflict of Nature of interest types
      natureOfInterest_Types:
        englishLanguage.lng_conflictOFInterest_NatureOfInterestTypes,

      //step 4 - procurement types
      procurement_irregularityType: englishLanguage.lng_procurementTypes,

      //snackbar
      snackbar: false,

      severity: null,

    };

    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  // uploading image files
  uploadFiles(data) {
    var bodyFormData = new FormData();

    for (let size = 0; size < data['corruption_details']['attachments'].length; size++) {
      bodyFormData.append('attachments[' + size + ']', data['corruption_details']['attachments'][size]);
    }

    bodyFormData.append('incident_id', this.state.incident_id);

    IncidentService.uploadFiles(bodyFormData).then(res => {
      console.log("response", res)
      if (res.status == 200) {
        this.setState({ message: res.data.message, snackbar: true, severity: "success", submittingForm: false })
        // setTimeout(() => {
        // }, 3000);
      } else {
        this.setState({ message: res.data.message, snackbar: true, severity: "error", submittingForm: false })
      }
    })
      .catch((error) => {
        console.log("error in uploading corruption files:", error);
      })

  }


  // uploading video files
  uploadVideoFiles(data) {

    var bodyFormData = new FormData();

    for (let size = 0; size < data['corruption_details']['videoAttachments'].length; size++) {
      bodyFormData.append('otherFiles[' + size + ']', data['corruption_details']['videoAttachments'][size]);
    }

    bodyFormData.append('incident_id', this.state.incident_id);
    bodyFormData.append('incident_reference_number', this.state.refNumber);

    //progress function
    const configProgress = {

      onUploadProgress: progressEvent => {
        console.log('PRogress..................')
        console.log(progressEvent)

        let loaded = progressEvent.loaded
        let total = progressEvent.total
        let loadedPercentage = Math.floor((loaded * 100) / total)

        console.log(loadedPercentage)

        if (loadedPercentage < 80) {
          this.setState({
            loadingProgress: loadedPercentage,
          })
        }
        else {
          this.setState({
            loadingProgress: 80,
          })
        }

      }

    }

    axios.post(appconst.UPLOAD_OTHER_FILES, bodyFormData, configProgress)
      .then(res => {
        console.log("response", res);
        if (res.status == 200) {
          // this.setState({ message: res.data.message, snackbar: true, severity: "success" })
          setTimeout(() => {
            this.setState({
              message: res.data.message,
              snackbar: true,
              severity: "success",
              loadingProgress: 100,
              attachmentUploading: false,
              submittingForm: false
            })
          }, 1000)
        } else {
          this.setState({
            message: res.data.message,
            snackbar: true,
            severity: "error",
            attachmentUploadingError: "Error in uploading corruption video files",
            attachmentUploading: false,
            submittingForm: false
          })
        }
      })
      .catch((error) => {
        console.log("error in uploading corruption files:", error);
        this.setState({
          attachmentUploading: false,
          attachmentUploadingError: "Error in uploading corruption video files",
          submittingForm: false
        })
      })

  }

  //form Submission
  formSubmit = async (event) => {

    // if(this.state.askToContact != "yes"){
    // event.preventDefault();
    // }
    this.setState({ submittingForm: true })

    console.log("Form Data: ", this.state.formdata);
    var data = this.state.formdata;
    var dialogBoxText = null;
    var dialogTimeout = 0;

    if (this.state.askToContact == "yes") {
      dialogBoxText = "step1_successMag_dialogBox";
      dialogTimeout = 4500;
      data['ask_to_contact'] = "Yes"
      this.setState({
        formdata: data
      })

    } else {
      dialogBoxText = "step4_successMag_dialogBox";
      dialogTimeout = 0;
    }

    /*  let res = await IncidentService.createIncident(this.state.formdata)
 
     if (res.status == 200) {
       this.setState({
         message: res.data.message,
         severity: "success",
         snackbar: true,
         instruction: dialogBoxText,
         refNumber: res.data.reference_number,
         incident_id: res.data.incident_id,
         //submittingForm:false
       },
         // ) 
         () => {
 
           //upload image file
           if (this.state.formdata.corruption_details.attachments.length > 0) {
             this.uploadFiles(data);
             this.setState({
               haveUploadAttachments: true,
             })
           }
 
           //upload video files
           if (this.state.formdata.corruption_details.videoAttachments.length > 0) {
             this.uploadVideoFiles(data);
             this.setState({
               haveUploadAttachments: true,
             })
           }
           else {
             this.setState({
               attachmentUploading: false,
               submittingForm: false
             })
           }
 
         })
 
     } else {
       this.setState({ message: res.data.message, snackbar: true, severity: "error", submittingForm: false })
     } */


    IncidentService.createIncident(this.state.formdata).then(res => {
      console.log("response", res.response)
      console.log("response 2", res.response.data.message)
      if (res.status == 200) {
        this.setState({
          message: res.data.message,
          severity: "success",
          snackbar: true,
          instruction: dialogBoxText,
          refNumber: res.data.reference_number,
          incident_id: res.data.incident_id,
          //submittingForm:false
        },
          // ) 
          () => {

            //upload image file
            if (this.state.formdata.corruption_details.attachments.length > 0) {
              this.uploadFiles(data);
              this.setState({
                haveUploadAttachments: true,
              })
            }

            //upload video files
            if (this.state.formdata.corruption_details.videoAttachments.length > 0) {
              this.uploadVideoFiles(data);
              this.setState({
                haveUploadAttachments: true,
              })
            }
            else {
              this.setState({
                attachmentUploading: false,
                submittingForm: false
              })
            }

          })


      } else {
        this.setState({ message: res.data.message || "Incident Report Unsuccessful.Please Try Again!", snackbar: true, severity: "error", submittingForm: false })
      }

    })
      .catch((error) => {
        this.setState({ submittingForm: false })
        this.setState({ message: "Incident Report Unsuccessful.Please Try Again!", snackbar: true, severity: "error", submittingForm: false })

        console.log("Error in corruption form:", error);
      })

  }

  //Last dialog box
  finishDialogBoxRefClose = () => {
    this.setState({
      instruction: '',
    });
    window.location.href = '/home';
  }

  //reload after adding
  // finishDialogBoxRefClose = () => {

  //     if(this.instruction != ''){
  //         this.setState({
  //             instruction: '',
  //         });
  //     }
  //     window.location.reload(false);
  // }

  //snackbar
  closeSnackBar = (event, response) => {
    if (response === "clickaway") {
      return;
    }
    this.setState({
      snackbar: false,
    })
  }

  //stepper functions
  handleNext = () => {
    this.setState({ activeStep: (this.state.activeStep + 1) })
  }
  handleBack = (val) => {

    if (val == 'resetStep3Details') {
      var data = this.state.formdata;

      data['type_of_corruption'] = null;

      data['latitude'] = null;
      data['longitude'] = null;
      data['public_authority_involved'] = true;
      data['district'] = null;
      data['incident_date_time'] = null;
      data['designation_of_authority'] = null;
      data['name_of_authority'] = null;
      data['divisional_secretariat'] = null;
      data['incident_status'] = null;

      this.setState({
        formdata: data,
        incident_date: '',
        incident_time: '',
        mapPosition: {
          lat: 6.927,
          lng: 79.861,
        }
      });
      console.log(this.state.formdata);
    }
    else if (val == "resetStep2Details") {

      var data = this.state.formdata;
      data['type_of_corruption'] = null;
      data['name'] = null;
      data['email'] = null;
      data['age'] = null;
      data['gender'] = null;
      data['address'] = null;
      data['contact_number'] = null;
      data['anonymous'] = false;

      this.setState({
        formdata: data,
        askToContact: "no",
      });

    }

    //stepper step back
    this.setState({ activeStep: (this.state.activeStep - 1) })
  }

  handleReset = () => {
    this.setState({ activeStep: 0 })
  }

  //select category (step 2)
  selectCategory(val) {
    // console.log(name);
    var data = this.state.formdata;
    data['type_of_corruption'] = val;
    this.setState({
      formdata: data,
    });

    this.handleNext();
  }

  handleChange = async (event) => {
    event.persist();
    var data = this.state.formdata;

    if (event.target.name == 'checkbox_Anonymous' || event.target.name == 'checkbox_NotAnonymous') {
      var is_anonymous = false;

      //anonymous
      if (event.target.name == 'checkbox_Anonymous') {

        // console.log(event.target.name, event.target.value);

        if (event.target.value == "false") {
          is_anonymous = true;
          // console.log(event.target.name, event.target.value);
        } else {
          is_anonymous = false;
        }

        //not anonymous
      } else {

        if (event.target.value == "true") {
          is_anonymous = false;
        } else {
          is_anonymous = true;
        }
        // console.log(event.target.name, event.target.value);
      }

      //set correct anonymous state
      data['anonymous'] = is_anonymous;

      //Prevent switch between forms with different data to same state
      if (is_anonymous) {
        data['name'] = null;
        data['email'] = null;
        data['age'] = null;
        data['gender'] = null;
        data['address'] = null;
        data['contact_number'] = null;

        this.setState({
          instruction: "step1_Anonymousform_dialog",
          askToContact: "no",
        });

      }
      else {
        data['age'] = null;
        data['gender'] = null;
      }

      this.setState({
        formdata: data
      });

    }

    else {
      let eventName = event.target.name;
      let eventValue = event.target.value;

      if (eventValue == "true" || eventValue == "false") {
        data[eventName] = (eventValue == "true" ? true : false);
      }
      else {
        data[eventName] = eventValue;
      }

      this.setState({
        formdata: data,
      });

      console.log(this.state.formdata);
    }

  }

  handleCorruptionChange = (event) => {
    event.persist();
    var data = this.state.formdata;

    let eventName = event.target.name;
    let eventValue = event.target.value;

    if (eventValue == "true" || eventValue == "false") {
      data.corruption_details[eventName] = (eventValue == "true" ? true : false);
    }
    else if (eventName === "other_details") {
      data.corruption_details[eventName] = eventValue;

      var finalCount = storyWordLimit;

      if (this.state.formdata.corruption_details.other_details != null) {
        var count = this.state.formdata.corruption_details.other_details.length;
        finalCount = storyWordLimit - count;
      }

      this.setState({
        formdata: data,
        countWords: finalCount,
      });
    }
    else {
      data.corruption_details[eventName] = eventValue;
    }

    this.setState({
      formdata: data
    });

    console.log(this.state.formdata);

  }

  //change date time
  onChangeTime = (e) => {

    var data = this.state.formdata;
    var eName = e.target.name;
    var eVal = e.target.value;
    var incidentStatus = this.state.formdata.incident_status;

    var resSnackbar = false;
    var resMessage = null;

    var resDate = this.state.incident_date;
    var resTime = this.state.incident_time;

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    console.log(today);

    var date1 = Date.parse(today);
    var date2 = Date.parse(eVal);


    if (eName == "incident_time") {
      resTime = eVal;
    }
    else {

      if (incidentStatus === "Has Happened") {

        if (date1 >= date2) {
          resDate = eVal;
        }
        else {
          resSnackbar = true;
          resMessage = "Please Select Valid Date";
        }

      }
      else if (incidentStatus === "Is Happening") {

        if (date1 === date2) {
          resDate = eVal;
        }
        else {
          resSnackbar = true;
          resMessage = "Please Select Valid Date";
        }

      }
      else if (incidentStatus === "Will Happen in the future") {

        if (date1 <= date2) {
          resDate = eVal;
        }
        else {
          resSnackbar = true;
          resMessage = "Please Select Valid Date";
        }
      }
      else {
        resDate = '';
        resSnackbar = true;
        resMessage = "Please Select Incident State Before Select Date";

      }

    }

    data['incident_date_time'] = resDate + " " + resTime;

    this.setState({
      formdata: data,
      incident_date: resDate,
      incident_time: resTime,
      snackbar: resSnackbar,
      message: resMessage,
      severity: "error",
    });

    console.log(this.state);

  }

  //set divisional secretariat array
  setDivisionalSecretariats(selectedDis) {

    var mainArray = this.state.divisionalSecretariatsAll;
    var sec_arr = [];

    for (var i = 0; i < 25; i++) {

      var dis = mainArray[i].district;

      if (dis === selectedDis) {
        sec_arr = mainArray[i].divisional_secretariats;
      }
    }

    console.log(sec_arr);

    this.setState({
      divisionalSecretariats: sec_arr,
    })

  }

  //set values from selections (Autocomplete)
  setSelectedValue(key, value) {
    console.log(key, value);

    var data = this.state.formdata;
    var latValue, lngValue;
    var foundDistrict = false;

    // map
    if (key == 'district') {

      for (let i = 0; i <= 25; i++) {

        if (this.state.districtsTypes[i].value == value) {
          latValue = this.state.districtsTypes[i].lat;
          lngValue = this.state.districtsTypes[i].lng;
          foundDistrict = true;
          // console.log(latValue, lngValue);
        }
      }

      if (foundDistrict) {
        data['district'] = value;
        data['latitude'] = latValue;
        data['longitude'] = lngValue;

        this.setState({
          formdata: data,
          mapPosition: {
            lat: latValue,
            lng: lngValue,
          }
        });
      }

      //set the value for the divisional secretariats (lng_report_divisional_secretariats)
      this.setDivisionalSecretariats(value);

    }
    else if (key == 'incident_status') {

      data['incident_status'] = value;
      data['incident_date_time'] = null;

      this.setState({
        formdata: data,
        incident_time: '',
        incident_date: '',
      });
    }
    else {
      data[key] = value;

      this.setState({
        formdata: data
      });
    }

    console.log(this.state.formdata);

  }

  setSelectedCorruptionValue(key, value) {
    console.log(key, value);
    var data = this.state.formdata;

    data.corruption_details[key] = value;

    this.setState({
      formdata: data
    });
    // console.log(this.state.formdata);
  }

  setSelectedCorruptionValueBribery(key, valLabel) {
    console.log("Key and Value:", key, valLabel);
    var type = typeof (valLabel);
    var actVal = null;
    var arr = this.state.briberyType;

    if (valLabel != null) {
      actVal = this.getActualValue(arr, valLabel);
    }

    var data = this.state.formdata;
    data.corruption_details[key] = actVal;

    this.setState({
      formdata: data,
      briberyTypeSelectionLabel: valLabel,
    });
    console.log(this.state);
  }

  //get label from language array
  getActualValue(arr, labelVal) {

    console.log("getActualValue called", arr, labelVal);
    var actVal = null;
    var len = arr.length;

    for (var i = 0; i < len; i++) {
      if (arr[i].label == labelVal) {
        actVal = arr[i].value;
      }
    }

    console.log(actVal);
    return actVal;
  }

  //dialog
  dialogBoxRefOpen = (name) => {

    this.setState({
      instruction: name,
    });
  }
  dialogBoxRefClose = () => {
    this.setState({
      instruction: '',
    });
  }

  //File choose action
  handlefileUpload = event => {
    event.persist();

    var data = this.state.formdata;
    var imageURLS = [];
    var imageFiles = [];
    var totalFileSize = 0;
    var totalFiles = 0;
    var alreadyUploadedFiles = this.state.formdata.corruption_details.attachments;
    var alreadyUploadedNoOFFiles = alreadyUploadedFiles.length;
    var alreadyUploadedTotalFileSize = this.state.uploadedFileSize;


    if (alreadyUploadedNoOFFiles > 0) {
      imageFiles = alreadyUploadedFiles;
      imageURLS = this.state.image_urls;
    }

    if (event.target.files[0] !== undefined) {

      totalFiles = alreadyUploadedNoOFFiles + event.target.files.length;

      if (totalFiles < 6) {

        for (let j = 0; j < event.target.files.length; j++) {

          let file = event.target.files[j];
          totalFileSize = alreadyUploadedTotalFileSize + file.size;

          if (totalFileSize > 5000000) {
            this.setState({
              message: "Total File Size Must Be Less Than 5MB",
              severity: "error",
              snackbar: true,
            });
            break;
          }
          else {

            // console.log('Selected file:', file);

            // if(file.size > 1000000){
            //     this.setState({
            //         message: "For each file size must be less than 1MB.",
            //         severity: "error",
            //         snackbar: true,
            //     });
            //     break;
            // }
            // else 

            if (file.type == "image/jpeg" || file.type == "image/png") {

              alreadyUploadedTotalFileSize = totalFileSize;

              //formdata array
              imageFiles.push(file);

              //add local URL to preview
              imageURLS.push(URL.createObjectURL(file));
              // console.log(file);

            }
            else {
              this.setState({
                message: "You can only upload Images(JPEG,PNG)",
                severity: "error",
                snackbar: true,
              });
            }

          }

        }
      }
      else {
        this.setState({
          message: "Can't Upload More Than 5 Files.",
          severity: "error",
          snackbar: true,
        })
      }

      //No of files
    }

    //set to from data
    data['corruption_details']['attachments'] = imageFiles;

    this.setState({
      image_urls: imageURLS,
      noOfFiles: imageFiles.length,
      formdata: data,
      uploadedFileSize: alreadyUploadedTotalFileSize,
    })
    console.log(this.state);
  };

  removeUploadSelectedItem = (val) => {

    // console.log("object:",val);
    var arr = this.state.image_urls;
    var data = this.state.formdata;
    var attach = this.state.formdata.corruption_details.attachments;
    var index = -1;

    for (let j = 0; j < arr.length; j++) {
      if (arr[j] === val) {
        index = j;
      }
    }

    if (index > -1) {

      var noFiles = this.state.noOfFiles - 1;
      var resTotalSize = 0;

      arr.splice(index, 1);
      // console.log(arr);
      // this.state.image_urls = arr;

      //remove from attachments
      attach.splice(index, 1);

      if (noFiles == 0) {
        document.getElementById("contained-button-file").value = "";
        data['corruption_details']['attachments'] = [];
        this.setState({
          noOfFiles: 0,
          image_urls: [],
          formdata: data,
          uploadedFileSize: 0,
        });
      }
      else {
        data['corruption_details']['attachments'] = attach;

        for (var i = 0; i < attach.length; i++) {
          resTotalSize = resTotalSize + attach[i].size;
        }

        this.setState({
          noOfFiles: noFiles,
          image_urls: arr,
          formdata: data,
          uploadedFileSize: resTotalSize,
        });
      }

    }
    // console.log(index);
    console.log(this.state);
  }

  openAttachment = (event) => {
    var url = event.target.getAttribute('value');
    // console.log("url",url);
    window.open(url);
  }

  //Video Select and Upload
  handleVideoUpload = event => {
    event.persist();

    var data = this.state.formdata;
    var videoURLS = [];
    var videoFiles = [];
    var totalFileSize = 0;
    var totalFiles = 0;
    var alreadyUploadedFiles = this.state.formdata.corruption_details.videoAttachments;
    var alreadyUploadedNoOFFiles = alreadyUploadedFiles.length;
    var alreadyUploadedTotalVideoFileSize = this.state.uploadedVideoFileSize;


    if (alreadyUploadedNoOFFiles > 0) {
      videoFiles = alreadyUploadedFiles;
      videoURLS = this.state.video_urls;
    }

    if (event.target.files[0] !== undefined) {

      totalFiles = alreadyUploadedNoOFFiles + event.target.files.length;

      if (totalFiles < 4) {

        for (let j = 0; j < event.target.files.length; j++) {

          let file = event.target.files[j];
          totalFileSize = alreadyUploadedTotalVideoFileSize + file.size;

          if (totalFileSize > 50000000) {
            this.setState({
              message: "Total File Size Must Be Less Than 50MB",
              severity: "error",
              snackbar: true,
            });
            break;
          }
          else {

            // console.log('Selected file:', file);

            // if(file.size > 1000000){
            //     this.setState({
            //         message: "For each file size must be less than 1MB.",
            //         severity: "error",
            //         snackbar: true,
            //     });
            //     break;
            // }
            // else 

            if (file.type == "video/mp4" || file.type == "video/avi" || file.type == "video/m4v" || file.type == "video/mpg") {

              alreadyUploadedTotalVideoFileSize = totalFileSize;

              //formdata array
              videoFiles.push(file);

              //add local URL to preview
              videoURLS.push(URL.createObjectURL(file));
              // console.log(file);

            }
            else {
              this.setState({
                message: "You can only upload Videos(mp4,avi,m4v,mpg)",
                severity: "error",
                snackbar: true,
              });
            }

          }

        }
      }
      else {
        this.setState({
          message: "Can't Upload More Than 3 Files.",
          severity: "error",
          snackbar: true,
        })
      }

      //No of files
    }
    else {
      console.log('No files');
    }

    //set to from data
    data['corruption_details']['videoAttachments'] = videoFiles;

    this.setState({
      video_urls: videoURLS,
      noOfVideoFiles: videoFiles.length,
      formdata: data,
      uploadedVideoFileSize: alreadyUploadedTotalVideoFileSize,
    })

    console.log(this.state);

  };

  removeUploadSelectedVideoItem = (val) => {

    // console.log("object:",val);
    var arr = this.state.video_urls;
    var data = this.state.formdata;
    var attach = this.state.formdata.corruption_details.videoAttachments;
    var index = -1;

    for (let j = 0; j < arr.length; j++) {
      if (arr[j] === val) {
        index = j;
      }
    }

    if (index > -1) {

      var noFiles = this.state.noOfVideoFiles - 1;
      var resTotalSize = 0;

      arr.splice(index, 1);
      // console.log(arr);
      // this.state.image_urls = arr;

      //remove from attachments
      attach.splice(index, 1);

      if (noFiles == 0) {
        document.getElementById("contained-button-Video-Upload").value = "";
        data['corruption_details']['videoAttachments'] = [];
        this.setState({
          noOfVideoFiles: 0,
          video_urls: [],
          formdata: data,
          uploadedVideoFileSize: 0,
        });
      }
      else {
        data['corruption_details']['videoAttachments'] = attach;

        for (var i = 0; i < attach.length; i++) {
          resTotalSize = resTotalSize + attach[i].size;
        }

        this.setState({
          noOfVideoFiles: noFiles,
          video_urls: arr,
          formdata: data,
          uploadedVideoFileSize: resTotalSize,
        });
      }

    }
    // console.log(index);
    console.log(this.state);
  }

  // map function
  onMarkerDragEnd = (e) => {
    let newLatitude = e.latLng.lat(),
      newLongitude = e.latLng.lng();

    console.log(newLatitude, newLongitude);

    var data = this.state.formdata;
    data['latitude'] = newLatitude;
    data['longitude'] = newLongitude;

    Geocode.fromLatLng(newLatitude, newLongitude).then(
      response => {
        this.setState({
          formdata: data,
          mapPosition: {
            lat: newLatitude,
            lng: newLongitude,
          },
        })
      },
      error => {
        console.log(error);
      }
    )

  }

  //stepper step 1 dialog box radio button selection
  nextStepperStep1 = () => {

    this.setState({
      instruction: '',
    });

    if (this.state.askToContact == "yes") {
      this.formSubmit();
    }
    else {
      this.handleNext();
    }

  }

  async loadLanguageDetails() {

    var lng = await languageService.getLanguageDetails();
    var data = this.state.formdata;

    if (lng == 'Sinhala') {
      data['reported_language'] = 'Sinhala';
      this.setState({
        languageName: 'Sinhala',
        language: sinhalaLanguage,
        formdata: data,
        steps: sinhalaLanguage.corruptionReport_stepperSteps,
        genderSelection: sinhalaLanguage.lng_genders,
        categorySelection: sinhalaLanguage.lng_corruption_types,
        districtsTypes: sinhalaLanguage.lng_Districts,
        public_authority_type: sinhalaLanguage.lng_publicAuthorities,
        reportIncidentTypes: sinhalaLanguage.lng_incidentTypes,
        reportDetailsDialogSelection: sinhalaLanguage.corruptionReport_nextDialogBoxSelection,

        divisionalSecretariatsAll: sinhalaLanguage.lng_report_divisional_secretariats,
        reportingPersonTypes: sinhalaLanguage.lng_ReportingPersonTypes,
        briberyType: sinhalaLanguage.lng_Corruption_Bribe_Types,
        propertyTypes: sinhalaLanguage.lng_misused_PropertyTypes,
        conflictOfInterestTypes: sinhalaLanguage.lng_conflictOFInterest_AnswerTypes,
        natureOfInterest_Types: sinhalaLanguage.lng_conflictOFInterest_NatureOfInterestTypes,
        procurement_irregularityType: sinhalaLanguage.lng_procurementTypes,

      })
    }
    else if (lng == 'Tamil') {
      data['reported_language'] = 'Tamil';
      this.setState({
        languageName: 'Tamil',
        language: tamilLanguage,
        formdata: data,
        steps: tamilLanguage.corruptionReport_stepperSteps,
        genderSelection: tamilLanguage.lng_genders,
        categorySelection: tamilLanguage.lng_corruption_types,
        districtsTypes: tamilLanguage.lng_Districts,
        public_authority_type: tamilLanguage.lng_publicAuthorities,
        reportIncidentTypes: tamilLanguage.lng_incidentTypes,
        reportDetailsDialogSelection: tamilLanguage.corruptionReport_nextDialogBoxSelection,

        divisionalSecretariatsAll: tamilLanguage.lng_report_divisional_secretariats,
        reportingPersonTypes: tamilLanguage.lng_ReportingPersonTypes,
        briberyType: tamilLanguage.lng_Corruption_Bribe_Types,
        propertyTypes: tamilLanguage.lng_misused_PropertyTypes,
        conflictOfInterestTypes: tamilLanguage.lng_conflictOFInterest_AnswerTypes,
        natureOfInterest_Types: tamilLanguage.lng_conflictOFInterest_NatureOfInterestTypes,
        procurement_irregularityType: tamilLanguage.lng_procurementTypes,

      })
    }
    console.log("Corruption Section:", this.state);

  }

  componentDidMount() {

    //select language
    this.loadLanguageDetails();

    //stepper show/hide
    if (window.innerWidth <= 1000) {
      this.setState({
        stepperHidden: true,
        styleClassName: false,

      })
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1000) {
        this.setState({
          stepperHidden: true,
          styleClassName: false,

        })
      }
      else {
        this.setState({
          stepperHidden: false,
          styleClassName: true,

        })
      }
    });

  }

  handleLocationChange({ position, address }) {
    // Set new location
    var data = this.state.formdata;
    data['latitude'] = position.lat;
    data['longitude'] = position.lng;
    this.setState({ mapPosition: position, formdata: data });
  }

  render() {
    const { classes } = this.props;
    /* 
        // google map
        const MapComponent = compose(
          withProps({
            googleMapURL:
              "https://maps.googleapis.com/maps/api/js?key=AIzaSyCL6g9mcTszQRG_7fMOLTBvBCzYlD71ai4&libraries=places",
            // "https://maps.googleapis.com/maps/api/js?key=AIzaSyAGePQeaEd7glCHJUQINRs-1YviE8rCub8&libraries=places",
            loadingElement: <div style={{ height: "100%" }} />,
            containerElement: <div style={{ height: "400px" }} />,
            mapElement: <div style={{ height: "100%" }} />,
          }),
          withScriptjs,
          withGoogleMap
        )((props) => (
          <GoogleMap
            google={props.google}
            defaultZoom={12}
            defaultCenter={{
              lat: this.state.mapPosition.lat,
              lng: this.state.mapPosition.lng,
            }}
          >
            <Marker
              google={props.google}
              name={"Sample Map Location Marker"}
              draggable={true}
              onDragEnd={(e) => this.onMarkerDragEnd(e)}
              position={{
                lat: this.state.formdata.latitude,
                lng: this.state.formdata.longitude,
              }}
            ></Marker>
          </GoogleMap>
        )); */

    return (
      <div
        style={{
          minHeight: "100vh",
          background:
            `radial-gradient(circle at -35% 0%, ${incident_report_theme} 15%, #fff8f8 50%, #ffffff 100%)`,
        }}
      >
        <div
          className={this.state.styleClassName ? classes.mainPadding : "p-10"}
        // style={{
        //   background:
        //     " radial-gradient(circle at -35% 0%, #FB3640 15%, #fff8f8 50%, #ffffff 100%)",
        // }}
        >
          <Grid container spacing={6}>
            <Grid item align="flex-end" className=" w-full pb-5">
              <div style={{ float: "right" }}>
                <IconButton
                  style={{ color: "#0e334b" }}
                  onClick={() => {
                    window.location.href = "/home";
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Grid>

            {/* Heading image and Title */}
            <Grid
              className={classes.heading}
              item
              container
              xs={12}
              lg={3}
              direction="column"
              // justifyContent="space-around"
              alignItems="center"
            >
              <Grid item align="center" className=" w-full pb-10 mt-10">
                {/* <img alt="share" src="/assets/images/reportIncident Logo.png" /> */}
                <img alt="share" src="/assets/images/share2.png" />
              </Grid>
              <Grid item align="center" className=" w-full ">
                <Typography
                  // className="mt-5"
                  variant="h1"
                  style={{ fontSize: 30, fontWeight: 600, color: "black" }}
                >
                  {/* Share Your Experience and Views on Bribery and Corruption */}
                  {this.state.language.shareStory_title}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} lg={9} className=" mt--15">
              {/* Report Form */}
              <div className={classes.formStyles_root}>
                {/* Stepper */}
                <div
                  className={classes.stepperStyles}
                  hidden={this.state.stepperHidden}
                >
                  <Stepper
                    alternativeLabel
                    style={{ backgroundColor: "transparent" }}
                    activeStep={this.state.activeStep}
                    connector={<ColorlibConnector />}
                  >
                    {this.state.steps.map((label) => (
                      <Step key={label}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>

                  <div>
                    {/* { this.state.activeStep === this.state.steps.length ? (
                                <div>
                                    <Typography className={classes.instructions}>
                                        All Steps Completed
                                    </Typography>
                                    <Button onClick={this.handleReset} className={classes.button}>Reset</Button>
                                </div>
                            ):(
                                <div>
                                </div>
                            )} */}
                  </div>
                </div>

                {/*-------------Stepper Step 1-----------------*/}

                {this.state.activeStep === 0 ? (
                  <div>
                    {/* Checkbox */}

                    <Grid
                      className={classes.checkboxStyle}
                      container
                      justifyContent="center"
                      alignItems="center"
                      spacing={3}
                    >
                      <Grid item xs={12} sm={6}>
                        <Checkbox
                          className={classes.checkboxStyle}
                          name="checkbox_NotAnonymous"
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          value={this.state.formdata.anonymous}
                          checked={
                            this.state.formdata.anonymous == false
                              ? true
                              : false
                          }
                          color="primary"
                        />
                        <label>
                          {/* I want to share my details. */}
                          {
                            this.state.language
                              .corruptionReport_ShareDetailsCheckbox1
                          }
                        </label>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Checkbox
                          name="checkbox_Anonymous"
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          color="primary"
                          value={this.state.formdata.anonymous}
                          checked={
                            this.state.formdata.anonymous == true ? true : false
                          }
                        />
                        <label>
                          {/* I want to stay anonymous. */}
                          {
                            this.state.language
                              .corruptionReport_ShareDetailsCheckbox2
                          }
                        </label>
                      </Grid>
                    </Grid>

                    {/* ----------------Normal form ------------------*/}
                    {this.state.formdata.anonymous == false ? (
                      <div>
                        <ValidatorForm
                          onSubmit={() => {
                            this.dialogBoxRefOpen("step1_Normalform_dialog");
                          }}
                        >
                          <Grid container spacing={3}>
                            {/* Name */}
                            <Grid item xs={12} sm={6}>
                              <Tooltip
                                // "Please Enter Your Name"
                                title={
                                  this.state.language
                                    .corruptionReport_step1NameFieldTooltip
                                }
                                arrow
                              >
                                <TextValidator
                                  className={classes.textStyles}
                                  type="text"
                                  // "Name"
                                  label={
                                    this.state.language
                                      .corruptionReport_step1NameFieldPlaceholder
                                  }
                                  fullWidth
                                  name="name"
                                  size="small"
                                  value={this.state.formdata.name}
                                  onChange={(e) => {
                                    this.handleChange(e);
                                  }}
                                  validators={["required", 'matchRegexp:^[\p{L}\u0D80-\u0DFF\u0B80-\u0BFFa-zA-Z ]+$']}
                                  errorMessages={[
                                    // "Name is required!"
                                    this.state.language
                                      .corruptionReport_step1NameFieldErrorMessage,
                                    this.state.language.corruptionReport_step1NameFieldIsString
                                  ]}
                                //   variant="outlined"
                                />
                              </Tooltip>
                            </Grid>

                            {/* Age */}
                            <Grid item xs={12} sm={6}>
                              <Tooltip
                                arrow
                                // "Please Select Your Age"
                                title={
                                  this.state.language
                                    .corruptionReport_step1AgeFieldTooltip
                                }
                              >
                                <Autocomplete
                                  className="w-full"
                                  options={ageRanges}
                                  getOptionLabel={(opt) => opt.value}
                                  name="age"
                                  size="small"
                                  value={{ value: this.state.formdata.age }}
                                  onChange={(e, v) =>
                                    this.setSelectedValue(
                                      "age",
                                      v == null ? null : v.value
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextValidator
                                      {...params}
                                      //   variant="outlined"
                                      className={classes.textStyles}
                                      // "Select Age"
                                      label={
                                        this.state.language
                                          .corruptionReport_step1AgeFieldPlaceholder
                                      }
                                      value={
                                        this.state.formdata.age == null
                                          ? ""
                                          : this.state.formdata.age
                                      }
                                      validators={["required"]}
                                      errorMessages={[
                                        // "Age is required!"
                                        this.state.language
                                          .corruptionReport_step1AgeFieldErrorMessage,
                                      ]}
                                    />
                                  )}
                                />
                              </Tooltip>
                            </Grid>

                            {/* <Grid item xs={12} sm={6} >
                            <Tooltip title="Please Enter Your Age" arrow>
                                <TextValidator 
                                    type="number" 
                                    label="Age"
                                    size='small'
                                    variant="outlined"
                                    value={this.state.formdata.age}
                                    name="age"
                                    onChange={(e) =>{ this.handleChange(e) }}
                                    validators={["required", "minNumber:10"]}
                                    errorMessages={["Age is required!","Please enter valid Age"]}
                                    fullWidth/>
                            </Tooltip>
                        </Grid> */}

                            {/* Address */}
                            <Grid item xs={12} sm={12}>
                              <Tooltip
                                // "Please Enter Your Address"
                                title={
                                  this.state.language
                                    .corruptionReport_step1AddressFieldTooltip
                                }
                                arrow
                              >
                                <TextValidator
                                  type="text"
                                  // "Address"
                                  label={
                                    this.state.language
                                      .corruptionReport_step1AddressFieldPlaceholder
                                  }
                                  variant="outlined"
                                  className={classes.textStyles}
                                  multiline={true}
                                  rows={4}
                                  size="small"
                                  name="address"
                                  value={this.state.formdata.address}
                                  onChange={(e) => {
                                    this.handleChange(e);
                                  }}
                                  validators={["required"]}
                                  errorMessages={[
                                    // "Address is required!"
                                    this.state.language
                                      .corruptionReport_step1AddressFieldErrorMessage,
                                  ]}
                                  fullWidth
                                />
                              </Tooltip>
                            </Grid>

                            {/* PhoneNumber */}
                            <Grid item xs={12} sm={6}>
                              <Tooltip
                                // "Please Enter Your Contact Number"
                                title={
                                  this.state.language
                                    .corruptionReport_step1ContactNumberFieldTooltip
                                }
                                arrow
                              >
                                <TextValidator
                                  type="text"
                                  // "Contact Number"
                                  label={
                                    this.state.language
                                      .corruptionReport_step1ContactNumberFieldPlaceholder
                                  }
                                  //   variant="outlined"
                                  className={classes.textStyles}
                                  size="small"
                                  value={this.state.formdata.contact_number}
                                  name="contact_number"
                                  onChange={(e) => {
                                    this.handleChange(e);
                                  }}
                                  validators={[
                                    "required",
                                    "matchRegexp:^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$",
                                  ]}
                                  errorMessages={[
                                    // "Phone Number is required!",
                                    this.state.language
                                      .corruptionReport_step1ContactNumberFieldErrorMessage1,
                                    // "Please enter valid Phone Number(Eg:0772345678)"
                                    this.state.language
                                      .corruptionReport_step1ContactNumberFieldErrorMessage2,
                                  ]}
                                  fullWidth
                                />
                              </Tooltip>
                            </Grid>

                            {/* Email */}
                            <Grid item xs={12} sm={6}>
                              <Tooltip
                                // "Please Enter Your Email"
                                title={
                                  this.state.language
                                    .corruptionReport_step1EmailFieldTooltip
                                }
                                arrow
                              >
                                <TextValidator
                                  type="email"
                                  // "Email"
                                  label={
                                    this.state.language
                                      .corruptionReport_step1EmailFieldPlaceholder
                                  }
                                  helperText=""
                                  //   variant="outlined"
                                  className={classes.textStyles}
                                  value={this.state.formdata.email}
                                  name="email"
                                  size="small"
                                  onChange={(e) => {
                                    this.handleChange(e);
                                  }}
                                  validators={[/* "required", */ "isEmail"]}
                                  errorMessages={[
                                    // "Email is required",
                                    /*  this.state.language
                                       .corruptionReport_step1EmailFieldErrorMessage1,
                                     // "Please enter valid email" */
                                    this.state.language
                                      .corruptionReport_step1EmailFieldErrorMessage2,
                                  ]}
                                  fullWidth
                                />
                              </Tooltip>
                            </Grid>

                            {/* Gender */}
                            <Grid item xs={12} sm={12}>
                              <FormControl component="fieldset">
                                <Typography
                                  style={{
                                    fontSize: 15,
                                    fontFamily: "Source Sans Pro",
                                  }}
                                  variant="caption"
                                >
                                  {/* Select Gender */}
                                  {
                                    this.state.language
                                      .corruptionReport_step1GenderFieldTooltip
                                  }
                                </Typography>

                                <RadioGroup className={classes.radioButton} row>
                                  {this.state.genderSelection.map((item) => (
                                    <FormControlLabel
                                      key={item.key}
                                      value={item.value}
                                      control={
                                        <Radio
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "Source Sans Pro",
                                          }}
                                          color="primary"
                                          required={
                                            this.state.formdata.gender ===
                                              null &&
                                              item.key === "gender_type2"
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      checked={
                                        this.state.formdata.gender == item.value
                                          ? true
                                          : false
                                      }
                                      name="gender"
                                      onChange={(e) => {
                                        this.handleChange(e);
                                      }}
                                      label={item.label}
                                      display="inline"
                                    />
                                  ))}
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>

                          {/* next button normal form */}
                          <div>
                            <Button
                              className={["mt-5", classes.btnNext, classes.btnAlign]}
                              variant="contained"
                              // color="primary"
                              // size="medium"

                              type="submit"
                            // onClick={() => { this.dialogBoxRefOpen("step1_Normalform_dialog") }}
                            // onClick={this.handleNext}
                            >
                              {/* Next */}
                              {
                                this.state.language
                                  .corruptionReport_step1NextButton
                              }
                            </Button>
                          </div>

                          {/* Dialog box 1*/}
                          <Dialog
                            open={
                              this.state.instruction == "step1_Normalform_dialog" ? true : false
                            }
                            onClose={this.dialogBoxRefClose}
                          >
                            <DialogTitle>
                              {/* To continue with the processing please select one of the below, */}
                              {
                                this.state.language
                                  .corruptionReport_nextDialogBoxStep1Title
                              }
                            </DialogTitle>

                            <DialogContent>
                              <Grid item xs={12} sm={6}>
                                <FormControl>
                                  <RadioGroup row className={classes.radioButton}>
                                    <FormControlLabel

                                      control={<Radio color="primary" />}
                                      label={
                                        this.state.reportDetailsDialogSelection[1].value
                                      }
                                      display="inline"
                                      checked={
                                        this.state.askToContact == "no" ? true : false
                                      }
                                      onChange={(e) => {
                                        this.setState({ askToContact: "no" });
                                      }}
                                    />

                                    <FormControlLabel
                                      control={<Radio color="primary" />}
                                      label={
                                        this.state
                                          .reportDetailsDialogSelection[0].value
                                      }
                                      display="inline"
                                      onChange={(e) => {
                                        this.setState({ askToContact: "yes" });
                                      }}
                                      checked={
                                        this.state.askToContact == "yes" ? true : false
                                      }
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                            </DialogContent>

                            <DialogActions>
                              <Button
                                style={{ color: incident_report_theme, }}
                                color="primary"
                                size="medium"
                                onClick={this.nextStepperStep1}
                              >
                                {/* Next */}
                                {
                                  this.state.language.corruptionReport_nextDialogBoxStep1Button1
                                }
                              </Button>

                              <Button
                                style={{ color: incident_report_theme, }}
                                onClick={() => this.dialogBoxRefClose()}
                                color="primary"
                                size="medium"

                              >
                                {/* Cancel */}
                                {
                                  this.state.language.corruptionReport_nextDialogBoxStep1Button2
                                }
                              </Button>
                            </DialogActions>
                          </Dialog>

                          {/* final form submission dialog */}
                          <Dialog
                            open={
                              this.state.instruction == "step1_successMag_dialogBox" ? true : false
                            }
                          >
                            <DialogTitle>
                              {/* <div style={{textAlign: 'center'}}>
                                <Typography variant="h5">Thankyou for standing against corruption !</Typography>
                                <Typography variant="h6">Your Reference Number is {this.state.refNumber} !</Typography>
                            </div> */}
                              <div style={{ textAlign: "center" }}>
                                <Typography variant="h5">
                                  {/* Thank You for Standing Against Corruption ! */}
                                  {
                                    this.state.language.corruptionReport_nextDialogBoxStep1AskToContactTitleP1
                                  }
                                </Typography>
                                <Typography variant="h4">
                                  {/* Your Reference Number is  */}
                                  {
                                    this.state.language.corruptionReport_nextDialogBoxStep1AskToContactTitleP2
                                  }
                                </Typography>
                                <Typography variant="h3">
                                  <strong>{this.state.refNumber}</strong>
                                </Typography>
                              </div>
                            </DialogTitle>

                            <DialogContent>
                              <Grid item xs={12} sm={6}></Grid>
                              <Alert severity="success">
                                <strong>
                                  {/* Your Report Submitted Successfully!. */}
                                  {
                                    this.state.language.corruptionReport_nextDialogBoxStep1AskToContactDescriptionP1
                                  }
                                </strong>
                                <p>
                                  {/* If you wish to submit more supporting documents or evidence, please post them to "PPP Program Officer, Transparency International Sri Lanka, 5/1, Elibank Road, Colombo 05." mentioning the reference number on the top left corner of the document. */}
                                  {
                                    this.state.language.corruptionReport_nextDialogBoxStep1AskToContactDescriptionP2
                                  }
                                </p>
                              </Alert>
                            </DialogContent>

                            <DialogActions>
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={this.finishDialogBoxRefClose}
                                style={{ float: "left", margin: 10 }}
                              >
                                {/* Ok */}
                                {
                                  this.state.language.corruptionReport_nextDialogBoxStep1AfterAskToContactButton
                                }
                              </Button>
                            </DialogActions>
                          </Dialog>

                          <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "right" }}
                            open={this.state.snackbar}
                            autoHideDuration={2500}
                            onClose={this.closeSnackBar}
                            name="snackBar"
                          >
                            <Alert
                              severity={this.state.severity}
                              onClose={this.closeSnackBar}
                            >
                              {this.state.message}
                            </Alert>
                          </Snackbar>
                        </ValidatorForm>
                      </div>
                    ) : (
                      <div>
                        {/* ----------------Anonymous form ------------------*/}
                        <Grid>
                          <ValidatorForm
                            onSubmit={() => {
                              this.handleNext();
                            }}
                          >
                            <Grid
                              container
                              justify="center"
                              alignItems="center"
                              direction="row"
                              spacing={3}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                className={classes.formStyles_girdItem}
                              >
                                <Tooltip
                                  arrow
                                  // "Please Select Your Age"
                                  title={
                                    this.state.language.corruptionReport_step1AgeFieldTooltip
                                  }
                                >
                                  <Autocomplete
                                    className="w-full"
                                    options={ageRanges}
                                    getOptionLabel={(opt) => opt.value}
                                    name="age"
                                    size="small"
                                    onChange={(e, v) =>
                                      this.setSelectedValue(
                                        "age",
                                        v == null ? null : v.value
                                      )
                                    }
                                    renderInput={(params) => (
                                      <TextValidator
                                        {...params}
                                        // variant="outlined"
                                        className={classes.textStyles}
                                        fullWidth
                                        // "Select Age"
                                        label={
                                          this.state.language
                                            .corruptionReport_step1AgeFieldPlaceholder
                                        }
                                        validators={["required"]}
                                        errorMessages={[
                                          // "Age is required!"
                                          this.state.language
                                            .corruptionReport_step1AgeFieldErrorMessage,
                                        ]}
                                        value={
                                          this.state.formdata.age == null
                                            ? ""
                                            : this.state.formdata.age
                                        }
                                      />
                                    )}
                                  />
                                </Tooltip>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sm={6}
                                className={classes.formStyles_girdItem}
                              >
                                <Tooltip
                                  arrow
                                  // "Please Select Your Gender"
                                  title={
                                    this.state.language
                                      .corruptionReport_step1AnonymousFormGenderFieldTooltip
                                  }
                                >
                                  <Autocomplete
                                    className="w-full"
                                    options={this.state.genderSelection}
                                    getOptionLabel={(opt) => opt.label}
                                    name="gender"
                                    size="small"
                                    onChange={(e, v) =>
                                      this.setSelectedValue(
                                        "gender", v.value
                                      )
                                    }
                                    renderInput={(params) => (
                                      <TextValidator
                                        {...params}
                                        // variant="outlined"
                                        className={classes.textStyles}
                                        fullWidth
                                        // "Select Gender"
                                        label={
                                          this.state.language
                                            .corruptionReport_step1AnonymousFormGenderFieldPlaceholder
                                        }
                                        validators={["required"]}
                                        errorMessages={[
                                          // "Gender is required!"
                                          this.state.language
                                            .corruptionReport_step1AnonymousFormGenderFieldError,
                                        ]}
                                        value={this.state.formdata.gender}
                                      />
                                    )}
                                  />
                                </Tooltip>
                              </Grid>

                            </Grid>

                            {/* next button anonymous form */}
                            <div>
                              <Button
                                className={["mt-5", classes.btnNext, classes.btnAlign]}
                                variant="contained"
                                // color="primary"
                                // size="medium"

                                type="submit"
                              >
                                {/* Next */}
                                {
                                  this.state.language
                                    .corruptionReport_step1AnonymousFormNextButton
                                }
                              </Button>
                            </div>

                            {/* Dialog box 2*/}
                            <Dialog
                              open={
                                this.state.instruction ==
                                  "step1_Anonymousform_dialog"
                                  ? true
                                  : false
                              }
                              onClose={this.dialogBoxRefClose}
                            >

                              <DialogContent>

                                {/* close icon */}
                                <div style={{ textAlign: "end", width: '100%', height: "0px", }}>
                                  <IconButton
                                    style={{ color: "#0e334b" }}
                                    onClick={this.dialogBoxRefClose}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </div>

                                <Alert severity="warning">
                                  <center>
                                    <Typography variant="h5">
                                      {/* Note */}
                                      {
                                        this.state.language
                                          .corruptionReport_anonymousSwitchDialogBoxTitle
                                      }
                                    </Typography>
                                  </center>
                                  <p>
                                    {/* We encourage you to provide as much personal information as possible, so we have a better chance of verifying the complaint/story. */}
                                    {
                                      this.state.language
                                        .corruptionReport_anonymousSwitchDialogBoxDescription
                                    }
                                  </p>
                                </Alert>

                              </DialogContent>

                              <DialogActions>
                                {/* <Button
                                    color="primary"
                                    size="medium"
                                    onClick={ () => {
                                            this.handleNext();
                                            this.setState({
                                                instruction: '',
                                            });
                                        }
                                    } >
                                    Next
                                </Button> */}
                              </DialogActions>
                            </Dialog>
                          </ValidatorForm>
                        </Grid>
                      </div>
                    )}

                    {/* step 1 end div */}
                  </div>
                ) : (
                  <div> </div>
                )}

                {/* Report Form div*/}
              </div>

              {/*-------------Stepper Step 2-----------------*/}

              {this.state.activeStep === 1 &&
                this.state.formdata.type_of_corruption == null ? (

                <div>
                  <div>
                    {/* alignItems="center" */}
                    <Grid container spacing={2} justify="center">
                      <Grid item>
                        <Tooltip
                          open={
                            this.state.category_select_activeNow == 1
                              ? true
                              : false
                          }
                          onOpen={() => {
                            this.setState({ category_select_activeNow: 1 });
                          }}
                          onClose={() => {
                            this.setState({ category_select_activeNow: null });
                          }}
                          title={this.state.categorySelection[0].desc}
                          arrow
                        >
                          <Paper
                            className={classes.categorySelectionPaper}
                            name="bribery_category"
                            onClick={() => this.selectCategory("Bribery")}
                          >
                            <img
                              src="/assets/images/categoryMenu_bribary.png"
                              className={classes.categorySelectionImage}
                            />
                            <h4
                              className={
                                classes.categorySelectionCardDescription
                              }
                            >
                              {/* Bribery */}
                              {this.state.categorySelection[0].label}
                            </h4>
                          </Paper>
                        </Tooltip>
                      </Grid>

                      <Grid item>
                        <Tooltip
                          open={
                            this.state.category_select_activeNow == 2
                              ? true
                              : false
                          }
                          onOpen={() => {
                            this.setState({ category_select_activeNow: 2 });
                          }}
                          onClose={() => {
                            this.setState({ category_select_activeNow: null });
                          }}
                          title={this.state.categorySelection[1].desc}
                          arrow
                        >
                          <Paper
                            className={classes.categorySelectionPaper}
                            name="sexual_favours_category"
                            onClick={() =>
                              this.selectCategory("Sexual Favours")
                            }
                          >
                            <img
                              src="/assets/images/categoryMenu_sexualFavours.png"
                              className={classes.categorySelectionImage}
                            />
                            <h4
                              className={
                                classes.categorySelectionCardDescription
                              }
                            >
                              {/* Sexual Favours */}
                              {this.state.categorySelection[1].label}
                            </h4>
                          </Paper>
                        </Tooltip>
                      </Grid>

                      <Grid item>
                        <Tooltip
                          open={
                            this.state.category_select_activeNow == 3
                              ? true
                              : false
                          }
                          onOpen={() => {
                            this.setState({ category_select_activeNow: 3 });
                          }}
                          onClose={() => {
                            this.setState({ category_select_activeNow: null });
                          }}
                          title={this.state.categorySelection[2].desc}
                          arrow
                        >
                          <Paper
                            className={classes.categorySelectionPaper}
                            name="misuse_funds_property_category"
                            onClick={() =>
                              this.selectCategory(
                                "Misuse of Public Funds or Property"
                              )
                            }
                          >
                            <img
                              src="/assets/images/categoryMenu_miuseProperty.png"
                              className={classes.categorySelectionImage}
                            />

                            {
                              this.state.formdata.reported_language == "Tamil" ?
                                <h4
                                  className={
                                    classes.categorySelectionCardDescription
                                  }
                                  style={{
                                    fontSize: 10,
                                    // color: 'red',
                                  }}
                                >
                                  {/* Misuse of Public Funds or Property */}
                                  {this.state.categorySelection[2].label}
                                </h4>
                                :
                                <h4
                                  className={
                                    classes.categorySelectionCardDescription
                                  }
                                >
                                  {/* Misuse of Public Funds or Property */}
                                  {this.state.categorySelection[2].label}
                                </h4>

                            }

                          </Paper>
                        </Tooltip>
                      </Grid>

                      <Grid item>
                        <Tooltip
                          open={
                            this.state.category_select_activeNow == 4
                              ? true
                              : false
                          }
                          onOpen={() => {
                            this.setState({ category_select_activeNow: 4 });
                          }}
                          onClose={() => {
                            this.setState({ category_select_activeNow: null });
                          }}
                          title={this.state.categorySelection[3].desc}
                          arrow
                        >
                          <Paper
                            className={classes.categorySelectionPaper}
                            name="misuse_position_category"
                            onClick={() =>
                              this.selectCategory("Misuse of Public Position")
                            }
                          >
                            <img
                              src="/assets/images/categoryMenu_publcPosition.png"
                              className={classes.categorySelectionImage}
                            />
                            <h4
                              className={
                                classes.categorySelectionCardDescription
                              }
                            >
                              {/* Misuse of Public Position */}
                              {this.state.categorySelection[3].label}
                            </h4>
                          </Paper>
                        </Tooltip>
                      </Grid>

                      <Grid item>
                        <Tooltip
                          open={
                            this.state.category_select_activeNow == 5
                              ? true
                              : false
                          }
                          onOpen={() => {
                            this.setState({ category_select_activeNow: 5 });
                          }}
                          onClose={() => {
                            this.setState({ category_select_activeNow: null });
                          }}
                          title={this.state.categorySelection[4].desc}
                          arrow
                        >
                          <Paper
                            className={classes.categorySelectionPaper}
                            name="conflict_interest_category"
                            onClick={() =>
                              this.selectCategory("Conflict of Interest")
                            }
                          >
                            <img
                              src="/assets/images/categoryMenu_conflictInterest.png"
                              className={classes.categorySelectionImage}
                            />
                            <h4
                              className={
                                classes.categorySelectionCardDescription
                              }
                            >
                              {/* Conflict of Interest */}
                              {this.state.categorySelection[4].label}
                            </h4>
                          </Paper>
                        </Tooltip>
                      </Grid>

                      <Grid item>
                        <Tooltip
                          open={
                            this.state.category_select_activeNow == 6
                              ? true
                              : false
                          }
                          onOpen={() => {
                            this.setState({ category_select_activeNow: 6 });
                          }}
                          onClose={() => {
                            this.setState({ category_select_activeNow: null });
                          }}
                          title={this.state.categorySelection[5].desc}
                          arrow
                        >
                          <Paper
                            className={classes.categorySelectionPaper}
                            name="public_procurement_irregularities_category"
                            onClick={() =>
                              this.selectCategory(
                                "Public Procurement Irregularities"
                              )
                            }
                          >
                            <img
                              src="/assets/images/categoryMenu_procurement_Irregularities.png"
                              className={classes.categorySelectionImage}
                            />
                            <h4
                              className={
                                classes.categorySelectionCardDescription
                              }
                            >
                              {/* Public Procurement Irregularities */}
                              {this.state.categorySelection[5].label}
                            </h4>
                          </Paper>
                        </Tooltip>
                      </Grid>

                      <Grid item>
                        <Tooltip
                          open={
                            this.state.category_select_activeNow == 7
                              ? true
                              : false
                          }
                          onOpen={() => {
                            this.setState({ category_select_activeNow: 7 });
                          }}
                          onClose={() => {
                            this.setState({ category_select_activeNow: null });
                          }}
                          title={this.state.categorySelection[6].desc}
                          arrow
                        >
                          <Paper
                            className={classes.categorySelectionPaper}
                            name="other_category"
                            onClick={() => this.selectCategory("Other")}
                          >
                            <img
                              src="/assets/images/categoryMenu_other.png"
                              className={classes.categorySelectionImage}
                            />
                            <h4
                              className={
                                classes.categorySelectionCardDescription
                              }
                            >
                              {/* Other */}
                              {this.state.categorySelection[6].label}
                            </h4>
                          </Paper>
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <div>
                          <Button
                            // size="small"
                            className={[classes.btnNext, classes.btnAlign]}
                            onClick={this.handleBack}
                            variant="contained"
                          // color="primary"
                          //onClick={() => this.handleBack("resetStep2Details")}
                          >
                            {/* Back */}
                            {this.state.language.corruptionReport_step2Button}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              ) : (
                <div></div>
              )
              }

              {/*-------------Stepper Step 3-----------------*/}
              {
                this.state.activeStep === 2 &&
                  this.state.formdata.type_of_corruption != null ? (
                  <div>
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      open={this.state.snackbar}
                      autoHideDuration={2500}
                      onClose={this.closeSnackBar}
                      name="snackBar"
                    >
                      <Alert
                        severity={this.state.severity}
                        onClose={this.closeSnackBar}
                      >
                        {this.state.message}
                      </Alert>
                    </Snackbar>

                    <div className={classes.formStyles_root}>
                      <ValidatorForm
                        onSubmit={() => {
                          this.handleNext();
                        }}
                      >
                        <Grid container spacing={3}>
                          {/* Public authority involved */}
                          {/* <Grid item xs={12} sm={6}>
                                
                                <FormControl>

                                    <Typography variant="caption">
                                        Public Authority Involved
                                        {this.state.language.corruptionReport_step3PublicAuthorityFieldTitle}
                                    </Typography>

                                    <RadioGroup row>
                                        <FormControlLabel
                                            name="public_authority_involved"
                                            value={true}
                                            control={ <Radio color="primary"/> }
                                            checked={this.state.formdata.public_authority_involved === true ? true : false}
                                            onChange={(e) =>{ this.handleChange(e) }}
                                            // "Yes"
                                            label={this.state.language.corruptionReport_step3PublicAuthorityFieldAns1}
                                            display="inline"
                                        />

                                        <FormControlLabel
                                            name="public_authority_involved"
                                            value={false}
                                            control={ <Radio color="primary"/> }
                                            checked={this.state.formdata.public_authority_involved === false ? true : false}
                                            onChange={(e) =>{ this.handleChange(e) }}
                                            // "No"
                                            label={this.state.language.corruptionReport_step3PublicAuthorityFieldAns2}
                                            display="inline"
                                        />
                                    </RadioGroup>

                                </FormControl>

                            </Grid> */}

                          {/* Name of the public involved */}
                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              // "Please Authority Involved"
                              title={
                                this.state.language
                                  .corruptionReport_step3PublicAuthorityNameFieldTooltip
                              }
                              arrow
                            >
                              <Autocomplete
                                className="w-full"
                                options={this.state.public_authority_type}
                                getOptionLabel={(option) => option.label}
                                onChange={(e, v) =>
                                  this.setSelectedValue(
                                    "name_of_authority",
                                    v == null ? null : v.value
                                  )
                                }
                                renderInput={(param) => (
                                  <TextValidator
                                    {...param}
                                    // variant="outlined"
                                    className={classes.textStyles}
                                    // "Select Authority"
                                    label={
                                      this.state.language
                                        .corruptionReport_step3PublicAuthorityNameFieldPlaceHolder
                                    }
                                    validators={
                                      this.state.formdata
                                        .public_authority_involved == true
                                        ? ["required"]
                                        : []
                                    }
                                    errorMessages={[
                                      // "Authority field is required!"
                                      this.state.language
                                        .corruptionReport_step3PublicAuthorityNameFieldRequired,
                                    ]}
                                    value={this.state.formdata.name_of_authority}
                                    size="small"
                                  />
                                )}
                              />
                            </Tooltip>
                          </Grid>

                          {/* designation of authority */}
                          < Grid item xs={12} sm={6} >
                            <Tooltip
                              // "Please Enter Name and Designation of the Person Involved"
                              title={
                                this.state.language
                                  .corruptionReport_step3PublicAuthorityDesignationFieldTooltip
                              }
                              arrow
                            >
                              <TextValidator
                                className={["w-full", classes.textStyles]}
                                type="text"
                                // variant="outlined"
                                value={
                                  this.state.formdata.designation_of_authority
                                }
                                // "Name and Designation"
                                label={
                                  this.state.language
                                    .corruptionReport_step3PublicAuthorityDesignationFieldPlaceHolder
                                }
                                name="designation_of_authority"
                                size="small"
                                /*  // validators={
                                 //   this.state.formdata.public_authority_involved ==
                                 //     true
                                 //     ? ["required"]
                                 //     : []
                                 // }
                                 // errorMessages={[
                                 //   //"Name and Designation field is required!"
                                 //   this.state.language
                                 //     .corruptionReport_step3PublicAuthorityDesignationFieldRequired,
                                 // ]} */
                                validators={["maxStringLength:400"]}
                                errorMessages={[this.state.language.designation_of_authority_validation_msg]}
                                onChange={(e) => {
                                  this.handleChange(e);
                                }}
                                fullWidth
                              />
                            </Tooltip>
                          </Grid>

                          {/* Incident_status */}
                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              // "Please Select Incident Status"
                              title={
                                this.state.language
                                  .corruptionReport_step3IncidentStatusFieldTooltip
                              }
                              arrow
                            >
                              <Autocomplete
                                className="w-full"
                                size="small"
                                options={this.state.reportIncidentTypes}
                                getOptionLabel={(option) => option.label}
                                onChange={(e, v) =>
                                  this.setSelectedValue(
                                    "incident_status",
                                    v == null ? null : v.value
                                  )
                                }
                                renderInput={(param) => (
                                  <TextValidator
                                    {...param}
                                    // variant="outlined"
                                    className={classes.textStyles}
                                    // "Select Incident Status"
                                    label={
                                      this.state.language
                                        .corruptionReport_step3IncidentStatusFieldPlaceHolder
                                    }
                                    validators={["required"]}
                                    errorMessages={[
                                      // "Incident Status field is required!"
                                      this.state.language
                                        .corruptionReport_step3IncidentStatusFieldRequired,
                                    ]}
                                    value={this.state.formdata.incident_status}
                                  />
                                )}
                              />
                            </Tooltip>
                          </Grid>

                          {/* District */}
                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              // "Please Select District"
                              title={
                                this.state.language
                                  .corruptionReport_step3DistrictTooltip
                              }
                              arrow
                            >
                              <Autocomplete
                                className="w-full"
                                size="small"
                                options={this.state.districtsTypes}
                                getOptionLabel={(opt) => opt.label}
                                onChange={(e, v) =>
                                  this.setSelectedValue(
                                    "district",
                                    v == null ? null : v.value
                                  )
                                }
                                renderInput={(params) => (
                                  <TextValidator
                                    {...params}
                                    className={classes.textStyles}
                                    // variant="outlined"
                                    // "Select District"
                                    label={
                                      this.state.language
                                        .corruptionReport_step3DistrictPlaceHolder
                                    }
                                    validators={["required"]}
                                    errorMessages={[
                                      // "This field is required!"
                                      this.state.language
                                        .corruptionReport_step3DistrictRequired,
                                    ]}
                                    value={this.state.formdata.district}
                                  />
                                )}
                              />
                            </Tooltip>
                          </Grid>

                          {/* Divisional Secretariats*/}
                          {this.state.formdata.district != "National level" &&
                            <Grid item xs={12} sm={6}>
                              <Tooltip
                                // "Please select divisional secretariat"
                                title={
                                  this.state.language
                                    .corruptionReport_step3DivisionalSecretariatsTooltip
                                }
                                arrow
                              >
                                <Autocomplete
                                  disabled={
                                    this.state.formdata.district === null
                                      ? true
                                      : false
                                  }
                                  className="w-full"
                                  size="small"
                                  options={this.state.divisionalSecretariats}
                                  getOptionLabel={(opt) => String(opt.label)}
                                  onChange={(e, v) =>
                                    this.setSelectedValue(
                                      "divisional_secretariat",
                                      v == null ? null : v.value
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextValidator
                                      {...params}
                                      className={classes.textStyles}
                                      // variant="outlined"
                                      // "Select Divisional Secretariats"
                                      label={
                                        this.state.language
                                          .corruptionReport_step3DivisionalSecretariatsPlaceHolder
                                      }
                                      validators={["required"]}
                                      errorMessages={[
                                        // "This field is required!"
                                        this.state.language
                                          .corruptionReport_step3DivisionalSecretariatsRequired,
                                      ]}
                                      value={
                                        this.state.formdata.divisional_secretariat
                                      }
                                    />
                                  )}
                                />
                              </Tooltip>
                            </Grid>
                          }
                          {/* Date */}
                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              // "Please Select Date"
                              title={
                                this.state.language
                                  .corruptionReport_step3DateTooltip
                              }
                              arrow
                            >
                              <TextValidator
                                type="date"
                                size="small"
                                //InputProps={{ inputProps: { max: Util.getParsedDate(new Date()) } }}
                                // variant="outlined"
                                className={classes.textStyles}
                                placeholder="Date"
                                name="incident_date"
                                value={this.state.incident_date}
                                onChange={(e) => {
                                  this.onChangeTime(e);
                                }}
                                validators={["required"]}
                                errorMessages={[
                                  // "Date field is required!"
                                  this.state.language
                                    .corruptionReport_step3DateRequired,
                                ]}
                                fullWidth
                              />
                            </Tooltip>
                          </Grid>

                          {/* Time*/}
                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              // "Please Select Time"
                              title={
                                this.state.language
                                  .corruptionReport_step3TimeTooltip
                              }
                              arrow
                            >
                              <TextValidator
                                type="time"
                                size="small"
                                // variant="outlined"
                                className={classes.textStyles}
                                name="incident_time"
                                value={this.state.incident_time}
                                onChange={(e) => {
                                  this.onChangeTime(e);
                                }}
                                // validators={["required"]}
                                // errorMessages={[
                                //   // "Time field is required!"
                                //   this.state.language
                                //     .corruptionReport_step3TimeRequired,
                                // ]}
                                fullWidth
                              />
                            </Tooltip>
                          </Grid>

                          {/* Map*/}
                          <Grid item xs={12} sm={12}>
                            <div style={{ height: "50vh", width: "100%" }}>
                              {this.state.formdata.district != "National level" &&
                                <Typography variant="body2">
                                  {/* Please Select Location */}
                                  {
                                    this.state.language
                                      .corruptionReport_step3MapTitle
                                  }
                                </Typography>
                              }

                              {this.state.formdata.district != "National level" &&
                                <div>


                                  <LocationPicker
                                    containerElement={<div style={{ height: "100%" }} />}
                                    mapElement={<div style={{ height: "400px" }} />}
                                    defaultPosition={this.state.mapPosition}
                                    onChange={this.handleLocationChange}
                                  />

                                  {/*  <MapComponent /> */}
                                </div>
                              }

                              <Grid item xs={12} sm={12}>
                                <div>
                                  <Button
                                    className={[classes.btnNext, classes.btnAlign]}
                                    // onClick={this.handleNext}
                                    variant="contained"
                                    // color="primary"
                                    type="submit"
                                  >
                                    {/* Next */}
                                    {
                                      this.state.language
                                        .corruptionReport_step3NextButton
                                    }
                                  </Button>
                                </div>
                                <div>
                                  <Button
                                    className={[classes.btnNext, classes.btnAlign]}
                                    // size="small"

                                    onClick={() =>
                                      this.handleBack("resetStep3Details")
                                    }
                                    variant="contained"
                                    // color="primary"
                                    style={{ marginRight: 12 }}
                                  >
                                    {/* Back */}
                                    {
                                      this.state.language
                                        .corruptionReport_step3BackButton
                                    }
                                  </Button>
                                </div>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                      </ValidatorForm>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

              {/*-------------Stepper Step 4-----------------*/}
              {this.state.activeStep === 3 &&
                this.state.formdata.type_of_corruption != null ? (
                <div>
                  <div className={classes.formStyles_root}>
                    <ValidatorForm onSubmit={(e) => { !this.state.submittingForm && this.formSubmit(e) }}>
                      {/* <Typography variant="h5" style={{marginBottom: 30}}>Report Incidents of Bribery and Corruption</Typography> */}

                      <div>
                        {/* Bribery && Sexual Favour --------------------- */}

                        {this.state.formdata.type_of_corruption ===
                          this.state.categorySelection[0].value ||
                          this.state.formdata.type_of_corruption ===
                          this.state.categorySelection[1].value ? (
                          <div>
                            {/* Checkboxes */}
                            <Grid
                              className={classes.checkboxStyle}
                              container
                              spacing={3}
                              alignItems="center"
                            >
                              <Grid item xs={12} md={4}>
                                <Typography variant="body2">
                                  {/* Is the person you are reporting a*: */}
                                  {
                                    this.state.language
                                      .corruptionReport_step4_Bribery_field1Title
                                  }
                                </Typography>
                              </Grid>

                              {this.state.language.lng_ReportingPersonTypes.map(
                                (item) => (
                                  <Grid item xs={12} md={2}>
                                    <Checkbox
                                      name="you_are"
                                      // "Agent"
                                      value={item.value}
                                      onChange={(e) => {
                                        this.handleCorruptionChange(e);
                                      }}
                                      color="primary"
                                      checked={
                                        this.state.formdata.corruption_details
                                          .you_are == item.value
                                          ? true
                                          : false
                                      }
                                      required={
                                        this.state.formdata.corruption_details
                                          .you_are == null
                                          ? true
                                          : false
                                      }
                                    />

                                    <label>
                                      {/* Agent */}
                                      {item.label}
                                    </label>
                                  </Grid>
                                )
                              )}
                            </Grid>

                            {/* Bribery ---------------------------------- */}
                            {this.state.formdata.type_of_corruption ===
                              this.state.categorySelection[0].value ? (
                              <div>
                                <Grid container spacing={3}>
                                  {/* from what type of incident*/}
                                  <Grid item xs={12} sm={6}>
                                    <Tooltip
                                      // "Please Select Bribe Type"
                                      title={
                                        this.state.language
                                          .corruptionReport_step4_Bribery_field2Title
                                      }
                                      arrow
                                    >
                                      <Autocomplete
                                        className="w-full"
                                        size="small"
                                        freeSolo
                                        options={this.state.briberyType}
                                        getOptionLabel={(option) =>
                                          option.label || option
                                        }
                                        onInputChange={(e, v) =>
                                          this.setSelectedCorruptionValueBribery(
                                            "bribe_form",
                                            v
                                          )
                                        }
                                        defaultValue={""}
                                        renderInput={(para) => (
                                          <TextValidator
                                            {...para}
                                            // variant="outlined"
                                            className={classes.textStyles}
                                            validators={["required"]}
                                            errorMessages={[
                                              // "Bribe Type is required"
                                              this.state.language
                                                .corruptionReport_step4_Bribery_field2Error,
                                            ]}
                                            value={
                                              this.state.briberyTypeSelectionLabel
                                            }
                                            // "In what form was the bribe"
                                            label={
                                              this.state.language
                                                .corruptionReport_step4_Bribery_field2Placeholder
                                            }
                                          />
                                        )}
                                      />
                                    </Tooltip>
                                  </Grid>
                                  {/* Monetary value */}
                                  <Grid item xs={12} sm={6}>
                                    <Tooltip
                                      // "Please Enter the amount"
                                      title={
                                        this.state.language
                                          .corruptionReport_step4_Bribery_field3Title
                                      }
                                      arrow
                                    >
                                      <TextValidator
                                        type="number"
                                        size="small"
                                        // "Monetary Value"
                                        label={
                                          this.state.language
                                            .corruptionReport_step4_Bribery_field3Placeholder
                                        }
                                        // variant="outlined"
                                        className={classes.textStyles}
                                        name="monetary_value"
                                        onChange={(e) => {
                                          this.handleCorruptionChange(e);
                                        }}
                                        value={
                                          this.state.formdata.corruption_details
                                            .monetary_value
                                        }
                                        validators={[
                                          "minNumber:1",
                                          "maxNumber:1000000000",
                                        ]}
                                        errorMessages={[
                                          "Minimum Value must be grater than 0",
                                          "Maximum Value is 1000000000",
                                        ]}
                                        fullWidth
                                      />
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        ) : (
                          <div></div>
                        )}

                        {/* end Bribery && Sexual Favour ---------------------------------- */}

                        {/* Misuse of Public Funds or Property----- */}
                        {this.state.formdata.type_of_corruption ===
                          this.state.categorySelection[2].value ? (
                          <div>
                            {/* Selection */}
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <Tooltip
                                  // "Please Select Type of The Property"
                                  title={
                                    this.state.language
                                      .corruptionReport_step4_MisuseProperty_fieldTitle
                                  }
                                  arrow
                                >
                                  <Autocomplete
                                    className="w-full"
                                    options={this.state.propertyTypes}
                                    size="small"
                                    getOptionLabel={(opt) => opt.label}
                                    onChange={(e, v) =>
                                      this.setSelectedCorruptionValue(
                                        "type_of_property",
                                        v == null ? null : v.value
                                      )
                                    }
                                    renderInput={(params) => (
                                      <TextValidator
                                        {...params}
                                        // variant="outlined"
                                        className={classes.textStyles}
                                        // "Select Type of The Property "
                                        label={
                                          this.state.language
                                            .corruptionReport_step4_MisuseProperty_fieldPlaceholder
                                        }
                                        validators={["required"]}
                                        errorMessages={[
                                          // "Type of Property is required"
                                          this.state.language
                                            .corruptionReport_step4_MisuseProperty_fieldError,
                                        ]}
                                        value={
                                          this.state.formdata.corruption_details
                                            .type_of_property
                                        }
                                      />
                                    )}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {/* Misuse of Public Funds or Property ----- */}

                        {/* Misuse of Public Position ----- */}
                        {this.state.formdata.type_of_corruption ===
                          this.state.categorySelection[3].value ? (
                          <div>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6} arrow>
                                <Tooltip
                                  // "What benefit has the culprit obtained or given out? "
                                  title={
                                    this.state.language
                                      .corruptionReport_step4_MisusePosition_fieldTitle
                                  }
                                >
                                  <TextValidator
                                    className={["w-full", classes.textStyles]}
                                    type="text"
                                    size="small"
                                    // variant="outlined"

                                    name="benefit"
                                    // "What benefit has the culprit obtained?"
                                    label={
                                      this.state.language
                                        .corruptionReport_step4_MisusePosition_fieldPlaceholder
                                    }
                                    onChange={(e) => {
                                      this.handleCorruptionChange(e);
                                    }}
                                    validators={["required"]}
                                    errorMessages={[
                                      // "Benefit is required"
                                      this.state.language
                                        .corruptionReport_step4_MisusePosition_fieldError,
                                    ]}
                                    value={
                                      this.state.formdata.corruption_details
                                        .benefit
                                    }
                                    fullWidth
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {/* Misuse of Public Position ----- */}

                        {/* Conflict of Interest ----- */}
                        {this.state.formdata.type_of_corruption ===
                          this.state.categorySelection[4].value ? (
                          <div>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <FormControl>
                                  <Typography
                                    variant="body2"
                                    style={{
                                      fontFamily: "Source Sans Pro",
                                      fontSize: 15,
                                    }}
                                  >
                                    {/* Does the person have a private interest? */}
                                    {
                                      this.state.language
                                        .corruptionReport_step4_ConflictOFInterest_field1Title
                                    }
                                  </Typography>

                                  <RadioGroup
                                    row
                                    style={{
                                      fontFamily: "Source Sans Pro",
                                      fontSize: 15,
                                    }}
                                  >
                                    {this.state.conflictOfInterestTypes.map(
                                      (item) => (
                                        <FormControlLabel
                                          key={item.key}
                                          value={item.value}
                                          control={
                                            <Radio
                                              color="primary"
                                              required={
                                                this.state.formdata
                                                  .corruption_details
                                                  .private_interest == null &&
                                                  item.value === "Yes"
                                                  ? true
                                                  : false
                                              }
                                            />
                                          }
                                          checked={
                                            this.state.formdata
                                              .corruption_details
                                              .private_interest === item.value
                                              ? true
                                              : false
                                          }
                                          label={item.label}
                                          name="private_interest"
                                          onChange={(e) => {
                                            this.handleCorruptionChange(e);
                                          }}
                                          display="inline"
                                        />
                                      )
                                    )}
                                  </RadioGroup>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <FormControl>
                                  <Typography
                                    variant="body2"
                                    style={{
                                      fontFamily: "Source Sans Pro",
                                      fontSize: 15,
                                    }}
                                  >
                                    {/* Did the person disclose his or her interest? */}
                                    {
                                      this.state.language
                                        .corruptionReport_step4_ConflictOFInterest_field2Title
                                    }
                                  </Typography>

                                  <RadioGroup
                                    row
                                    style={{
                                      fontFamily: "Source Sans Pro",
                                      fontSize: 15,
                                    }}
                                  >
                                    {this.state.conflictOfInterestTypes.map(
                                      (item) => (
                                        <FormControlLabel
                                          key={item.key}
                                          value={item.value}
                                          control={
                                            <Radio
                                              color="primary"
                                            // required={this.state.formdata.corruption_details.disclose_interest == null
                                            //     && item.value === "Yes"
                                            // ? true:false}
                                            />
                                          }
                                          checked={
                                            this.state.formdata
                                              .corruption_details
                                              .disclose_interest === item.value
                                              ? true
                                              : false
                                          }
                                          label={item.label}
                                          name="disclose_interest"
                                          onChange={(e) => {
                                            this.handleCorruptionChange(e);
                                          }}
                                          display="inline"
                                        />
                                      )
                                    )}
                                  </RadioGroup>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <FormControl>
                                  <Typography
                                    variant="body2"
                                    style={{
                                      fontFamily: "Source Sans Pro",
                                      fontSize: 15,
                                    }}
                                  >
                                    {/* Did he or she participate in the making of a decision that involved his or her private interest? */}
                                    {
                                      this.state.language
                                        .corruptionReport_step4_ConflictOFInterest_field3Title
                                    }
                                  </Typography>

                                  <RadioGroup
                                    row
                                    style={{
                                      fontFamily: "Source Sans Pro",
                                      fontSize: 15,
                                    }}
                                  >
                                    {this.state.conflictOfInterestTypes.map(
                                      (item) => (
                                        <FormControlLabel
                                          key={item.key}
                                          value={item.value}
                                          control={
                                            <Radio
                                              color="primary"
                                              required={
                                                this.state.formdata
                                                  .corruption_details
                                                  .involved == null &&
                                                  item.value === "Yes"
                                                  ? true
                                                  : false
                                              }
                                            />
                                          }
                                          checked={
                                            this.state.formdata
                                              .corruption_details.involved ===
                                              item.value
                                              ? true
                                              : false
                                          }
                                          label={item.label}
                                          name="involved"
                                          onChange={(e) => {
                                            this.handleCorruptionChange(e);
                                          }}
                                          display="inline"
                                        />
                                      )
                                    )}
                                  </RadioGroup>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <FormControl>
                                  <Typography
                                    variant="body2"
                                    style={{
                                      fontFamily: "Source Sans Pro",
                                      fontSize: 15,
                                    }}
                                  >
                                    {/* Was the person authorized to participate in the process of making that decision? */}
                                    {
                                      this.state.language
                                        .corruptionReport_step4_ConflictOFInterest_field4Title
                                    }
                                  </Typography>

                                  <RadioGroup
                                    row
                                    style={{
                                      fontFamily: "Source Sans Pro",
                                      fontSize: 15,
                                    }}
                                  >
                                    {this.state.conflictOfInterestTypes.map(
                                      (item) => (
                                        <FormControlLabel
                                          key={item.key}
                                          value={item.value}
                                          control={
                                            <Radio
                                              color="primary"
                                            // required={this.state.formdata.corruption_details.authorized_participate == null
                                            //     && item.value === "Yes"
                                            // ? true:false}
                                            />
                                          }
                                          checked={
                                            this.state.formdata
                                              .corruption_details
                                              .authorized_participate ===
                                              item.value
                                              ? true
                                              : false
                                          }
                                          label={item.label}
                                          name="authorized_participate"
                                          onChange={(e) => {
                                            this.handleCorruptionChange(e);
                                          }}
                                          display="inline"
                                        />
                                      )
                                    )}
                                  </RadioGroup>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} sm={12}>
                                <Tooltip
                                  // "Please Select Nature of Interest"
                                  title={
                                    this.state.language
                                      .corruptionReport_step4_ConflictOFInterest_field5Title
                                  }
                                  arrow
                                >
                                  <Autocomplete
                                    className="w-full"
                                    size="small"
                                    options={this.state.natureOfInterest_Types}
                                    getOptionLabel={(opt) => opt.label}
                                    onChange={(e, v) =>
                                      this.setSelectedCorruptionValue(
                                        "nature_of_interest",
                                        v == null ? null : v.value
                                      )
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        // variant="outlined"
                                        className={classes.textStyles}
                                        // "Select Nature of Interest"
                                        label={
                                          this.state.language
                                            .corruptionReport_step4_ConflictOFInterest_field5PlaceHolder
                                        }
                                      />
                                    )}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {/* Conflict of Interest ----- */}

                        {/* Public Procurement Irregularities ----- */}
                        {this.state.formdata.type_of_corruption ===
                          this.state.categorySelection[5].value ? (
                          <div>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <Tooltip
                                  // "What type of public procurement irregularity?"
                                  title={
                                    this.state.language
                                      .corruptionReport_step4_Procurement_fieldTitle
                                  }
                                  arrow
                                >
                                  <Autocomplete
                                    className="w-full"
                                    size="small"
                                    options={
                                      this.state.procurement_irregularityType
                                    }
                                    getOptionLabel={(opt) => opt.label}
                                    onChange={(e, v) =>
                                      this.setSelectedCorruptionValue(
                                        "type_of_procurement",
                                        v == null ? null : v.value
                                      )
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        // variant="outlined"
                                        className={classes.textStyles}
                                        // "Type of the public procurement irregularity"
                                        label={
                                          this.state.language
                                            .corruptionReport_step4_Procurement_fieldPlaceholder
                                        }
                                      />
                                    )}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {/* Public Procurement Irregularities ----- */}

                        {/* Other &&  Public Procurement Irregularities*/}
                        <div></div>
                      </div>

                      {/* common */}
                      <Grid container spacing={3}>
                        {/* Other */}
                        <Grid item xs={12} sm={12}>
                          <Tooltip
                            // "More Details"
                            title={
                              this.state.language
                                .corruptionReport_step4_Other_fieldTitle
                            }
                            arrow
                          >
                            <TextValidator
                              type="text"
                              size="small"
                              // "Any Other Details"
                              label={
                                this.state.language
                                  .corruptionReport_step4_Other_fieldPlaceholder
                              }
                              variant="outlined"
                              className={classes.textStyles}
                              multiline={true}
                              rows={4}
                              name="other_details"
                              helperText={
                                "Word Limit(" +
                                this.state.countWords +
                                "/" +
                                storyWordLimit +
                                ")"
                              }
                              inputProps={{ maxLength: storyWordLimit }}
                              // value={this.state.formdata.corruption_details.other_details}
                              onChange={(e) => {
                                this.handleCorruptionChange(e);
                              }}
                              // validators={["required"]}
                              // errorMessages={[
                              // "Description is required"
                              // this.state.language.corruptionReport_step4_Other_fieldError,
                              // ]}
                              value={
                                this.state.formdata.corruption_details
                                  .other_details
                              }
                              fullWidth
                            />
                          </Tooltip>
                        </Grid>

                        {/* Evidence Attachments Images */}
                        <Grid item xs={12} sm={6}>
                          <input
                            id="contained-button-file"
                            size="small"
                            name="attachments"
                            multiple
                            type="file"
                            onChange={this.handlefileUpload}
                            style={{ display: "none" }}
                          />

                          <label htmlFor="contained-button-file">
                            <div style={{ float: "left" }}>
                              <Button
                                style={{
                                  color: "white",
                                  backgroundColor: incident_report_theme, //"#FB3640",
                                  "&:hover": {
                                    color: "black",
                                    backgroundColor: "#ce6c10",
                                  },
                                }}
                                variant="contained"
                                // color="primary"

                                startIcon={<CloudUploadIcon />}
                                component="span"
                              >
                                {/* Upload */}
                                {
                                  this.state.language.corruptionReport_step4UploadButton
                                }
                                &ensp;
                              </Button>
                              {/* no of images */}
                              {
                                this.state.languageName === 'Tamil' ?
                                  <Fragment>
                                    <p className="w-full" style={{ display: 'flex' }}>
                                      {this.state.language
                                        .corruptionReport_step4UploadButtonText +
                                        " " +
                                        this.state.formdata.corruption_details
                                          .attachments.length}
                                    </p>
                                  </Fragment>
                                  :
                                  <p style={{ display: "inline", marginLeft: 5 }}>
                                    {this.state.language
                                      .corruptionReport_step4UploadButtonText +
                                      " " +
                                      this.state.formdata.corruption_details
                                        .attachments.length}
                                  </p>
                              }
                            </div>
                          </label>
                        </Grid>

                        {/* Evidence Attachments Video*/}
                        <Grid item xs={12} sm={6}>
                          <input
                            id="contained-button-Video-Upload"
                            size="small"
                            name="video_Attachments"
                            multiple
                            type="file"
                            onChange={this.handleVideoUpload}
                            style={{ display: "none" }}
                          />

                          <label htmlFor="contained-button-Video-Upload">
                            <div style={{ float: "right" }}>
                              <Button
                                style={{
                                  color: "white",
                                  backgroundColor: incident_report_theme, //"#FB3640",
                                  "&:hover": {
                                    color: "black",
                                    backgroundColor: "#ce6c10",
                                  },
                                }}
                                variant="contained"
                                // color="primary"

                                startIcon={<PlayCircleFilledIcon />}
                                component="span"
                              >
                                {/* Upload Videos */}
                                {
                                  this.state.language.corruptionReport_step4UploadButtonVideo
                                }
                                &ensp;
                              </Button>
                              {/* <p style={{ display: "inline", marginLeft: 5 }}>
                                {this.state.language
                                  .corruptionReport_step4UploadButtonText +
                                  " " +
                                  this.state.formdata.corruption_details.videoAttachments.length}
                              </p> */}
                            </div>
                          </label>
                        </Grid>

                        {/* attachments preview */}
                        <Grid item xs={12} sm={12}>

                          {/* attachments images */}
                          {this.state.noOfFiles > 0 && (
                            <Grid container spacing={2}>
                              {this.state.image_urls.map((img) => (
                                <Grid item xs={12} sm={4} key={img}>
                                  <Card
                                    style={{
                                      cursor: "pointer",
                                      backgroundColor: "#f1f1f1",
                                    }}
                                  >
                                    <CardMedia
                                      component="img"
                                      height="150"
                                      image={img}
                                      value={img}
                                      onClick={this.openAttachment}
                                    />
                                    <CardActions>
                                      <Tooltip
                                        // "Remove"
                                        title={
                                          this.state.language
                                            .corruptionReport_step4TrashIconTooltip
                                        }
                                        arrow
                                      >
                                        <IconButton
                                          className={classes.deleteTrashIcon}
                                          onClick={() =>
                                            this.removeUploadSelectedItem(img)
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </CardActions>
                                  </Card>
                                </Grid>
                              ))}
                            </Grid>
                          )}

                          {/* attachments Videos */}
                          {this.state.noOfVideoFiles > 0 && (
                            <Grid container spacing={2}>
                              {this.state.video_urls.map((video) => (
                                <Grid item xs={12} sm={4} key={video}>
                                  <Card
                                    style={{
                                      cursor: "pointer",
                                      backgroundColor: "#f1f1f1",
                                    }}
                                  >
                                    <CardMedia
                                      component="img"
                                      height="150"
                                      image={'/assets/images/videoPreview.png'}
                                      value={video}
                                      onClick={this.openAttachment}
                                    />
                                    <CardActions>
                                      <Tooltip
                                        // "Remove"
                                        title={
                                          this.state.language.corruptionReport_step4TrashIconTooltip
                                        }
                                        arrow
                                      >
                                        <IconButton
                                          className={classes.deleteTrashIcon}
                                          onClick={() =>
                                            this.removeUploadSelectedVideoItem(video)
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </CardActions>
                                  </Card>
                                </Grid>
                              ))}
                            </Grid>
                          )}

                        </Grid>

                        {/* Note */}
                        <Grid item xs={12} md={12} style={{ marginBottom: 10 }}>
                          <Alert severity="info">
                            <AlertTitle>
                              {/* Please Read Before Submit */}
                              {
                                this.state.language
                                  .corruptionReport_step4InstructionsTitle
                              }
                            </AlertTitle>
                            <p align="justify">
                              {
                                this.state.language
                                  .corruptionReport_step4InstructionsDescription
                              }
                              {/* Files may contain hidden personal information that could jeopardize your
                                        anonymity. If you wish to remain anonymous, please remove all such information
                                        before uploading a file. If you are unable to remove such information, please
                                        send a printed copy of the document anonymously to "PPP Program Officer,
                                        Transparency International Sri Lanka, 5/1, Elibank Road, Colombo 05". Please
                                        mention the reference number that is provided at the end of the reporting process. */}
                            </p>
                          </Alert>
                        </Grid>

                        {/* back button */}
                        <Grid item xs={12} sm={12}>
                          <div>
                            <Button
                              variant="contained"
                              // size="small"
                              disabled={this.state.submittingForm}
                              color="primary"
                              type="submit"
                              className={[classes.btnNext, classes.btnAlign]}
                            >
                              {/* Finish */}
                              {
                                this.state.language
                                  .corruptionReport_step4FinishButton
                              }
                            </Button>
                          </div>
                          <div >
                            <Button
                              style={{ marginRight: 12 }}
                              onClick={this.handleBack}
                              variant="contained"
                              disabled={this.state.submittingForm}
                              // color="primary"
                              className={[classes.btnNext, classes.btnAlign]}
                            >
                              {/* Back */}
                              {
                                this.state.language
                                  .corruptionReport_step4BackButton
                              }
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </ValidatorForm>

                    <Dialog
                      open={
                        this.state.instruction == "step4_successMag_dialogBox"
                          ? true
                          : false
                      }
                    >
                      <DialogTitle>
                        <div style={{ textAlign: "center" }}>
                          <Typography variant="h5">
                            {/* Thank You for Standing Against Corruption ! */}
                            {
                              this.state.language
                                .corruptionReport_step4DialogBoxTitleP1
                            }
                          </Typography>
                          <Typography variant="h4">
                            {/* Your Reference Number is  */}
                            {
                              this.state.language
                                .corruptionReport_step4DialogBoxTitleP2
                            }
                          </Typography>
                          <Typography variant="h3">
                            <strong>{this.state.refNumber}</strong>
                          </Typography>
                        </div>
                      </DialogTitle>

                      <DialogContent>
                        <Grid item xs={12} sm={6}></Grid>
                        <Alert severity="success">
                          <strong>
                            {/* Your Report Submitted Successfully!. */}
                            {
                              this.state.language
                                .corruptionReport_step4DialogBoxDescriptionP1
                            }
                          </strong>
                          <p align="justify">
                            {/* If you wish to submit more supporting documents or evidence, please post them to "PPP Program Officer, 
                                    Transparency International Sri Lanka, 5/1, Elibank Road, Colombo 05." mentioning the reference number on the 
                                    top left corner of the document. */}
                            {
                              this.state.language
                                .corruptionReport_step4DialogBoxDescriptionP2
                            }
                          </p>
                        </Alert>

                        {this.state.attachmentUploading &&
                          <Alert severity="warning">
                            <div style={{ textAlign: 'center' }}>
                              <CircularProgress />
                            </div>
                            <strong>
                              {/* Please wait. Attachments Uploading.*/}
                              {
                                this.state.language.corruptionReport_step4DialogBoxDescriptionP3
                              }
                              <LinearProgressWithLabel value={this.state.loadingProgress} />
                            </strong>
                          </Alert>
                        }

                        {this.state.attachmentUploading == false && this.state.attachmentUploadingError == null &&
                          this.state.haveUploadAttachments &&
                          <Alert severity="success">
                            <strong>
                              {/* Attachments upload successful!*/}
                              {
                                this.state.language.corruptionReport_step4DialogBoxDescriptionP4
                              }
                            </strong>
                          </Alert>
                        }

                        {this.state.attachmentUploadingError != null &&
                          <Alert severity="error">
                            <strong>
                              {
                                this.state.attachmentUploadingError
                              }
                            </strong>
                          </Alert>
                        }

                      </DialogContent>

                      <DialogActions>
                        <div >
                          {
                            this.state.attachmentUploading == false &&
                            <Button
                              // variant="contained"
                              color="primary"
                              type="submit"
                              onClick={this.finishDialogBoxRefClose}

                            >
                              {/* Ok */}
                              {
                                this.state.language.corruptionReport_step4DialogBoxButton
                              }
                            </Button>
                          }
                        </div>
                      </DialogActions>
                    </Dialog>

                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      open={this.state.snackbar}
                      autoHideDuration={2500}
                      onClose={this.closeSnackBar}
                      name="snackBar"
                    >
                      <Alert
                        severity={this.state.severity}
                        onClose={this.closeSnackBar}
                      >
                        {this.state.message}
                      </Alert>
                    </Snackbar>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </Grid>
          </Grid >

          {/* Main div end */}
        </div >
      </div >
    );
  }
}

ReportIncident.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReportIncident);
