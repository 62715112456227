
export const englishLanguage = {

    //home page -----------------------------------------------

    //app bar
    home_appBar: "Home",
    aboutUs_appBar: "About Us",
    news_appBar: "News",
    incidentMap_appBar: "Incidents Map",
    statistics_appBar: "Statistics",
    howTo_appBar: "How To",
    incidentReport_appBar: "Reports & Stories",

    //sub menu Report & Stories
    incident_appBar: "Reports",
    story_appBar: "Stories",

    //slide show
    mainTitle_SlideShow: "How To Use Apesalli.lk",
    mainDescription_SlideShow: "Watch Video and Learn More About Apesalli and How to Lodge Complain",

    //button titles
    incidentReportButtonTitle_Story: "If You See Something, Say Something",
    incidentReportButtonTitle_Corruption: "Words Move Hearts, Hearts move Limbs",
    incidentReportButtonTitle_Election: "Election Violations",

    //button descriptions
    // incidentReportButtonDescription_Story: "Share Your Experience and Views on Bribery and Corruption",
    // incidentReportButtonDescription_Corruption: "Report Incidents of Bribery and Corruption to TISL",
    // incidentReportButtonDescription_Election: "If You See Violations, Report Election Violations to TISL",
    incidentReportButtonDescription_Story: "Report Incidents of Bribery and Corruption to TISL",
    incidentReportButtonDescription_Corruption: "Share Your Experience and Views on Bribery and Corruption",
    incidentReportButtonDescription_Election: "Report Election Violations to TISL",

    //Read more Button
    readMoreButton: "Read More...",
    viewMoreButton: "View More...",

    //Read more Reports Button
    readMoreReportsButton: "Read More Reports...",

    //Read more News Button
    readMoreNewsButton: "Read More News...",

    //titles
    incidents_title: "Incidents",

    //Footer -----------------------------------------------
    footer_addressColomboTitle: "Colombo Office",
    footer_addressColomboDescription: "No.366, Nawala Road, Nawala, Rajagiriya ",

    footer_addressPhoneName: "Phone:",
    footer_addressColomboPhoneValue: " (+94) 0114369781 | 0114369782 | 0114369783",

    footer_addressFaxName: "Fax:",
    footer_addressColomboFaxValue: " (+94) 0112865777",

    footer_addressEmailName: "Email:",
    footer_addressColomboEmailValue: " tisl@tisrilanka.org",

    footer_addressWebName: "Web:",
    footer_addressColomboWebValue: "www.tisrilanka.org",
    footer_addressColomboWebHrefValue: "https://www.tisrilanka.org",

    footer_addressMataraTitle: "Matara Office",
    footer_addressMataraDescription: "17, Kalidasa Road, Matara",

    footer_addressMataraPhoneValue: " (+94) 041 22 33 522",

    footer_addressMataraEmailValue: " alac-matara@tisrilanka.org",

    footer_addressVavuniyaTitle: "Vavuniya Office",
    footer_addressVavuniyaDescription: "SLRCS Building , Off Park Road , Vavuniya",

    footer_addressVavuniyaPhoneValue: " (+94) 024 222 76 76",
    footer_addressVavuniyaEmailValue: " alac-vavuniya@tisrilanka.org",

    footer_disclaimer_btn: "Disclaimer",
    footer_privacyPolicy_btn: "Privacy Policy",

    //Disclaimer -----------------------------------------------
    disclaimer_mainTitle: "Disclaimer",
    disclaimer_descriptionP1: "The information contained on this website is for general information purposes only. The information is provided by the general public and while we endeavor to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.",
    disclaimer_descriptionP2: "Transparency International Sri Lanka makes all reasonable efforts to ensure that the information presented on this website is accurate at the time it is published, but accepts no liability whatsoever for its correctness, reliability, topicality or completeness. Transparency International Sri Lanka assumes no responsibility for any direct or indirect loss suffered by users or third parties in connection with the use of the website.",
    disclaimer_descriptionP3: "Transparency International Sri Lanka will only take legal or other necessary action based on complaints/ stories submitted by users after a thorough follow-up and verification of the complaint, and if it is deemed that there is a viable cause of action. Transparency International Sri Lanka takes no responsibility for, and will not be liable for, not taking any action in relation to submissions made to the website. Transparency International Sri Lanka reserves the right to not take action based on submissions made to the website. Transparency International Sri Lanka takes no responsibility for, and will not be liable for, any delays on the part of government authorities in taking action against any complaint.",
    disclaimer_descriptionP4: "Every effort is made to keep the website up and running smoothly. However, Transparency International Sri Lanka takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.",
    disclaimer_descriptionP5: "Through this website you may be able to link to other websites which are not under the control of Transparency International Sri Lanka. We have no control over the nature, content and availability of those websites. The inclusion of any links does not necessarily imply a recommendation or an endorsement of the views expressed within them. Transparency Sri Lanka is neither a governmental nor law enforcement agency.",

    //FAQ page -----------------------------------------------

    //Title
    faq_mainTitle: "FAQs",

    //Video Section - body 1
    faq_subTitle: "How to use ApeSalli.lk",
    // faq_description: "Watch Video and Learn More About Apesalli and How to Lodge Complain",

    //body 2
    faq_question1: "1.	What does this website do?",
    faq_q1Answer: "Apesalli.lk is an exposure and discussion platform for all things on anti-corruption." +
        "You can report incidents of bribery and corruption and also share your experiences on bribery and " +
        "corruption in government, private or civil society sector using Apesalli.lk. This is a platform for " +
        "both activism and advocacy on anti-corruption in Sri Lanka.",

    faq_question2: "2.   Why should I be interested?",
    faq_q2AnswerPara1: "Bribery and corruption have become a norm in our society and most of us turn a " +
        "blind eye to it. This vicious cycle can only be broken by taking a stand against this very " +
        "problem and apesalli.lk gives Sri Lankans an opportunity to do just that.",
    faq_q2AnswerPara2: "We know that everyone leads a busy lifestyle, and taking a stand on a cause " +
        "might consume time that you do not have to spare! This platform simplifies the process of taking a " +
        "stand against bribery and corruption. Report an incident or share your story, and the platform will " +
        "help propagate the message and on possible occasions, take action on behalf of you.",

    faq_question3: "3.	What steps are taken if I report an incident?",
    faq_q3AnswerPart1: "We will,",
    faq_q3AnswerPart2: "- Conduct an initial filtering of complaints received",
    faq_q3AnswerPart3: "- Independently verify the facts of the reported incident",
    faq_q3AnswerPart4: "- Collect more evidence if necessary",
    faq_q3AnswerPart5: "- Assist the complainant to submit the complaint or directly submit " +
        "the complaint to the relevant authorities",

    faq_question4: "4.	What happens when I share my experience?",
    faq_q4Answer: "We may post the story on the TISL’s website and/or social " +
        "media for millions of other people to read, share and inspire. We may take " +
        "necessary legal action based on the stories, after informing the submitter and " +
        "based on the availability of information.",

    faq_question5: "5.	What difference I can make if I share my experience on bribery and corruption?",
    faq_q5Answer: "Sharing your experience will inspire and motivate others to share their stories as " +
        "well. Also, it will help us find corruption trends and hotspots. Most importantly sharing " +
        "your story, no matter how small you think it is, will contribute to the fight against corruption!",

    faq_question6: "6.	 What are the types of corruption you can report?",

    // faq_q6AnswerP1Title: "Bribery - ",
    // faq_q6AnswerP1Description: "The offering, promising, giving, accepting or soliciting of an advantage as an inducement for an action which is illegal, unethical or a breach of trust. Inducements can take the form of gifts, loans, fees, rewards or other advantages (taxes, services, donations, etc.)  The bribe could be in the form of appointment, cash, commission, valuable security, property or interest in property, failure to take action, favor, fee, gift, loan, promise, reward and service.",

    // faq_q6AnswerP2Title: "Sexual favors - ",
    // faq_q6AnswerP2Description: "When an individual who goes to a public, judicial, peace or police officer for a public service is required to perform a sexual favour in return for the service that they are entitled to by the law.",

    // faq_q6AnswerP3Title: "Misuse of Public Funds - ",
    // faq_q6AnswerP3Description: "When a person holding office in an institution, organization or company dishonestly and illegally appropriates, uses or traffics the funds and goods they have been entrusted with for personal enrichment or other activities. ",

    // faq_q6AnswerP4Title: "Misuse of Public Property(Movable/Immovable) -",
    // faq_q6AnswerP4Description: "Misuse of any movable or immovable property in every description that are vested in and maintained by the State and used for the purposes of the State. Types of public property are land, buildings, vehicle equipment or other facilities or human resources.",

    // faq_q6AnswerP5Title: "Misuse of Public Position  - ",
    // faq_q6AnswerP5Description: "A person who uses his office to improperly benefit oneself or another person.",

    // faq_q6AnswerP6Title: "Conflict of Interest - ",
    // faq_q6AnswerP6Description: "Situation where an individual or the entity for which they work, whether a government, business, media outlet or civil society organization, is confronted with choosing between the duties and demands of their position and their own private interests. The interest could be in the nature of self-dealing, outside employment, family interests and gifts.",

    // faq_q6AnswerP7Title: "Public Procurement Irregularities  - ",
    // faq_q6AnswerP7Description: "Any irregularities in multi-step process of established procedures to acquire goods and services by any individual, company or organization — from the initial needs assessment to the contract’s award and service delivery. The irregularity could be absence of open tendering, collusion, conflict of interest or lack of qualification.",

    // faq_q6AnswerP8Title: "Other",
    // faq_q6AnswerP8Description:"",

    faq_question7: "7.	 Why do I have to disclose my personal information?",
    faq_q7Answer: "You do not have to and reports and stories can be shared anonymously through " +
        "the website. However, if the personal information is disclosed, it makes it easier for us to " +
        "look into and gather information about the incident, and to help you follow up.",

    //About US page -----------------------------------------------

    //title
    aboutUs_Title: "About Us",

    //description
    aboutUs_description1: "Deep rooted and pervasive corruption, in the political, " +
        "public, private and civil society sectors has been a persistent obstacle to the " +
        "development of Sri Lanka.",

    aboutUs_description2: "Over the years there have been many members of the public " +
        "who have taken a stand against corruption and bribery or simply borne its burden " +
        "in silence. Apesalli.lk was created with the objective of giving the public an opportunity " +
        "to report and share their stories on incidents of corruption and bribery. By shedding a light " +
        "on these illicit activities, Apesalli.lk aims to create a broader discourse on corruption and " +
        "bribery, capture the popular narrative on the issue, as well as highlight those who wish " +
        "to take a stand against such acts.",
    aboutUs_description3: "Apesalli.lk is powered by TISL’s mandate on anti-corruption. " +
        "Transparency International Sri Lanka (TISL) is the local chapter of Transparency " +
        "International(TI), the global movement against corruption, committed to promoting " +
        "good governance and eradicating corruption in Sri Lanka.TISL’s mission is to support " +
        "the collective effort to eradicate corruption in " +
        "order to build a future Sri Lanka which is equitable, peaceful, and just.",

    //Privacy Policy page -----------------------------------------------

    //title
    privacyPolicy_Title: "Privacy Policy",

    //Descriptions
    privacyPolicy_Description1: "This website is a platform for you to post " +
        "your stories or to submit your complaints. Whilst we, at Apesalli.lk, " +
        "welcome all viewpoints, we request you to submit constructive input.",

    privacyPolicy_Description2: "Users are responsible for all content posted by them and the website shall not be responsible for the accuracy or legality of any material posted by users on this website. Please do not report incidents or submit stories that are false, defamatory, abusive, threatening, harmful, obscene, or profane.",
    privacyPolicy_Description3: "By using this website, you consent to our collection and use of information relating to your use of the website as described in this Privacy Policy. This privacy policy explains how we treat your personal data and protect your privacy when you use Apesalli.lk. We do not collect, use or disclose your personal information for any purpose other than as detailed below, to further or promote the objectives of Apesalli.lk or our other projects.",
    privacyPolicy_Description4: "We are committed to protecting your privacy as a visitor to our website and take the privacy and security of your information very seriously.",

    privacyPolicy_subTitle1: "1. INFORMATION COLLECTION:",
    privacyPolicy_subTitle1Description: "When you visit the site, you may provide two types of information: (a) personal information that you knowingly choose to disclose, and which is collected on an individual basis; and (b) website use information collected on an aggregate basis as you and other users browse the website.",

    privacyPolicy_subTitle1P1: "1.1. Personal Information",
    privacyPolicy_subTitle1P1DescriptionP1: "Personal information about an individual may include the following: Name, Address, Email, Phone number, Age, Gender. You may post reports, perform searches and queries, share and transmit information and use the interactive features of the website. In doing this we receive different types of information about you including information that is required when you submit a report/complaint or share your stories, as well as personal information that you may choose to share publicly.",
    privacyPolicy_subTitle1P1DescriptionP2: "By choosing to submit your personal information, you are giving TISL permission to the use said personal information for further fact finding pertaining to the complaint/story submitted and to contact you if required to obtain further information. The personal information submitted will not be displayed on the website interface without your express consent. Personal information will not be submitted to third parties without express consent or in an anonymized or aggregated manner, unless it is with regard to a legal action.",

    privacyPolicy_subTitle1P2: "1.2. Geo-tagging location information",
    privacyPolicy_subTitle1P2Description: "When you submit the location of the alleged incident of bribery or corruption, the website will use geo-tagging or location data to approximately identify the location where the incident happened. This location information is used for displaying the approximate location that the reported incident of bribery or corruption took place on the heat map provided on the website. This location information could also be used for fact finding, information gathering and complaint verification conducted by TISL. Information about the exact location as reported or submitted by you will only be disclosed to necessary authorities or other third parties only with your express consent and awareness, when the person who submitted the information is identifiable.",

    privacyPolicy_subTitle1P3: "1.3. Website Use Information",
    privacyPolicy_subTitle1P3DescriptionP1: "Similar to other websites, this website utilizes a standard technology called 'cookies' and server logs and may collect information about how the website is used by you.",
    privacyPolicy_subTitle1P3DescriptionP2: "A cookie is a very small text document which often includes an anonymous unique identifier. When you visit a website, that site's computer seeks certain electronic, automated permissions from your computer, to store this file in a part of your hard drive specifically designated for cookies. Each website can send its own cookie to your browser if your browser's preferences allow it.",
    privacyPolicy_subTitle1P3DescriptionP3: "Information gathered through cookies and server logs may include the date and time of visits, the pages viewed, the time spent on our website, and the websites visited just before and just after the website.",
    privacyPolicy_subTitle1P3DescriptionP4: "As you use the website, the website uses its cookies to differentiate you from other users so your preferences are unique to you. In some cases, the website also uses cookies to prevent you from seeing unnecessary advertisements or requiring you to log in more than is necessary for security. Cookies, in conjunction with our server log files, allow us to calculate the aggregate number of people visiting our website and parts of the website which are most popular with our users. This helps us gather feedback in order to constantly improve the website and our services. Cookies do not allow us to gather any personal information about you and we do not generally store any personal information that you provide to us, in your cookies.",
    privacyPolicy_subTitle1P3DescriptionP5: "Please note that, to protect your privacy, your browser will only permit the website to access the cookies it has already sent to you, not the cookies sent to you by other websites. This means that our website can neither access cookies sent to you by other websites, nor can other websites access or recognize cookies sent by our website to your computer.",

    privacyPolicy_subTitle2: "2. SHARING YOUR INFORMATION WITH THIRD PARTIES:",
    privacyPolicy_subTitle2DescriptionP1: "We will not share any information provided by you, without your express consent, with any third parties, except as detailed herein below.",
    privacyPolicy_subTitle2DescriptionP2: "When a complaint is submitted to this website, TISL will follow-up on actions that can be taken in relation to the alleged incident reported. In such instances, you may be required to share your personal information. Please note that we will obtain your confirmation before we undertake any action on the request submitted by you, in this regard. When a story or report is submitted to the website, TISL will moderate and post them as content on the website.",
    privacyPolicy_subTitle2DescriptionP3: "We do not share any information collected through cookies, as detailed above, with any third parties. We may be required to disclose information relating to your use of the website under applicable law or if we believe that such action is necessary to (a) conform to the law or comply with legal documents served upon us; (b) protect and defend our rights and property, the website, and/or the users of the website; or (c) act under circumstances to protect the safety of users of this website, our security or that of third parties.",

    privacyPolicy_subTitle3: "3. SECURITY FOR YOUR DATA:",
    privacyPolicy_subTitle3Description: "We will take all the appropriate precautions and the requisite security measures that are technically feasible to protect your information to prevent any unauthorized use, loss, misuse or alteration/abuse of your information. We may use the information we receive about you as part of our efforts to keep the website safe and secure, for internal operations including troubleshooting, data analysis, testing, research and service improvement. In any case, we restrict access of your personal information to select employees who need to know this information in order to operate, develop or improve our services, and who are contractually bound to protect such information.",

    privacyPolicy_subTitle4: "4. THIRD PARTY LINKS:",
    privacyPolicy_subTitle4DescriptionP1: "This website may contain links to third party websites that are not controlled by TISL. These third party links are available to you as a convenience and you agree to use these links at your own risk. Please be aware that neither Apesalli.lk nor TISL is responsible for the practices employed by third party websites linked to Apesalli.lk or content contained therein.",
    privacyPolicy_subTitle4DescriptionP2: "Often hyperlinks to other websites are provided solely as pointers to information on topics that may be useful to the users of the website. Please remember that when you use a link to go from our website to another web site, this Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including websites which contain further links, is subject to that third party website's own rules and policies and we have no liability whatsoever, in such circumstances.",


    privacyPolicy_subTitle5: "5. CHANGES TO THIS PRIVACY POLICY:",
    privacyPolicy_subTitle5Description1P1: "This Privacy Policy is effective as of ",
    privacyPolicy_subTitle5Description1P2: "In the event we change our Privacy Policy and procedures, we will post those changes on the website to keep you aware of what information we collect, how such information is used and under what circumstances we may disclose it.",
    privacyPolicy_subTitle5Description2: "However, changes to the privacy policy may be made without prior notice, and by accessing this website you are deemed to be aware of the new/changed Privacy Policy; and to have consented to the same. You are requested to regularly visit this page to update yourself of the current and prevailing Privacy Policy.",
    privacyPolicy_subTitle5Description3: "Note: Please do not post fraudulent or defamatory reports or stories for which you may be liable. If a law enforcement agency challenges such report on a legal basis, we will be required to share your IP address. If successfully sued in relation to any content posted or submitted by you, you could be held liable.",


    //Share Story Form(Page) -----------------------------------------------

    //title
    //shareStory_title: "Share Your Experience and Views on Bribery and Corruption",
    shareStory_title: "Report Incidents of Bribery and Corruption to TISL",

    //form
    shareStory_publicAuthorityFieldTitle: "Public Authority Involved?",
    shareStory_publicAuthorityFieldAns1: "Yes",
    shareStory_publicAuthorityFieldAns2: "No",

    shareStory_authorityNamePlaceholder: "Name and Designation of The Person Involved",
    shareStory_authorityNameTooltip: "Please Enter Name and the Designation of the Person Involved",

    shareStory_storyAuthorityPlaceholder: "Select Authority",
    shareStory_storyAuthorityTooltip: "Please Select Authority",

    shareStory_storyTitlePlaceholder: "Title",
    shareStory_storyTitleTooltip: "Please Enter Title of the Story",

    shareStory_dateFieldHelperText: "Incident Date",
    shareStory_dateFieldTooltip: "Please Enter Date of Incident",

    shareStory_districtFieldPlaceholder: "Select District",
    shareStory_districtFieldTooltip: "Please Select District",

    shareStory_storyFieldPlaceholder: "Your Story",
    shareStory_storyFieldTooltip: "Please Enter The Story",

    shareStory_storyFieldWordLimit: "Word Limit",

    shareStory_shareInfoFieldTitle: "Do You Want to Share Your Personal Information?",
    shareStory_shareInfoFieldAns1: "Yes",
    shareStory_shareInfoFieldAns2: "No",

    shareStory_nameField: "Name",
    shareStory_nameFieldTooltip: "Please Enter Name",

    shareStory_phoneField: "Contact Number",
    shareStory_phoneFieldTooltip: "Please Enter Contact Number",

    shareStory_emailField: "Email",
    shareStory_emailFieldTooltip: "Please Enter Email",

    //form validation errors
    shareStory_requiredErrorMessageFieldDate: "Date is Required !",
    shareStory_requiredErrorMessageFieldDistrict: "District is Required !",
    shareStory_requiredErrorMessageFieldStory: "Story is Required !",
    shareStory_requiredErrorMessageFieldName: "Name is Required !",
    shareStory_requiredErrorMessageFieldEmail: "Email is Required !",
    shareStory_requiredErrorMessageFieldContactNumber: "Contact Number is Required !",

    shareStory_ValidErrorMessageFieldEmail: "Enter Valid Email Address !",
    shareStory_contactNumberErrorMessageField: "Please Enter Valid Contact Number(Eg:0772345678)",

    //dialog boxes
    shareStory_storyFieldDialogMessage: "Please Enter Further Details Including The Reason For Asking For The Bribe, Name and Designation of The Public Official Involved, Time of The Incident, etc.",
    shareStory_storyFieldDialogButton: "Ok",

    shareStory_noteDialogMessageTitle: "Note",
    shareStory_noteDialogMessage: "We encourage you to provide as much personal information as possible, so we have a better chance of verifying the complaint/story.",

    shareStory_afterShareDialogMessageTitle: "Thank You for Standing Against Corruption !",
    shareStory_afterShareDialogMessageP1: "Your Reference Number is",
    shareStory_afterShareDialogMessageP2: "Your Report Submitted Successfully!.",
    shareStory_afterShareDialogMessageP3: '*If you wish to submit more supporting documents or evidence, please post them to "No.366, Nawala Road, Nawala, Rajagiriya." mentioning the reference number on the top left corner of the document. Or Fax the documents anonymously to 0112865777.',
    shareStory_afterShareDialogButton: "Ok",

    //info alert
    shareStory_infoText: "Stories will be shared following review by administrator ",

    //button
    shareStory_shareButton: "Share",

    //25 districts
    lng_Districts: [
        { value: "National level", lat: 6.9271, lng: 79.8612, label: "National level" },
        { value: "Ampara", lat: 7.3018, lng: 81.6747, label: "Ampara" },
        { value: "Anuradhapura", lat: 8.3114, lng: 80.4037, label: "Anuradhapura" },
        { value: "Badulla", lat: 6.9934, lng: 81.0550, label: "Badulla" },
        { value: "Batticaloa", lat: 7.7310, lng: 81.6747, label: "Batticaloa" },
        { value: "Colombo", lat: 6.9271, lng: 79.8612, label: "Colombo" },
        { value: "Galle", lat: 6.0535, lng: 80.2210, label: "Galle" },
        { value: "Gampaha", lat: 7.0840, lng: 80.0098, label: "Gampaha" },
        { value: "Hambantota", lat: 6.1429, lng: 81.1212, label: "Hambantota" },
        { value: "Jaffna", lat: 9.6615, lng: 80.0255, label: "Jaffna" },
        { value: "Kalutara", lat: 6.5854, lng: 79.9607, label: "Kalutara" },
        { value: "Kandy", lat: 7.2906, lng: 80.6337, label: "Kandy" },
        { value: "Kegalle", lat: 7.2513, lng: 80.3464, label: "Kegalle" },
        { value: "Kilinochchi", lat: 9.3803, lng: 80.3770, label: "Kilinochchi" },
        { value: "Kurunegala", lat: 7.4818, lng: 80.3609, label: "Kurunegala" },
        { value: "Mannar", lat: 8.9810, lng: 79.9044, label: "Mannar" },
        { value: "Matale", lat: 7.4675, lng: 80.6234, label: "Matale" },
        { value: "Matara", lat: 5.9549, lng: 80.5550, label: "Matara" },
        { value: "Monaragala", lat: 6.8728, lng: 81.3507, label: "Monaragala" },
        { value: "Mullaitivu", lat: 9.2671, lng: 80.8142, label: "Mullaitivu" },
        { value: "Nuwara Eliya", lat: 6.9497, lng: 80.7891, label: "Nuwara Eliya" },
        { value: "Polonnaruwa", lat: 7.9403, lng: 81.0188, label: "Polonnaruwa" },
        { value: "Puttalam", lat: 8.0408, lng: 79.8394, label: "Puttalam" },
        { value: "Ratnapura", lat: 6.7056, lng: 80.3847, label: "Ratnapura" },
        { value: "Trincomalee", lat: 8.5874, lng: 81.2152, label: "Trincomalee" },
        { value: "Vavuniya", lat: 8.7542, lng: 80.4982, label: "Vavuniya" },
    ],

    //public authorities
    lng_publicAuthorities: [
        {
            label: "Public Sector Organizations",
            value: "Public Sector Organizations",
        },
        {
            label: "Private Sector Organizations",
            value: "Private Sector Organizations",
        },
        {
            label: "Non-government Organizations",
            value: "Non-government Organizations",
        },
    ],

    //incident types
    lng_incidentTypes: [
        {
            key: 1,
            label: "Has Happened",
            value: "Has Happened",
        },
        {
            key: 2,
            label: "Is Happening",
            value: "Is Happening",
        },
        {
            key: 3,
            label: "Will Happen in the future",
            value: "Will Happen in the future",
        },
    ],

    //Report Corruption Form(Page) -----------------------------------------------

    //title
    //corruptionReport_title: "Report Incidents of Bribery and Corruption to TISL",
    corruptionReport_title: "Share Your Experience and Views on Bribery and Corruption",

    //stepper Steps
    corruptionReport_stepperSteps:
        [
            "Confidentiality",
            "Select Category",
            "General Information",
            "Category Information",
        ],

    //Step 1 - form 1
    corruptionReport_ShareDetailsCheckbox1: "I want to share my details.",
    corruptionReport_ShareDetailsCheckbox2: "I want to stay anonymous.",

    corruptionReport_step1NameFieldTooltip: "Please Enter Your Name",
    corruptionReport_step1NameFieldPlaceholder: "Name",
    corruptionReport_step1NameFieldErrorMessage: "Name is required!",
    corruptionReport_step1NameFieldIsString: "Please enter valid Name",

    corruptionReport_step1AgeFieldTooltip: "Please Enter Your Age",
    corruptionReport_step1AgeFieldPlaceholder: "Select Age",
    corruptionReport_step1AgeFieldErrorMessage: "Age is required!",

    corruptionReport_step1AddressFieldTooltip: "Please Enter Your Address",
    corruptionReport_step1AddressFieldPlaceholder: "Address",
    corruptionReport_step1AddressFieldErrorMessage: "Address is required!",

    corruptionReport_step1ContactNumberFieldTooltip: "Please Enter Your Contact Number",
    corruptionReport_step1ContactNumberFieldPlaceholder: "Contact Number",
    corruptionReport_step1ContactNumberFieldErrorMessage1: "Contact Number is required!",
    corruptionReport_step1ContactNumberFieldErrorMessage2: "Please enter valid Phone Number(Eg:0772345678)",

    corruptionReport_step1EmailFieldTooltip: "Please Enter Your Email",
    corruptionReport_step1EmailFieldPlaceholder: "Email",
    corruptionReport_step1EmailFieldErrorMessage1: "Email is required!",
    corruptionReport_step1EmailFieldErrorMessage1: "Please Enter Valid Email",

    corruptionReport_step1GenderFieldTooltip: "Select Gender",

    //genders
    lng_genders: [
        {
            key: "gender_type2",
            label: "Male",
            value: "Male",
        },
        {
            key: "gender_type3",
            label: "Female",
            value: "Female",
        },
        {
            key: "gender_type4",
            label: "Other",
            value: "Other",
        },
        {
            key: "gender_type5",
            label: "Prefer not to say",
            value: "Prefer not to say",
        },
    ],

    //anonymous form
    corruptionReport_step1AnonymousFormGenderFieldTooltip: "Please Select Your Gender",
    corruptionReport_step1AnonymousFormGenderFieldPlaceholder: "Select Gender",
    corruptionReport_step1AnonymousFormGenderFieldError: "Gender is required!",

    //dialogBox
    corruptionReport_nextDialogBoxStep1Title: "To continue with the processing please select one of the below,",

    corruptionReport_nextDialogBoxSelection: [
        {
            value: "Ask our staff to contact you",
        },
        {
            value: "Answer the questionnaire",
        }
    ],

    corruptionReport_nextDialogBoxStep1AskToContactTitleP1: "Thank You for Standing Against Corruption !",
    corruptionReport_nextDialogBoxStep1AskToContactTitleP2: "Your Reference Number is ",
    corruptionReport_nextDialogBoxStep1AskToContactDescriptionP1: "Your Report Submitted Successfully!.",
    corruptionReport_nextDialogBoxStep1AskToContactDescriptionP2: 'If you wish to submit more supporting documents or evidence, please post them to "No.366, Nawala Road, Nawala, Rajagiriya." mentioning the reference number on the top left corner of the document.',

    corruptionReport_anonymousSwitchDialogBoxTitle: "Note",
    corruptionReport_anonymousSwitchDialogBoxDescription: "We encourage you to provide as much personal information as possible, so we have a better chance of verifying the complaint/story.",


    //button
    corruptionReport_step1NextButton: "Next",

    corruptionReport_nextDialogBoxStep1Button1: "Next",
    corruptionReport_nextDialogBoxStep1Button2: "Cancel",

    corruptionReport_nextDialogBoxStep1AfterAskToContactButton: "Ok",

    corruptionReport_step1AnonymousFormNextButton: "Next",

    //Step 2 - selection

    //categories
    lng_corruption_types: [

        {
            key: 1,
            label: "Bribery",
            value: "Bribery",
            desc: "The offering, promising, giving, accepting or soliciting of an advantage as an inducement for an action which is illegal, unethical or a breach of trust. Inducements can take the form of gifts, loans, fees, rewards or other advantages (taxes, services, donations, etc.)",
        },
        {
            key: 2,
            label: "Sexual Favours",
            value: "Sexual Favours",
            desc: "when an individual who goes to a public, judicial, peace or police officer for a public service is required to perform a sexual favour in return for the service that they are entitled to by the law ",
        },
        {
            key: 3,
            label: "Misuse of Public Funds or Property",
            value: "Misuse of Public Funds or Property",
            desc: "When a person holding office in an institution, organisation or company dishonestly and illegally appropriates, uses or traffics the funds and goods they have been entrusted with for personal enrichment or other activities ",

        },
        {
            key: 4,
            label: "Misuse of Public Position",
            value: "Misuse of Public Position",
            desc: "A person who uses his office to improperly benefit oneself or another person "
        },
        {
            key: 5,
            label: "Conflict of Interest",
            value: "Conflict of Interest",
            desc: "Situation where an individual or the entity for which they work, whether a government, business, media outlet or civil society organization, is confronted with choosing between the duties and demands of their position and their own private interests ",
        },
        {
            key: 6,
            label: "Public Procurement Irregularities",
            value: "Public Procurement Irregularities",
            desc: "Any irregularities in multi-step process of established procedures to acquire goods and services by any individual, company or organization — from the initial needs assessment to the contract’s award and service delivery "
        },
        {
            key: 7,
            label: "Other",
            value: "Other",
            desc: "other",
        },

    ],

    //button
    corruptionReport_step2Button: "Back",

    //Step 3 - Form

    //form
    corruptionReport_step3PublicAuthorityFieldTitle: "Public Authority Involved",
    corruptionReport_step3PublicAuthorityFieldAns1: "Yes",
    corruptionReport_step3PublicAuthorityFieldAns2: "No",

    corruptionReport_step3PublicAuthorityNameFieldTooltip: "Please Select Authority Involved",
    corruptionReport_step3PublicAuthorityNameFieldPlaceHolder: "Select Authority",
    corruptionReport_step3PublicAuthorityNameFieldRequired: "Authority field is required!",

    corruptionReport_step3PublicAuthorityDesignationFieldTooltip: "Please Enter Name and Designation of the Person Involved",
    corruptionReport_step3PublicAuthorityDesignationFieldPlaceHolder: "Name and Designation of The Person Involved",
    corruptionReport_step3PublicAuthorityDesignationFieldRequired: "Name and Designation field is required!",

    corruptionReport_step3IncidentStatusFieldTooltip: "Please Select Incident Status",
    corruptionReport_step3IncidentStatusFieldPlaceHolder: "Select Incident Status",
    corruptionReport_step3IncidentStatusFieldRequired: "Incident Status field is required!",

    corruptionReport_step3DistrictTooltip: "Please Select District",
    corruptionReport_step3DistrictPlaceHolder: "Select District",
    corruptionReport_step3DistrictRequired: "District field is required!",

    corruptionReport_step3DivisionalSecretariatsTooltip: "Please Select Divisional Secretariat",
    corruptionReport_step3DivisionalSecretariatsPlaceHolder: "Select Divisional Secretariat",
    corruptionReport_step3DivisionalSecretariatsRequired: "Divisional Secretariat field is required!",

    corruptionReport_step3DateTooltip: "Please Select Date of Incident",
    corruptionReport_step3DatePlaceHolder: "Select Date",
    corruptionReport_step3DateRequired: "Date field is required!",

    corruptionReport_step3TimeTooltip: "Please Select Time of Incident",
    corruptionReport_step3TimePlaceHolder: "Select Time",
    corruptionReport_step3TimeRequired: "Time field is required!",

    corruptionReport_step3MapTitle: "If the automatically selected location is incorrect or if you know the exact location of the incident, please select it on the map below",
    //"Please Select Location",

    //buttons
    corruptionReport_step3BackButton: "Back",
    corruptionReport_step3NextButton: "Next",

    //step 4 - form

    //bribery from & sexual favor form common
    corruptionReport_step4_Bribery_field1Title: "Is the person you are reporting a*:",

    //person types
    lng_ReportingPersonTypes: [
        {
            key: 1,
            label: 'Agent',
            value: 'Agent',
        },
        {
            key: 2,
            label: 'Giver',
            value: 'Giver',
        },
        {
            key: 3,
            label: 'Recipient',
            value: 'Recipient',
        },
        {
            key: 4,
            label: 'Unknown',
            value: 'Unknown',
        },
    ],

    //bribe typeS
    lng_Corruption_Bribe_Types: [

        { key: 1, label: "Appointment", value: "Appointment" },
        { key: 2, label: "Cash", value: "Cash" },
        { key: 3, label: "Property or interest in property", value: "Property or interest in property" },
        { key: 4, label: "Commission", value: "Commission" },
        { key: 5, label: "Valuable Security", value: "Valuable Security" },
        { key: 6, label: "Failure to take action", value: "Failure to take action" },
        { key: 7, label: "Favor", value: "Favor" },
        { key: 8, label: "Fee", value: "Fee" },
        { key: 9, label: "Gift", value: "Gift" },
        { key: 10, label: "Loan", value: "Loan" },
        { key: 11, label: "Promise", value: "Promise" },
        { key: 12, label: "Reward", value: "Reward" },
        { key: 13, label: "Service", value: "Service" },

    ],

    //bribe type form field
    corruptionReport_step4_Bribery_field2Title: "Please Select Bribe Type",
    corruptionReport_step4_Bribery_field2Placeholder: "In what form was the bribe",
    corruptionReport_step4_Bribery_field2Error: "Bribe Type is required",

    //monetary value form field
    corruptionReport_step4_Bribery_field3Title: "Please Enter the amount",
    corruptionReport_step4_Bribery_field3Placeholder: "Monetary Value",

    //misused properties
    corruptionReport_step4_MisuseProperty_fieldTitle: "Please Select Type of The Property",
    corruptionReport_step4_MisuseProperty_fieldPlaceholder: "Select Type of The Property",
    corruptionReport_step4_MisuseProperty_fieldError: "Type of Property is required",

    lng_misused_PropertyTypes: [
        { key: 1, label: "Money", value: "Money" },
        { key: 2, label: "Land", value: "Land" },
        { key: 3, label: "Buildings", value: "Buildings" },
        { key: 4, label: "Vehicle", value: "Vehicle" },
        { key: 5, label: "Equipment", value: "Equipment" },
        { key: 6, label: "Public officials/Human resources", value: "Public officials/Human resources" },
        { key: 7, label: "Other", value: "Other" },
    ],

    //misused position
    corruptionReport_step4_MisusePosition_fieldTitle: "What benefit has the culprit obtained or given out?",
    corruptionReport_step4_MisusePosition_fieldPlaceholder: "What benefit has the culprit obtained?",
    corruptionReport_step4_MisusePosition_fieldError: "Benefit is required",

    //conflict of interest types
    lng_conflictOFInterest_AnswerTypes: [
        {
            key: "type1",
            label: "Yes",
            value: "Yes",
        },
        {
            key: "type2",
            label: "No",
            value: "No",
        },
        {
            key: "type3",
            label: "Unknown",
            value: "Unknown",
        }
    ],

    //conflict of interest form fields
    corruptionReport_step4_ConflictOFInterest_field1Title: "Does the person have a private interest?",
    corruptionReport_step4_ConflictOFInterest_field2Title: "Did the person disclose his or her interest?",
    corruptionReport_step4_ConflictOFInterest_field3Title: "Did he or she participate in the making of a decision that involved his or her private interest?",
    corruptionReport_step4_ConflictOFInterest_field4Title: "Was the person authorized to participate in the process of making that decision?",

    corruptionReport_step4_ConflictOFInterest_field5Title: "Please Select Nature of Interest",
    corruptionReport_step4_ConflictOFInterest_field5PlaceHolder: "Select Nature of Interest",

    lng_conflictOFInterest_NatureOfInterestTypes: [
        { key: 1, label: "Self-Dealing", value: "Self-Dealing" },
        { key: 2, label: "Outside Employment", value: "Outside Employment" },
        { key: 3, label: "Family Interests", value: "Family Interests" },
        { key: 4, label: "Gifts", value: "Gifts" },
        { key: 5, label: "Unknown", value: "Unknown" },
    ],

    //procurement form fields
    corruptionReport_step4_Procurement_fieldTitle: "What type of public procurement irregularity?",
    corruptionReport_step4_Procurement_fieldPlaceholder: "Type of the public procurement irregularity",

    lng_procurementTypes: [
        { key: 1, label: "Absence of open tendering", value: "Absence of open tendering" },
        { key: 2, label: "Collusion", value: "Collusion" },
        { key: 3, label: "Conflict of Interest", value: "Conflict of Interest" },
        { key: 4, label: "Lack of qualification", value: "Lack of qualification" },
        { key: 5, label: "Unknown", value: "Unknown" },
    ],

    //other details form filed
    corruptionReport_step4_Other_fieldTitle: "More Details",
    corruptionReport_step4_Other_fieldPlaceholder: "Any Other Details",
    corruptionReport_step4_Other_fieldError: "Description is required",

    //button
    corruptionReport_step4BackButton: "Back",
    corruptionReport_step4FinishButton: "Finish",

    //file upload
    corruptionReport_step4UploadButton: "Upload Images",
    corruptionReport_step4UploadButtonVideo: "Upload Videos",
    corruptionReport_step4TrashIconTooltip: "Remove",
    corruptionReport_step4UploadButtonText: "Files Chosen:",

    //instructions
    corruptionReport_step4InstructionsTitle: "Please Read Before Submit",
    corruptionReport_step4InstructionsDescription: 'Files may contain hidden personal information that could jeopardize your anonymity. If you wish to remain anonymous, please remove all such information before uploading a file. If you are unable to remove such information, please send a printed copy of the document anonymously to "No.366, Nawala Road, Nawala, Rajagiriya." Please mention the reference number that is provided at the end of the reporting process.',

    //Dialog box step 4
    corruptionReport_step4DialogBoxTitleP1: "Thank You for Standing Against Corruption !",
    corruptionReport_step4DialogBoxTitleP2: "Your Reference Number is ",

    corruptionReport_step4DialogBoxDescriptionP1: "Your Report Submitted Successfully!.",
    corruptionReport_step4DialogBoxDescriptionP2: 'If you wish to submit more supporting documents or evidence, please post them to "No.366, Nawala Road, Nawala, Rajagiriya." mentioning the reference number on the top left corner of the document.',
    corruptionReport_step4DialogBoxDescriptionP3: "Please wait. Uploading Attachments...",
    corruptionReport_step4DialogBoxDescriptionP4: "Attachments Uploaded successfully!",

    corruptionReport_step4DialogBoxButton: "Ok",

    //Report Election Form(Page) -----------------------------------------------

    //title
    electionReport_title: "Report Election Violations",

    //stepper steps
    electionReport_stepperSteps:
        [
            "Personal Information",
            "General Information",
            "Submit",
        ],

    //stepper step 1 - form
    electionReport_step1_nameFieldTitle: "Please Enter Your Name",
    electionReport_step1_nameFieldPlaceholder: "Name",
    electionReport_step1_nameFieldError: "Name is required",

    electionReport_step1_ageFieldTitle: "Please Select Your Age",
    electionReport_step1_ageFieldPlaceholder: "Select Age",
    electionReport_step1_ageFieldError: "Age is required!",

    electionReport_step1_addressFieldTitle: "Please Enter Your Address",
    electionReport_step1_addressFieldPlaceholder: "Address",
    electionReport_step1_addressFieldError: "Address is required!",

    electionReport_step1_contactNumberFieldTitle: "Please Enter Your Contact Number",
    electionReport_step1_contactNumberFieldPlaceholder: "Contact Number",
    electionReport_step1_contactNumberFieldError1: "Contact Number is required!",
    electionReport_step1_contactNumberFieldError2: "Please Enter valid Phone Number(Eg:0772345678)",

    electionReport_step1_emailFieldTitle: "Please Enter Your Email",
    electionReport_step1_emailFieldPlaceholder: "Email",
    electionReport_step1_emailFieldError1: "Email is required!",
    electionReport_step1_emailFieldError2: "Please Enter valid Email!",

    electionReport_step1_genderFieldTitle: "Select Gender",

    //buttons
    electionReport_step1_NextButton: "Next",

    //stepper step 2 - form
    electionReport_step2_publicAuthorityInvolvedFieldTitle: "Public authority involved?",
    electionReport_step2_publicAuthorityInvolvedFieldAns1: "Yes",
    electionReport_step2_publicAuthorityInvolvedFieldAns2: "No",

    electionReport_step2_publicAuthorityFieldTitle: "Please Select Authority Involved",
    electionReport_step2_publicAuthorityFieldPlaceholder: "Select Authority",
    electionReport_step2_publicAuthorityFieldError: "Authority field is required!",

    electionReport_step2_publicAuthorityDesignationFieldTitle: "Please Enter Name and Designation of the Person Involved",
    electionReport_step2_publicAuthorityDesignationFieldPlaceholder: "Name and Designation of person involved",
    electionReport_step2_publicAuthorityDesignationFieldError: "Name and Designation field is required!",

    electionReport_step2_incidentTypeFieldTitle: "Please Select Incident",
    electionReport_step2_incidentTypeFieldPlaceholder: "Select Incident Type",
    electionReport_step2_incidentTypeFieldError: "Incident Type field is required!",

    electionReport_step2_districtFieldTitle: "Please Select District",
    electionReport_step2_districtFieldPlaceholder: "Select District",
    electionReport_step2_districtFieldError: "District field is required!",

    electionReport_step2_divisionalSecretariatFieldTitle: "Please select Divisional Secretariat",
    electionReport_step2_divisionalSecretariatFieldPlaceholder: "Select Divisional Secretariats",
    electionReport_step2_divisionalSecretariatFieldError: "Divisional Secretariats field is required!",

    electionReport_step2_dateFieldTitle: "Please Select Date of Incident",
    electionReport_step2_DateFieldError: "Date field is required!",

    electionReport_step2_timeFieldTitle: "Please Select Time of Incident",
    electionReport_step2_TimeFieldError: "Time field is required!",

    electionReport_step2_LocationMapField: "If the automatically selected location is incorrect or if you know the exact location of the incident, please select it on the map below.",

    //buttons
    electionReport_step2_backButton: "Back",
    electionReport_step2_nextButton: "Next",

    //stepper step 3 - form

    electionReport_step3_Title: "More Specific Information",

    electionReport_step3_noteText: "Apesalli.lk only monitors misuse of public property/resources during elections",

    //form
    electionReport_step3_misusedFieldTitle: "What is being misused? Select one or more from below options.",

    //misused types
    lan_election_misusedTypes: [
        {
            key: 1,
            label: "Use of State Vehicles for Election Campaign Purposes ",
            value: "Use of State Vehicles for Election Campaign Purposes ",
        },
        {
            key: 2,
            label: "Use of State Building & Premises/ Lands for Election Campaign Purposes ",
            value: "Use of State Building & Premises/ Lands for Election Campaign Purposes ",
        },
        {
            key: 3,
            label: "Appointments, Transfers & Promotions for State Officials targeting Election ",
            value: "Appointments, Transfers & Promotions for State Officials targeting Election ",
        },
        {
            key: 4,
            label: "Misusing State sponsored Development Activities ",
            value: "Misusing State sponsored Development Activities ",
        },
        {
            key: 5,
            label: "State Officers acting in favour of Political Parties & in Breach of Official Duties ",
            value: "State Officers acting in favour of Political Parties & in Breach of Official Duties ",
        },
        {
            key: 6,
            label: "Giving Incentives/Equipment with Political Objectives ",
            value: "Giving Incentives/Equipment with Political Objectives ",
        },
        {
            key: 7,
            label: "Use of State Officials & Security Resources for Election Campaign ",
            value: "Use of State Officials & Security Resources for Election Campaign ",
        },
        {
            key: 8,
            label: "During the Period of an Election State Officers using Social Media for the Promotion of or in Prejudice to Any Party, Group or Candidate ",
            value: "During the Period of an Election State Officers using Social Media for the Promotion of or in Prejudice to Any Party, Group or Candidate ",
        },
        {
            key: 9,
            label: "Manipulation of State sponsored Livelihood Grants & Allowances for Election Purposes ",
            value: "Manipulation of State sponsored Livelihood Grants & Allowances for Election Purposes ",
        },
        {
            key: 10,
            label: "Using Equipment and Facilities of State Media Institutions Without Paying ",
            value: "Using Equipment and Facilities of State Media Institutions Without Paying ",
        },
        {
            key: 11,
            label: "Participating in Opening Ceremonies/ Government Funded Functions ",
            value: "Participating in Opening Ceremonies/ Government Funded Functions ",
        },
        {
            key: 12,
            label: "Other Illegal Election Campaigns Using State Resources ",
            value: "Other Illegal Election Campaigns Using State Resources ",
        },
    ],

    //misused nature types
    lng_misused_natureTypes: [
        {
            key: "misused_description_types 1",
            label: "Use of State Vehicles to put up posters/ organize political rallies and meetings/ transport voters and similar election related activities",
            value: "Use of State Vehicles to put up posters/ organize political rallies and meetings/ transport voters and similar election related activities",
        },
        {
            key: "misused_description_types 2",
            label: "Use of State buildings, premises or lands for election rallies/meetings, to maintain offices for election candidates and to display posters/cutouts without making the necessary payments ",
            value: "Use of State buildings, premises or lands for election rallies/meetings, to maintain offices for election candidates and to display posters/cutouts without making the necessary payments ",
        },
        {
            key: "misused_description_types 3",
            label: "Using State Funds to purchase and distribute sports equipment, agricultural equipment, and similar welfare aid during election times.",
            value: "Using State Funds to purchase and distribute sports equipment, agricultural equipment, and similar welfare aid during election times.",
        }
    ],

    //form fields
    electionReport_step3_natureOfMisuseFieldTitle: "Describe the misuse",
    electionReport_step3_natureOfMisuseFieldPlaceholder: "Please Describe Nature of Misuse",
    electionReport_step3_natureOfMisuseFieldError: "Nature of Misuse field is required",

    electionReport_step3_politicalPartyFieldTitle: "Details of the Political Party Involved:",
    electionReport_step3_politicalPartyFieldPlaceholder: "Political Party Involved",

    //upload
    electionReport_step3_uploadButtonTitle1: "Upload Images",
    electionReport_step3_uploadButtonTitle2: "Selected:",

    electionReport_step3_trashButtonTooltip: "Remove",

    //buttons
    electionReport_step3_finishButton: "Finish",

    //dialogBox
    electionReport_step3_DialogBoxTitle1: "Thank you for standing against corruption !",
    electionReport_step3_DialogBoxTitle2: "Your Reference Number is ",
    electionReport_step3_DialogBoxDescriptionP1: "Your Report Submitted Successfully!.",
    electionReport_step3_DialogBoxDescriptionP2: "If you wish to submit more supporting documents or evidence, please Fax the documents anonymously to 0112865777.",
    electionReport_step3_DialogBoxButton: "Ok",

    //divisional secretariats

    lng_report_divisional_secretariats: [

        {
            key: 1,
            district: 'Kandy',
            divisional_secretariats: [
                { key: 1, value: 'Akurana', label: 'Akurana', },
                { key: 2, value: 'Delthota', label: 'Delthota', },
                { key: 3, value: 'Doluwa', label: 'Doluwa', },
                { key: 4, value: 'Gagawata Korale', label: 'Gagawata Korale', },
                { key: 5, value: 'Ganga Ihala Korale', label: 'Ganga Ihala Korale', },
                { key: 6, value: 'Harispattuwa', label: 'Harispattuwa', },
                { key: 7, value: 'Hatharaliyadda', label: 'Hatharaliyadda', },
                { key: 8, value: 'Kundasale', label: 'Kundasale', },
                { key: 9, value: 'Medadumbara', label: 'Medadumbara', },
                { key: 10, value: 'Minipe', label: 'Minipe', },
                { key: 11, value: 'Panvila', label: 'Panvila', },
                { key: 12, value: 'Pasbage Korale', label: 'Pasbage Korale', },
                { key: 13, value: 'Pathadumbara', label: 'Pathadumbara', },
                { key: 14, value: 'Pathahewaheta', label: 'Pathahewaheta', },
                { key: 15, value: 'Poojapitiya', label: 'Poojapitiya', },
                { key: 16, value: 'Thumpane', label: 'Thumpane', },
                { key: 17, value: 'Udadumbara', label: 'Udadumbara', },
                { key: 18, value: 'Udapalatha', label: 'Udapalatha', },
                { key: 19, value: 'Udunuwara', label: 'Udunuwara', },
                { key: 20, value: 'Yatinuwara', label: 'Yatinuwara', },
            ],

        },
        {
            key: 2,
            district: 'Matale',

            divisional_secretariats: [
                { key: 1, value: 'Ambanganga Korale', label: 'Ambanganga Korale', },
                { key: 2, value: 'Dambulla', label: 'Dambulla', },
                { key: 3, value: 'Galewela', label: 'Galewela', },
                { key: 4, value: 'Laggala-Pallegama', label: 'Laggala-Pallegama', },
                { key: 5, value: 'Matale', label: 'Matale', },
                { key: 6, value: 'Naula', label: 'Naula', },
                { key: 7, value: 'Pallepola', label: 'Pallepola', },
                { key: 8, value: 'Rattota', label: 'Rattota', },
                { key: 9, value: 'Ukuwela', label: 'Ukuwela', },
                { key: 10, value: 'Wilgamuwa', label: 'Wilgamuwa', },
                { key: 11, value: 'Yatawatta', label: 'Yatawatta', },
            ],

        },
        {
            key: 3,
            district: 'Nuwara Eliya',

            divisional_secretariats: [
                { key: 1, value: 'Ambagamuwa', label: 'Ambagamuwa', },
                { key: 2, value: 'Hanguranketha', label: 'Hanguranketha', },
                { key: 3, value: 'Kothmale', label: 'Kothmale', },
                { key: 4, value: 'Nuwara Eliya', label: 'Nuwara Eliya', },
                { key: 5, value: 'Walapane', label: 'Walapane', },
            ],

        },
        {
            key: 4,
            district: 'Ampara',

            divisional_secretariats: [
                { key: 1, value: 'Addalachchenai', label: 'Addalachchenai', },
                { key: 2, value: 'Akkaraipattu', label: 'Akkaraipattu', },
                { key: 3, value: 'Alayadiwembu', label: 'Alayadiwembu', },
                { key: 4, value: 'Ampara', label: 'Ampara', },
                { key: 5, value: 'Damana', label: 'Damana', },
                { key: 6, value: 'Dehiattakandiya', label: 'Dehiattakandiya', },
                { key: 7, value: 'Eragama', label: 'Eragama', },
                { key: 8, value: 'Kalmunai Muslim', label: 'Kalmunai Muslim', },
                { key: 9, value: 'Kalmunai Tamil', label: 'Kalmunai Tamil', },
                { key: 10, value: 'Karaitivu', label: 'Karaitivu', },
                { key: 11, value: 'Lahugala', label: 'Lahugala', },
                { key: 12, value: 'Mahaoya', label: 'Mahaoya', },
                { key: 13, value: 'Navithanveli', label: 'Navithanveli', },
                { key: 14, value: 'Ninthavur', label: 'Ninthavur', },
                { key: 15, value: 'Padiyathalawa', label: 'Padiyathalawa', },
                { key: 16, value: 'Pothuvil', label: 'Pothuvil', },
                { key: 17, value: 'Sainthamarathu', label: 'Sainthamarathu', },
                { key: 18, value: 'Samanthurai', label: 'Samanthurai', },
                { key: 19, value: 'Thirukkovil', label: 'Thirukkovil', },
                { key: 20, value: 'Uhana', label: 'Uhana', },
            ],

        },
        {
            key: 5,
            district: 'Batticaloa',

            divisional_secretariats: [
                { key: 1, value: 'Eravur Pattu', label: 'Eravur Pattu', },
                { key: 2, value: 'Eravur Town', label: 'Eravur Town', },
                { key: 3, value: 'Kattankudy', label: 'Kattankudy', },
                { key: 4, value: 'Koralai Pattu', label: 'Koralai Pattu', },
                { key: 5, value: 'Koralai Pattu Central', label: 'Koralai Pattu Central', },
                { key: 6, value: 'Koralai Pattu North', label: 'Koralai Pattu North', },
                { key: 7, value: 'Koralai Pattu South', label: 'Koralai Pattu South', },
                { key: 8, value: 'Koralai Pattu West', label: 'Koralai Pattu West', },
                { key: 9, value: 'Manmunai North', label: 'Manmunai North', },
                { key: 10, value: 'Manmunai Pattu', label: 'Manmunai Pattu', },
                { key: 11, value: 'Manmunai S. and Eruvil Pattu', label: 'Manmunai S. and Eruvil Pattu', },
                { key: 12, value: 'Manmunai South West', label: 'Manmunai South West', },
                { key: 13, value: 'Manmunai West', label: 'Manmunai West', },
                { key: 14, value: 'Porativu Pattu', label: 'Porativu Pattu', },
            ],

        },
        {
            key: 6,
            district: 'Trincomalee',

            divisional_secretariats: [
                { key: 1, value: 'Gomarankadawala', label: 'Gomarankadawala', },
                { key: 2, value: 'Kantalai', label: 'Kantalai', },
                { key: 3, value: 'Kinniya', label: 'Kinniya', },
                { key: 4, value: 'Kuchchaveli', label: 'Kuchchaveli', },
                { key: 5, value: 'Morawewa', label: 'Morawewa', },
                { key: 6, value: 'Muttur', label: 'Muttur', },
                { key: 7, value: 'Padavi Sri Pura', label: 'Padavi Sri Pura', },
                { key: 8, value: 'Seruvila', label: 'Seruvila', },
                { key: 9, value: 'Thambalagamuwa', label: 'Thambalagamuwa', },
                { key: 10, value: 'Trincomalee', label: 'Trincomalee', },
                { key: 11, value: 'Verugal', label: 'Verugal', },

            ],


        },
        {
            key: 7,
            district: 'Anuradhapura',

            divisional_secretariats: [
                { key: 1, value: 'Galnewa', label: 'Galnewa', },
                { key: 2, value: 'Galenbindunuwewa', label: 'Galenbindunuwewa', },
                { key: 3, value: 'Horowpothana', label: 'Horowpothana', },
                { key: 4, value: 'Ipalogama', label: 'Ipalogama', },
                { key: 5, value: 'Kahatagasdigiliya', label: 'Kahatagasdigiliya', },
                { key: 6, value: 'Kebithigollewa', label: 'Kebithigollewa', },
                { key: 7, value: 'Kekirawa', label: 'Kekirawa', },
                { key: 8, value: 'Mahavilachchiya', label: 'Mahavilachchiya', },
                { key: 9, value: 'Medawachchiya', label: 'Medawachchiya', },
                { key: 10, value: 'Mihinthale', label: 'Mihinthale', },
                { key: 11, value: 'Nachchadoowa', label: 'Nachchadoowa', },
                { key: 12, value: 'Nochchiyagama', label: 'Nochchiyagama', },
                { key: 13, value: 'Nuwaragam Palatha Central', label: 'Nuwaragam Palatha Central', },
                { key: 14, value: 'Nuwaragam Palatha East', label: 'Nuwaragam Palatha East', },
                { key: 15, value: 'Padaviya', label: 'Padaviya', },
                { key: 16, value: 'Palagala', label: 'Palagala', },
                { key: 17, value: 'Palugaswewa', label: 'Palugaswewa', },
                { key: 18, value: 'Rajanganaya', label: 'Rajanganaya', },
                { key: 19, value: 'Rambewa', label: 'Rambewa', },
                { key: 20, value: 'Thalawa', label: 'Thalawa', },
                { key: 21, value: 'Thambuttegama', label: 'Thambuttegama', },
                { key: 22, value: 'Thirappane', label: 'Thirappane', },

            ],

        },
        {
            key: 8,
            district: 'Polonnaruwa',

            divisional_secretariats: [
                { key: 1, value: 'Dimbulagala', label: 'Dimbulagala', },
                { key: 2, value: 'Elahera', label: 'Elahera', },
                { key: 3, value: 'Hingurakgoda', label: 'Hingurakgoda', },
                { key: 4, value: 'Lankapura', label: 'Lankapura', },
                { key: 5, value: 'Medirigiriya', label: 'Medirigiriya', },
                { key: 6, value: 'Thamankaduwa', label: 'Thamankaduwa', },
                { key: 7, value: 'Welikanda', label: 'Welikanda', },

            ],

        },
        {
            key: 9,
            district: 'Jaffna',

            divisional_secretariats: [
                { key: 1, value: 'Delft', label: 'Delft', },
                { key: 2, value: 'Island North', label: 'Island North', },
                { key: 3, value: 'Island South', label: 'Island South', },
                { key: 4, value: 'Jaffna', label: 'Jaffna', },
                { key: 5, value: 'Karainagar', label: 'Karainagar', },
                { key: 6, value: 'Nallur', label: 'Nallur', },
                { key: 7, value: 'Thenmaradchi', label: 'Thenmaradchi', },
                { key: 8, value: 'Vadamaradchi East', label: 'Vadamaradchi East', },
                { key: 9, value: 'Vadamaradchi North', label: 'Vadamaradchi North', },
                { key: 10, value: 'Vadamaradchi South-West', label: 'Vadamaradchi South-West', },
                { key: 11, value: 'Valikamam East', label: 'Valikamam East', },
                { key: 12, value: 'Valikamam North', label: 'Valikamam North', },
                { key: 13, value: 'Valikamam South', label: 'Valikamam South', },
                { key: 14, value: 'Valikamam South-West', label: 'Valikamam South-West', },
                { key: 15, value: 'Valikamam West', label: 'Valikamam West', },

            ],


        },
        {
            key: 10,
            district: 'Kilinochchi',

            divisional_secretariats: [
                { key: 1, value: 'Kandavalai', label: 'Kandavalai', },
                { key: 2, value: 'Karachchi', label: 'Karachchi', },
                { key: 3, value: 'Pachchilaipalli', label: 'Pachchilaipalli', },
                { key: 4, value: 'Poonakary', label: 'Poonakary', },

            ],

        },
        {
            key: 11,
            district: 'Mannar',

            divisional_secretariats: [
                { key: 1, value: 'Madhu', label: 'Madhu', },
                { key: 2, value: 'Mannar', label: 'Mannar', },
                { key: 3, value: 'Manthai West', label: 'Manthai West', },
                { key: 4, value: 'Musalai', label: 'Musalai', },
                { key: 5, value: 'Nanaddan', label: 'Nanaddan', },

            ],

        },
        {
            key: 12,
            district: 'Mullaitivu',

            divisional_secretariats: [
                { key: 1, value: 'Manthai East', label: 'Manthai East', },
                { key: 2, value: 'Maritimepattu', label: 'Maritimepattu', },
                { key: 3, value: 'Oddusuddan', label: 'Oddusuddan', },
                { key: 4, value: 'Puthukudiyiruppu', label: 'Puthukudiyiruppu', },
                { key: 5, value: 'Thunukkai', label: 'Thunukkai', },
                { key: 6, value: 'Welioya', label: 'Welioya', },

            ],

        },
        {
            key: 13,
            district: 'Vavuniya',

            divisional_secretariats: [
                { key: 1, value: 'Vavuniya', label: 'Vavuniya', },
                { key: 2, value: 'Vavuniya North', label: 'Vavuniya North', },
                { key: 3, value: 'Vavuniya South', label: 'Vavuniya South', },
                { key: 4, value: 'Vengalacheddikulam', label: 'Vengalacheddikulam', },

            ],


        },
        {
            key: 14,
            district: 'Kurunegala',

            divisional_secretariats: [
                { key: 1, value: 'Alawwa', label: 'Alawwa', },
                { key: 2, value: 'Ambanpola', label: 'Ambanpola', },
                { key: 3, value: 'Bamunakotuwa', label: 'Bamunakotuwa', },
                { key: 4, value: 'Bingiriya', label: 'Bingiriya', },
                { key: 5, value: 'Ehetuwewa', label: 'Ehetuwewa', },
                { key: 6, value: 'Galgamuwa', label: 'Galgamuwa', },
                { key: 7, value: 'Ganewatta', label: 'Ganewatta', },
                { key: 8, value: 'Giribawa', label: 'Giribawa', },
                { key: 9, value: 'Ibbagamuwa', label: 'Ibbagamuwa', },
                { key: 10, value: 'Katupotha', label: 'Katupotha', },

                { key: 11, value: 'Kobeigane', label: 'Kobeigane', },
                { key: 12, value: 'Kotavehera', label: 'Kotavehera', },
                { key: 13, value: 'Kuliyapitiya East', label: 'Kuliyapitiya East', },
                { key: 14, value: 'Kuliyapitiya West', label: 'Kuliyapitiya West', },
                { key: 15, value: 'Kurunegala', label: 'Kurunegala', },
                { key: 16, value: 'Mahawa', label: 'Mahawa', },
                { key: 17, value: 'Mallawapitiya', label: 'Mallawapitiya', },
                { key: 18, value: 'Maspotha', label: 'Maspotha', },
                { key: 19, value: 'Mawathagama', label: 'Mawathagama', },
                { key: 20, value: 'Narammala', label: 'Narammala', },

                { key: 21, value: 'Nikaweratiya', label: 'Nikaweratiya', },
                { key: 22, value: 'Panduwasnuwara', label: 'Panduwasnuwara', },
                { key: 23, value: 'Pannala', label: 'Pannala', },
                { key: 24, value: 'Polgahawela', label: 'Polgahawela', },
                { key: 25, value: 'Polpithigama', label: 'Polpithigama', },
                { key: 26, value: 'Rasnayakapura', label: 'Rasnayakapura', },
                { key: 27, value: 'Rideegama', label: 'Rideegama', },
                { key: 28, value: 'Udubaddawa', label: 'Udubaddawa', },
                { key: 29, value: 'Wariyapola', label: 'Wariyapola', },
                { key: 30, value: 'Weerambugedara', label: 'Weerambugedara', },

            ],

        },
        {
            key: 15,
            district: 'Puttalam',

            divisional_secretariats: [
                { key: 1, value: 'Anamaduwa', label: 'Anamaduwa', },
                { key: 2, value: 'Arachchikattuwa', label: 'Arachchikattuwa', },
                { key: 3, value: 'Chilaw', label: 'Chilaw', },
                { key: 4, value: 'Dankotuwa', label: 'Dankotuwa', },
                { key: 5, value: 'Kalpitiya', label: 'Kalpitiya', },
                { key: 6, value: 'Karuwalagaswewa', label: 'Karuwalagaswewa', },
                { key: 7, value: 'Madampe', label: 'Madampe', },
                { key: 8, value: 'Mahakumbukkadawala', label: 'Mahakumbukkadawala', },
                { key: 9, value: 'Mahawewa', label: 'Mahawewa', },
                { key: 10, value: 'Mundalama', label: 'Mundalama', },
                { key: 11, value: 'Nattandiya', label: 'Nattandiya', },
                { key: 12, value: 'Nawagattegama', label: 'Nawagattegama', },
                { key: 13, value: 'Pallama', label: 'Pallama', },
                { key: 14, value: 'Puttalam', label: 'Puttalam', },
                { key: 15, value: 'Vanathavilluwa', label: 'Vanathavilluwa', },
                { key: 16, value: 'Wennappuwa', label: 'Wennappuwa', },

            ],

        },
        {
            key: 16,
            district: 'Kegalle',

            divisional_secretariats: [
                { key: 1, value: 'Aranayaka', label: 'Aranayaka', },
                { key: 2, value: 'Bulathkohupitiya', label: 'Bulathkohupitiya', },
                { key: 3, value: 'Dehiovita', label: 'Dehiovita', },
                { key: 4, value: 'Deraniyagala', label: 'Deraniyagala', },
                { key: 5, value: 'Galigamuwa', label: 'Galigamuwa', },
                { key: 6, value: 'Kegalle', label: 'Kegalle', },
                { key: 7, value: 'Mawanella', label: 'Mawanella', },
                { key: 8, value: 'Rambukkana', label: 'Rambukkana', },
                { key: 9, value: 'Ruwanwella', label: 'Ruwanwella', },
                { key: 10, value: 'Warakapola', label: 'Warakapola', },
                { key: 11, value: 'Yatiyanthota', label: 'Yatiyanthota', },

            ],

        },
        {
            key: 17,
            district: 'Ratnapura',

            divisional_secretariats: [
                { key: 1, value: 'Ayagama', label: 'Ayagama', },
                { key: 2, value: 'Balangoda', label: 'Balangoda', },
                { key: 3, value: 'Eheliyagoda', label: 'Eheliyagoda', },
                { key: 4, value: 'Elapattha', label: 'Elapattha', },
                { key: 5, value: 'Embilipitiya', label: 'Embilipitiya', },
                { key: 6, value: 'Godakawela', label: 'Godakawela', },

                { key: 7, value: 'Imbulpe', label: 'Imbulpe', },
                { key: 8, value: 'Kahawatta', label: 'Kahawatta', },
                { key: 9, value: 'Kalawana', label: 'Kalawana', },
                { key: 10, value: 'Kiriella', label: 'Kiriella', },
                { key: 11, value: 'Kolonna', label: 'Kolonna', },
                { key: 12, value: 'Kuruvita', label: 'Kuruvita', },

                { key: 13, value: 'Nivithigala', label: 'Nivithigala', },
                { key: 14, value: 'Opanayaka', label: 'Opanayaka', },
                { key: 15, value: 'Pelmadulla', label: 'Pelmadulla', },
                { key: 16, value: 'Ratnapura', label: 'Ratnapura', },
                { key: 17, value: 'Weligepola', label: 'Weligepola', },
                // { key: 18, value: 'Pelmadulla', label: 'Pelmadulla', },

            ],

        },
        {
            key: 18,
            district: 'Galle',

            divisional_secretariats: [
                { key: 1, value: 'Akmeemana', label: 'Akmeemana', },
                { key: 2, value: 'Ambalangoda', label: 'Ambalangoda', },
                { key: 3, value: 'Baddegama', label: 'Baddegama', },
                { key: 4, value: 'Balapitiya', label: 'Balapitiya', },
                { key: 5, value: 'Benthota', label: 'Benthota', },
                { key: 6, value: 'Bope-Poddala', label: 'Bope-Poddala', },
                { key: 7, value: 'Elpitiya', label: 'Elpitiya', },
                { key: 8, value: 'Galle', label: 'Galle', },
                { key: 9, value: 'Gonapinuwala', label: 'Gonapinuwala', },
                { key: 10, value: 'Habaraduwa', label: 'Habaraduwa', },
                { key: 11, value: 'Hikkaduwa', label: 'Hikkaduwa', },
                { key: 12, value: 'Imaduwa', label: 'Imaduwa', },
                { key: 13, value: 'Karandeniya', label: 'Karandeniya', },
                { key: 14, value: 'Nagoda', label: 'Nagoda', },
                { key: 15, value: 'Neluwa', label: 'Neluwa', },
                { key: 16, value: 'Niyagama', label: 'Niyagama', },
                { key: 17, value: 'Thawalama', label: 'Thawalama', },
                { key: 18, value: 'Welivitiya-Divithura', label: 'Welivitiya-Divithura', },
                { key: 19, value: 'Yakkalamulla', label: 'Yakkalamulla', },

            ],
        },
        {
            key: 19,
            district: 'Hambantota',

            divisional_secretariats: [
                { key: 1, value: 'Ambalantota', label: 'Ambalantota', },
                { key: 2, value: 'Angunakolapelessa', label: 'Angunakolapelessa', },
                { key: 3, value: 'Beliatta', label: 'Beliatta', },
                { key: 4, value: 'Hambantota', label: 'Hambantota', },
                { key: 5, value: 'Katuwana', label: 'Katuwana', },
                { key: 6, value: 'Lunugamvehera', label: 'Lunugamvehera', },
                { key: 7, value: 'Okewela', label: 'Okewela', },
                { key: 8, value: 'Sooriyawewa', label: 'Sooriyawewa', },
                { key: 9, value: 'Tangalle', label: 'Tangalle', },
                { key: 10, value: 'Thissamaharama', label: 'Thissamaharama', },
                { key: 11, value: 'Walasmulla', label: 'Walasmulla', },
                { key: 12, value: 'Weeraketiya', label: 'Weeraketiya', },
            ],

        },
        {
            key: 20,
            district: 'Matara',

            divisional_secretariats: [
                { key: 1, value: 'Akuressa', label: 'Akuressa', },
                { key: 2, value: 'Athuraliya', label: 'Athuraliya', },
                { key: 3, value: 'Devinuwara', label: 'Devinuwara', },
                { key: 4, value: 'Dickwella', label: 'Dickwella', },
                { key: 5, value: 'Hakmana', label: 'Hakmana', },
                { key: 6, value: 'Kamburupitiya', label: 'Kamburupitiya', },
                { key: 7, value: 'Kirinda Puhulwella', label: 'Kirinda Puhulwella', },
                { key: 8, value: 'Kotapola', label: 'Kotapola', },
                { key: 9, value: 'Malimbada', label: 'Malimbada', },
                { key: 10, value: 'Matara', label: 'Matara', },
                { key: 11, value: 'Mulatiyana', label: 'Mulatiyana', },
                { key: 12, value: 'Pasgoda', label: 'Pasgoda', },
                { key: 13, value: 'Pitabeddara', label: 'Pitabeddara', },
                { key: 14, value: 'Thihagoda', label: 'Thihagoda', },
                { key: 15, value: 'Weligama', label: 'Weligama', },
                { key: 16, value: 'Welipitiya', label: 'Welipitiya', },
            ],

        },
        {
            key: 21,
            district: 'Badulla',

            divisional_secretariats: [
                { key: 1, value: 'Badulla', label: 'Badulla', },
                { key: 2, value: 'Bandarawela', label: 'Bandarawela', },
                { key: 3, value: 'Ella', label: 'Ella', },
                { key: 4, value: 'Haldummulla', label: 'Haldummulla', },
                { key: 5, value: 'Hali-Ela', label: 'Hali-Ela', },
                { key: 6, value: 'Haputale', label: 'Haputale', },
                { key: 7, value: 'Kandaketiya', label: 'Kandaketiya', },
                { key: 8, value: 'Lunugala', label: 'Lunugala', },
                { key: 9, value: 'Mahiyanganaya', label: 'Mahiyanganaya', },
                { key: 10, value: 'Meegahakivula', label: 'Meegahakivula', },
                { key: 11, value: 'Passara', label: 'Passara', },
                { key: 12, value: 'Rideemaliyadda', label: 'Rideemaliyadda', },
                { key: 13, value: 'Soranathota', label: 'Soranathota', },
                { key: 14, value: 'Uva-Paranagama', label: 'Uva-Paranagama', },
                { key: 15, value: 'Welimada', label: 'Welimada', },

            ],

        },
        {
            key: 22,
            district: 'Monaragala',

            divisional_secretariats: [
                { key: 1, value: 'Badalkumbura', label: 'Badalkumbura', },
                { key: 2, value: 'Bibile', label: 'Bibile', },
                { key: 3, value: 'Buttala', label: 'Buttala', },
                { key: 4, value: 'Katharagama', label: 'Katharagama', },
                { key: 5, value: 'Madulla', label: 'Madulla', },
                { key: 6, value: 'Medagama', label: 'Medagama', },
                { key: 7, value: 'Moneragala', label: 'Moneragala', },
                { key: 8, value: 'Sevanagala', label: 'Sevanagala', },
                { key: 9, value: 'Siyambalanduwa', label: 'Siyambalanduwa', },
                { key: 10, value: 'Thanamalvila', label: 'Thanamalvila', },
                { key: 11, value: 'Wellawaya', label: 'Wellawaya', },
            ],

        },
        {
            key: 23,
            district: 'Colombo',

            divisional_secretariats: [
                { key: 1, value: 'Colombo', label: 'Colombo', },
                { key: 2, value: 'Dehiwala', label: 'Dehiwala', },
                { key: 3, value: 'Homagama', label: 'Homagama', },
                { key: 4, value: 'Kaduwela', label: 'Kaduwela', },
                { key: 5, value: 'Kesbewa', label: 'Kesbewa', },
                { key: 6, value: 'Kolonnawa', label: 'Kolonnawa', },
                { key: 7, value: 'Kotte', label: 'Kotte', },
                { key: 8, value: 'Maharagama', label: 'Maharagama', },
                { key: 9, value: 'Moratuwa', label: 'Moratuwa', },
                { key: 10, value: 'Padukka', label: 'Padukka', },
                { key: 11, value: 'Ratmalana', label: 'Ratmalana', },
                { key: 12, value: 'Seethawaka', label: 'Seethawaka', },
                { key: 13, value: 'Thimbirigasyaya', label: 'Thimbirigasyaya', },
            ],

        },
        {
            key: 24,
            district: 'Gampaha',

            divisional_secretariats: [
                { key: 1, value: 'Attanagalla', label: 'Attanagalla', },
                { key: 2, value: 'Biyagama', label: 'Biyagama', },
                { key: 3, value: 'Divulapitiya', label: 'Divulapitiya', },
                { key: 3, value: 'Dompe', label: 'Dompe', },
                { key: 3, value: 'Gampaha', label: 'Gampaha', },
                { key: 3, value: 'Ja-Ela', label: 'Ja-Ela', },
                { key: 3, value: 'Katana', label: 'Katana', },
                { key: 3, value: 'Kelaniya', label: 'Kelaniya', },
                { key: 3, value: 'Mahara', label: 'Mahara', },
                { key: 3, value: 'Minuwangoda', label: 'Minuwangoda', },
                { key: 3, value: 'Mirigama', label: 'Mirigama', },
                { key: 3, value: 'Negombo', label: 'Negombo', },
                { key: 3, value: 'Wattala', label: 'Wattala', },
            ],

        },
        {
            key: 25,
            district: 'Kalutara',

            divisional_secretariats: [
                { key: 1, value: 'Agalawatta', label: 'Agalawatta', },
                { key: 2, value: 'Bandaragama', label: 'Bandaragama', },
                { key: 3, value: 'Beruwala', label: 'Beruwala', },
                { key: 4, value: 'Bulathsinhala', label: 'Bulathsinhala', },
                { key: 5, value: 'Dodangoda', label: 'Dodangoda', },
                { key: 6, value: 'Horana', label: 'Horana', },
                { key: 7, value: 'Ingiriya', label: 'Ingiriya', },
                { key: 8, value: 'Kalutara', label: 'Kalutara', },
                { key: 9, value: 'Madurawela', label: 'Madurawela', },
                { key: 10, value: 'Mathugama', label: 'Mathugama', },
                { key: 11, value: 'Millaniya', label: 'Millaniya', },
                { key: 12, value: 'Palindanuwara', label: 'Palindanuwara', },
                { key: 13, value: 'Panadura', label: 'Panadura', },
                { key: 14, value: 'Walallavita', label: 'Walallavita', },

            ],

        },

    ],

    //Reports Page - Home
    reportsPage_Title: 'Reports',
    reportsPage_Button: 'Read More...',

    //Stories Page - Home
    storiesPage_Title: 'Stories',
    storiesPage_Button: 'Read More...',

    storiesPage_authorityName: 'Name of The Authority',
    storiesPage_personDesignation: 'Name and Designation of The Person Involved',
    storiesPage_district: 'District',
    storiesPage_divisionalSecratarient: 'Divisional Secretariat',
    storiesPage_IncidentDate: 'Incident Date',
    storiesPage_IncidentTime: 'Incident Time',
    storiesPage_story: 'Story - ',

    //Incident Single View Page - Home
    inidentSigleView_Main_TitleFirst: 'Incident',
    inidentSigleView_Main_Title1: 'Story',
    inidentSigleView_Main_Title2: 'Election Violation',
    inidentSigleView_published_date_Title: 'Published on',

    inidentSigleView_BriberyAndSexualFavours_youAre: "You Involved As",
    inidentSigleView_Bribery_form: "Bribe From",
    inidentSigleView_Bribery_value: "Monetary Value",

    inidentSigleView_misuseFunds: "Type of Property",

    inidentSigleView_misusePosition: "Benefit",

    inidentSigleView_conflict_field1: "Private Interest",
    inidentSigleView_conflict_field2: "Nature Of Interest",
    inidentSigleView_conflict_field3: "Disclose Interest",
    inidentSigleView_conflict_field4: "Involved",
    inidentSigleView_conflict_field5: "Authorized Participate",

    inidentSigleView_procurement_field: "Type of Procurement",

    inidentSigleView_election_field1: "Nature of Misuse",
    inidentSigleView_election_field2: "Political Party",
    inidentSigleView_election_field3: "What Is Being Misused?",

    inidentSigleView_otherDetails: "Other Details",

    //other pages - search bar
    searchbar_placeholder: "Search by reference",
    incidents_latestStories: "Latest Stories",

    //News Page - Home
    newsPage_Title: 'News',
    newsPage_Button: 'Read More...',
    newsPage_latestText: "Latest News",

    //News Single View Page - Home
    newsPage_published_Txt: 'Published',
    newsPage_published_date_Title: 'Published on',
    newsPage_links_title: 'Click to Read More...',


    //statistics Page
    statisticsPage_button1: "Total Incidents",
    statisticsPage_button2: "Action Taken",
    statisticsPage_button3: "Total Stories",
    statisticsPage_button4: "Election Violations",

    statisticsPage_Chart_hoverText1: "Incidents",

    statisticsPage_Chart_Tytpe1: "Line View",
    statisticsPage_Chart_Tytpe2: "Bar View",
    statisticsPage_Chart_Tytpe3: "Tiled View",

    statisticsPage_Chart_SaveImage: "Save Image",
    incidentsChart_noOfIncidents: "Incident(s)",

    //Age wise Chart
    statisticsPage_AgeChart_Title: "Incidents Age Wise",
    statisticsPage_AgeChart_XAxis: "Age",

    //All incidents by category Chart
    statisticsPage_CategoryChart_Title: "All Incidents by Category",
    statisticsPage_CategoryChartChart_hoverText: "Incident Type",
    incidentsChart_title: "All Incidents by Category",
    incidentsChart_hoverText: "{a}<br/><strong>{b}</strong>: {c} Incidents",
    incidentsChart_bribery: "Bribery",
    incidentsChart_sexualFavours: "Sexual Favours",
    incidentsChart_election: "Election",
    incidentsChart_misuseOfProperty: "Misuse of Public Funds or Property",
    incidentsChart_misuseOfPosition: "Misuse of Public Position",
    incidentsChart_conflict: "Conflict of Interest",
    incidentsChart_procurement: "Public Procurement Irregularities",
    incidentsChart_other: "Other",

    incidentsChart_askToContact: "Ask to Contact",

    //year wise chart
    statisticsPage_YearChart_Title: "Incidents Year Wise",
    statisticsPage_YearChart_XAxis: "Year",

    //year Gender Wise chart
    statisticsPage_GenderChart_Title: "Incidents Gender Wise",
    statisticsPage_GenderChart_XAxis: "Gender",


    //Org Wise wise chart
    statisticsPage_OrgChart_Title: "All Incidents by Organization",
    statisticsPage_OrgChartChart_hoverText: "Incident Type",

    //District Wise wise chart
    statisticsPage_DistrictChart_Title: "All Incidents by District",

    designation_of_authority_validation_msg:"Please enter a shorter than 400 characters",
    nature_of_misuse_length_validation_msg:"Please enter a shorter than 800 characters"

};
