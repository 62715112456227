import React, { Component } from "react";
import PropTypes from "prop-types";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {
  Card,
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  Link,
  Grid,
  SwipeableDrawer,
  Avatar,
  Icon,
  IconButton,
  MenuItem,
  Button,
  GridList,
  GridListTile,
  GridListTileBar,
  Divider,
  ListItemText,
} from "@material-ui/core";
import ReactPlayer from "react-player";
import { themeColors } from "app/MatxLayout/MatxTheme/themeColors";
import StatisticsServices from "../../../services/StatisticsServices";
import SpeedIcon from "@material-ui/icons/Speed";
import BarChartIcon from "@material-ui/icons/BarChart";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Zoom from '@material-ui/core/Zoom';

import { withStyles } from "@material-ui/core/styles";


import { englishLanguage } from '../../languages/englishLanguage';
import { sinhalaLanguage } from '../../languages/sinhalaLanguage';
import { tamilLanguage } from '../../languages/tamilLanguage';

import languageService from "app/services/languageService";


const styleSheet = (theme) => ({
  gridList: {
    height: 275,
  },

  newsImage: {
    width: 90,
    height: 60,
    paddingRight: theme.spacing(1),
  },
  newsHrLine: {
    backgroundColor: "#0074e1",
    height: 3,
  },
  listItem: {
    borderBottomColor: "#dbdcdc",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
  cardBackground: {
    boxShadow:
      " 0 8px 20px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",

    // backgroundColor:"#bfd5eb",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    borderRadius: "16px",
    position: "relative",
    zIndex: 0,
    // boxShadow: "none",
    textAlign: "center",
    padding: "20px 0px",
    color: "rgb(0, 82, 73)",
    backgroundColor: "rgb(248, 248, 248)",
    // backgroundColor:"black"
  },
  mainPadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 70,
      paddingRight: 70,
      paddingTop: 50,
      paddingBottom: 50,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 30,
      paddingBottom: 30,
    },
    icon_style: {
      height: 90,
      width: "100%",
      color: "red",
    },
  },
  bottomItemButton: {
    borderRadius: 0,

    // border: "1px solid #0074E1
    textAlign: "center",
    height: 170,
    backgroundColor: "white", // added by shammika
    boxShadow:
      " 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",
    transition: "all ease 200ms",

    [theme.breakpoints.down("sm")]: {
      margin: 30,
    },
  },

  butonSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonIcon: {
    width: 90,
    height: 90,
    boxShadow:
      " 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",

    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    marginTop: -45,
    borderRadius: "100px",

  },
  buttonTitle: {
    fontFamily: "Source Sans Pro",
    textAlign: "center",
    color: "white", // Changed by shammika",
    fontSize: 35,
    fontWeight: 900,
  },
  buttonSubTitle: {
    fontFamily: "Source Sans Pro",
    // fontStyle:"italic",
    textAlign: "center",
    color: "white", // changed by shammika",
    fontWeight: 500,
    fontSize: 18,
  },
});

class StatisticsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action_takens: 0,
      total_cases: 0,
      total_corruptions: 0,
      total_election_violations: 0,
      total_stories: 0,

      language: englishLanguage,
      languageName: 'English',

    };
  }

  async loadData() {
    let statistics = await StatisticsServices.getAllStatistics();
    console.log("statistics", statistics.data);
    //console.log("pagination",corruptions.data.insidents)
    if (statistics.status == 200) {
      this.setState({
        action_takens: statistics.data.action_takens,
        total_cases: statistics.data.total_cases,
        total_corruptions: statistics.data.total_corruptions,
        total_election_violations: statistics.data.total_election_violations,
        total_stories: statistics.data.total_stories,
      });
    }
  }

  async componentDidMount() {

    this.loadData(1);

    var lng = await languageService.getLanguageDetails();

    if (lng == 'Sinhala') {
      this.setState({
        language: sinhalaLanguage,
        languageName: 'Sinhala',
      })
    }
    else if (lng == 'Tamil') {
      this.setState({
        language: tamilLanguage,
        languageName: 'Tamil',
      })
    }
    // console.log("Home Incident Chart Section:", this.state);

  }

  render() {
    const { classes } = this.props;
    return (
      // <div style={{ backgroundColor: themeColors.purple1.palette.theme_color.main }}>
      <div
        className="pt-20"
        style={{
          //  background:
          // "linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6)",
          backgroundImage: `url(/assets/images/statBackground.png)`,
          backgroundSize: 'cover'
        }}
      >

        <Grid
          justifyContent="center"
          container
          spacing={5}
          className={classes.mainPadding}
        >
          <Zoom in={true} timeout={800} >
            <Grid item xs={12} md={3}>
              <Grid
                elevation={5}
                className={[classes.bottomItemButton]}
                style={{
                  borderRadius: 10,
                  background: " linear-gradient(to right, #0f2027, #203a43, #2c5364)",
                }}
              >
                <Grid className={classes.butonSection}>
                  <Grid className={classes.buttonIcon}>
                    <img
                      alt="total incidents"
                      src="/assets/images/totalIncidents.png"
                      style={{ width: "100%", paddingTop: 1 }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  className={classes.buttonIncide}
                  alignItems="center"
                  justify="center"
                  direction="column" // changed row to column by shammika
                >
                  <Grid item xs={7}>
                    <Typography
                      variant="h5"
                      className={["py-3", classes.buttonTitle]}
                      style={{ fontWeight: 900 }}
                    >
                      {this.state.total_cases}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={["px-2", classes.buttonSubTitle]}
                    style={{
                      fontSize: this.state.languageName === "Tamil" ? 17 : (this.state.languageName === "Sinhala" ? 16 : 18),
                    }}
                  >
                    {" "}
                    {this.state.language.statisticsPage_button1}{/* Total Incidents */}
                  </Typography>
                </Grid>
              </Grid>
              {/* <Card elevation={5} className={["pb-2", classes.cardBackground]}>
              <Grid container className="p-0" justify="center">
                
                <BarChartIcon style={{ width: "100%", height: 45 }} />
                <Typography
                  variant="h2"
                  component="h2"
                  style={{ fontSize: 35, color: "#343232", fontWeight: 800, margin:"5px 0" }}
                >
                  {this.state.total_cases}
                </Typography>
              </Grid>

              <Grid container className="p-0" justify="center">
                <Typography
                  variant="p"
                  component="p"
                  gutterBottom
                  style={{ fontSize: 16, color: "#343232", fontWeight: 500 }}
                >
                  Total Incidents
                </Typography>
              </Grid>
            </Card> */}
            </Grid>
          </Zoom>
          <Zoom in={true} timeout={800}  >
            <Grid item xs={12} md={3}>
              <Grid
                elevation={5}
                className={[classes.bottomItemButton]}
                style={{
                  borderRadius: 10,
                  // background: "linear-gradient(to right, #ed213a, #93291e)",
                  background: "linear-gradient(to right, #007FFF, #186DC2)",
                }}
              >
                <Grid className={classes.butonSection}>
                  <Grid className={classes.buttonIcon}>
                    <img
                      alt="say something"
                      src="/assets/images/saySomething.png"
                      style={{ width: "100%", paddingTop: 1 }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  className={classes.buttonIncide}
                  alignItems="center"
                  justify="center"
                  direction="column" // changed row to column by shammika
                >
                  <Grid item xs={7}>
                    <Typography
                      variant="h5"
                      className={["py-3", classes.buttonTitle]}
                    >
                      {this.state.action_takens}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={["px-2", classes.buttonSubTitle]}
                    style={{
                      fontSize: this.state.languageName === "Tamil" ? 17 : (this.state.languageName === "Sinhala" ? 16 : 18),
                    }}
                  >
                    {this.state.language.statisticsPage_button2}
                    {/* Action Taken */}
                  </Typography>
                </Grid>
              </Grid>

              {/* <Card
              elevation={5}
              className={["pb-2", classes.cardBackground]}
              style={{ backgroundColor: "#fa5d5d" }}
            >
              <Grid container className="p-0" justify="center">
                <SpeedIcon style={{ width: "100%", height: 45 }} />

                <Typography
                  variant="h2"
                  component="h2"
                  style={{
                    fontSize: 35,
                    color: "#343232",
                    fontWeight: 800,
                    margin: "5px 0",
                  }}
                >
                  {this.state.action_takens}
                </Typography>
              </Grid>

              <Grid
                container
                cclassName={["pb-2", classes.cardBackground]}
                justify="center"
              >
                <Typography
                  variant="p"
                  component="p"
                  gutterBottom
                  style={{ fontSize: 16, color: "#343232", fontWeight: 500 }}
                >
                  Action Taken
                </Typography>
              </Grid>
            </Card> */}
            </Grid>
          </Zoom>
          <Zoom in={true} timeout={800}>
            <Grid item xs={12} md={3}>
              <Grid
                elevation={5}
                className={[classes.bottomItemButton]}
                style={{
                  borderRadius: 10,
                  // background: "linear-gradient(to right,#eea849,#f46b45)",
                  background: "linear-gradient(to right,#1c6190,#204762)",
                }}
              >
                <Grid className={classes.butonSection}>
                  <Grid className={classes.buttonIcon}>
                    <img
                      alt="share Img"
                      src="/assets/images/share.png"
                      style={{ width: "100%", paddingTop: 1 }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  className={classes.buttonIncide}
                  alignItems="center"
                  justify="center"
                  direction="column" // changed row to column by shammika
                >
                  <Grid item xs={7}>
                    <Typography
                      variant="h5"
                      className={["py-3", classes.buttonTitle]}
                    >
                      {this.state.total_stories}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={["px-2", classes.buttonSubTitle]}
                    style={{
                      fontSize: this.state.languageName === "Tamil" ? 17 : (this.state.languageName === "Sinhala" ? 16 : 18),
                    }}
                  >
                    {this.state.language.statisticsPage_button3}
                    {/* Total Stories */}
                  </Typography>
                </Grid>
              </Grid>

              {/* <Card
              elevation={5}
              className={["pb-2", classes.cardBackground]}
              style={{ backgroundColor: "#ffb56f" }}
            >
              <Grid container className="p-0" justify="center">
                <RecordVoiceOverIcon style={{ width: "100%", height: 45 }} />
                <Typography
                  variant="h2"
                  component="h2"
                  style={{
                    fontSize: 35,
                    color: "#343232",
                    fontWeight: 800,
                    margin: "5px 0",
                  }}
                >
                  {this.state.total_stories}
                </Typography>
              </Grid>

              <Grid container className="p-0" justify="center">
                <Typography
                  variant="p"
                  component="p"
                  gutterBottom
                  style={{ fontSize: 16, color: "#343232", fontWeight: 500 }}
                >
                  Total Stories
                </Typography>
              </Grid>
            </Card> */}
            </Grid>
          </Zoom>
          <Zoom in={true} timeout={800} >
            <Grid item xs={12} md={3}>
              <Grid
                elevation={5}
                className={[classes.bottomItemButton]}
                style={{
                  borderRadius: 10,
                  // background: "linear-gradient(to right, #f8c842, #bd9015)",
                  background: "linear-gradient(to right, #0047ab, #003279)",
                }}
              >
                <Grid className={classes.butonSection}>
                  <Grid className={classes.buttonIcon}>
                    <img
                      alt="electionReportLogo"
                      src="/assets/images/btnElectionLogo.png"
                      style={{ width: "100%", paddingTop: 1 }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  className={classes.buttonIncide}
                  alignItems="center"
                  justify="center"
                  direction="column" // changed row to column by shammika
                >
                  <Grid item xs={7}>
                    <Typography
                      variant="h5"
                      className={["py-3", classes.buttonTitle]}
                    >
                      {this.state.total_election_violations}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={["px-2", classes.buttonSubTitle]}
                    style={{
                      fontSize: this.state.languageName === "Tamil" ? 17 : (this.state.languageName === "Sinhala" ? 16 : 18),
                    }}
                  >
                    {this.state.language.statisticsPage_button4}
                    {/* Election Violations */}
                  </Typography>
                </Grid>
              </Grid>
              {/* <Card
              elevation={5}
              className={["pb-2", classes.cardBackground]}
              style={{ backgroundColor: "#fff3b5" }}
            >
              <Grid container className="p-0" justify="center">
                <PostAddIcon style={{ width: "100%", height: 45 }} />
                <Typography
                  variant="h2"
                  component="h2"
                  style={{ fontSize: 35, color: "#343232", fontWeight: 800 }}
                >
                  {this.state.total_election_violations}
                </Typography>
              </Grid>

              <Grid container className="p-0" justify="center">
                <Typography
                  variant="p"
                  component="p"
                  gutterBottom
                  style={{ fontSize: 16, color: "#343232", fontWeight: 500 }}
                >
                  Election Violations
                </Typography>
              </Grid>
            </Card> */}
            </Grid>
          </Zoom>

        </Grid>

      </div>
    );
  }
}

export default withStyles(styleSheet)(StatisticsSection);
