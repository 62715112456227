import axios from "axios";
import localStorageService from "./localStorageService";
import UserServices from "./UserServices";
import * as appconst from '../appconst';

class JwtAuthService {

  // Dummy user object just for the demo
  user = {
    userId: "1",
    roles: ['ADMIN', 'A'],
    displayName: "Jason Alexander",
    email: "jasonalexander@gmail.com",
    photoURL: "/assets/images/face-6.jpg",
    age: 25,
    token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh"
  }

  // You need to send http request with email and passsword to your server in this method
  // Your server will return user object & a Token
  // User should have role property
  // You can define roles in app/auth/authRoles.js
  loginWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {




      axios.post(appconst.LOGIN,
        {
          email: email,
          password: password
        })
        .then((response) => {
          //var jwtDecode = require('jwt-decode');
          // var userdata = jwtDecode(response.data.access_token);
          console.log("login", response)
          var user = {
            userId: response.data.user.id,
            role: JSON.parse(response.data.user.role)[0],
            roles: JSON.parse(response.data.user.role),
            //role:"Super Admin",
            displayName: response.data.user.name,
            email: response.data.user.email,
            photoURL: "/assets/images/logonew.png",
            age: 25,
            token: response.data.token
          };
          resolve(user);
        })
        .catch((error) => {
          console.log("erroreee", error)
          var data = {
            Error: "Invalid user credentials",
          }
          resolve(data);
        })



    }).then(data => {
      // Login successful
      // Save token
      this.setSession(data.token);
      // Set user
      this.setUser(data);
      return data;
    });
  };

  // You need to send http requst with existing token to your server to check token is valid
  // This method is being used when user logged in & app is reloaded
  loginWithToken = async () => {
    /*  return new Promise((resolve, reject) => {
       setTimeout(() => {
         resolve(this.user);
       }, 100);
     }).then(data => {
       // Token is valid
       this.setSession(data.token);
       this.setUser(data);
       return data;
     }); */

    let user = await localStorageService.getItem("auth_user");
    console.log("user logined", user)
    if (user != null) {

      UserServices.getMyDetail().then(res => {
        if (res.data.status == "Token is Expired") {
          this.logout();
          return null;
        }

      }).catch(error => {
        console.log(error);

      })

      this.setSession(user.token);
      this.setUser(user);
    } else {
      this.logout()
    }
    return user;
  };


  //user set password
  firstTimeSetPassword = async (params, password) => {

    const a = new Promise((resolve, reject) => {
      axios.post(appconst.USER_SET_PASSWORD + params.userId + "?expires=" + params.expires + "&hash=" + params.hash + "&signature=" + params.signature, {
        "password": password
      }).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log(error);
          return resolve(error);
        })
    });

    return await a;
  }



  //user set password
  reSetPassword = async (email) => {

    const a = new Promise((resolve, reject) => {
      axios.post(appconst.USER_RESET_PASSWORD, {
        "email": email
      }).then(res => {
        return resolve(res);
      })
        .catch((error) => {
          console.log(error);
          return resolve(error);
        })
    });

    return await a;
  }

  logout = () => {
    this.setSession(null);
    this.removeUser();
  }

  // Set token to all http request header, so you don't need to attach everytime
  setSession = token => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      //axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      //delete axios.defaults.headers.common["Authorization"];
    }
  };

  // Save user to localstorage
  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  }



  //Get user from localstorage
  getUser = async (user) => {
    return await localStorageService.getItem("auth_user");
  }
  // Remove user from localstorage
  removeUser = () => {
    localStorage.removeItem("auth_user");
  }
}

export default new JwtAuthService();
