import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Carousel from "react-material-ui-carousel";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  Link,
  Popover,
  Grid,
  SwipeableDrawer,
  Avatar,
  Icon,
  IconButton,
  MenuItem,
  Button,
  GridList,
  GridListTile,
  GridListTileBar,
  Divider,
  ListItemText,
  Popper,
  Paper,
} from "@material-ui/core";
import { SimpleCard, MatxProgressBar } from "matx";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import Footer from "./Footer";
import AppAppBar from "./AppAppBar";
import * as appconst from "../../../appconst";

import { withStyles } from "@material-ui/core/styles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ShareIcon from "@material-ui/icons/Share";
import IncidentServices from "app/services/IncidentServices";
import Util from "../../../services/Util";

import { englishLanguage } from "../../languages/englishLanguage";
import { sinhalaLanguage } from "../../languages/sinhalaLanguage";
import { tamilLanguage } from "../../languages/tamilLanguage";

import languageService from "app/services/languageService";

//Newly Added
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { Alert, AlertTitle } from "@material-ui/lab";
import DetailsIcon from "@material-ui/icons/Details";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
} from "react-share";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const styleSheet = (theme) => ({
  mainPadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 110,
      paddingRight: 70,
      paddingTop: 92,
      paddingBottom: 50,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 100,
      paddingBottom: 30,
    },
  },
  mainPadding2: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 110,
      paddingRight: 70,
      paddingTop: 50,
      paddingBottom: 50,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  customPadding: {
    paddingLeft: 10,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  socialIconsBack: {
    margin: 8,
  },
  socialIcons: {
    transition: "all ease 200ms",
    "&:hover": {
      //   opacity: "0.5",
      transform: "scale(1.4)",
    },
  },
  shareIconStyle: {
    color: "white",
    // "&:hover": {
    //   color: "red",
    //   width: "25px",
    //   height: "25px",
    // },
  },
  incidentDetails: {
    width: "100%",
    // backgroundImage: `url(/assets/images/incBacks.png)`,
    backgroundSize: "cover",
    backgroundColor: "#e1e1e1",
    borderRadius: 32,
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  incidentDetailsTitle: {
    marginRight: 8,
    marginLeft: 10,
    fontWeight: 700,
    padding: "7px 0 3px 5px",
  },
  incidentDetailsSubTitle: {
    fontWeight: 200,
    padding: "2px 15px",
  },
  margin: {
    margin: theme.spacing(1),
  },
  incidentImage: {
    width: "100%",
    height: 350,
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      height: 240,
    },
  },
  incidentTopSection: {
    marginTop: 20,
    //fontFamily: "ReithSerif,Helvetica,Arial,freesans,sans-serif",
    fontWeight: " bold",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      marginTop: -16,
      fontSize: 25,
    },
  },
});

class ViewIncident extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: englishLanguage,
      userSelectedLanguage: englishLanguage,

      title: "",
      updatedDate: "",
      type_of_corruption: "Story",

      images: [],
      haveAttachments: false,
      otherDetails: "",

      // shareMenuOpen: false,
      anchorEl: null,

      public_authority_involved: "",
      name_of_authority: "",
      district: "",
      divisional_secretariat: "",
      incident_date: "",
      incident_time: "",
      designation_of_authority: "",

      you_are: null,
      bribe_form: null,
      type_of_property: null,
      private_interest: null,
      nature_of_interest: null,
      involved: null,
      disclose_interest: null,
      authorized_participate: null,
      type_of_procurement: null,
      nature_of_misuse: null,

      incident_report_All: {},
      misused: [],
    };
    this.getIncidentDetails = this.getIncidentDetails.bind(this);
    this.openShareMenu = this.openShareMenu.bind(this);
    this.handleCloseShareMenu = this.handleCloseShareMenu.bind(this);
  }

  openShareMenu = (e) => {
    this.setState({
      anchorEl: e.currentTarget,
    });
  };

  handleCloseShareMenu() {
    this.setState({
      anchorEl: null,
    });
  }

  async getIncidentDetails(id) {
    var lng = await languageService.getLanguageDetails();
    var selectedLanguage = englishLanguage;
    // console.log("language",lng);

    //user selected language
    if (lng == "Sinhala") {
      selectedLanguage = sinhalaLanguage;
    } else if (lng == "Tamil") {
      selectedLanguage = tamilLanguage;
    }

    //data submitted language
    var languageRes = englishLanguage;

    IncidentServices.getSingleIncident(id)
      .then((res) => {
        console.log(res.data);

        var incident = res.data.insident[0];
        var election_misused = [];
        var arr_misused1 = [];
        var arr_misused2 = [];

        var form_language = incident.language;

        //set form submitted language
        if (form_language == "Sinhala") {
          languageRes = sinhalaLanguage;
        } else if (form_language == "Tamil") {
          languageRes = tamilLanguage;
        }

        var incidentType_Title = languageRes.inidentSigleView_Main_Title1; //"Story";

        if (incident.title != null) {
          incidentType_Title = incident.title;
        }
        // console.log(incidentType_Title);

        var resCorruptionType = "Story";

        var u_date = Util.getParsedDate(incident.updated_at);

        //get authority type with language
        var resOrg = incident.name_of_authority;
        var orgsArr = languageRes.lng_publicAuthorities;
        resOrg = this.getConvertedValue(orgsArr, resOrg);
        // console.log(resOrg);

        //get district with language
        var resDistrict = incident.district;
        var districtArr = languageRes.lng_Districts;
        resDistrict = this.getConvertedValue(districtArr, resDistrict);

        //get Divisional Secretariat with language
        var resDivisional_secretariat = incident.divisional_secretariat;
        if (resDivisional_secretariat != null) {
          var divisional_secretariatArr =
            languageRes.lng_report_divisional_secretariats;
          resDivisional_secretariat =
            this.getConverted_divisional_secretariat_Value(
              divisional_secretariatArr,
              incident.district,
              resDivisional_secretariat
            );
        }

        //get incident type(Bribery,Conflict/Election...)
        if (incident.incident_report != null) {
          incidentType_Title = incident.incident_report.type_of_corruption;
          resCorruptionType = incident.incident_report.type_of_corruption;

          var arr_corruptionTypes = languageRes.lng_corruption_types;

          //get value with relevant language
          incidentType_Title = this.getConvertedValue(
            arr_corruptionTypes,
            incidentType_Title
          );
        }

        var i_attachments = null;
        var res_attachments = false;
        var other_details = { other_derails: null, attachments: null };

        if (incident.incident_report != null) {
          if (incident.incident_report.other_details != null) {
            other_details = incident.incident_report.other_details;
            if (incident.incident_report.other_details.attachments != null) {
              res_attachments = true;
              i_attachments = JSON.parse(
                incident.incident_report.other_details.attachments
              );
            }
          }
        }
        // console.log(i_attachments);

        var lng_You_are = null;
        var lng_bribe_form = null;
        var lng_type_of_property = null;
        var lng_private_interest = null;
        var lng_nature_of_interest = null;
        var lng_involved = null;
        var lng_disclose_interest = null;
        var lng_authorized_participate = null;
        var lng_type_of_procurement = null;
        var lng_nature_of_misuse = null;

        if (resCorruptionType == "Bribery") {
          var arr1 = languageRes.lng_ReportingPersonTypes;
          var arr2 = languageRes.lng_Corruption_Bribe_Types;
          var val1 = incident.incident_report.bribery.you_are;
          var val2 = incident.incident_report.bribery.bribe_form;

          lng_You_are = this.getConvertedValue(arr1, val1);
          lng_bribe_form = this.getConvertedValue(arr2, val2);

          if (lng_bribe_form == null) {
            lng_bribe_form = val2;
          }
        } else if (resCorruptionType == "Sexual Favours") {
          var arr1 = languageRes.lng_ReportingPersonTypes;
          var val1 = incident.incident_report.sexual_favors.you_are;

          lng_You_are = this.getConvertedValue(arr1, val1);
        } else if (resCorruptionType == "Misuse of Public Funds or Property") {
          var arr1 = languageRes.lng_misused_PropertyTypes;
          var val1 =
            incident.incident_report.misuse_public_funds.type_of_property;

          lng_type_of_property = this.getConvertedValue(arr1, val1);
        } else if (resCorruptionType == "Conflict of Interest") {
          var arr1 = languageRes.lng_conflictOFInterest_AnswerTypes;
          var arr2 = languageRes.lng_conflictOFInterest_NatureOfInterestTypes;
          var val1 =
            incident.incident_report.conflict_of_interest.private_interest;
          var val2 =
            incident.incident_report.conflict_of_interest.nature_of_interest;
          var val3 = incident.incident_report.conflict_of_interest.involved;
          var val4 =
            incident.incident_report.conflict_of_interest.disclose_interest;
          var val5 =
            incident.incident_report.conflict_of_interest
              .authorized_participate;

          lng_private_interest = this.getConvertedValue(arr1, val1);
          lng_involved = this.getConvertedValue(arr1, val3);
          lng_disclose_interest = this.getConvertedValue(arr1, val4);
          lng_authorized_participate = this.getConvertedValue(arr1, val5);
          lng_nature_of_interest = this.getConvertedValue(arr2, val2);
        } else if (resCorruptionType == "Public Procurement Irregularities") {
          var arr1 = languageRes.lng_procurementTypes;
          var val1 = incident.incident_report.procurement.type_of_procurement;

          lng_type_of_procurement = this.getConvertedValue(arr1, val1);
        } else if (resCorruptionType === "Election") {
          incidentType_Title = languageRes.inidentSigleView_Main_Title2; //"Election Violation";
          election_misused = incident.incident_report.election.misused;
          var lng_allMisused = languageRes.lan_election_misusedTypes;

          if (election_misused) {
            arr_misused1 = election_misused.split(" ,");
            for (var i = 0; i < arr_misused1.length; i++) {
              var val = arr_misused1[i] + " ";
              var lng_val = this.getConvertedValue(lng_allMisused, val);
              arr_misused2.push(lng_val);
            }
          }

          var arr = languageRes.lng_misused_natureTypes;
          var val = incident.incident_report.election.nature_of_misuse;
          lng_nature_of_misuse = this.getConvertedValue(lng_allMisused, val);

          if (lng_nature_of_misuse == null) {
            lng_nature_of_misuse = val;
          }
        }

        incidentType_Title = `${languageRes.inidentSigleView_Main_TitleFirst} - ${incidentType_Title}`;

        this.setState({
          language: languageRes,
          userSelectedLanguage: selectedLanguage,
          title: incidentType_Title.toUpperCase(),
          story: incident.story,
          type_of_corruption: resCorruptionType,
          updatedDate: u_date,
          haveAttachments: res_attachments,
          images: i_attachments,
          public_authority_involved: incident.public_authority_involved,
          name_of_authority: resOrg,
          district: resDistrict,
          divisional_secretariat: resDivisional_secretariat,
          designation_of_authority: incident.designation_of_authority,
          incident_date: Util.getParsedDate(incident.incident_date_time),
          incident_time: Util.getParsedTime(incident.incident_date_time),

          otherDetails: other_details.other_details,
          incident_report_All: incident.incident_report,

          you_are: lng_You_are,
          bribe_form: lng_bribe_form,
          type_of_property: lng_type_of_property,
          private_interest: lng_private_interest,
          nature_of_interest: lng_nature_of_interest,
          involved: lng_involved,
          disclose_interest: lng_disclose_interest,
          authorized_participate: lng_authorized_participate,
          type_of_procurement: lng_type_of_procurement,

          misused: arr_misused2,
          nature_of_misuse: lng_nature_of_misuse,
        });
        console.log("State", this.state);
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getConvertedValue(arr, val) {
    var len = arr.length;
    var newValue = null;

    for (var i = 0; i < len; i++) {
      if (arr[i].value == val) {
        newValue = arr[i].label;
      }
    }

    return newValue;
  }

  //get divisional secretariat label
  getConverted_divisional_secretariat_Value(arr, district, val) {
    var len = arr.length;
    var newValue = null;

    for (var i = 0; i < len; i++) {
      if (arr[i].district === district) {
        arr[i].divisional_secretariats.forEach((secretariat) => {
          // console.log(secretariat);

          if (secretariat.value === val) {
            newValue = secretariat.label;
          }
        });
      }
    }

    return newValue;
  }

  async componentDidMount() {
    let paramId = this.props.match.params.id;
    this.getIncidentDetails(paramId);
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        {/* App bar component */}
        <AppAppBar />

        <div
          className={[classes.mainPadding]}
          style={{
            // backgroundColor: "#1777c0",
            backgroundImage: "url(/assets/images/storiBackground.png)",
            width: "100%",
            height: 270,
            backgroundSize: "cover",
            display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h4" className={classes.incidentTopSection}>
                {this.state.title}
                {/* BANNING CHEMICAL FERTILISER - RIGHT THING, WRONG LINE */}
              </Typography>
              <Typography
                className="mt-2"
                style={{
                  fontWeight: 200,
                  color: "#f3f3f3",
                  fontSize: "1.2rem",
                }}
              >
                {/* Published on  */}
                {this.state.language.inidentSigleView_published_date_Title +
                  " " +
                  this.state.updatedDate}
                &ensp;
                {/* added Icon Button class*/}
                <IconButton>
                  <ShareIcon
                    id="share_Icon"
                    fontSize="inherit"
                    className={classes.shareIconStyle}
                    onClick={(e) => this.openShareMenu(e)}
                  />
                </IconButton>
                <Popover
                  open={Boolean(this.state.anchorEl)}
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={this.handleCloseShareMenu}
                >
                  <FacebookShareButton
                    url={String(window.location)}
                    className={classes.socialIconsBack}
                  >
                    <FacebookIcon
                      size={32}
                      round={true}
                      className={classes.socialIcons}
                    />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={String(window.location)}
                    className={classes.socialIconsBack}
                  >
                    <TwitterIcon
                      size={32}
                      round={true}
                      className={classes.socialIcons}
                    />
                  </TwitterShareButton>
                  <LinkedinShareButton
                    url={String(window.location)}
                    className={classes.socialIconsBack}
                  >
                    <LinkedinIcon
                      size={32}
                      round={true}
                      className={classes.socialIcons}
                    />
                  </LinkedinShareButton>
                  <WhatsappShareButton
                    url={String(window.location)}
                    className={classes.socialIconsBack}
                  >
                    <WhatsappIcon
                      size={32}
                      round={true}
                      className={classes.socialIcons}
                    />
                  </WhatsappShareButton>
                  <TelegramShareButton
                    url={String(window.location)}
                    className={classes.socialIconsBack}
                  >
                    <TelegramIcon
                      size={32}
                      round={true}
                      className={classes.socialIcons}
                    />
                  </TelegramShareButton>
                </Popover>
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div
          className={classes.mainPadding2}
          style={{ backgroundColor: "white" }}
        >
          <Fade in={true}>
            <Grid container spacing={5} style={{ backgroundColor: "white" }}>
              <Grid item xs={12} md={6}>
                {this.state.haveAttachments && this.state.images.length > 1 ? (
                  <Carousel
                    autoPlay={true}
                    indicators={false}
                    timeout={500}
                    animation={"fade"}
                  >
                    {/* <img src="/assets/images/carousel_pic6.jpg" style={{ width: "100%" , height: 350,}} />
                                <img src="/assets/images/carousel_pic5.jpg" style={{ width: "100%", height: 350, }} /> */}

                    {this.state.images.map((item) => (
                      <img
                        alt="Incident "
                        src={appconst.ATTACHMENTS_LOCATION + item}
                        className={classes.incidentImage}
                        key={item}
                      />
                    ))}
                  </Carousel>
                ) : this.state.haveAttachments &&
                  this.state.images.length == 1 ? (
                  <img
                    alt="Incident"
                    src={appconst.ATTACHMENTS_LOCATION + this.state.images[0]}
                    className={classes.incidentImage}
                  />
                ) : (
                  <img
                    alt="Incident"
                    src="/assets/images/default_incident.png"
                    className={classes.incidentImage}
                  />
                )}
              </Grid>

              <Grid item xs={12} md={6} className={classes.customPadding}>
                <Grid container xs={12}>
                  {/* <Grid item container sm={6} xs={12}>
                    <div className={classes.incidentDetails}>
                      <Typography className={classes.incidentDetailsTitle}>
                        Public Authority Involved
                      </Typography>
                    </div>
                    <Typography className={classes.incidentDetailsSubTitle}>
                      {this.state.public_authority_involved === "0"
                        ? "No"
                        : "Yes"}
                    </Typography>
                  </Grid> */}

                  <Grid item container sm={6} xs={12}>
                    <div className={classes.incidentDetails}>
                      <Typography className={classes.incidentDetailsTitle}>
                        {/* Name of The Authority */}
                        {this.state.language.storiesPage_authorityName}
                      </Typography>
                    </div>
                    <Typography className={classes.incidentDetailsSubTitle}>
                      {this.state.name_of_authority}
                    </Typography>
                  </Grid>

                  <Grid item container xs={12}>
                    <div className={classes.incidentDetails}>
                      <Typography className={classes.incidentDetailsTitle}>
                        {/* Name and Designation of The Person Involved */}
                        {this.state.language.storiesPage_personDesignation}
                      </Typography>
                    </div>
                    <Typography className={classes.incidentDetailsSubTitle}>
                      {this.state.designation_of_authority}
                    </Typography>
                  </Grid>

                  <Grid item container sm={6} xs={12}>
                    <div className={classes.incidentDetails}>
                      <Typography className={classes.incidentDetailsTitle}>
                        {/* District */}
                        {this.state.language.storiesPage_district}
                      </Typography>
                    </div>
                    <Typography className={classes.incidentDetailsSubTitle}>
                      {this.state.district}
                    </Typography>
                  </Grid>

                  {this.state.story == null && (
                    <Grid item container sm={6} xs={12}>
                      <div className={classes.incidentDetails}>
                        <Typography className={classes.incidentDetailsTitle}>
                          {/* Divisional Secretariat */}
                          {
                            this.state.language
                              .storiesPage_divisionalSecratarient
                          }
                        </Typography>
                      </div>
                      <Typography className={classes.incidentDetailsSubTitle}>
                        {this.state.divisional_secretariat}
                      </Typography>
                    </Grid>
                  )}

                  <Grid item container sm={6} xs={12}>
                    <div className={classes.incidentDetails}>
                      <Typography className={classes.incidentDetailsTitle}>
                        {/* Incident Date */}
                        {this.state.language.storiesPage_IncidentDate}
                      </Typography>
                    </div>
                    <Typography className={classes.incidentDetailsSubTitle}>
                      {this.state.incident_date}
                    </Typography>
                  </Grid>

                  <Grid item container sm={6} xs={12}>
                    <div className={classes.incidentDetails}>
                      <Typography className={classes.incidentDetailsTitle}>
                        {/* Incident Time */}
                        {this.state.language.storiesPage_IncidentTime}
                      </Typography>
                    </div>
                    <Typography className={classes.incidentDetailsSubTitle}>
                      {this.state.incident_time}
                    </Typography>
                  </Grid>

                  {/* Type */}

                  {/* Bribery */}

                  {this.state.type_of_corruption === "Bribery" && (
                    <Grid item container xs={12}>
                      <Grid item container xs={12}>
                        <div className={classes.incidentDetails}>
                          <Typography className={classes.incidentDetailsTitle}>
                            {
                              this.state.language
                                .inidentSigleView_BriberyAndSexualFavours_youAre
                            }
                          </Typography>
                        </div>
                        <Typography className={classes.incidentDetailsSubTitle}>
                          {this.state.you_are}
                        </Typography>
                      </Grid>

                      <Grid item container xs={12}>
                        <div className={classes.incidentDetails}>
                          <Typography className={classes.incidentDetailsTitle}>
                            {this.state.language.inidentSigleView_Bribery_form}
                          </Typography>
                        </div>
                        <Typography className={classes.incidentDetailsSubTitle}>
                          {this.state.bribe_form}
                        </Typography>
                      </Grid>

                      <Grid item container xs={12}>
                        <div className={classes.incidentDetails}>
                          <Typography className={classes.incidentDetailsTitle}>
                            {this.state.language.inidentSigleView_Bribery_value}
                          </Typography>
                        </div>
                        <Typography className={classes.incidentDetailsSubTitle}>
                          Rs.
                          {
                            this.state.incident_report_All.bribery
                              .monetary_value
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {/* Sexual Favours */}
                  {this.state.type_of_corruption === "Sexual Favours" && (
                    <Grid item container xs={12}>
                      <Grid item container xs={12}>
                        <div className={classes.incidentDetails}>
                          <Typography className={classes.incidentDetailsTitle}>
                            {
                              this.state.language
                                .inidentSigleView_BriberyAndSexualFavours_youAre
                            }
                          </Typography>
                        </div>
                        <Typography className={classes.incidentDetailsSubTitle}>
                          {this.state.you_are}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  {/*Misuse of Public Funds or Property  */}
                  {this.state.type_of_corruption ===
                    "Misuse of Public Funds or Property" && (
                    <Grid item container xs={12}>
                      <Grid item container xs={12}>
                        <div className={classes.incidentDetails}>
                          <Typography className={classes.incidentDetailsTitle}>
                            {this.state.language.inidentSigleView_misuseFunds}
                          </Typography>
                        </div>
                        <Typography className={classes.incidentDetailsSubTitle}>
                          {this.state.type_of_property}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  {/*Misuse of Public Position  */}
                  {this.state.type_of_corruption ===
                    "Misuse of Public Position" && (
                    <Grid item container xs={12}>
                      <Grid item container xs={12}>
                        <div className={classes.incidentDetails}>
                          <Typography className={classes.incidentDetailsTitle}>
                            {
                              this.state.language
                                .inidentSigleView_misusePosition
                            }
                          </Typography>
                        </div>
                        <Typography className={classes.incidentDetailsSubTitle}>
                          {
                            this.state.incident_report_All
                              .misuse_public_position.benefit
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  {/*Conflict of Interest"  */}
                  {this.state.type_of_corruption === "Conflict of Interest" && (
                    <Grid item container xs={12}>
                      <Grid item container xs={12}>
                        <div className={classes.incidentDetails}>
                          <Typography className={classes.incidentDetailsTitle}>
                            {
                              this.state.language
                                .inidentSigleView_conflict_field1
                            }
                          </Typography>
                        </div>
                        <Typography className={classes.incidentDetailsSubTitle}>
                          {this.state.private_interest}
                        </Typography>
                      </Grid>

                      <Grid item container xs={12}>
                        <div className={classes.incidentDetails}>
                          <Typography className={classes.incidentDetailsTitle}>
                            {
                              this.state.language
                                .inidentSigleView_conflict_field2
                            }
                          </Typography>
                        </div>
                        <Typography className={classes.incidentDetailsSubTitle}>
                          {this.state.nature_of_interest}
                        </Typography>
                      </Grid>

                      <Grid item container xs={12}>
                        <div className={classes.incidentDetails}>
                          <Typography className={classes.incidentDetailsTitle}>
                            {
                              this.state.language
                                .inidentSigleView_conflict_field3
                            }
                          </Typography>
                        </div>
                        <Typography className={classes.incidentDetailsSubTitle}>
                          {this.state.disclose_interest}
                        </Typography>
                      </Grid>

                      <Grid item container xs={12}>
                        <div className={classes.incidentDetails}>
                          <Typography className={classes.incidentDetailsTitle}>
                            {
                              this.state.language
                                .inidentSigleView_conflict_field4
                            }
                          </Typography>
                        </div>
                        <Typography className={classes.incidentDetailsSubTitle}>
                          {this.state.involved}
                        </Typography>
                      </Grid>

                      <Grid item container xs={12}>
                        <div className={classes.incidentDetails}>
                          <Typography className={classes.incidentDetailsTitle}>
                            {
                              this.state.language
                                .inidentSigleView_conflict_field5
                            }
                          </Typography>
                        </div>
                        <Typography className={classes.incidentDetailsSubTitle}>
                          {this.state.authorized_participate}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  {/*Public Procurement Irregularities  */}
                  {this.state.type_of_corruption ===
                    "Public Procurement Irregularities" && (
                    <Grid item container xs={12}>
                      <Grid item container xs={12}>
                        <div className={classes.incidentDetails}>
                          <Typography className={classes.incidentDetailsTitle}>
                            {
                              this.state.language
                                .inidentSigleView_procurement_field
                            }
                          </Typography>
                        </div>
                        <Typography className={classes.incidentDetailsSubTitle}>
                          {this.state.type_of_procurement}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  {/*Election */}
                  {this.state.type_of_corruption === "Election" && (
                    <Grid item container xs={12}>
                      <Grid item container xs={12}>
                        <div className={classes.incidentDetails}>
                          <Typography className={classes.incidentDetailsTitle}>
                            {
                              this.state.language
                                .inidentSigleView_election_field1
                            }
                          </Typography>
                        </div>
                        <Typography className={classes.incidentDetailsSubTitle}>
                          {this.state.nature_of_misuse}
                        </Typography>
                      </Grid>

                      <Grid item container xs={12}>
                        <div className={classes.incidentDetails}>
                          <Typography className={classes.incidentDetailsTitle}>
                            {
                              this.state.language
                                .inidentSigleView_election_field2
                            }
                          </Typography>
                        </div>
                        <Typography className={classes.incidentDetailsSubTitle}>
                          {
                            this.state.incident_report_All.election
                              .political_party
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {/*No Story */}
              {this.state.story != null && (
                <Grid item container xs={12}>
                  <Grid item container xs={12}>
                    <div style={{ width: "100%" }}>
                      <Typography style={{ fontSize: 18, fontWeight: 700 }}>
                        {this.state.language.storiesPage_story}
                      </Typography>
                    </div>
                    <Typography style={{ lineHeight: 2 }} align="justify">
                      {this.state.story}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12} md={12} className="pt-3">
                {/* Election */}
                {/* {this.state.type_of_corruption === "Election" && (
                <>
                  <Typography
                    Typography
                    variant="p"
                    component="p"
                    style={{ color: "#000", fontWeight: "bold" }}
                  >
                    {this.state.language.inidentSigleView_election_field3}
                  </Typography>
                  <ul>
                    {this.state.misused.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </>
              )} */}
                {/* Election */}
                {this.state.type_of_corruption === "Election" && (
                  <Grid item container xs={12}>
                    <Grid item container xs={12}>
                      <div>
                        <Typography className={classes.incidentDetailsTitle}>
                          {this.state.language.inidentSigleView_election_field3}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item container xs={12} sm={6}>
                    <List>
                      {this.state.misused.map((item) => (
                        <ListItem key={item}>
                          <ListItemIcon>
                            <ChevronRightIcon />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.incidentDetailsSubTitle}
                            primary={item}
                          />
                        </ListItem>
                      ))}
                    </List>
                    </Grid>
                  </Grid>
                )}

                {this.state.otherDetails != null && (
                  <Grid item container xs={12}>
                    <Grid item container sm={6} xs={12}>
                      <Alert
                        icon={<DetailsIcon fontSize="inherit" />}
                        severity="info"
                      >
                        <AlertTitle>
                          {/* Other Details */}
                          {this.state.language.inidentSigleView_otherDetails}
                        </AlertTitle>
                        <Typography align="justify">
                          {this.state.otherDetails}
                        </Typography>
                      </Alert>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Fade>
        </div>
        {/* Footer */}
        <Footer />
      </Fragment>
    );
  }
}

export default withStyles(styleSheet)(ViewIncident);
