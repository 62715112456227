import React, { Component, Fragment } from 'react';
import ReactDOM from "react-dom";
import Preloader from "./components/Preloader/Preloader";
import Timer from "./components/Countdown/Timer";
import Optin from "./components/Optin/Optin";
import { withStyles } from "@material-ui/core/styles";
import "./styles.css";
import ElectionServices from 'app/services/ElectionServices';

const styleSheet = ((theme) => ({
    videoContainer: {
        //borderRadius: 15,
        overflow: "hidden",

    },
}));
class CommingSoon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: false,
            onDuration: 0,
            loaded: false,
        };

    }


    async componentDidMount() {
        let launchStatus = await ElectionServices.getLaunchStatus();
        console.log("launch status", launchStatus.data.launch.status)

        if (launchStatus.data.launch.status == 1) {
            window.location.replace("/languageSelect");
        } else if (launchStatus.data.launch.status == 0) {
            this.setState({ loaded: true })
        }

        setTimeout(() => {
            this.setState({ playing: true, onDuration: 1 })
        }, 1000);

    }
    componentWillUnmount() {

    }


    render() {
        const { classes } = this.props
        const preventDefault = (event) => event.preventDefault();
        return (
            this.state.loaded ?
                <div className="commingSoonContainer">
                    <div className="App">
                        <div className="commingcontainer" style={{ color: 'white' }}>
                            <h1 >
                                Apesalli.lk
                                <br />
                                Coming Soon
                            </h1>
                            <Timer />
                            <Optin />
                            <Preloader />
                        </div>
                    </div>
                </div>
                : <div></div>
        );
    }
}





export default withStyles(styleSheet)(CommingSoon);