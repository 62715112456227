import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  Link,
  Button,
  Grid,
  SwipeableDrawer,
  Avatar,
  Icon,
  IconButton,
  MenuItem,
  Menu,
} from "@material-ui/core";
import { MatxMenu, MatxSearchBox } from "matx";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { themeColors } from "app/MatxLayout/MatxTheme/themeColors";
import { useScrollTrigger } from "@material-ui/core";

import { englishLanguage } from "../../languages/englishLanguage";
import { sinhalaLanguage } from "../../languages/sinhalaLanguage";
import { tamilLanguage } from "../../languages/tamilLanguage";

import languageService from "app/services/languageService";
import LanguageIcon from "@material-ui/icons/Language";

import HomeIcon from "@material-ui/icons/Home";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import LineStyleIcon from "@material-ui/icons/LineStyle";
import MapIcon from "@material-ui/icons/Map";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import InfoIcon from "@material-ui/icons/Info";


import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PublicIcon from '@material-ui/icons/Public';

const styleSheet = {
  appbar: {
    //backgroundColor: themeColors.purple1.palette.theme_color.main,
    //Change Background - by shammika
    background: "transparent",
    boxShadow: "0px 0px", //added by shammika
    padding: "3px 93px 0px 90px ", //added by shammika
    // marginBottom: "20px",

    color: "black",
    transition: "all .5s ease",
    WebkitTransition: "all .5s ease",
    MozTransition: "all .5s ease",
  },
  appBarScroll: {
    // backgroundColor: "red",
    // transition: "all .5s ease",
    // WebkitTransition: "all .5s ease",
    // MozTransition: "all .5s ease",
  },
  menuitemEnglish: {
    //fontFamily: "Source Sans Pro", //added by shammika
    color: "#ffffff", // changed by shammika
    fontWeight: "400",
    fontSize: 16, // changed by shammika
    textAlign: "center",
    "&:hover": {
      // backgroundColor:"white",
      // color: "#01648b", //changed by shammika
      borderBottomColor: "white",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
    },
  },

  menuitemSinhalaAndTamil: {
    //fontFamily: "Source Sans Pro", //added by shammika
    color: "#ffffff", // changed by shammika
    fontWeight: "400",
    fontSize: 14, // changed by shammika
    textAlign: "center",
    "&:hover": {
      // backgroundColor:"white",
      // color: "#01648b", //changed by shammika
      borderBottomColor: "white",
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
    },
  },

  list: {
    width: 200,
  },
  padding: {
    marginLeft: 30,
    cursor: "pointer",
  },

  sideBarIcon: {
    padding: 0,
    color: "white",
    cursor: "pointer",
  },

  listItemIcon: {
    marginRight: 5,
  },
  nested: {
    marginLeft: 40,
  },
  expand: {
    marginLeft: 60
  }
};

class AppAppBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: englishLanguage,
      languageName: "English",
      drawerActivate: false,
      drawer: false,
      shouldShow: null,
      anchorEl: null,
      anchorEl2: null,
      dropdown: false,
      dropdown2: false,

      //small screen drawer language select dropdown - by shammika
      displayMenu: false
    };

    this.createDrawer = this.createDrawer.bind(this);
    this.openDropdown2 = this.openDropdown2.bind(this);
    this.destroyDrawer = this.destroyDrawer.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);

    //collapse and uncollapse dropdown - by shammika
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  }

  async loadLanguageDetails() {
    var lng = await languageService.getLanguageDetails();

    if (lng == "Sinhala") {
      this.setState({
        language: sinhalaLanguage,
        languageName: "Sinhala",
      });
    } else if (lng == "Tamil") {
      this.setState({
        language: tamilLanguage,
        languageName: "Tamil",
      });
    }

    // console.log("App bar:", this.state);
  }

  componentWillMount() {
    //load language details
    this.loadLanguageDetails();

    if (window.innerWidth <= 1200) {
      this.setState({
        drawerActivate: true,
      });
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 1200) {
        this.setState({
          drawerActivate: true,
        });
      } else {
        this.setState({
          drawerActivate: false,
        });
      }
    });
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, dropdown: true });
  };

  openDropdown2 = (event) => {
    this.setState({ anchorEl2: event.currentTarget, dropdown2: true });
  };

  changeLanguage(language) {
    languageService.setLanguageDetails(language);
    window.location.reload(false);
  }

  //show dropdown list - by shammika
  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  //hide dropdown list - by shammika
  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }

  //Small Screens
  createDrawer() {
    return (
      <div className="myNewClass">
        <AppBar
          id="appbar12"
          position="fixed"
          className={this.props.classes.appbar}
          style={{ paddingLeft: 5, paddingRight: 5 }}
        >
          <Toolbar>
            <Grid
              container
              direction="row"
            >
              <Grid item style={{ width: '80%' }}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                >
                  <img
                    src="/assets/images/apesalli_logo.png"
                    alt="company-logo"
                    style={{ width: 40 }}
                  />
                  <Typography variant="h5" style={{ fontWeight: "600", color: "white", cursor: "pointer" }}>
                    Apesalli
                  </Typography>
                </Grid>
              </Grid>

              <Grid item alignItems="center" style={{ width: '20%', justifyContent: 'flex-end', display: 'flex' }} >
                <MenuIcon
                  color="#ffffff"
                  className={this.props.classes.sideBarIcon}
                  onClick={() => {
                    this.setState({ drawer: true });
                  }}
                />
              </Grid>



            </Grid>
          </Toolbar>
        </AppBar>

        <SwipeableDrawer
          open={this.state.drawer}
          onClose={() => {
            this.setState({ drawer: false });
          }}
          onOpen={() => {
            this.setState({ drawer: true });
          }}
        >
          <div
            tabIndex={0}
            role="button"
          // onClick={() => {
          //   this.setState({ drawer: false });
          // }}
          // onKeyDown={() => {
          //   this.setState({ drawer: false });
          // }}
          >
            <List className={this.props.classes.list}>
              <ListItem
                onClick={() => {
                  window.location.href = "/home";
                }}
                key={1}
                button
                divider
                className={this.props.classes.listItemStyle}
              >
                <HomeIcon
                  fontsize="small"
                  className={this.props.classes.listItemIcon}
                />
                {this.state.language.home_appBar}{" "}
              </ListItem>
              <ListItem
                onClick={() => {
                  window.location.href = "/faq";
                }}
                key={1}
                button
                divider
                className={this.props.classes.listItemStyle}
              >
                <LiveHelpIcon
                  fontsize="small"
                  className={this.props.classes.listItemIcon}
                />
                {/* How to */}
                {this.state.language.howTo_appBar}
              </ListItem>

              <ListItem
                onClick={() => {
                  window.location.href = "/incidents";
                }}
                key={3}
                button
                divider
                className={this.props.classes.listItemStyle}
              >
                <AssignmentIcon
                  fontsize="small"
                  className={this.props.classes.listItemIcon}
                />
                {this.state.language.incident_appBar}
              </ListItem>
              <ListItem
                divider
                onClick={() => {
                  window.location.href = "/stories";
                }}
                key={3}
                button
                className={this.props.classes.listItemStyle}
              >
                <ChromeReaderModeIcon
                  fontsize="small"
                  className={this.props.classes.listItemIcon}
                />
                {/* Stories */}
                {this.state.language.story_appBar}
              </ListItem>

              <ListItem
                divider
                onClick={() => {
                  window.location.href = "/news";
                }}
                key={3}
                button
                className={this.props.classes.listItemStyle}
              >
                {/* News */}
                <LineStyleIcon className={this.props.classes.listItemIcon} />
                {this.state.language.news_appBar}
              </ListItem>

              <ListItem
                divider
                onClick={() => {
                  window.location.href = "/incidentMap";
                }}
                key={3}
                button
                className={this.props.classes.listItemStyle}
              >
                {/* Incidents Map */}
                <MapIcon
                  fontsize="small"
                  className={this.props.classes.listItemIcon}
                />
                {this.state.language.incidentMap_appBar}
              </ListItem>

              <ListItem
                divider
                onClick={() => {
                  window.location.href = "/statistics";
                }}
                key={3}
                button
                className={this.props.classes.listItemStyle}
              >
                {/* Statistics */}
                <EqualizerIcon
                  fontsize="small"
                  className={this.props.classes.listItemIcon}
                />
                {this.state.language.statistics_appBar}
              </ListItem>
              <ListItem divider button onClick={this.showDropdownMenu}>

                <LanguageIcon fontsize="small"
                  className={this.props.classes.listItemIcon} />
                Language
                <div className={this.props.classes.expand}></div>
                {this.state.displayMenu ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              {this.state.displayMenu ? (
                <div>
                  <ListItem

                    onClick={() => this.changeLanguage("English")}
                    key={3}
                    button
                    className={[this.props.classes.nested,]}
                  >
                    <PublicIcon fontsize="small"
                      className={this.props.classes.listItemIcon} />
                    English
                  </ListItem>
                  <ListItem

                    onClick={() => this.changeLanguage("Sinhala")}
                    key={3}
                    button
                    className={[this.props.classes.nested,]}
                  >
                    <PublicIcon fontsize="small"
                      className={this.props.classes.listItemIcon} />
                    සිංහල
                  </ListItem>
                  <ListItem

                    onClick={() => this.changeLanguage("Tamil")}
                    key={3}
                    button
                    className={[this.props.classes.nested,]}
                  >
                    <PublicIcon fontsize="small"
                      className={this.props.classes.listItemIcon} />
                    தமிழ்
                  </ListItem>
                </div>) : (null)}
              {/* <Collapse in={this.state.displayMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button className={this.classes.nested}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <ListItemText primary="Starred" />
                  </ListItem>
                </List>
              </Collapse> */}

              <ListItem
                onClick={() => {
                  window.location.href = "/aboutUs";
                }}
                key={2}
                button
                className={this.props.classes.listItemStyle}
              >
                <InfoIcon
                  fontsize="small"
                  className={this.props.classes.listItemIcon}
                />
                {this.state.language.aboutUs_appBar}{" "}
              </ListItem>
              {/* <ListItem key={3} button divider> Contact With Us </ListItem> */}
            </List>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }

  //Larger Screens
  destroyDrawer() {
    const { classes } = this.props;
    return (
      <AppBar id="appbar12" className={this.props.classes.appbar}>
        <Toolbar>
          <Link href="/home">
            <img
              src="/assets/images/apesalli_logo.png"
              alt="company-logo"
              style={{ width: 80 }}
            />
          </Link>

          <Link href="/home" style={{ textDecoration: "none" }}>
            <Typography
              className="mx-6"
              variant="h5"
              style={{ fontWeight: "600", color: "white", cursor: "pointer" }}
              color="inherit"
            >
              Apesalli
            </Typography>
          </Link>

          <Grid style={{ width: "90%" }}>
            <Link underline="none" href={"/home"}>
              <Typography
                variant="subheading"
                className={[classes.padding,
                this.state.languageName === "English" ?
                  classes.menuitemEnglish : classes.menuitemSinhalaAndTamil
                ]}
                color="inherit"
              // style={{ fontSize: '13px',}}
              >
                {/* Home */}
                {this.state.language.home_appBar}
              </Typography>
            </Link>
            <Link underline="none" href={"/faq"}>
              <Typography
                variant="subheading"
                className={[classes.padding,
                this.state.languageName === "English" ?
                  classes.menuitemEnglish : classes.menuitemSinhalaAndTamil
                ]}
                color="inherit"
              >
                {/* How to */}
                {this.state.language.howTo_appBar}
              </Typography>
            </Link>
            <Link underline="none" onClick={this.handleClick}>
              <Typography
                variant="subheading"
                className={[classes.padding,
                this.state.languageName === "English" ?
                  classes.menuitemEnglish : classes.menuitemSinhalaAndTamil
                ]}
                color="inherit"
              >
                {/* Reports & Stories */}
                {this.state.language.incidentReport_appBar}
              </Typography>
            </Link>

            <Menu
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              //keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              style={{ top: 30 }}
              open={this.state.dropdown}
              onClose={() => {
                this.setState({ dropdown: false });
              }}
            >
              <MenuItem>
                <Link underline="none" href={"/incidents"}>
                  <Typography
                    variant="subheading"
                    className={this.state.languageName === "English" ?
                      classes.menuitemEnglish : classes.menuitemSinhalaAndTamil
                    }
                    color="inherit"
                    style={{ color: "black" }}
                  >
                    {/* Reports */}
                    {this.state.language.incident_appBar}
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link underline="none" href={"/stories"}>
                  <Typography
                    variant="subheading"
                    className={this.state.languageName === "English" ?
                      classes.menuitemEnglish : classes.menuitemSinhalaAndTamil
                    }
                    color="inherit"
                    style={{ color: "black" }}
                  >
                    {/* Stories */}
                    {this.state.language.story_appBar}
                  </Typography>
                </Link>
              </MenuItem>
            </Menu>

            <Link underline="none" href={"/news"}>
              <Typography
                variant="subheading"
                className={[classes.padding,
                this.state.languageName === "English" ?
                  classes.menuitemEnglish : classes.menuitemSinhalaAndTamil
                ]}
                color="inherit"
              >
                {/* News */}
                {this.state.language.news_appBar}
              </Typography>
            </Link>

            <Link underline="none" href={"/incidentMap"}>
              <Typography
                variant="subheading"
                className={[classes.padding,
                this.state.languageName === "English" ?
                  classes.menuitemEnglish : classes.menuitemSinhalaAndTamil
                ]}
                color="inherit"
              >
                {/* Incidents Map */}
                {this.state.language.incidentMap_appBar}
              </Typography>
            </Link>
            <Link underline="none" href={"/statistics"}>
              <Typography
                variant="subheading"
                className={[classes.padding,
                this.state.languageName === "English" ?
                  classes.menuitemEnglish : classes.menuitemSinhalaAndTamil
                ]}
                color="inherit"
              >
                {/* Statistics */}
                {this.state.language.statistics_appBar}
              </Typography>
            </Link>
          </Grid>

          <Grid
            style={{
              width: "40%",
              alignItems: "center",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            {/* Profile */}
            <Link underline="none" href={"/session/signin"}>
              <img
                backgroundColor
                src="/assets/images/login_icon.png"
                className={[classes.padding]}
                style={{ height: 30, width: 29 }}
              />
            </Link>

            {/* language select */}
            <Link underline="none">
              <Typography
                style={{ color: "white" }}
                variant="subheading"

                color="inherit"
                className={[classes.padding, classes.menuitemEnglish]}
                onClick={(e) => this.openDropdown2(e)}
              >
                {/* <LanguageIcon
                  fontSize="large"
                  onClick={(e) => this.openDropdown2(e)}
                /> */}
                සිං | En | த
              </Typography>

              <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl2}
                // anchorOrigin={{
                //   vertical: 'bottom',
                //   horizontal: 'center',
                // }}
                style={{ top: 30 }}
                open={this.state.dropdown2}
                onClose={() => this.setState({ dropdown2: false })}
              >
                <MenuItem onClick={() => this.changeLanguage("English")}>
                  English
                </MenuItem>
                <MenuItem onClick={() => this.changeLanguage("Sinhala")}>
                  සිංහල
                </MenuItem>
                <MenuItem onClick={() => this.changeLanguage("Tamil")}>
                  தமிழ்
                </MenuItem>
              </Menu>
            </Link>

            {/* About Us */}
            <Link underline="none" href={"/aboutUs"}>
              <Typography
                variant="subheading"
                className={[classes.padding,
                this.state.languageName === "English" ?
                  classes.menuitemEnglish : classes.menuitemSinhalaAndTamil
                ]}
                color="inherit"
              >
                {this.state.language.aboutUs_appBar}
              </Typography>
            </Link>

            {/*<Avatar alt="Remy Sharp" src="/assets/images/face-6.jpg" />*/}
            {/* <MatxMenu
                menuButton={
                  <img
                    className="mx-2 align-middle circular-image-small cursor-pointer"
                    src="/assets/images/face-6.jpg"
                    alt="user"
                  />
                }
              >
                <MenuItem>
                  <Link className={classes.menuItem} to="/">
                    <Icon> home </Icon>
                    <span className="pl-4"> Home </span>
                  </Link>
                </MenuItem>
                <MenuItem>
                  
                  <Icon> person </Icon>
                  <span className="pl-4"> Profile </span>
                  
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <Icon> settings </Icon>
                  <span className="pl-4"> Settings </span>
                </MenuItem>
                <MenuItem
                  onClick={this.handleSignOut}
                  className={classes.menuItem}
                >
                  <Icon> power_settings_new </Icon>
                  <span className="pl-4"> Logout </span>
                </MenuItem>
              </MatxMenu>
              */}
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }

  render() {
    return (
      <div>
        {this.state.drawerActivate ? this.createDrawer() : this.destroyDrawer()}
      </div>
    );
  }
}

AppAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styleSheet)(AppAppBar);
