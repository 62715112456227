import localStorageService from "./localStorageService";

class  LanguageService {

    //set language details to local
    setLanguageDetails = async(lan) => {
        const language = await localStorageService.setItem("language", lan);
        return language;
    }

    //get language details from local
    getLanguageDetails = async() => {
        const lan = await localStorageService.getItem("language");
        return lan;
    }

}

export default new LanguageService();