import React, { Component, Fragment, useEffect } from "react";
import {
  Grid,
  Card,
  Button,
  Typography,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";

import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { SimpleCard } from "matx";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import Container from "@material-ui/core/Container";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import * as appVariables from "../../../appVariables";

import CloseIcon from "@material-ui/icons/Close";
import IncidentService from "../../../services/IncidentServices";

import { englishLanguage } from "../../languages/englishLanguage";
import { sinhalaLanguage } from "../../languages/sinhalaLanguage";
import { tamilLanguage } from "../../languages/tamilLanguage";
import languageService from "app/services/languageService";

import {
  storyWordLimit, ageRanges,
  share_story_theme,
  share_story_theme_dark,
}
  from "../../../appVariables";

import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import Util from "app/services/Util";
//All styles
const styles = (theme) => ({
  root: {
    // marginTop: 80,
    paddingLeft: 100,
    paddingRight: 100,
    paddingTop: 30,
    paddingBottom: 30,
    fontFamily: "Source Sans Pro",
  },
  mainPadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 100,
      paddingRight: 100,
      paddingTop: 30,
      paddingBottom: 30,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 30,
      paddingBottom: 30,
    },
  },

  heading: {
    marginBottom: 10,
  },
  //TextField styles by shammika
  textStyles: {
    "& label": {
      color: "#25282c",
      fontSize: 15,
      fontFamily: "Source Sans Pro",
      fontWeight: 400,

      "& hover": {
        fontWeight: "bold",
      },
    },
    "& label.Mui-focused": {
      color: "#25282c",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#25282c",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: 'red',
      },
      "&:hover fieldset": {
        // borderColor: 'yellow',
      },
      "&.Mui-focused fieldset": {
        borderColor: "#25282c",
      },
    },
  },
  radioButton: {
    "& .MuiRadio-colorPrimary": {
      color: share_story_theme, //"#fd902a"
    }
  },

  //Buttons styles
  btnShare: {
    display: "grid",
    float: "right",
    width: "20%",
    transition: "all ease 200ms",
    [theme.breakpoints.down("xs")]: {
      width: "100%",

    },
    padding: "7px 10px",
    color: "white",
    backgroundColor: share_story_theme, //"#fd902a",
    "&:hover": {
      color: "white",
      backgroundColor: share_story_theme_dark, //"#e61d1d",
      transform: "scale(1.02)",
    },
  },
});
class ShareStoryComponent extends Component {
  //scrollbar-container
  constructor(props) {
    super(props);
    this.state = {
      snackbar: false,
      message: "",
      severity: "success",
      referenceNoDialog: false,
      referenceNo: null,

      //language
      language: englishLanguage,
      languageName: "English",

      //anonymous dialog
      noteDialog: false,

      //word limit
      countWords: storyWordLimit,

      //className
      styleClassName: true,

      instruction: false,
      district: englishLanguage.lng_Districts,

      public_authority_type: englishLanguage.lng_publicAuthorities,

      formdata: {
        reported_language: 'English',
        public_authority_involved: true,
        name_of_authority: null,
        designation_of_authority: null,
        divisional_secretariat: null,
        district: null,
        incident_date_time: null,
        story: null,
        anonymous: false,
        name: null,
        contact_number: null,
        email: null,
        agreement: true,
        type: "Share Story",
        age: null,
        gender: null,
        address: null,
        type_of_corruption: null,
        latitude: null,
        longitude: null,
        incident_status: null,
        title: null,

        corruption_details: {
          you_are: null,
          bribe_form: null,
          monetary_value: null,
          type_of_property: null,
          benefit: null,
          private_interest: null,
          nature_of_interest: null,
          involved: null,
          disclose_interest: null,
          authorized_participate: null,
          type_of_procurement: null,
          misused: null,
          nature_of_misuse: null,
          political_party: null,
          other_details: null,
          attachments: [],
        },
      },
    };
    //creates a reference for your element to use
    this.myDivToFocus = React.createRef();
  }

  async loadLanguageDetails() {
    var lng = await languageService.getLanguageDetails();
    var sinhala_districts = sinhalaLanguage.lng_Districts;
    var tamil_districts = tamilLanguage.lng_Districts;
    var data = this.state.formdata;

    if (lng == 'Sinhala') {

      data['reported_language'] = 'Sinhala';

      this.setState({
        language: sinhalaLanguage,
        languageName: "Sinhala",
        formdata: data,
        district: sinhala_districts,
        public_authority_type: sinhalaLanguage.lng_publicAuthorities,
      })
    }
    else if (lng == 'Tamil') {

      data['reported_language'] = 'Tamil';

      this.setState({
        languageName: "Tamil",
        language: tamilLanguage,
        formdata: data,
        district: tamil_districts,
        public_authority_type: tamilLanguage.lng_publicAuthorities,
      });
    }
    console.log("Story Section:", this.state);

  }

  componentDidMount() {
    this.loadLanguageDetails();

    // let district = appVariables.district;

    // this.setState({ district })

    if (window.innerWidth <= 900) {
      this.setState({
        styleClassName: false,
      });
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 900) {
        this.setState({
          styleClassName: false,
        });
      } else {
        this.setState({
          styleClassName: true,
        });
      }
    });
  }

  handleChange = async (event) => {
    event.persist();

    var data = this.state.formdata;

    // console.log(event.target.name, event.target.value)

    // if (event.target.name == "public_authority_involved") {
    //     var ans = null;
    //     if (event.target.value == "true") {
    //         ans = true;
    //     } else if (event.target.value == "false") {
    //         ans = false;
    //     }
    //     data['public_authority_involved'] = ans;
    //     this.setState({
    //         formdata: data
    //     });

    // } else if

    if (event.target.name == "anonymous") {
      var ans = null;
      if (event.target.value == "true") {
        ans = true;
        this.openNoteDialog();
      } else if (event.target.value == "false") {
        ans = false;
      }
      data["anonymous"] = ans;
      data["name"] = null;
      data["contact_number"] = null;
      data["email"] = null;

      this.setState({
        formdata: data,
      });
    } else if (event.target.name === "story") {
      data["story"] = event.target.value;

      var finalCount = storyWordLimit;

      if (this.state.formdata.story != null) {
        var count = this.state.formdata.story.length;
        var finalCount = storyWordLimit - count;
      }

      this.setState({
        formdata: data,
        countWords: finalCount,
      });
    } else {
      data[event.target.name] = event.target.value;
      this.setState({
        formdata: data,
      });
    }

    console.log(this.state);
  };

  //File choose action
  handlefileUpload = (event) => {
    event.persist();

    var uploaddata = this.state.formdata;

    if (event.target.files[0] !== undefined) {
      for (let size = 0; size < event.target.files.length; size++) {
        console.log("Selected file:", event.target.files[size]);
        let file = event.target.files[size];
        uploaddata["corruption_details"]["attachments"].push(file);
      }
      // uploaddata['corruption_details']=this.state.case[0].id;
      // uploaddata['case_uploads'] = event.target.files[0] || event.dataTransfer.files[0];
    }

    this.setState({
      formdata: uploaddata,
    });
  };

  setDistrictDataDropdown(key, value) {
    console.log(key, value);
    var data = this.state.formdata;

    data[key] = value;
    this.setState(
      {
        formdata: data,
      },
      () => {
        console.log(this.state.formdata);
      }
    );
  }

  formSubmit = (event) => {
    console.log("form data", this.state.formdata);
    var data = this.state.formdata;

    IncidentService.createIncident(data)
      .then((res) => {
        console.log("response", res);
        if (res.status == 200) {
          this.setState({
            message: res.data.message,
            snackbar: true,
            severity: "success",
            referenceNoDialog: true,
            referenceNo: res.data.reference_number,
          });
          setTimeout(() => {
            // window.location.reload(false);
          }, 3000);
        } else {
          this.setState({
            message: res.data.message,
            snackbar: true,
            severity: "error",
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // //uploading files
  // uploadFiles(data) {
  //     var bodyFormData = new FormData()

  //     for (let size = 0; size < data['corruption_details']['attachments'].length; size++) {
  //         bodyFormData.append('attachments[' + size + ']', data['corruption_details']['attachments'][size]);
  //     }

  //     IncidentService.uploadFiles(bodyFormData).then(res => {
  //         if (res.status == 200) {
  //             this.setState({ message: res.data.message, snackbar: true, severity: "success" })
  //             setTimeout(() => {
  //                 // window.location.reload(false);
  //             }, 3000);
  //         } else {
  //             this.setState({ message: res.data.message, snackbar: true, severity: "error" })
  //         }
  //     })
  //         .catch((error) => {
  //             console.log("error", error);
  //         })

  // }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false });
  };

  closeRefferenceDialog = () => {
    {
      this.setState({ referenceNoDialog: false });
    }
    // window.location.reload(false);
    window.location.href = "/home";
  };

  openNoteDialog = () => {
    this.setState({
      noteDialog: true,
    });
  };

  closeNoteDialog = () => {
    this.setState({
      noteDialog: false,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <div
          className={classes.mainPadding}
          style={{
            minHeight: "100vh",
            background:
              `radial-gradient(circle at -35% 0%, ${share_story_theme} 12%, #fff8f8 50%, #ffffff 100%)`,
          }}
        >
          <Grid container spacing={6}>
            {/* Close Button */}
            <Grid xs={12} item align="flex-end" className=" w-full pb-5">
              <div style={{ float: "right" }}>
                <IconButton
                  style={{ color: "#0e334b" }}
                  onClick={() => {
                    window.location.href = "/home";
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Grid>

            {/* Heading image and Title */}
            <Grid
              item
              container
              xs={12}
              md={4}
              direction="column"
              // justifyContent="space-around"
              alignItems="center"
              className={classes.heading}
            >
              <Grid item align="center" className=" w-full pb-10 mt-10 ">
                {/* <img alt="share" src="/assets/images/share2.png" /> */}
                <img alt="share" src="/assets/images/reportIncident Logo.png" />

              </Grid>
              <Grid item align="center" className=" w-full ">
                <Typography
                  // className="mt-5"
                  variant="h1"
                  style={{ fontSize: 30, fontWeight: 600, color: "#1B1717" }}
                >
                  {/* Report Incidents of Bribery and Corruption to TISL */}
                  {this.state.language.corruptionReport_title}{" "}

                </Typography>
              </Grid>
            </Grid>

            {/* Form Grid by shammika */}
            <Grid
              justifyContent="center"
              item
              container
              xs={12}
              md={8}
              className=" mt--10"
            >
              <ValidatorForm
                onSubmit={() => {
                  this.formSubmit();
                }}
              >
                <Grid container spacing={2}>
                  {/*                             
                            <Grid item xs={12} sm={6}>
                                <FormControl className="mb-5" component="fieldset">
                                    <FormLabel component="legend">
                                        // Public Authority Involved?
                                        {this.state.language.shareStory_publicAuthorityFieldTitle}
                                    </FormLabel>
                                    <RadioGroup row aria-label="public_authority_involved" name="public_authority_involved" value={true} onChange={(e) => this.handleChange(e)}>
                                        <FormControlLabel display="inline" value={true} 
                                            checked={this.state.formdata.public_authority_involved == true ? true : false} 
                                            //yes
                                            control={<Radio color="primary" size='small' value={true} />} 
                                            label={this.state.language.shareStory_publicAuthorityFieldAns1} />
                                        <FormControlLabel display="inline" value={false} 
                                            checked={this.state.formdata.public_authority_involved == false ? true : false} 
                                            control={<Radio color="primary" size='small' value={false} />} 
                                            //no
                                            label={this.state.language.shareStory_publicAuthorityFieldAns2} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid> */}

                  {/* Name of the public involved */}
                  <Grid item xs={12} sm={6}>
                    <Tooltip
                      // "Please Select Authority"
                      title={
                        this.state.language.shareStory_storyAuthorityTooltip
                      }
                      arrow
                    >
                      <Autocomplete
                        className="w-full"
                        options={this.state.public_authority_type}
                        getOptionLabel={(option) => option.label}
                        onChange={(e, v) =>
                          this.setDistrictDataDropdown(
                            "name_of_authority",
                            v == null ? null : v.value
                          )
                        }
                        renderInput={(param) => (
                          <TextValidator
                            {...param}
                            className={classes.textStyles}
                            //variant="outlined"
                            // "Select Public Authority"
                            label={
                              this.state.language
                                .shareStory_storyAuthorityPlaceholder
                            }
                            validators={
                              this.state.formdata.public_authority_involved ==
                                true
                                ? ["required"]
                                : []
                            }
                            errorMessages={[
                              // "Public Authority field is required!"
                              this.state.language
                                .corruptionReport_step3PublicAuthorityNameFieldRequired,
                            ]}
                            value={this.state.formdata.name_of_authority}
                            size="small"
                          />
                        )}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Tooltip
                      // Please Enter Title of the Story
                      title={this.state.language.shareStory_storyTitleTooltip}
                      arrow
                    >
                      <TextValidator
                        // className="mb-5 w-full"
                        className={[classes.textStyles, "mb-5 w-full"]}
                        // variant="outlined"
                        //Name of The Public Involved
                        label={
                          this.state.language.shareStory_storyTitlePlaceholder
                        }
                        onChange={this.handleChange}
                        size="small"
                        name="title"
                        value={this.state.formdata.title}
                        //validators={['maxStringLength:13']}
                        errorMessages={[""]}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid item xs={12} sm={this.state.languageName === "Tamil" ? 12 : 6}>
                    <Tooltip
                      // Please Enter Name and the Designation of the Authority
                      title={
                        this.state.language.shareStory_authorityNameTooltip
                      }
                      arrow
                    >
                      <TextValidator
                        // className="mb-5 w-full"
                        className={[classes.textStyles, "mb-5 w-full"]}
                        // variant="outlined"
                        //Name and Designation of The Authority Involved
                        label={
                          this.state.language
                            .shareStory_authorityNamePlaceholder
                        }
                        onChange={this.handleChange}
                        size="small"
                        name="designation_of_authority"
                        value={this.state.formdata.designation_of_authority}
                        //validators={['maxStringLength:13']}
                        errorMessages={[""]}
                      />
                    </Tooltip>
                  </Grid>

                  {/* Date */}
                  <Grid item xs={12} sm={6}>
                    <Tooltip
                      // "Date of Incident"
                      title={this.state.language.shareStory_dateFieldTooltip}
                      arrow
                    >
                      <TextValidator
                        // className="mb-5 w-full"
                        InputProps={{ inputProps: { max: Util.getParsedDate(new Date()) } }}
                        className={[classes.textStyles, "mb-5 w-full"]}
                        type="date"
                        size="small"
                        // variant="outlined"
                        //"Date of Incident"
                        label={this.state.language.shareStory_dateFieldTooltip}
                        name="incident_date_time"
                        onChange={this.handleChange}
                        value={
                          this.state.formdata.incident_date_time == null
                            ? ""
                            : this.state.formdata.incident_date_time
                        }
                        validators={["required"]}
                        errorMessages={[
                          // 'This field is required'
                          this.state.language
                            .shareStory_requiredErrorMessageFieldDate,
                        ]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Tooltip
                      // "Please Select District"
                      title={
                        this.state.language.shareStory_districtFieldTooltip
                      }
                      arrow
                    >
                      <Autocomplete
                        // className="mb-5 w-full"
                        className={[classes.textStyles, "mb-5 w-full"]}
                        options={this.state.district}
                        getOptionLabel={(option) => option.label}
                        size="small"
                        name="district"
                        onChange={(event, value) =>
                          this.setDistrictDataDropdown(
                            "district",
                            value == null ? null : value.value
                          )
                        }
                        //value={this.state.formdata.district}

                        renderInput={(params) => (
                          <TextValidator
                            {...params}
                            //Select District
                            label={
                              this.state.language
                                .shareStory_districtFieldPlaceholder
                            }
                            // variant="outlined"
                            className={[classes.textStyles, "mb-5 w-full"]}
                            validators={["required"]}
                            errorMessages={[
                              // "this field is required",
                              this.state.language
                                .shareStory_requiredErrorMessageFieldDistrict,
                            ]}
                            value={
                              this.state.formdata.district == null
                                ? ""
                                : this.state.formdata.district
                            }
                          />
                        )}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Tooltip
                      // "Please Enter the Story"
                      title={this.state.language.shareStory_storyFieldTooltip}
                      arrow
                    >
                      <TextValidator
                        // className="mb-5 w-full"
                        className={[classes.textStyles, "mb-5 w-full"]}
                        variant="outlined"
                        // "Your Story"
                        label={
                          this.state.language.shareStory_storyFieldPlaceholder
                        }
                        onChange={this.handleChange}
                        size="small"
                        name="story"
                        //Word Limit
                        helperText={
                          this.state.language.shareStory_storyFieldWordLimit +
                          "(" +
                          this.state.countWords +
                          "/" +
                          storyWordLimit +
                          ")"
                        }
                        inputProps={{ maxLength: storyWordLimit }}
                        value={this.state.formdata.story}
                        onClick={() => {
                          this.state.formdata.story == null
                            ? this.setState({ instruction: true })
                            : this.setState({ instruction: false });
                        }}
                        multiline={true}
                        rows={5}
                        validators={
                          !this.state.formdata.anonymous ? ["required"] : []
                        }
                        errorMessages={[
                          // "Story is required",
                          this.state.language
                            .shareStory_requiredErrorMessageFieldStory,
                        ]}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  {/* <Grid item xs={12} sm={6}>

                                <FormControl className="mb-5" component="fieldset">
                                    <FormLabel component="legend">Upload</FormLabel>
                                    <input
                                        className="mb-5 mt-2 w-full"
                                        variant="outlined"
                                        label="Upload"
                                        onChange={this.handlefileUpload}
                                        size='small'
                                        name="attachments"
                                        multiple
                                        type="file"

                                        //value={}
                                        //validators={['maxStringLength:13']}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        errorMessages={[
                                            "",
                                        ]}
                                    />
                                </FormControl>


                            </Grid>
                                    */}

                  <Grid item xs={12} sm={this.state.languageName === "Tamil" ? 12 : 6}>
                    <FormControl className="mb-5" component="fieldset">
                      <FormLabel
                        style={{ color: "#25282c" }}
                        component="legend"
                      >
                        {/* Do You Want to Share Your Personal Information? */}
                        {this.state.language.shareStory_shareInfoFieldTitle}
                      </FormLabel>
                      <RadioGroup
                        className={classes.radioButton}
                        row
                        aria-label="anonymous"
                        name="anonymous"
                        value={true}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <FormControlLabel
                          style={{ color: "#25282c" }}
                          display="inline"
                          value={false}
                          checked={
                            this.state.formdata.anonymous == false
                              ? true
                              : false
                          }
                          control={
                            <Radio

                              color="primary"
                              size="small"
                              value={false}
                            />
                          }
                          // "Yes"
                          label={
                            this.state.language.shareStory_shareInfoFieldAns1
                          }
                        />
                        <FormControlLabel
                          style={{ color: "#25282c" }}
                          display="inline"
                          value={true}
                          checked={
                            this.state.formdata.anonymous == true ? true : false
                          }
                          control={
                            <Radio color="primary" size="small" value={true} />
                          }
                          // "No"
                          label={
                            this.state.language.shareStory_shareInfoFieldAns2
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <Dialog
                  open={this.state.noteDialog == true ? true : false}
                  onClick={this.closeNoteDialog}
                >
                  <DialogContent>

                    {/* close icon */}
                    <div style={{ textAlign: "end", width: '100%', height: "0px", }}>
                      <IconButton
                        style={{ color: "#0e334b" }}
                        onClick={this.closeNoteDialog}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>

                    <Alert severity="warning">
                      <center>
                        <Typography variant="h5">
                          {/* Note */}
                          {
                            this.state.language
                              .shareStory_noteDialogMessageTitle
                          }
                        </Typography>
                      </center>
                      <p>
                        {/* We encourage you to provide as much personal information as possible, so we have a better chance of verifying the complaint/story. */}
                        {this.state.language.shareStory_noteDialogMessage}
                      </p>
                    </Alert>
                  </DialogContent>

                  <DialogActions></DialogActions>
                </Dialog>

                {this.state.formdata.anonymous == false ? (
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          // "Please Enter Name"
                          title={
                            this.state.language.shareStory_nameFieldTooltip
                          }
                          arrow
                        >
                          <TextValidator
                            className={["mb-5 w-full", classes.textStyles]}
                            // variant="outlined"
                            // "Name"
                            label={this.state.language.shareStory_nameField}
                            onChange={this.handleChange}
                            size="small"
                            name="name"
                            value={this.state.formdata.name}
                            validators={
                              !this.state.formdata.anonymous ? ["required", 'matchRegexp:^[\p{L}\u0D80-\u0DFF\u0B80-\u0BFFa-zA-Z ]+$'] : ['matchRegexp:^[\p{L}\u0D80-\u0DFF\u0B80-\u0BFFa-zA-Z ]+$']
                            }
                            errorMessages={!this.state.formdata.anonymous ? [// "Name is required",
                              this.state.language.shareStory_requiredErrorMessageFieldName, this.state.language.corruptionReport_step1NameFieldIsString
                            ] : [this.state.language.corruptionReport_step1NameFieldIsString]}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          // "Please Enter Contact Number"
                          title={
                            this.state.language.shareStory_phoneFieldTooltip
                          }
                          arrow
                        >
                          <TextValidator
                            // className="mb-5 w-full"
                            className={["mb-5 w-full", classes.textStyles]}
                            // variant="outlined"
                            //"Contact Number"
                            label={this.state.language.shareStory_phoneField}
                            onChange={this.handleChange}
                            size="small"
                            name="contact_number"
                            value={this.state.formdata.contact_number}
                            validators={[
                              "required",
                              "matchRegexp:^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$",
                            ]}
                            errorMessages={[
                              // "Contact number is required",
                              this.state.language
                                .shareStory_requiredErrorMessageFieldContactNumber,
                              // "Please enter valid Phone Number(Eg:0772345678)"
                              this.state.language
                                .shareStory_contactNumberErrorMessageField,
                            ]}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          // "Please Enter Email"
                          title={
                            this.state.language.shareStory_emailFieldTooltip
                          }
                          arrow
                        >
                          <TextValidator
                            // className="mb-5 w-full"
                            // variant="outlined"
                            className={["mb-5 w-full", classes.textStyles]}
                            // "Email"
                            label={this.state.language.shareStory_emailField}
                            onChange={this.handleChange}
                            size="small"
                            name="email"
                            value={this.state.formdata.email}
                            validators={
                              !this.state.formdata.anonymous
                                ? ["isEmail"]
                                : []
                            }
                            errorMessages={[
                              // "Enter valid email",
                              this.state.language
                                .shareStory_ValidErrorMessageFieldEmail,
                              // "Email is required"
                              /*  this.state.language
                                 .shareStory_requiredErrorMessageFieldEmail, */
                            ]}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  <div></div>
                )}
                <div >

                  <Alert severity="info" className="m-5">
                    <strong>
                      {/* Stories will be shared following review by administrator. */}
                      {this.state.language.shareStory_infoText}
                    </strong>
                  </Alert>

                  <Button
                    className={classes.btnShare}
                    variant="contained"
                    // color="secondary"
                    // size="small"
                    type="submit"
                  // /startIcon={<SaveIcon />}
                  >
                    {/* SHARE */}
                    {this.state.language.shareStory_shareButton}
                  </Button>
                </div>
              </ValidatorForm>
            </Grid>
          </Grid>
        </div>

        <Dialog
          onClose={() => {
            this.setState({ instruction: false });
          }}
          aria-labelledby="simple-dialog-title"
          open={this.state.instruction}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* Please enter further details including the reason for asking for the bribe, name and designation of the public official involved, time of the incident, etc. */}
              {this.state.language.shareStory_storyFieldDialogMessage}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ instruction: false });
              }}
              color="primary"
            >
              {/* Ok */}
              {this.state.language.shareStory_storyFieldDialogButton}
            </Button>
          </DialogActions>

        </Dialog>

        <Dialog open={this.state.referenceNoDialog}>
          <DialogTitle>
            {/* <div style={{textAlign: 'center'}}>
                                <Typography variant="h5">Thankyou for standing against corruption !</Typography>
                                <Typography variant="h6">Your Reference Number is {this.state.referenceNo}</Typography>
                            </div> */}
            <div style={{ textAlign: "center" }}>
              <Typography variant="h5">
                {/* Thank You for Standing Against Corruption ! */}
                {this.state.language.shareStory_afterShareDialogMessageTitle}
              </Typography>
              <Typography variant="h4">
                {/* Your Reference Number is */}
                {this.state.language.shareStory_afterShareDialogMessageP1}
              </Typography>
              <Typography variant="h3">
                <strong>{this.state.referenceNo}</strong>
              </Typography>
            </div>
          </DialogTitle>

          <DialogContent>
            <Grid item xs={12} sm={6}></Grid>
            <Alert severity="success">
              <strong>
                {/* Your Report Submitted Successfully!. */}
                {this.state.language.shareStory_afterShareDialogMessageP2}
              </strong>
              <p>
                {/* *If you wish to submit more supporing documents or evidence, please post them to "PPP Program Officer, Transparency Intermational Sri Lanka, 5/1, Elibank Road, Colombo 05" mentioning the reference number on the top left corner of the document. Or Fax the documents anonymously to 0112501707. */}
                {this.state.language.shareStory_afterShareDialogMessageP3}
              </p>
            </Alert>
            <div className="AlertBox">
              <Alert severity="info" style={{ margin: "0 !important" }} >
                <strong>
                  {/* Stories will be shared following review by administrator. */}
                  {this.state.language.shareStory_infoText}
                </strong>
              </Alert>
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => this.closeRefferenceDialog()}
              color="primary"
            >
              {/* Ok */}
              {this.state.language.shareStory_afterShareDialogButton}
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbar}
          autoHideDuration={2500}
          onClose={this.handleClose}
        >
          <Alert onClose={this.handleClose} severity={this.state.severity}>
            {this.state.message}
          </Alert>
        </Snackbar>
      </Fragment>
    );
  }
}

export default withStyles(styles)(ShareStoryComponent);
