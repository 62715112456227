//export const ENDPOINT = "http://127.0.0.1:8000/api/";
 export const ATTACHMENTS_LOCATION = "https://apesallitorages.blob.core.windows.net/apesallicontainers/Attachments/";
export const NEWS_ATTACHMENTS_LOCATION = "https://apesallitorages.blob.core.windows.net/apesallicontainers/Attachments/news/";

export const ENDPOINT = "https://apesalli.lk/api/";
//export const ENDPOINT = "http://172.174.204.49/api/";
//export const ATTACHMENTS_LOCATION = "https://dzpxrbig2jlom.cloudfront.net/Attachments/";
//export const NEWS_ATTACHMENTS_LOCATION = "https://dzpxrbig2jlom.cloudfront.net/Attachments/news/";

export const PROFILE_PIC_LOCATION = "https://apesallitorages.blob.core.windows.net/apesallicontainers/Attachments/";
export const VIDEO_ATTACHMENTS_LOCATION = "https://apesallitorages.blob.core.windows.net/apesallicontainers/otherattachments/";

export const HOMEPAGE_VIDEO_LOCATION_SINHALA = "https://apesallitorages.blob.core.windows.net/apesallicontainers/homepageVideo/Apesalli_Sinhala Video_New.mp4";
export const HOMEPAGE_VIDEO_LOCATION_TAMIL = "https://apesallitorages.blob.core.windows.net/apesallicontainers/homepageVideo/Ape_salli_Tamil_video_New.mp4";
export const HOMEPAGE_VIDEO_LOCATION_ENGLISH = "https://apesallitorages.blob.core.windows.net/apesallicontainers/homepageVideo/Apesalli_English_New.mp4";

export const FONT = "Cambria W02 Regular";//"Hind Guntur, sans-serif"

export const GENERATE_REPORT = ENDPOINT + "reports/all";

export const LOGIN = ENDPOINT + "users/login";
export const ALL_USERS = ENDPOINT + "users/all";
export const CREATE_USER = ENDPOINT + "users/create";
export const USER_SET_PASSWORD = ENDPOINT + "email/verify/";
export const USER_RESET_PASSWORD = ENDPOINT + "users/forgotpassword";
export const MY_PROFILE = ENDPOINT + "users/myDetails";
export const GET_SINGLE_USER = ENDPOINT + "user/single/";
export const EDIT_USER = ENDPOINT + "users/edit/";
export const UPLOAD_PROFILE_PICTURE = ENDPOINT + "users/upload/profilepic";
export const USER_ACTIVE_STATUS_CHANGE = ENDPOINT + "users/active/";

export const UPLOAD_FILES = ENDPOINT + "incidents/uploads";
export const UPLOAD_NEW_FILES = ENDPOINT + "incidents/addNewFiles";
export const DELETE_FILES = ENDPOINT + "incidents/file/delete";

export const CREATE_INCIDENT = ENDPOINT + "incidents/create";
export const ALL_CORRUPTIONS = ENDPOINT + "statistics/allcorruptions";
export const ALL_CORRUPTIONS_FOR_MAP = ENDPOINT + "map/allincidents";
export const All_INCIDENT_WITH_FILTERS = ENDPOINT + "incidents/withfilters";
export const All_INCIDENT_WITH_FILTERS_PUBLIC = ENDPOINT + "incidents/published";
export const GET_SINGLE_INCIDENT = ENDPOINT + "incidents/single/";
export const UPDATE_INCIDENT_STATE = ENDPOINT + "incidents/changeStatus/";
export const UPDATE_INCIDENT_PUBLISH = ENDPOINT + "incidents/changePublish/";
export const Edit_INCIDENT = ENDPOINT + "incidents/edit/";

export const ALL_STATISTICS = ENDPOINT + "statistics/allcounts";
export const ALL_STATISTICS_BY_YEARLY = ENDPOINT + "allincidents/yearly";
export const ALL_STATISTICS_BY_GENDER = ENDPOINT + "statistics/count/genderwise";
export const ALL_STATISTICS_BY_AGE = ENDPOINT + "statistics/count/agewise";
export const ALL_STATISTICS_BY_ORGANIZATION = ENDPOINT + "statistics/count/organizationwise";
export const ALL_STATISTICS_BY_DISTRICT = ENDPOINT + "statistics/count/districtwise";

export const CREATE_NEWS = ENDPOINT + "news/create";
export const GET_NEWS_WITH_FILTERS = ENDPOINT + "news/withfilters";
export const GET_SINGLE_NEWS = ENDPOINT + "news/single/";
export const UPLOAD_NEWS_FILES = ENDPOINT + "news/uploads";
export const UPDATE_NEWS_STATE = ENDPOINT + "news/changeStatus/";
export const UPDATE_NEWS_PUBLISH = ENDPOINT + "news/changePublish/";

export const EDIT_NEWS = ENDPOINT + "news/edit/";
export const UPLOAD_NEWS_NEW_FILES = ENDPOINT + "news/addNewFiles";
export const DELETE_NEWS_FILES = ENDPOINT + "news/file/delete";

export const UPLOAD_OTHER_FILES = ENDPOINT + "incidents/uploads/otherFiles";
export const UPLOAD_NEWS_OTHER_FILES = ENDPOINT + "incidents/addnew/otherFiles";
export const DELETE_OTHER_FILES = ENDPOINT + "incidents/delete/otherFiles";

export const GET_ELECTION_STATUS = ENDPOINT + "elections/status";
export const CHANGE_ELECTION_STATUS = ENDPOINT + "elections/changeStatus";

export const GET_LAUNCH_STATUS = ENDPOINT + "launch/status";
// export const EDIT_NEWS=ENDPOINT+"news/edit/";