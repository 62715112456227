import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  Link,
  Grid,
  SwipeableDrawer,
  Avatar,
  Icon,
  IconButton,
  MenuItem,
  Button,
  GridList,
  GridListTile,
  GridListTileBar,
  Divider,
  ListItemText,
} from "@material-ui/core";
import { SimpleCard, MatxProgressBar } from "matx";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import Footer from "./components/Footer";
import AppAppBar from "./components/AppAppBar";

import { withStyles } from "@material-ui/core/styles";

import { englishLanguage } from "..//languages/englishLanguage";
import { sinhalaLanguage } from "../languages/sinhalaLanguage";
import { tamilLanguage } from "../languages/tamilLanguage";
import languageService from "app/services/languageService";

const styleSheet = (theme) => ({
  gridList: {
    height: 275,
  },

  mainPadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 112,
      paddingRight: 120,
      paddingTop: 40,
      paddingBottom: 50,
    },
    [theme.breakpoints.down("md")]: {
      padding: 20,
      paddingTop: 30,
      paddingBottom: 30,
    },
  },

  mainMargin: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 100,
      marginRight: 100,
      marginTop: -50
    },
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  newsImage: {
    width: 90,
    height: 60,
    paddingRight: theme.spacing(1),
  },
  newsHrLine: {
    backgroundColor: "#0074e1",
    height: 3,
  },
  listItem: {
    borderBottomColor: "#dbdcdc",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
  pageTitlePadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 116,
      paddingTop: 60,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 30,
      paddingTop: 60,
    },
  },
});

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: englishLanguage,
    };
  }

  async loadLanguageDetails() {
    var lng = await languageService.getLanguageDetails();

    if (lng == "Sinhala") {
      this.setState({
        language: sinhalaLanguage,
      });
    } else if (lng == "Tamil") {
      this.setState({
        language: tamilLanguage,
      });
    }
    // console.log("FAQ Page:", this.state);
  }

  componentDidMount() {
    this.loadLanguageDetails();
  }

  render() {
    const { classes } = this.props;
    const preventDefault = (event) => event.preventDefault();
    return (
      <Fragment>
        {/* App bar component */}
        <AppAppBar />

        <div
          className={classes.pageTitlePadding}
          style={{
            backgroundImage: `url(/assets/images/statBackground.png)`,
            width: "100%",
            height: 160,
            backgroundSize: "cover",
            display: "flex",
            //justifyContent: "center",
            float: "left",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            style={{ fontSize: 34, color: "white", fontWeight: 600 }}
          >
            {/* About Us */}
            {this.state.language.aboutUs_Title}
          </Typography>
        </div>

        <Fade in={true}>
          <div className={classes.paragraph}>
            <Grid
              container
              spacing={1}
              className={classes.mainPadding}
              style={{ backgroundColor: "white" }}
            >
              <div style={{ backgroundColor: "white" }} className={[classes.mainPadding, classes.mainMargin]}>
                <Typography
                  variant="p"
                  component="p"
                  gutterBottom
                  align="justify"
                  style={{ fontWeight: 500, lineHeight: 2 }}
                >
                  {/* Deep rooted and pervasive corruption, in the political, public, private and 
                                civil society sectors has been a persistent obstacle to the development of Sri Lanka. */}
                  {this.state.language.aboutUs_description1}

                  {/* <strong> */}
                  {/* Over the years there have been many members of the public who have taken 
                                    a stand against corruption and bribery or simply borne its burden in silence. 
                                    Apesalli.lk was created with the objective of giving the public an opportunity to 
                                    report and share their stories on incidents of corruption and bribery. By shedding a
                                     light on these illicit activities, Apesalli.lk aims to create a broader discourse on
                                      corruption and bribery, capture the popular narrative on the issue, as well as highlight
                                       those who wish to take a stand against such acts. */}
                  {this.state.language.aboutUs_description2}
                  {/* </strong> */}
                </Typography>

                <br />
                <Typography
                  variant="p"
                  component="p"
                  align="justify"
                  gutterBottom
                  style={{ fontWeight: 500, lineHeight: 2 }}
                >
                  {/* Apesalli.lk is powered by TISL’s mandate on anti-corruption. 
                                Transparency International Sri Lanka (TISL) is the local chapter of the global 
                                movement against corruption, committed to promoting good governance and eradicating 
                                corruption in Sri Lanka. TISL’s mission is to support the collective effort to eradicate 
                                corruption in order to build a future Sri Lanka which is equitable, peaceful, and just. */}
                  {this.state.language.aboutUs_description3}
                </Typography>
              </div>
            </Grid>
          </div>
        </Fade>
        {/* Footer */}
        <Footer />
      </Fragment>
    );
  }
}

export default withStyles(styleSheet)(AboutUs);
