import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {
    AppBar, Toolbar, Typography, List, ListItem, Link,
    Grid, SwipeableDrawer, Avatar, Icon, IconButton, MenuItem, Button,
    GridList, GridListTile, GridListTileBar, Divider, ListItemText
} from '@material-ui/core';
import { SimpleCard, MatxProgressBar } from "matx";
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Footer from './components/Footer';
import AppAppBar from './components/AppAppBar';
import StatisticsSection from './components/StatisticsSection';
import BarChat from './components/Charts/BarChat';
import AllIncidentsChart from './components/Charts/AllIncidentsChart';
import GenderWise from './components/Charts/GenderWise';
import OrganizationWise from './components/Charts/OrganizationWise';
import AgeWise from './components/Charts/AgeWise';
import DistrictWise from './components/Charts/DistrictWise';

import { withStyles } from "@material-ui/core/styles";

const styleSheet = ((theme) => ({
    gridList: {
        height: 275,
    },

    mainPadding: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 50,
            paddingRight: 50,
            paddingTop: 50,
            paddingBottom: 50

        },
        [theme.breakpoints.down('md')]: {
            padding: 20,
            paddingTop: 30,
            paddingBottom: 30

        },
    },
    card: {

        [theme.breakpoints.up('md')]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
    },
    newsImage: {
        width: 90,
        height: 60,
        paddingRight: theme.spacing(1),
    },
    newsHrLine: {
        backgroundColor: '#0074e1',
        height: 3,
    },
    listItem: {
        borderBottomColor: "#dbdcdc",
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
    },
    // chartStyle:{
    //     padding:10,
    //     // borderRadius:10, 
    //     boxShadow:" 0 0px 0px 0px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)"
    // }
}));


class Statistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }




    render() {
        const { classes } = this.props
        const preventDefault = (event) => event.preventDefault();
        return (
            <Fragment>
                {/* App bar component */}
                <AppAppBar />


                <Fade in={true}>
                    <div >
                        <StatisticsSection></StatisticsSection>
                        <Grid className={classes.mainPadding} justifyContent="center" container style={{ backgroundColor: "white", }} >
                            <Grid item xs={12} >
                                <BarChat />
                            </Grid>
                        </Grid>
                        <Divider varient="middle"></Divider>


                        <Grid className={classes.mainPadding} justifyContent="space-evenly" container style={{ backgroundColor: "white" }}>
                            <Grid item xs={12} md={6} className={classes.chartStyle} >
                                <AllIncidentsChart />
                            </Grid>

                            <Grid item xs={12} md={6} className={classes.chartStyle}>
                                <OrganizationWise />
                            </Grid>


                        </Grid>
                        <Divider varient="middle"></Divider>

                        <Grid justifyContent="space-evenly" className={classes.mainPadding} container style={{ backgroundColor: "white" }} >
                            <Grid item xs={12} md={6} className={classes.chartStyle}>
                                <DistrictWise />
                            </Grid>

                            <Grid className={classes.chartStyle} item xs={12} md={6} >
                                <GenderWise />
                            </Grid>

                        </Grid>

                        <Divider varient="middle"></Divider>

                        <Grid className={classes.mainPadding} container style={{ backgroundColor: "white" }} >

                            <Grid className={classes.chartStyle} item xs={12} md={6} >
                                <AgeWise />
                            </Grid>

                        </Grid>



                    </div>


                </Fade>
                {/* Footer */}
                <Footer />
            </Fragment>
        );
    }
}





export default withStyles(styleSheet)(Statistics);